<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | RAYHANE` : `SITE_NAME`
      }}</template>
    </metainfo>
    <div class="my-6 mt-24 w-full">
      <div class="flex justify-between items-center mb-4">
        <div>
          <h1 class="text-3xl text-black font-semibold">
            Offers & Promo codes
          </h1>
          <h1 class="text-xl text-gray font-semibold mt-3">Manage offers</h1>
        </div>
        <div :class="!$route.path.includes('promo') ? 'block' : 'hidden'">
          <router-link to="/admin/offers/new">
            <button class="btn bg-cyan font-bold">
              <span class="text-xl">+ </span> New Offer
            </button></router-link
          >
        </div>
        <div :class="$route.path.includes('promo') ? 'block' : 'hidden'">
          <router-link to="/admin/offers/promo/new">
            <button class="btn bg-cyan font-bold">
              <span class="text-xl">+ </span> New Promo code
            </button></router-link
          >
        </div>
      </div>
      <div class="flex items-center">
        <router-link to="/admin/offers">
          <div
            :class="
              $route.fullPath == '/admin/offers'
                ? 'text-black border-b-4 border-black'
                : 'text-gray'
            "
            class="font-semibold text-md mr-3 flex items-center cursor-pointer"
          >
            Offers
          </div></router-link
        >
        <router-link to="/admin/offers/promo">
          <div
            :class="
              $route.fullPath == '/admin/offers/promo'
                ? 'text-black border-b-4 border-black'
                : 'text-gray'
            "
            class="font-semibold text-md mr-3 flex items-center cursor-pointer"
          >
            Promo codes
          </div></router-link
        >
      </div>
      <PromoCodesTable />
    </div>
  </div>
</template>
<script>
import PromoCodesTable from "@/components/tables/PromoCodesTable.vue";
import { useOffersStore } from "@/store/offers";
import { useMeta } from "vue-meta";

export default {
  setup() {
    useMeta({
      title: "Offers & Promo codes",
      htmlAttrs: { lang: "en", amp: true },
    });
    const offersStore = useOffersStore();
    return {
      offersStore,
    };
  },
  components: { PromoCodesTable },
};
</script>
<style></style>
