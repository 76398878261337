<template>
  <div>
    <div class="flex flex-col mt-4">
      <div class="my-6 mt-24 w-full"><SingleTeacherReviewsTable /></div>
    </div>
  </div>
</template>
<script>
import SingleTeacherReviewsTable from "@/components/tables/SingleTeacherReviewsTable.vue";

export default {
  components: { SingleTeacherReviewsTable },
  setup() {
    return {};
  },
  data() {
    return {};
  },
};
</script>
