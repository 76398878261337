<template>
  <div class="user-actions w-10 mx-auto">
    <a
      class="text-blueGray-500 block"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <div class="items-center flex justify-center">
        <img :src="actions" alt="actions" />
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-xl border border-[#e3e3e3] min-w-[140px]"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <router-link
        class="text-sm py-2 px-4 font-normal flex items-center w-full hover:bg-slate-200"
        :to="'/admin/teachers/' + teacher.id"
      >
        <img :src="editIcon" class="mr-2" alt="editIcon" />

        Edit</router-link
      >
      <a
        v-if="teacher.status == 'active'"
        href="javascript:void(0);"
        @click="blockTeacher"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="block" class="mr-2" alt="block" />

        Block
      </a>

      <router-link
        v-if="authStore.user?.permissions.includes('manage_payments')"
        class="text-sm py-2 px-4 font-normal flex items-center w-full hover:bg-slate-200"
        :to="'/admin/teachers/balance/' + teacher.id"
      >
        <img :src="paymentIcon" class="mr-2" alt="block" />
        Make a payment</router-link
      >
      <a
        v-if="teacher.status == 'inactive'"
        href="javascript:void(0);"
        @click="unBlockTeacher"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="block" class="mr-2" alt="block" />

        Unblock
      </a>

      <router-link
        class="text-sm py-2 px-4 font-normal flex items-center w-full hover:bg-slate-200"
        :to="'/admin/teachers/' + teacher.id + '/courses'"
      >
        <img :src="coursesIcon" class="mr-2" alt="block" />
        Show courses</router-link
      >
      <router-link
        class="text-sm py-2 px-4 font-normal flex items-center w-full hover:bg-slate-200"
        :to="'/admin/teachers/' + teacher.id + '/reviews'"
      >
        <img :src="reviewsIcon" class="mr-2" alt="block" />
        Show reviews</router-link
      >
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import actions from "@/assets/icons/cards/threeDots.svg";
import editIcon from "@/assets/icons/cards/editIcon.svg";
import reviewsIcon from "@/assets/icons/cards/reviewsIcon.svg";
import deleteIcon from "@/assets/icons/cards/deleteIcon.svg";
import paymentIcon from "@/assets/icons/cards/paymentIcon.svg";
import coursesIcon from "@/assets/icons/cards/coursesIcon.svg";
import block from "@/assets/icons/cards/block.svg";
import { useTeachersStore } from "@/store/teachers";
import { useAuthStore } from "@/store/auth";
export default {
  props: ["teacher"],
  data() {
    return {
      dropdownPopoverShow: false,
      showBlockModal: false,
      showUnBlockModal: false,
    };
  },
  setup() {
    const teachersStore = useTeachersStore();
    const authStore = useAuthStore();

    return {
      coursesIcon,
      block,
      reviewsIcon,
      teachersStore,
      actions,
      editIcon,
      deleteIcon,
      paymentIcon,
      authStore,
    };
  },
  methods: {
    blockTeacher() {
      this.teachersStore.selectedTeacher = this.teacher;
      this.teachersStore.isBlockModal = true;

      // this to close popper
      const dropdowns = document.querySelectorAll(".user-actions");
      dropdowns.forEach(function (dropdown) {
        dropdowns.forEach(() => {
          dropdown.children[1].classList.add("hidden");
        });
      });
    },

    unBlockTeacher() {
      this.teachersStore.selectedTeacher = this.teacher;
      this.teachersStore.isUnblockModal = true;

      // this to close popper
      const dropdowns = document.querySelectorAll(".user-actions");
      dropdowns.forEach(function (dropdown) {
        dropdowns.forEach(() => {
          dropdown.children[1].classList.add("hidden");
        });
      });
    },

    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        const dropdowns = document.querySelectorAll(".user-actions");
        // Add click event listener to each dropdown component
        dropdowns.forEach(function (dropdown) {
          dropdowns.forEach(() => {
            dropdown.children[1].classList.add("hidden");
          });
        });
        this.$refs.popoverDropdownRef.classList.remove("hidden");

        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-end",
        });
      }
    },
  },
};
</script>
