<template>
  <div class="w-full mb-4">
    <LoaderModal v-if="categoriesStore.isLoadingModal" />
    <div class="my-2">
      <h1 class="text-3xl text-black font-semibold">Courses</h1>
      <h1 class="text-md text-gray font-semibold">Manage your categories</h1>
    </div>
    <div
      class="bg-purple text-white font-bold text-3xl p-5 rounded-t-xl border-[#c9c9c9] border-b-0 border"
    >
      New category
    </div>
    <form
      @submit.prevent="addCategory"
      class="w-full border-[#c9c9c9] border border-t-0 p-4 rounded-xl rounded-t-none"
    >
      <div><p class="text-purple mb-1 text-left">Cover</p></div>

      <div class="py-4 text-xl border-[#CACACA] rounded-md">
        <label class="cursor-pointer w-fit flex items-center" for="inputTag">
          <div
            v-if="!cover"
            class="flex flex-col upload-block justify-center items-center p-2 h-40 w-60 rounded-md border-dashed"
          >
            <p class="text-[#7C7C7C] mb-1 text-center text-base mt-2">
              Click here to select a file
            </p>

            <input
              class="hidden"
              hidden
              id="inputTag"
              @change="handleCoverChange"
              type="file"
              accept="image/*"
            />
          </div>

          <img v-show="cover" class="h-40 w-60" ref="output" />
          <img
            @click="removeImage"
            :src="trash"
            class="ml-2"
            v-if="cover"
            width="40"
          />
        </label>
      </div>
      <p class="text-red-500" v-if="v$.cover.$error">Cover is required</p>
      <div class="md:flex">
        <div class="md:mr-6 my-3 md:w-6/12">
          <p class="text-purple mb-1 text-left">Category name</p>
          <input
            v-model="name"
            type="text"
            class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
          />
          <p class="text-red-500" v-if="v$.name.$error">
            Category name must be at least 3 characters
          </p>
        </div>

        <!-- // until destination is back -->
        <div class="md:w-6/12 md:mr-6 my-3" v-if="false">
          <p class="text-purple mb-1 text-left">Destination</p>

          <SelectDestinationsDropdown />
          <p class="text-red-500" v-if="destinationError">
            destination is required
          </p>
        </div>
      </div>
      <div class="flex justify-start my-4">
        <button class="btn btn bg-cyan font-bold" type="submit">Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import SelectDestinationsDropdown from "../partials/SelectDestinationsDropdown.vue";
import { useDestinationsStore } from "@/store/destinations";
import { useCategoriesStore } from "@/store/categories";
import LoaderModal from "@/components/modals/LoaderModal.vue";

import trash from "@/assets/icons/cards/trash.svg";
export default {
  data() {
    return {
      name: "",
      cover: null,
      isImageUploaded: false,
      destinationError: false,
    };
  },
  setup() {
    const destinationsStore = useDestinationsStore();
    const categoriesStore = useCategoriesStore();

    return { v$: useVuelidate(), trash, destinationsStore, categoriesStore };
  },
  methods: {
    async addCategory() {
      console.log(this.destinationsStore.selectedDestination);
      const result = this.v$.$validate();
      if (!result || this.destinationError) {
        return;
      } else {
        const formData = new FormData();
        formData.append("name", this.name);
        formData.append("image", this.cover);
        formData.append(
          "destination",
          this.destinationsStore.selectedDestination
        );
        await this.categoriesStore.addCategory(formData);
        if (this.categoriesStore.isSuccess) {
          this.$swal({
            position: "center",
            icon: "success",
            title: "category saved successfully",
            showConfirmButton: false,
            timer: 1000,
          }).then(() => {
            this.$router.push("/admin/courses/categories");
          });
        } else if (this.categoriesStore.isFail) {
          this.$swal({
            icon: "danger",
            title: "Error accured while adding the category !",
            html: `<ul>
            ${this.categoriesStore.errorMessages.map((error) => {
              return error.map((message) => {
                return `<li class="text-red-500">
                      ${message}
                    </li>`;
              });
            })}
          </ul>`,
            willClose: async () => {
              this.categoriesStore.isFail = false;
              this.categoriesStore.isSuccess = false;
              this.categoriesStore.errorMessages = [];
            },
          });
        }
      }
    },
    handleCoverChange(event) {
      this.cover = event.target.files[0];
      let outputPic = this.$refs.output;
      outputPic.src = URL.createObjectURL(this.cover);
      outputPic.width = 200;
    },
    removeImage() {
      this.$refs.output.src = null;
      this.cover = null;
    },
  },
  validations() {
    return {
      name: { required, minLength: minLength(3) },
      cover: { required },

      // until destination is back
      // destination: {
      //   custom: () => this.destinationsStore.selectedDestination !== null,
      // },
    };
  },
  components: { SelectDestinationsDropdown, LoaderModal },
};
</script>

<style scoped>
.upload-block {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A0A0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
