<template>
  <div></div>
</template>
<script>
import { useAffiliationsStore } from "../../../store/affiliations";
export default {
  setup() {
    const affiliationStore = useAffiliationsStore();
    const { isAddAffiliationFail, isAddAffiliationSuccess } =
      useAffiliationsStore();
    return {
      affiliationStore,
      isAddAffiliationFail,
      isAddAffiliationSuccess,
    };
  },
  mounted() {
    if (this.affiliationStore.isAddAffiliationSuccess) {
      this.$swal({
        icon: "success",
        title: "Influencer added successfuly",
        timer: 1500,
        willClose: () => {
          this.affiliationStore.isAddAffiliationFail = false;
          this.affiliationStore.isAddAffiliationSuccess = false;
          this.$router.push("/admin/affiliation");
        },
      });

    }
  },
};
</script>
