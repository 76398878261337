<template>
  <div
    class="mx-auto bg-white rounded-xl shadow-lg overflow-visible text-center cursor-pointer relative transform transition duration-500 hover:shadow-2xl hover:scale-110 md:min-w-[200px] md:max-w-[300px]"
  >
    <SettingsDropdown :moderator="moderator" class="absolute top-1 right-4" />
    <div class="">
      <div class="md:flex-shrink-0">
        <img
          class="h-36 w-full object-cover md:w-36 mx-auto rounded-2xl"
          :src="profilePic"
          alt="Profile Picture"
        />
      </div>
      <div class="px-8 py-4">
        <p class="mt-2 text-black text-2xl font-semibold my-2">
          {{ first_name + " " + last_name }}
        </p>
        <div class="tracking-wide text-sm text-[#A0AEC0] font-semibold">
          {{ title }}
        </div>

        <div class="mt-4">
          <div class="mt-2 flex items-center font-semibold text-gray-500">
            <img :src="iconPhone" class="mr-2" alt="iconPhone" />
            <span>{{ phone }}</span>
          </div>
          <div class="mt-2 flex items-center font-semibold text-gray-500">
            <img :src="iconMail" class="mr-2" alt="iconMail" />
            <span>{{ email }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iconMail from "@/assets/icons/cards/iconMail.svg";
import iconPhone from "@/assets/icons/cards/iconPhone.svg";
import dropdown from "@/assets/icons/cards/dropdown.svg";
import SettingsDropdown from "@/components/dropdowns/SettingsDropdown.vue";
export default {
  props: {
    first_name: String,
    last_name: String,
    title: String,
    phone: String,
    email: String,
    profilePic: String,
    moderator: Object,
  },
  setup() {
    return {
      iconPhone,
      iconMail,
      dropdown,
    };
  },
  components: { SettingsDropdown },
};
</script>

<style>
/* Add any custom styles here */
</style>
