import { defineStore } from "pinia";
import axios from "@/axios/axios";
import { useAuthStore } from "./auth";

export const useAdsStore = defineStore("adStore", {
  state: () => ({
    ads: [],
    lastestAd: [],
    adsCount: 0,
    itemsPerPage: 10,
    totalPages: 0,
    to: 0,
    from: 0,
    currentPage: 1,
    errorsMessages: [],
    deletedAd: null,
    selectedDestination: null,
    isLoadingModal: false,
    isFailModal: false,
    pauseError: false,
    isFail: false,
    isSuccess: false,
    isShowEditor: false,
    errorMessage: "",
    showDeleteModal: false,
  }),
  actions: {
    async addAd(ad) {
      this.errorMessage = null;
      this.isFail = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const res = await axios.post(
          `${process.env.VUE_APP_API_BASE}ad/admin/store`,
          ad,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        this.getAds();
        console.log(res);
      } catch (err) {
        if (err.response.status == 422) {
          Object.values(err.response.data.errors).forEach(
            (val) => (this.errorsMessages = this.errorsMessages + "<br>" + val)
          );
        } else {
          this.errorsMessages.push("something went wrong");
        }
        this.isFail = true;
        this.isLoadingModal = false;
        console.log(err);
      }
    },

    async getAds(query) {
      this.errorMessage = null;
      const authStore = useAuthStore();

      try {
        if (!query) {
          this.isLoadingModal = true;
        } else {
          authStore.isLoading = true;
        }
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}ad/admin/get?q=${
            query ? `&q=${encodeURIComponent(query)}` : ""
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.ads = data.data;
        this.isLoadingModal = false;
        authStore.isLoading = false;
      } catch (err) {
        this.isLoadingModal = false;
        authStore.isLoading = false;
        this.errorMessage = err.response.data.errors;
        console.log(err);
      }
    },

    async deleteAd(adId) {
      this.errorMessage = null;
      this.isFail = false;
      this.isSuccess = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}ad/admin/delete/${adId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isSuccess = true;
        this.isLoadingModal = false;
        await this.getAds();
      } catch (err) {
        console.log(err);
        this.isFail = true;
        this.isLoadingModal = false;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getAd();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getAd();
      }
    },
  },
});
