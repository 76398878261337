<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <LoaderModal v-if="landingPageStore.isLoadingModal" />
    <div class="my-6 mt-24 w-full">
      <div class="w-full">
        <div class="my-2">
          <h1 class="text-3xl text-black font-semibold">Manage reviews</h1>
          <h1 class="text-md text-gray font-semibold mt-2">
            Manage your reviews
          </h1>
        </div>
        <div
          class="bg-purple text-white font-bold text-3xl p-5 rounded-t-xl border-[#c9c9c9] border-b-0 border"
        >
          New review
        </div>
        <form class="w-full border-[#c9c9c9] border border-t-0 p-4 rounded-xl">
          <div>
            <p class="text-purple mb-1 text-left">Client’s image</p>
          </div>
          <div class="my-2 mb-4 text-xl rounded-md">
            <label
              class="cursor-pointer w-fit flex items-center"
              for="inputTag"
            >
              <div
                v-if="!cover"
                :class="coverError ? 'border-red-500' : 'border-slate-300'"
                class="flex flex-col justify-center items-center p-2 h-40 w-60 rounded-md border-dashed border-[3px]"
              >
                <p
                  :class="coverError ? 'text-red-500' : 'text-[#8a8a8ac9]'"
                  class="mb-1 text-center text-base mt-2"
                >
                  Click here to select file
                </p>
                <input
                  class="hidden"
                  hidden
                  id="inputTag"
                  @change="handleCoverChange"
                  type="file"
                  accept="image/*"
                />
              </div>
              <img v-show="cover" class="h-40 w-60" ref="output" />
              <img
                @click="removeImage"
                :src="trash"
                class="ml-2"
                v-if="cover"
                width="40"
              />
            </label>
            <div v-if="coverError" class="text-red-500 text-base">
              <p>Client’s image is required</p>
            </div>
          </div>
          <div class="md:flex">
            <div class="md:mr-6 my-3 md:w-6/12">
              <p class="text-purple mb-1 text-left">Client's name</p>
              <input
                v-model="client_name"
                :class="
                  v$.client_name?.$pending
                    ? 'border-[#c9c9c9] border'
                    : v$.client_name?.$error
                    ? 'border-red-500 border-[3px]'
                    : 'border-[#c9c9c9] border'
                "
                type="text"
                class="text-gray-700 px-2 leading-tight focus:outline-none rounded-md w-full py-3"
              />
              <p class="text-red-500" v-if="v$.client_name?.$error">
                You should add a client's name
              </p>
            </div>
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Number of stars</p>
              <input
                v-model="stars_number"
                :class="
                  v$.stars_number?.$pending
                    ? 'border-[#c9c9c9] border'
                    : v$.stars_number?.$error
                    ? 'border-red-500 border-[3px]'
                    : 'border-[#c9c9c9] border'
                "
                type="number"
                min="1"
                max="4"
                class="text-gray-700 px-2 leading-tight focus:outline-none rounded-md w-full py-3"
              />
              <p class="text-red-500" v-if="v$.stars_number?.$error">
                The number of stars should be between 1 and 4
              </p>
            </div>
          </div>
          <div class="md:mr-6 my-3">
            <p class="text-purple mb-1 text-left">Comment</p>
            <input
              v-model="comment"
              :class="
                v$.comment?.$pending
                  ? 'border-[#c9c9c9] border'
                  : v$.comment?.$error
                  ? 'border-red-500 border-[3px]'
                  : 'border-[#c9c9c9] border'
              "
              type="text"
              class="text-gray-700 px-2 leading-tight focus:outline-none rounded-md w-full py-3"
            />
            <p class="text-red-500" v-if="v$.comment?.$error">
              You should add a comment
            </p>
          </div>
        </form>
        <div class="flex justify-start my-4">
          <button class="btn btn bg-cyan font-bold" @click="save">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, between } from "@vuelidate/validators";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import { useLandingPageStore } from "@/store/landingPage";
import router from "@/router";
export default {
  data() {
    return {
      client_name: "",
      last_name: "",
      stars_number: "",
      comment: "",
      cover: null,
      coverError: false,
    };
  },
  setup() {
    const landingPageStore = useLandingPageStore();
    return { v$: useVuelidate(), landingPageStore };
  },
  methods: {
    async save() {
      this.coverError = false;
      const result = await this.v$.$validate();
      if (!this.cover) {
        this.coverError = true;
      }
      if (!result || this.coverError) {
        return;
      } else {
        const formData = new FormData();

        formData.append("full_name", this.client_name);
        formData.append("comment", this.comment);
        formData.append("image", this.cover);
        formData.append("stars", this.stars_number);
        await this.landingPageStore.addLandingPageReview(formData);
        if (this.landingPageStore.isSuccess) {
          this.$swal({
            icon: "success",
            timer: 1500,
            title: "Success...",
            showConfirmButton: false,
            html:
              "<p class='text-red-500' >" +
              this.landingPageStore.errorsMessages +
              "<p/>",
          }).then(() => {
            router.push("/admin/landingPage/reviews");
          });
        } else if (this.landingPageStore.isFail) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            showConfirmButton: true,
            timer: 4000,
            html:
              "<p class='text-red-500' >" +
              this.landingPageStore.errorsMessages +
              "<p/>",
          });
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
        this.landingPageStore.isSuccess = false;
        this.landingPageStore.isFail = false;
        this.landingPageStore.errorsMessages = "";
      }
    },
    removeImage() {
      this.$refs.output.src = null;
      this.cover = null;
    },
    handleCoverChange(event) {
      this.coverError = false;
      this.cover = event.target.files[0];

      let outputPic = this.$refs.output;
      outputPic.src = URL.createObjectURL(this.cover);
      outputPic.width = 200;
    },
  },
  validations() {
    return {
      client_name: { required },
      stars_number: { required, between: between(1, 4) },
      comment: { required },
    };
  },
  components: { LoaderModal },
};
</script>

<style scoped>
.upload-block {
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A0A0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
