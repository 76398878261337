import axios from "axios";
// Create a new Axios instance with defaults
const instance = axios.create();

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    if (response.status === 403 || response.status === 401) {
      return Promise.reject(response); // Reject the promise to propagate the error
    } else {
      return response;
    }
  },
  (error) => {
    console.log(error);
    switch (error.response.status) {
      case 404:
        console.error("Not found");
        break;
      case 422:
        console.error("bad request");
        break;
      case 500:
        console.error("server error");
        break;
      case 400:
        console.error("server error");
        break;
      default:
        console.log("Forbidden or unauthorized access detected");
        console.log(error);
        window.location.href = "/auth/login";
        localStorage.removeItem("rayhane-admin");
        break;
    }
    return Promise.reject(error); // Reject the promise to propagate the error
  }
);

export default instance;
