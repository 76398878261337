import { defineStore } from "pinia";
import axios from "@/axios/axios";
import router from "@/router";
import { useAuthStore } from "./auth";

export const useUsersStore = defineStore("usersStore", {
  state: () => ({
    users: [],
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    from: 0,
    to: 0,
    isDeleteModal: false,
    isBlockModal: false,
    isUnblockModal: false,
    isLoadingModal: false,
    isSuccess: false,
    isFail: false,
    isShowEditor: false,
    isAddUserSuccess: false,
    isAddUserFail: false,
    isEditUserFail: false,
    isEditUserSuccess: false,
    errorMessages: [],
    editedUser: null,
    selectedUser: null,
    selectedPlan: null,
    selectedNationality: null,
    selectedSport: null,
    selectedDate: null,
    singleUser: null,

    ///
    students: [],
  }),
  actions: {
    async addUser(student) {
      try {
        this.isLoadingModal = true;
        this.isAddUserSuccess = false;
        this.isAddUserFail = false;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}student/admin/store`,
          student,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        this.isAddUserSuccess = true;
        this.isLoadingModal = false;
      } catch (err) {
        this.isAddUserFail = true;
        this.isLoadingModal = false;
        console.log(err);
        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
      }
    },

    async updateUser(student, studentId) {
      try {
        this.isLoadingModal = true;
        this.isEditUserSuccess = false;
        this.isEditUserFail = false;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}student/admin/update/${studentId}`,
          student,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        this.isEditUserSuccess = true;
        this.isLoadingModal = false;
      } catch (err) {
        this.isEditUserFail = true;
        this.isLoadingModal = false;
        console.log(err);
        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
      }
    },

    async getUsers(query) {
      const authStore = useAuthStore();

      try {
        if (!query) {
          this.isLoadingModal = true;
        } else {
          authStore.isLoading = true;
        }
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${
            process.env.VUE_APP_API_BASE
          }student/admin/get?paginate=true&page=${this.currentPage}${
            query ? `&q=${encodeURIComponent(query)}` : ""
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        this.users = data.data.data;
        this.totalPages = data.data.total;
        this.from = data.data.from;
        this.to = data.data.to;
        this.currentPage = data.data.current_page;
        this.isLoadingModal = false;
        authStore.isLoading = false;
      } catch (err) {
        authStore.isLoading = false;
        this.isLoadingModal = false;
        console.log(err);
      }
    },

    async deleteUser(studentId) {
      this.isFail = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}student/admin/delete/${studentId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        await this.getUsers();
      } catch (err) {
        this.isLoadingModal = false;
        this.isFail = true;
        console.log(err);
      }
    },

    async blockUser(studentId) {
      this.isFail = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}student/admin/block/${studentId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        await this.getUsers();
      } catch (err) {
        this.isLoadingModal = false;
        this.isFail = true;
        console.log(err);
      }
    },

    async unBlockUser(studentId) {
      this.isLoadingModal = true;
      this.isFail = false;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}student/admin/unblock/${studentId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        await this.getUsers();
      } catch (err) {
        this.isLoadingModal = false;
        this.isFail = true;
        console.log(err);
      }
    },

    async getOneUser(userId) {
      this.isLoadingModal = true;
      this.isFail = false;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}student/admin/get/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.singleUser = data.data;
        return data;
      } catch (err) {
        this.isLoadingModal = false;
        router.push("/admin/joueurs");
        console.log(err);
      }
    },

    async getStudents() {
      this.isLoadingModal = true;
      this.isFail = false;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}student/admin/get`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.students = data.data;

        return data;
      } catch (err) {
        this.isLoadingModal = false;
        router.push("/admin/joueurs");
        console.log(err);
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getUsers();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getUsers();
      }
    },
  },
});
