<template>
  <div
    class="flex justify-center items-center p-2 rounded-xl"
    :class="isSelected ? 'bg-[#00DDAF]' : 'bg-white'"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 21.725L4 17.7C3.68334 17.5167 3.43734 17.275 3.262 16.975C3.08667 16.675 2.99934 16.3417 3 15.975V8.025C3 7.65833 3.08767 7.325 3.263 7.025C3.43834 6.725 3.684 6.48333 4 6.3L11 2.275C11.3167 2.09167 11.65 2 12 2C12.35 2 12.6833 2.09167 13 2.275L20 6.3C20.3167 6.48333 20.5627 6.725 20.738 7.025C20.9133 7.325 21.0007 7.65833 21 8.025V15.975C21 16.3417 20.9123 16.675 20.737 16.975C20.5617 17.275 20.316 17.5167 20 17.7L13 21.725C12.6833 21.9083 12.35 22 12 22C11.65 22 11.3167 21.9083 11 21.725ZM11 12.575V19.425L12 20L13 19.425V12.575L19 9.1V8.05L17.925 7.425L12 10.85L6.075 7.425L5 8.05V9.1L11 12.575Z"
        :fill="color"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: ["color", "isSelected"],
};
</script>
<style></style>
