import { defineStore } from "pinia";
import axios from "@/axios/axios";
import { useAuthStore } from "./auth";

export const useTicketStore = defineStore("ticketStore", {
  state: () => ({
    tickets: [],
    landingPagesCount: 0,
    itemsPerPage: 10,
    errorsMessages: "",
    currentPage: 1,
    to: 0,
    from: 0,
    totalPages: 0,
    totalRecords: 0,
    isFail: false,
    isSuccess: false,
    isLoadingModal: false,
    editedTicket: null,
    showCloseModal: false,
    deletedId: null,
    singleTicket: null,
  }),
  actions: {
    async replyTicket(ticketId, reply) {
      console.log(ticketId, reply);
      this.errorMessage = null;
      this.isFail = false;
      this.isSuccess = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const res = await axios.post(
          `${process.env.VUE_APP_API_BASE}landing_page/admin/tickets/store`,
          ticketId,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        this.getTicket();
        console.log(res);
      } catch (err) {
        if (err.response.status == 422) {
          Object.values(err.response.data.errors).forEach(
            (val) => (this.errorsMessages = this.errorsMessages + "<br>" + val)
          );
        } else {
          this.errorsMessages.push("something went wrong");
        }
        this.isFail = true;
        this.isLoadingModal = false;
        console.log(err);
      }
    },
    async closeTicket(ticketId) {
      console.log(ticketId);
      this.errorMessage = null;
      this.isFail = false;
      this.isSuccess = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const res = await axios.post(
          `${process.env.VUE_APP_API_BASE}landing_page/admin/tickets/store`,
          ticketId,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        this.getTicket();
        console.log(res);
      } catch (err) {
        if (err.response.status == 422) {
          Object.values(err.response.data.errors).forEach(
            (val) => (this.errorsMessages = this.errorsMessages + "<br>" + val)
          );
        } else {
          this.errorsMessages.push("something went wrong");
        }
        this.isFail = true;
        this.isLoadingModal = false;
        console.log(err);
      }
    },
    async getTickets(query) {
      const authStore = useAuthStore();

      this.errorMessage = null;
      try {
        if (!query) {
          this.isLoadingModal = true;
        } else {
          authStore.isLoading = true;
        }
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}ticket/admin/get?paginate=true&page=${
            this.currentPage
          }&q=${query ? `&q=${encodeURIComponent(query)}` : ""}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.tickets = data.data.data;
        this.totalPages = data.data.last_page;
        this.currentPage = data.data.current_page;
        this.totalRecords = data.data.total;
        this.from = data.data.from;
        this.to = data.data.to;
        authStore.isLoading = false;
        this.isLoadingModal = false;
      } catch (err) {
        authStore.isLoading = false;
        this.isLoadingModal = false;
        this.errorMessage = err.response.data.errors;
        console.log(err);
      }
    },
    async getSingleTicket(ticketId) {
      this.errorMessage = null;
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}ticket/admin/get/${ticketId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.singleTicket = data.data;
        this.isLoadingModal = false;
      } catch (err) {
        this.isLoadingModal = false;
        console.log(err);
      }
    },
    async sendReply(reply) {
      this.errorMessage = null;
      this.isLoadingModal = true;
      this.isFail = false;
      this.isSuccess = false;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}ticket/admin/reply/${this.singleTicket.id}`,
          { content: reply },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isSuccess = true;
        this.isLoadingModal = false;
        await this.getSingleTicket(this.singleTicket.id);
      } catch (err) {
        console.log(err);
        this.isLoadingModal = false;
        this.isFail = true;
      }
    },

    async closeDiscussion(ticketId) {
      this.errorMessage = null;
      this.isLoadingModal = true;
      this.isFail = false;
      this.isSuccess = false;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}ticket/admin/close/${ticketId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        this.isSuccess = true;
        this.isLoadingModal = false;
        this.showDeleteModal = false;
      } catch (err) {
        console.log(err);
        this.isLoadingModal = false;
        this.showDeleteModal = false;
        this.isFail = true;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getTickets();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getTickets();
      }
    },
  },
});
