<template>
  <div></div>
</template>
<script>
import { useUsersStore } from "@/store/users";
export default {
  setup() {
    const userStore = useUsersStore();
    const { isEditUserFail } = useUsersStore();
    const { isEditUserSuccess } = useUsersStore();
    return {
      userStore,
      isEditUserFail,
      isEditUserSuccess,
    };
  },
  mounted() {
    if (this.userStore.isEditUserSuccess) {
      this.$swal({
        icon: "success",
        title: "User edited successfully",
        showConfirmButton: false,
        timer: 1500,
        willClose: () => {
          this.userStore.isEditUserFail = false;
          this.userStore.isEditUserSuccess = false;
          this.userStore.isShowEditor = false;
          this.$router.push({ path: '/admin/users' })
        },
      });
    }
  },
};
</script>
