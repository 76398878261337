<template>
  <div
    class="flex justify-center items-center p-2 rounded-xl"
    :class="isSelected ? 'bg-[#00DDAF]' : 'bg-white'"
  >
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 14C9.28333 14 9.521 13.904 9.713 13.712C9.905 13.52 10.0007 13.2827 10 13C10 12.7167 9.904 12.479 9.712 12.287C9.52 12.095 9.28267 11.9993 9 12C8.71667 12 8.479 12.096 8.287 12.288C8.095 12.48 7.99933 12.7173 8 13C8 13.2833 8.096 13.521 8.288 13.713C8.48 13.905 8.71733 14.0007 9 14ZM9 10C9.28333 10 9.521 9.904 9.713 9.712C9.905 9.52 10.0007 9.28267 10 9V5C10 4.71667 9.904 4.479 9.712 4.287C9.52 4.095 9.28267 3.99933 9 4C8.71667 4 8.479 4.096 8.287 4.288C8.095 4.48 7.99933 4.71733 8 5V9C8 9.28333 8.096 9.521 8.288 9.713C8.48 9.905 8.71733 10.0007 9 10ZM6.075 18C5.80833 18 5.55433 17.95 5.313 17.85C5.07167 17.75 4.859 17.6083 4.675 17.425L0.575 13.325C0.391667 13.1417 0.25 12.929 0.15 12.687C0.0500001 12.445 0 12.191 0 11.925V6.075C0 5.80833 0.0500001 5.55433 0.15 5.313C0.25 5.07167 0.391667 4.859 0.575 4.675L4.675 0.575C4.85833 0.391667 5.071 0.25 5.313 0.15C5.555 0.0500001 5.809 0 6.075 0H11.925C12.1917 0 12.446 0.0500001 12.688 0.15C12.93 0.25 13.1423 0.391667 13.325 0.575L17.425 4.675C17.6083 4.85833 17.75 5.071 17.85 5.313C17.95 5.555 18 5.809 18 6.075V11.925C18 12.1917 17.95 12.446 17.85 12.688C17.75 12.93 17.6083 13.1423 17.425 13.325L13.325 17.425C13.1417 17.6083 12.929 17.75 12.687 17.85C12.445 17.95 12.191 18 11.925 18H6.075Z"
        :fill="color"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: ["color", "isSelected"],
};
</script>
<style></style>
