<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
  >
    <div class="flex justify-between">
      <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3 class="flex font-medium text-lg">
              <router-link to="/admin/clubs">Tous les clubs</router-link>
              <img class="mx-2" :src="separator" alt="separtor" />
              <p>{{ clubsStore.singleClub?.club_name }}</p>
              <img class="mx-2" :src="separator" alt="separtor" />
              <p>Offres d’emploi</p>
            </h3>
          </div>
        </div>
      </div>
      <div class="flex">
        <filter-job-date />
        <filter-job-status />
      </div>
    </div>
    <div class="block w-full overflow-x-auto" v-if="clubsStore.singleClub">
      <!-- News table -->
      <table
        class="items-center w-full bg-transparent border-collapse"
        v-if="clubsStore.singleClub.jobOffers.length > 0"
      >
        <thead>
          <tr class="border-b">
            <th
              class="text-[#999999] h-10 w-10 align-middle border border-solid py-1 lg:py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              <img
                src="/img/checkbox.e6903039.svg"
                class="float-right"
                height="20"
                width="20"
                alt="checkbox"
              />
              <p class="invisible">icon</p>
            </th>
            <th
              class="text-[#999999] px-6 flex items-center align-middle border border-solid py-3 uppercase border-l-0 border-b-0 border-r-0 font-medium text-left"
            >
              ID Offre D’emploi
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Poste
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Date de publication
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Nombre de postulations
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Status
            </th>

            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(jobOffer, index) in clubsStore.singleClub.jobOffers"
            :key="index"
            class="border-b"
          >
            <th
              class="text-[#999999] h-10 w-10 align-middle border border-solid py-1 lg:py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              <img
                src="/img/checkbox.e6903039.svg"
                class="float-right"
                height="20"
                width="20"
                alt="checkbox"
              />
              <p class="invisible">icon</p>
            </th>
            <td class="align-middle border-l-0 border-r-0 p-4">
              #{{ jobOffer.id }}
            </td>
            <td class="align-middle border-l-0 border-r-0 p-4">
              {{ jobOffer.wanted_profile }}
            </td>
            <td class="align-middle text-center border-l-0 border-r-0 p-4">
              {{ jobOffer.createdAt.substring(0, 10) }}
            </td>
            <td class="align-middle border-l-0 border-r-0 p-4 text-center">
              {{
                jobOffer.applicationPlayers.length +
                jobOffer.applicationOthers.length
              }}
            </td>

            <td
              class="align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              <div
                v-if="jobOffer.status == 'ACTIF'"
                class="rounded-xl text-center px-2 py-1 font-medium text-primary border-primary border"
              >
                ACTIF
              </div>
              <div
                v-if="jobOffer.status == 'PAUSED'"
                class="rounded-xl text-center px-2 py-1 font-medium text-[#C79141] border-[#C79141] border"
              >
                PAUSE
              </div>
            </td>

            <td class="align-middle border-l-0 border-r-0 text-right"></td>
          </tr>
        </tbody>
      </table>
      <div v-else class="flex flex-col justify-center items-center py-10">
        <img :src="noRecords" alt="noRecords" class="w-2/6" />
        <div class="text-xl text-[#747373]">Aucun record pour le moment !</div>
      </div>
    </div>
  </div>
</template>
<script>
import checkbox from "@/assets/icons/cards/checkbox.svg";
import separator from "@/assets/icons/cards/separator.svg";
import FilterJobStatus from "../dropdowns/filters/jobs/FilterJobStatus.vue";
import FilterJobDate from "../dropdowns/filters/jobs/FilterJobDate.vue";
import noRecords from "@/assets/images/noRecords.svg";

export default {
  components: {
    FilterJobStatus,
    FilterJobDate,
  },
  data() {
    return {};
  },
  async mounted() {},
  setup() {
    return {
      checkbox,
      separator,
      noRecords,
    };
  },

  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
