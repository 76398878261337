<template>
  <div>
    <metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | RAYHANE` : `SITE_NAME`
      }}</template>
    </metainfo>
    <div class="flex flex-wrap mt-4">
      <div class="w-full p">
        <div class="my-6 mt-24">
          <div class="flex justify-between">
            <div class="flex items-center">
              <router-link to="/admin/landingPage/partners">
                <div
                  :class="
                    $route.fullPath == '/admin/landingPage/partners'
                      ? 'text-black border-b-4 border-black'
                      : 'text-gray'
                  "
                  class="font-semibold text-md mr-3 flex items-center cursor-pointer"
                >
                  Partners
                </div></router-link
              >
              <router-link to="/admin/landingPage/reviews">
                <div
                  :class="
                    $route.fullPath == '/admin/landingPage/reviews'
                      ? 'text-black border-b-4 border-black'
                      : 'text-gray'
                  "
                  class="font-semibold text-md mr-3 flex items-center cursor-pointer"
                >
                  Reviews
                </div></router-link
              >
            </div>
            <div :class="$route.path.includes('reviews') ? 'block' : 'hidden'">
              <router-link to="/admin/landingPage/reviews/new">
                <button class="btn bg-cyan font-bold">
                  <span class="text-xl">+ </span> Add review
                </button></router-link
              >
            </div>
          </div>

          <LandingPageReviewsTable />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LandingPageReviewsTable from "../../components/tables/LandingPageReviewsTable.vue";
import back from "@/assets/icons/cards/back.svg";
import forward from "@/assets/icons/cards/forward.svg";
import { useMeta } from "vue-meta";

export default {
  components: { LandingPageReviewsTable },
  setup() {
    useMeta({
      title: "Landing page reviews",
      htmlAttrs: { lang: "en", amp: true },
    });
    return { forward, back };
  },
};
</script>
<style></style>
