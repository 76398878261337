<script setup></script>
<template>
  <router-view />
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  font-family: "Inter", sans-serif;
  font-family: "Lexend", sans-serif;
}

html {
  overflow-x: hidden;
}
</style>
