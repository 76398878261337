<template>
  <div class="w-full">
    <div
      class="flex justify-between items-center bg-purple text-white font-bold text-3xl p-5 rounded-t-xl border-[#c9c9c9] border-b-0 border">
      <span> Content of the course </span>

      <svg v-if="!isValid" xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 512 512">
        <circle cx="256" cy="256" r="248" stroke="#00DDAF" fill="none" stroke-width="16" />
      </svg>
      <svg v-else xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 512 512">
        <path
          d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zM227.3 387.3l184-184c6.2-6.2 6.2-16.4 0-22.6l-22.6-22.6c-6.2-6.2-16.4-6.2-22.6 0L216 308.1l-70.1-70.1c-6.2-6.2-16.4-6.2-22.6 0l-22.6 22.6c-6.2 6.2-6.2 16.4 0 22.6l104 104c6.2 6.2 16.4 6.2 22.6 0z"
          fill="#00DDAF" />
      </svg>
    </div>

    <form class="w-full border-[#c9c9c9] border border-t-0 p-4 rounded-b-xl" @submit.prevent>
      <draggable v-if="content.length > 0" v-model="content" group="content-list" @start="dragging = true"
        @end="dragging = false" item-key="id">
        <template #item="{ element }">
          <div class="flex justify-between border-[#c9c9c9] border my-2 rounded-md" v-if="!element.to_remove">
            <div class="flex p-4 gap-4">
              <div class="border-r-[#c9c9c9] border border-l-0 border-y-0 pr-2">
                <img style="filter: grayscale(1)" :src="menuBurger" class="ml-2" width="40" />
              </div>
              <div class="flex items-center gap-4">
                <img v-if="element.src" :src="element.src" class="h-10 w-10" />
                <img v-if="element.attachments?.length && element.attachments[0].type == 'image'"
                  :src="element.attachments[0].url" class="h-10 w-10" />
                <img v-if="element.attachments?.length && element.attachments[0].type == 'video'" :src="VideoBackground"
                  class="h-10 w-10" />
                <img v-if="element.attachments?.length && element.attachments[0].type == 'PDF'" :src="PDFBackground"
                  class="h-10 w-10" />
                <span v-if="element.link">
                  <input
                    :class="`p-2 focus:outline-none ${!element.isReadOnly ? 'border-[#c9c9c9] border-2 rounded-md' : ''}`"
                    type="text" :readonly="element.isReadOnly" v-model="element.link">
                </span>
                <span>
                  <input
                    :class="`p-2 focus:outline-none ${!element.isReadOnly ? 'border-[#c9c9c9] border-2 rounded-md' : ''}`"
                    type="text" :readonly="element.isReadOnly" v-model="element.title">
                </span>
                <span>
                  <input
                    :class="`p-2 focus:outline-none ${!element.isReadOnly ? 'border-[#c9c9c9] border-2 rounded-md' : ''}`"
                    type="text" :readonly="element.isReadOnly" v-model="element.price">
                </span>
              </div>
            </div>
            <div class="flex align-middle">
              <button v-if="element.isReadOnly" class="mx-2" @click="EditContent(element)">
                <img :src="editIcon" class="mr-2 trash-image" alt="edit" />
              </button>
              <button v-else class="mx-2" @click="EditContent(element)">
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </button>

              <button class="mx-2" @click="removeContent(element)">
                <img :src="trash" alt="delete" class="trash-image" />
              </button>
            </div>
          </div>
        </template>
      </draggable>
      <div class="">
        <div>
          <p class="text-purple mb-1 text-left">Pictures or pdf files</p>
        </div>
        <div class="my-2 mb-4 text-xl rounded-md">
          <label class="cursor-pointer w-fit flex items-center" for="contentInput">
            <div v-if="!form.cover"
              class="flex flex-col upload-block justify-center items-center p-2 h-40 w-40 rounded-md border-dashed">
              <p class="text-[#e8e8e8c9] mb-1 text-center text-base mt-2">
                Click here to select file
              </p>

              <input class="hidden" multiple hidden id="contentInput" @change="handleContentCoverChange" type="file"
                accept="image/jpeg,image/png,image/jpg,application/pdf" />
            </div>

            <img v-show="form.cover" class="h-40 w-40" ref="contentImage" />
            <img @click="removeImage" :src="trash" class="ml-2" v-if="form.cover" width="40" />
          </label>
          <p class="text-red-500" v-if="v$.form.cover.$error">
            The cover is required
          </p>
        </div>
      </div>
      <div class="md:flex">
        <div class="md:w-6/12 md:mr-6 my-3">
          <p class="text-purple mb-1 text-left">Title</p>
          <div class="flex items-center justify-between border-[#c9c9c9] border rounded-md py-3 newPassword-container">
            <input class="text-gray-700 px-2 leading-tight focus:outline-none w-full newPassword-input" type="text"
              v-model="form.title" />
          </div>
          <p class="text-red-500" v-if="v$.form.title.$error">
            The title is required
          </p>
        </div>
        <div class="md:w-6/12 md:mr-6 my-3">
          <p class="text-purple mb-1 text-left">Price</p>
          <div class="flex items-center justify-between border-[#c9c9c9] border rounded-md py-3 newPassword-container">
            <input class="text-gray-700 px-2 leading-tight focus:outline-none w-full newPassword-input" type="number"
              min="0" v-model="form.price" />
          </div>
          <p class="text-red-500" v-if="v$.form.price.$error">
            The price is required
          </p>
        </div>
        <div class="md:w-6/12 md:mr-6 my-3">
          <p class="text-purple mb-1 text-left">Video link</p>
          <div class="flex items-center justify-between border-[#c9c9c9] border rounded-md py-3 newPassword-container">
            <input class="text-gray-700 px-2 leading-tight focus:outline-none w-full newPassword-input" type="text"
              v-model="form.videoLink" />
            <svg width="18" height="18" class="mx-2 cursor-pointer" viewBox="0 0 18 18" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_202_16568)">
                <path
                  d="M16.1895 7.84598L8.652 15.4022C7.1895 16.864 4.8105 16.864 3.34875 15.4022C1.887 13.9405 1.88625 11.5607 3.34875 10.0982L11.3197 2.15948C12.1973 1.28198 13.6245 1.28198 14.502 2.15948C15.3788 3.03698 15.3795 4.46348 14.502 5.34098L8.12175 11.689C7.82925 11.9815 7.35375 11.9815 7.06125 11.689C6.76875 11.3965 6.76875 10.921 7.06125 10.6285L12.381 5.34098L11.3197 4.28048L6 9.56723C5.121 10.4462 5.12175 11.872 6 12.7502C6.87825 13.6285 8.30325 13.6285 9.1815 12.7502L15.5618 6.40223C16.2945 5.66948 16.6597 4.71023 16.6597 3.75023C16.6597 1.67348 14.9752 0.000976562 12.9097 0.000976562C11.9497 0.000976562 10.9905 0.366976 10.2577 1.09973L2.2875 9.03698C1.263 10.063 0.75 11.4062 0.75 12.7502C0.75 15.6475 3.0975 18.0002 6 18.0002C7.344 18.0002 8.68725 17.4872 9.7125 16.4627L17.25 8.90648L16.1895 7.84598Z"
                  fill="#CDD2D8" />
              </g>
              <defs>
                <clipPath id="clip0_202_16568">
                  <rect width="18" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <p class="text-red-500" v-if="v$.form.videoLink.$error">
            The link is required
          </p>
        </div>
      </div>
      <div class="flex justify-start my-4">
        <button class="btn btn bg-cyan font-bold" @click="saveData">
          Add the content
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import trash from "@/assets/icons/cards/trash.svg";
import menuBurger from "@/assets/icons/sidebar/menuBurger.svg";
import draggable from "vuedraggable";
import { v4 as uuidv4 } from "uuid";
import PDFBackground from "@/assets/images/PDFBackground.jpg";
import VideoBackground from "@/assets/images/VideoBackground.jpg";
import editIcon from "@/assets/icons/cards/editIcon.svg";
import { CheckIcon } from "@heroicons/vue/20/solid";

export default {
  props: {
    isValid: {
      type: Boolean,
      required: true,
    },
    courseContent: {
      type: Object,
      required: false,
    },
  },

  components: { draggable, CheckIcon },

  data() {
    return {
      form: {
        title: "",
        price: 0,
        videoLink: "",
        cover: null,
        files: null,
      },

      content: [],
      dragging: false,
    };
  },

  watch: {
    courseContent: {
      handler(newVal) {
        const data = newVal.map((element) => {
          return { ...element, isReadOnly: true }
        })
        this.content = [...[...data]];
      },
      deep: true,
    },

    content: {
      handler(newVal) {
        if (newVal.length == 0) {
          this.$emit("updateValidity", false);
          return;
        } else {
          this.$emit("updateValidity", true);
          let data = newVal.map((item, index) => {
            let formData = new FormData();
            if (item.id && typeof item.id == "number") {
              formData.append("id", item.id);
              formData.append("to_remove", item.to_remove ?? false)
            }
            formData.append("title", item.title);
            formData.append("price", item.price);
            formData.append("type", item.type);
            if (item.order) formData.append("order", item.order);
            else formData.append("order", index);
            if (item.files)
              item.files.forEach((file) => {
                formData.append("files[]", file);
              });
            if (item.links)
              formData.append("links[]", item.links);
            return formData;
          });
          this.$emit("getCourseContent", data);
          return;
        }
      },
      deep: true,
    },
  },

  setup() {
    return {
      v$: useVuelidate(), trash, menuBurger, PDFBackground, VideoBackground,
      editIcon
    };
  },

  methods: {
    handleContentCoverChange(event) {
      this.form.cover = event.target.files[0];
      this.form.files = event.target.files;
      if (this.form.cover && this.form.cover.type === "application/pdf") {
        const imagePath = require("@/assets/images/PDFBackground.jpg");
        this.$refs.contentImage.src = imagePath;
        this.$refs.contentImage.width = 200;
      } else {
        let outputPic = this.$refs.contentImage;
        // this.categoriesStore.editedCategory.image = this.cover;
        outputPic.src = URL.createObjectURL(this.form.cover);
        outputPic.width = 200;
      }
    },

    removeImage() {
      this.$refs.contentImage.src = null;
      this.form.cover = null;
      // this.categoriesStore.editedCategory.image = null;
    },

    removeContent(element) {
      this.content = this.content.map(el => {
        if (el.id === element.id) {
          el.to_remove = true
        }
        return el
      });
    },

    EditContent(element) {
      const index = this.content.findIndex((el) => {
        return element.id == el.id;
      });
      this.content[index].isReadOnly = !this.content[index].isReadOnly;
    },

    async saveData() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      } else {
        const newContent = {
          id: uuidv4(),
          title: this.form.title,
          price: this.form.price,
          type: "attachment",
          links: this.form.videoLink,
          src: this.form.cover
            ? this.form.cover.type !== "application/pdf"
              ? URL.createObjectURL(this.form.cover)
              : require("@/assets/images/PDFBackground.jpg")
            : require("@/assets/images/VideoBackground.jpg"),
          files: this.form.files,
          isReadOnly: true,
        };
        this.content.push(newContent);
        this.form.cover = null;
        this.form.files = null;
        this.form.title = "";
        this.form.price = 0;
        this.form.videoLink = "";
        this.v$.$reset();
        return;
      }
    },

    videoLinkRequiredUnlessCoverExists(value) {
      // Check if cover is null or empty
      if (this.form.cover) {
        // Apply the required rule
        return required(value);
      }
      // If cover exists, then videoLink is not required
      return true;
    },
  },

  validations() {
    return {
      form: {
        title: { required },
        price: { required },
        cover: {},
        videoLink: {
          required: this.form.cover ? false : required,
        },
      },
    };
  },
};
</script>

<style scoped>
.upload-block {
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A0A0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.trash-image {
  width: 20px;
  height: 20px;
}
</style>
