import { defineStore } from "pinia";
import axios from "@/axios/axios";
import { useAuthStore } from "./auth";

export const useReportsStore = defineStore("reportsStore", {
  state: () => ({
    reports: [],
    isLoadingModal: false,
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    to: 0,
    from: 0,
    showDeleteModal: false,
  }),
  actions: {
    async getReports(query) {
      this.isLoadingModal = true;
      const authStore = useAuthStore();

      try {
        if (!query) {
          this.isLoadingModal = true;
        } else {
          authStore.isLoading = true;
        }
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${
            process.env.VUE_APP_API_BASE
          }student/admin/reports/get?paginate=true&page=${this.currentPage}${
            query ? `&q=${encodeURIComponent(query)}` : ""
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.reports = data.data.data;
        this.totalPages = data.data.last_page;
        this.currentPage = data.data.current_page;
        this.totalRecords = data.data.total;
        this.from = data.data.from;
        this.to = data.data.to;
        authStore.isLoading = false;
        this.isLoadingModal = false;
      } catch (err) {
        console.log(err);
        authStore.isLoading = false;
        this.isLoadingModal = false;
      }
    },
    async deleteReport() {
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}reports/getUserReports`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.reports = data.userReports;
        this.isLoadingModal = false;
        this.showDeleteModal = false;
      } catch (err) {
        this.showDeleteModal = false;

        this.isLoadingModal = false;
      }
    },

    async updateReport(reportId) {
      this.isLoadingModal = true;
      this.pauseError = false;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/report/unpause`,
          {
            reportId: reportId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        await this.getNews();
      } catch (err) {
        this.isLoadingModal = false;
        this.pauseError = true;
        console.log(err);
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getReports()
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getReports()
      }
    },
  },
});
