import { defineStore } from "pinia";
import axios from "@/axios/axios";
import router from "@/router";
export const useAuthStore = defineStore("authStore", {
  state: () => ({
    error: null,
    user: null,
    email: null,
    errorMessage: "",
    isLoading: false,
    isFail: false,
    isSuccess: false,
    resetCode: "",
    successReset: false,
    personalData: null,
    //search
    search: null,
    //UI STUFF
    collapseShow: "hidden",
  }),

  actions: {
    async authenticate(email, password) {
      this.error = null;
      this.isLoading = true;
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE}auth/admin/login`,
          { email: email, password: password }
        );

        this.user = {
          user: response.data.data.user,
          token: response.data.data.token,
          permissions: null,
        };

        this.user.permissions = response.data.data.user.userable.permission;
        this.isLoading = false;

        localStorage.setItem("rayhane-admin", JSON.stringify(this.user));

        router.push("/admin");
      } catch (err) {
        console.log(err);
        this.isLoading = false;
        this.error = "Invalid email or password";
      }
    },
    async sendResetCode(email) {
      this.error = null;
      this.isLoading = true;
      try {
        await axios.post(`${process.env.VUE_APP_API_BASE}admin/sendResetCode`, {
          email: email,
        });
        this.isLoading = false;
        this.email = email;
        router.push("/auth/confirmcode");
      } catch (err) {
        console.log(err);
        this.isLoading = false;
        this.error = "Ce mail n'existe pas";
      }
    },
    async verifyResetCode() {
      this.error = null;
      this.isLoading = true;
      try {
        await axios.post(`${process.env.VUE_APP_API_BASE}admin/verifyReset`, {
          email: this.email,
          code: this.resetCode,
        });
        this.isLoading = false;
        router.push("/auth/newPassword");
      } catch (err) {
        this.isLoading = false;
        this.error = "Code incorrect";
      }
    },
    async resetPassword(newPassword) {
      this.error = null;
      this.isLoading = true;
      try {
        await axios.post(`${process.env.VUE_APP_API_BASE}admin/resetPassword`, {
          email: this.email,
          code: this.resetCode,
          newPassword: newPassword,
        });
        this.successReset = true;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        this.error = "Code incorrect";
      }
    },
    async updateProfile(newProfile) {
      this.error = null;
      this.isLoading = true;
      this.isFail = false;
      this.isSuccess = false;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE}auth/admin/profile/update`,
          newProfile,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoading = false;
        this.isSuccess = true;
        this.user.user = response.data;
      } catch (err) {
        this.errorMessage = "";
        if (err.response.status == 422) {
          for (const [key, value] of Object.entries(err.response.data.errors)) {
            for (let error in value) {
              console.log(key);
              this.errorMessage += value[error];
            }
          }
        } else {
          this.errorMessage = "something went wrong";
        }
        console.log(err);
        this.isFail = true;
        this.isLoading = false;
      }
    },
    async getPersonalData() {
      this.error = null;
      this.isLoading = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE}user/info`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoading = false;
        this.user.user = response.data;
      } catch (err) {
        if (err.response.status == 401) {
          this.errorMessage = "Incorrect ancien mot de passe";
        }
        console.log(err);
        this.isLoading = false;
        this.isFail = true;
      }
    },

    disconnect() {
      this.user = null;
      localStorage.removeItem("rayhane-admin");
      router.push("/auth");
    },
    toggleCollapseShow(classes) {
      this.collapseShow = classes;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
});
