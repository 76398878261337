<template>
    <div>
        <BlockTeacherModal v-if="teachersStore.isBlockModal" />
        <UnBlockTeacherModal v-if="teachersStore.isUnblockModal" />
        <DeleteTeacherModal v-if="teachersStore.showDeleteModal" />
        <LoaderModal v-if="teachersStore.isLoadingModal" />
        <div class="flex justify-between items-center">
            <div>
                <h1 class="text-3xl text-black font-semibold">Teachers</h1>
                <h1 class="text-xl text-gray font-semibold">
                    Manage your teachers
                </h1>
            </div>
            <div class="flex items-center">
                <router-link to="/admin/teachers/new">
                    <button class="btn bg-cyan font-bold">
                        <span class="">+ </span> Add teacher
                    </button></router-link
                >
            </div>
        </div>
        <div
            class="relative min-w-0 break-words w-full mb-6 shadow-lg mt-4 rounded-xl border border-[#e8e8e8c9] bg-white"
        >
            <div class="block w-full overflow-x-auto">
                <table
                    class="items-center w-full bg-transparent border border-[#e8e8e8c9] rounded-xl overflow-hidden"
                    v-if="teachersStore.teachers.length > 0"
                >
                    <thead class="">
                        <tr class="border-[#e8e8e8c9] border">
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase -0 font-medium text-left"
                            >
                                Teacher name
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
                            >
                                Fonction
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
                            >
                                Balance
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
                            >
                                Percentage
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
                            >
                                Courses
                            </th>

                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
                            >
                                Comments
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
                            >
                                total rate
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
                            >
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(teacher, index) in teachersStore.teachers"
                            :key="index"
                            :class="
                                teacher.user.status == 'inactive'
                                    ? 'bg-red-100'
                                    : ''
                            "
                        >
                            <td
                                class="align-middle p-4 text-purple flex items-center"
                            >
                                <img
                                    v-if="!teacher.user.image?.url"
                                    :src="avatarUser"
                                    class="w-10 h-10 mr-2"
                                    alt="dfsd"
                                />
                                <img
                                    class="w-10 h-10 mr-2"
                                    v-else
                                    :src="teacher.user.image.url"
                                    alt="profile pic"
                                />
                                {{
                                    teacher.user.first_name +
                                    " " +
                                    teacher.user.last_name
                                }}
                            </td>
                            <td
                                class="align-middle p-4 text-purple items-center"
                            >
                                {{ teacher.function }}
                            </td>
                            <td class="align-middle p-4 text-center">
                                {{ teacher.balance }}
                            </td>

                            <td
                                class="align-middle whitespace-nowrap p-4 text-center"
                            >
                                {{ teacher.percentage }} %
                            </td>

                            <td class="align-middle text-center">
                                {{ teacher.courses_count }}
                            </td>
                            <td class="align-middle text-center">
                                {{ teacher.comments_count }}
                            </td>
                            <td class="align-middle text-center">
                                {{ parseFloat(teacher.total_rate.toFixed(1)) }}
                            </td>
                            <td class="align-middle text-center">
                                <TeachersDropdown :teacher="teacher.user" />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div
                    v-else
                    class="flex flex-col justify-center items-center py-10"
                >
                    <img :src="noRecords" alt="noRecords" class="w-2/6" />
                    <div class="text-xl text-[#747373]">
                        No records at the moment !
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-between items-center">
            <div>
                Showing {{ teachersStore.from }}-{{ teachersStore.to }} from
                {{ teachersStore.totalPages }} data
            </div>
            <div class="flex items-center">
                <div v-if="teachersStore.currentPage != 1">
                    <img
                        @click="teachersStore.previousPage()"
                        :src="forward"
                        class="cursor-pointer"
                        alt="forward"
                    />
                </div>

                <div
                    v-if="teachersStore.currentPage > 1"
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
                    :class="{
                        'text-gray border border-gray': true,
                        'bg-cyan text-white border-cyan': false,
                    }"
                    @click="teachersStore.previousPage()"
                >
                    {{ teachersStore.currentPage - 1 }}
                </div>

                <div
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12"
                    :class="
                        true
                            ? 'bg-cyan text-white border-cyan'
                            : ' text-gray border border-gray'
                    "
                >
                    {{ teachersStore.currentPage }}
                </div>

                <div
                    v-if="
                        teachersStore.currentPage + 1 <=
                        Math.ceil(
                            teachersStore.totalPages /
                                teachersStore.itemsPerPage
                        )
                    "
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
                    :class="{
                        'text-gray border border-gray': true,
                        'bg-cyan text-white border-cyan': false,
                    }"
                    @click="teachersStore.nextPage()"
                >
                    {{ teachersStore.currentPage + 1 }}
                </div>

                <div
                    v-if="
                        teachersStore.currentPage + 1 <=
                        Math.ceil(
                            teachersStore.totalPages /
                                teachersStore.itemsPerPage
                        )
                    "
                >
                    <img
                        class="cursor-pointer"
                        @click="teachersStore.nextPage()"
                        :src="back"
                        alt="back"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import checkbox from "@/assets/icons/cards/checkbox.svg";
import separator from "@/assets/icons/cards/separator.svg";
import deleteIcon from "@/assets/icons/cards/deleteIcon.svg";
import avatarUser from "@/assets/icons/cards/avatarUser.svg";
import back from "@/assets/icons/cards/back.svg";
import forward from "@/assets/icons/cards/forward.svg";

import noRecords from "@/assets/images/noRecords.svg";
import { useTeachersStore } from "@/store/teachers";
import TeachersDropdown from "../dropdowns/TeachersDropdown.vue";
import BlockTeacherModal from "../modals/teachers/BlockTeacherModal.vue";
import UnBlockTeacherModal from "../modals/teachers/UnBlockTeacherModal.vue";
import LoaderModal from "../modals/LoaderModal.vue";
import DeleteTeacherModal from "../modals/teachers/DeleteTeacherModal.vue";
export default {
    components: {
        TeachersDropdown,
        BlockTeacherModal,
        UnBlockTeacherModal,
        LoaderModal,
        DeleteTeacherModal,
    },
    data() {
        return {};
    },
    setup() {
        const teachersStore = useTeachersStore();
        return {
            back,
            forward,
            checkbox,
            avatarUser,
            deleteIcon,
            separator,
            teachersStore,
            noRecords,
        };
    },
    async mounted() {
        await this.teachersStore.getTeachers();
    },

    props: {
        color: {
            default: "light",
            validator: function (value) {
                // The value must match one of these strings
                return ["light", "dark"].indexOf(value) !== -1;
            },
        },
    },
};
</script>
