<template>
  <div
    class="flex justify-center items-center p-2 rounded-xl"
    :class="isSelected ? 'bg-[#00DDAF]' : 'bg-white'"
  >
    <svg
      width="23"
      height="23"
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 30H8v-3H4v3H2v-3a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2ZM30 30h-2v-3h-4v3h-2v-3a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2ZM20 30h-2v-3h-4v3h-2v-3a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2Z"
        :fill="color"
      ></path>
      <circle cx="16" cy="22" r="2" :fill="color"></circle>
      <circle cx="6" cy="22" r="2" :fill="color"></circle>
      <circle cx="26" cy="22" r="2" :fill="color"></circle>
      <circle cx="21" cy="18" r="2" :fill="color"></circle>
      <circle cx="11" cy="18" r="2" :fill="color"></circle>
      <path
        d="M26 14H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h20a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2ZM6 4v8h20V4Z"
        :fill="color"
      ></path>
      <path d="M0 0h32v32H0z" fill="none" style=""></path>
    </svg>
  </div>
</template>
<script>
export default {
  props: ["color", "isSelected"],
};
</script>
<style></style>
