<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <CourseLoaderModal :isNewCourse="true" v-if="coursesStore.isLoadingModal" />
    <metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | RAYHANE` : `SITE_NAME`
      }}</template>
    </metainfo>
    <div class="my-6 mt-24 w-full">
      <InformationForm
        :courseInfo="courseInfo"
        @getCourseInfo="getCourseInfo"
        @updateValidity="updateCourseInfoValidity"
        :isValid="courseInfoValid"
        :isNew="true"
      />
      <ContentForm
        @getCourseContent="getCourseContent"
        @updateValidity="updateCourseContentValidity"
        :isValid="courseContentValid"
      />
      <QuizzesNumber
        @getCourseQuizzes="getCourseQuizzes"
        @updateValidity="updateCourseQuizzesValidity"
        :isValid="courseQuizzesValid"
      />

      <div v-if="quizzesStore.errorMessages.length > 0">
        <p
          class="text-red-500"
          v-for="(err, index) in quizzesStore.errorMessages"
          :key="index"
        >
          {{ err }}
        </p>
      </div>

      <button
        :disabled="!(courseContentValid && courseInfoValid)"
        :class="`btn btn bg-cyan font-bold my-2 ${
          !(courseContentValid && courseInfoValid)
            ? 'opacity-50'
            : 'opacity-100'
        }`"
        @click="addCourse"
      >
        Save
      </button>
    </div>
  </div>
</template>
<script>
import ContentForm from "@/components/cards/courses/ContentForm.vue";
import InformationForm from "@/components/cards/courses/InformationForm.vue";
import QuizzesNumber from "@/components/cards/courses/QuizzesNumber.vue";
import { useCategoriesStore } from "@/store/categories";
import { useCoursesStore } from "@/store/courses";
import { useMeta } from "vue-meta";
import { useQuizzsStore } from "@/store/quizz";
import CourseLoaderModal from "@/components/modals/CourseLoaderModal.vue";
import { useTeachersStore } from "@/store/teachers";
import { useDestinationsStore } from "@/store/destinations";
export default {
  setup() {
    useMeta({
      title: "New course",
      htmlAttrs: { lang: "en", amp: true },
    });
    const categoriesStore = useCategoriesStore();
    const coursesStore = useCoursesStore();
    const quizzesStore = useQuizzsStore();
    const teachersStore = useTeachersStore();
    const destinationsStore = useDestinationsStore();
    return {
      categoriesStore,
      coursesStore,
      quizzesStore,
      teachersStore,
      destinationsStore
    };
  },

  data() {
    return {
      courseInfoValid: false,
      courseContentValid: false,
      courseQuizzesValid: false,
      courseInfoFormData: null,
      courseInfo: {
        course_title: "",
        description: "",
        price: 0,
        cover: null,
        teacher: null,
        destination: null,
        category: null,
        // cover: course.image ? await this.coursesStore.getCourseImage(course.image.url) : null,
      },
      courseContentFormData: null,
      courseQuizzesFormData: null,
    };
  },

  async mounted() {
    await Promise.all([
      this.teachersStore.getTeachersWithoutPagination(),
      this.categoriesStore.getCategories(),
      this.destinationsStore.getDestinations(),
    ])
    if (this.categoriesStore.categories.length == 0) {
      await this.categoriesStore.getCategories();
    }
  },
  methods: {
    updateCourseInfoValidity(isFormValid) {
      this.courseInfoValid = isFormValid;
    },

    updateCourseContentValidity(isFormValid) {
      this.courseContentValid = isFormValid;
    },

    updateCourseQuizzesValidity(isFormValid) {
      this.courseQuizzesValid = isFormValid;
    },

    getCourseInfo(formData) {
      this.courseInfoFormData = formData;
    },

    getCourseContent(formData) {
      this.courseContentFormData = formData;
    },

    getCourseQuizzes(formData) {
      this.courseQuizzesFormData = formData;
    },

    async addCourse() {
      let addedCourse;
      this.coursesStore.addError = false;
      this.coursesStore.errorMessages = [];
      if (!this.quizzesStore.validateQuizzesArray()) {
        // if quizz validation is bad break
        return;
      }
      try {
        this.coursesStore.loaderMessage = "Creating course main data";
        addedCourse = await this.coursesStore.addCourse(
          this.courseInfoFormData
        );

        this.coursesStore.loaderMessage = "Attaching lessons";
        await Promise.all(
          this.courseContentFormData.map(async (element) => {
            element.append("course_id", addedCourse.id);
            return await this.coursesStore.addLesson(element);
          })
        );
        this.courseQuizzesFormData.course_id = addedCourse.id;
        if (this.quizzesStore.quizzs.length > 0) {
          this.coursesStore.loaderMessage = "Attaching quizzes";
          await this.quizzesStore.addCourseQuizzes(this.courseQuizzesFormData);
        }
        this.coursesStore.isLoadingModal = false;
        this.$swal({
          icon: "success",
          title: "Course added successfully",
          showConfirmButton: true,
          willClose: () => {
            this.coursesStore.addError = false;
            this.coursesStore.addSuccess = false;
            this.coursesStore.loaderMessage = "";
            this.$router.push({ path: "/admin/courses" });
          },
        });
      } catch (error) {
        this.$swal({
          icon: "danger",
          title: "Error accured while adding the course !",
          html: `<ul>
            ${this.coursesStore.errorMessages.map((error) => {
              return error.map((message) => {
                return `<li class="text-red-500">
                      ${message}
                    </li>`;
              });
            })}
          </ul>`,
          willClose: async () => {
            this.coursesStore.addSuccess = false;
            this.coursesStore.addError = false;
            this.coursesStore.errorMessages = [];
            if (addedCourse)
              await this.coursesStore.deleteNewCourse(addedCourse.id);
          },
        });
        console.log(error);
      }
    },
  },

  components: {
    InformationForm,
    ContentForm,
    QuizzesNumber,
    CourseLoaderModal,
  },
};
</script>
<style></style>
