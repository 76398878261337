<template>
  <div class="affiliation-actions w-10 mx-auto">
    <a
      class="text-blueGray-500 block"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <div class="items-center flex justify-center">
        <img :src="actions" alt="actions" />
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-xl border border-[#e3e3e3] min-w-[140px]"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <router-link
        class="text-sm py-2 px-4 font-normal flex items-center w-full hover:bg-slate-200"
        :to="'/admin/affiliation/' + affiliation.id"
      >
        <img :src="editIcon" class="mr-2" alt="editIcon" />

        Edit</router-link
      >
      <a
        v-if="affiliation.status == 'active'"
        href="javascript:void(0);"
        @click="toggleBlock"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="block" class="mr-2" alt="block" />

        Block
      </a>
      <a
        v-if="affiliation.status == 'inactive'"
        href="javascript:void(0);"
        @click="toggleUnblock"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="block" class="mr-2" alt="block" />

        Unblock
      </a>
      <a
        href="javascript:void(0);"
        @click="toggleDelete"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="deleteIcon" class="mr-2" alt="block" />

        Delete
      </a>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import actions from "@/assets/icons/cards/threeDots.svg";
import editIcon from "@/assets/icons/cards/editIcon.svg";
import deleteIcon from "@/assets/icons/cards/deleteIcon.svg";
import { useAffiliationsStore } from "@/store/affiliations";
import block from "@/assets/icons/cards/block.svg";

export default {
  props: ["affiliation"],
  data() {
    return {
      dropdownPopoverShow: false,
      showBlockModal: false,
      showUnBlockModal: false,
    };
  },

  setup() {
    const affiliationsStore = useAffiliationsStore();
    return {
      affiliationsStore,
      actions,
      editIcon,
      block,
      deleteIcon,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        const dropdowns = document.querySelectorAll(".affiliation-actions");
        // Add click event listener to each dropdown component
        dropdowns.forEach(function (dropdown) {
          dropdowns.forEach(() => {
            dropdown.children[1].classList.add("hidden");
          });
        });
        this.$refs.popoverDropdownRef.classList.remove("hidden");

        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-end",
        });
      }
    },

    toggleDelete() {
      this.$swal({
        title: "Do you want to save the changes?",
        showCancelButton: true,
        confirmButtonColor: "#00DDAF",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm!",
        cancelButtonText: "Cancel!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.affiliationsStore
            .deleteAffiliation(this.affiliation.id)
            .then(() => {
              this.$swal("Influencer deleted successfully!", "", "success");
              this.affiliationsStore.getAffiliations();
            })
            .catch(() => {
              this.$swal("something wrong happened try again !", "", "warning");
            });
        }
      });
      const dropdowns = document.querySelectorAll(".affiliation-actions");
      // Add click event listener to each dropdown component
      dropdowns.forEach(function (dropdown) {
        dropdowns.forEach(() => {
          dropdown.children[1].classList.add("hidden");
        });
      });
    },

    toggleBlock() {
      const dropdowns = document.querySelectorAll(".affiliation-actions");
      // Add click event listener to each dropdown component
      dropdowns.forEach(function (dropdown) {
        dropdowns.forEach(() => {
          dropdown.children[1].classList.add("hidden");
        });
      });
      this.$swal({
        title: "Do you want to save the changes?",
        showCancelButton: true,
        confirmButtonColor: "#00DDAF",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm!",
        cancelButtonText: "Cancel!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.affiliationsStore
            .blockAffiliation(this.affiliation.id)
            .then(() => {
              this.$swal("Influencer blocked successfully!", "", "success");
              this.affiliationsStore.getAffiliations();
            })
            .catch(() => {
              this.$swal("something wrong happened try again !", "", "warning");
            });
        }
      });
    },

    toggleUnblock() {
      const dropdowns = document.querySelectorAll(".affiliation-actions");
      // Add click event listener to each dropdown component
      dropdowns.forEach(function (dropdown) {
        dropdowns.forEach(() => {
          dropdown.children[1].classList.add("hidden");
        });
      });
      this.$swal({
        title: "Do you want to save the changes?",
        showCancelButton: true,
        confirmButtonColor: "#00DDAF",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm!",
        cancelButtonText: "Cancel!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.affiliationsStore
            .unblockAffiliation(this.affiliation.id)
            .then(() => {
              this.$swal("Influencer unblocked successfully!", "", "success");
              this.affiliationsStore.getAffiliations();
              const dropdowns = document.querySelectorAll(
                ".affiliation-actions"
              );
              // Add click event listener to each dropdown component
              dropdowns.forEach(function (dropdown) {
                dropdowns.forEach(() => {
                  dropdown.children[1].classList.add("hidden");
                });
              });
            })
            .catch(() => {
              this.$swal("something wrong happened try again !", "", "warning");
            });
        }
      });
    },
  },
};
</script>
