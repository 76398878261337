<template>
  <div>
    <div
      class="relative min-w-0 break-words w-full mb-6 shadow-md mt-4 rounded-xl border border-[#e8e8e8c9] bg-white"
    >
      <DeleteCategoryModal v-if="false" />
      <LoaderModal v-if="categoriesStore.isLoadingModal" />
      <div class="block w-full overflow-x-auto">
        <!-- News table -->
        <table
          class="items-center w-full bg-transparent border border-[#e8e8e8c9] rounded-xl overflow-hidden"
          v-if="categoriesStore.categories.length > 0"
        >
          <thead class="">
            <tr class="border-[#e8e8e8c9] border">
              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
              >
                Category name
              </th>
              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
              >
                Destination
              </th>
              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
              >
                Number of lessons
              </th>
              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(category, index) in categoriesStore.categories"
              :key="index"
            >
              <td
                class="align-middle p-4 text-purple font-medium flex items-center"
              >
                <p>{{ category.name }}</p>
              </td>
              <td class="align-middle whitespace-nowrap text-left">
                <p>{{ category.destination }}</p>
              </td>

              <td class="align-middle whitespace-nowrap p-4 text-center">
                <p>{{ category.courses_count }}</p>
              </td>
              <td class="align-middle text-center">
                <CategoriesDropdown
                  :category="category"
                  :categoryId="category.id"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="flex flex-col justify-center items-center py-10">
          <img :src="noRecords" alt="noRecords" class="w-2/6" />
          <div class="text-xl text-[#747373]">No records at the moment !</div>
        </div>
      </div>
    </div>
    <div class="flex justify-between items-center">
      <div>
        Showing {{ categoriesStore.from }}-{{ categoriesStore.to }} from
        {{ categoriesStore.categories.length }} data
      </div>
      <div class="flex items-center">
        <div v-if="categoriesStore.currentPage != 1">
          <img
            @click="categoriesStore.previousPage()"
            :src="forward"
            class="cursor-pointer"
            alt="forward"
          />
        </div>

        <div
          class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12"
          :class="
            true
              ? 'bg-cyan text-white border-cyan'
              : ' text-gray border border-gray'
          "
        >
          {{ categoriesStore.currentPage }}
        </div>

        <div
          v-if="categoriesStore.currentPage + 1 <= categoriesStore.totalPages"
        >
          <img
            class="cursor-pointer"
            @click="categoriesStore.nextPage()"
            :src="back"
            alt="back"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import checkbox from "@/assets/icons/cards/checkbox.svg";
import separator from "@/assets/icons/cards/separator.svg";
import deleteReview from "@/assets/icons/cards/deleteReview.svg";
import avatarUser from "@/assets/icons/cards/avatarUser.svg";
import noRecords from "@/assets/images/noRecords.svg";
import { useCategoriesStore } from "@/store/categories";
import CategoriesDropdown from "../dropdowns/CategoriesDropdown.vue";
import DeleteCategoryModal from "../modals/courses/categories/DeleteCategoryModal.vue";
import back from "../../assets/icons/cards/back.svg";
import forward from "../../assets/icons/cards/forward.svg";
import LoaderModal from "../modals/LoaderModal.vue";

export default {
  components: { CategoriesDropdown, DeleteCategoryModal, LoaderModal },
  data() {
    return {};
  },
  setup() {
    const categoriesStore = useCategoriesStore();
    return {
      back,
      forward,
      checkbox,
      avatarUser,
      deleteReview,
      separator,
      categoriesStore,
      noRecords,
    };
  },
  async mounted() {
    await this.categoriesStore.getCategories();
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
