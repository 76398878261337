<template>
  <div></div>
</template>
<script>
import { useTeachersStore } from "@/store/teachers";

export default {
  setup() {
    const teacherStore = useTeachersStore();
    const { isAddTeacherFail } = useTeachersStore();
    const { isAddTeacherSuccess } = useTeachersStore();
    return {
      teacherStore,
      isAddTeacherFail,
      isAddTeacherSuccess,
    };
  },
  mounted() {
    if (this.teacherStore.isAddTeacherFail) {
      this.$swal({
        icon: "danger",
        title: "Error accured while adding the teacher !",
        html: `<ul>
            ${this.teacherStore.errorMessages.map((error) => {
          return error.map((message) => {
            return `<li class="text-red-500">
                      ${message}
                    </li>`
          })
        })}
          </ul>`,
        willClose: () => {
          this.teacherStore.isAddTeacherFail = false;
          this.teacherStore.isAddTeacherSuccess = false;
          this.teacherStore.isShowEditor = false;
          this.teacherStore.errorMessages = []
        },
      });
    }
  },
};
</script>
