import { defineStore } from "pinia";
import axios from "@/axios/axios";
import { useAuthStore } from "./auth";
export const useAffiliationsStore = defineStore("affiliationsStore", {
  state: () => ({
    affiliations: [],
    affiliation: null,
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    from: 0,
    to: 0,
    influencer: null,
    isBlockModal: false,
    isUnblockModal: false,
    isLoadingModal: false,
    isSuccess: false,
    isFail: false,
    isShowEditor: false,
    isAddAffiliationSuccess: false,
    isAddAffiliationFail: false,
    isEditAffiliationSuccess: false,
    isEditAffiliationFail: false,
    errorMessages: [],
    editedAffiliation: null,
  }),
  actions: {
    async getAffiliations(query) {
      const authStore = useAuthStore();
      try {
        if (!query) {
          this.isLoadingModal = true;
        } else {
          authStore.isLoading = true;
        }
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${
            process.env.VUE_APP_API_BASE
          }influencer/admin/get?paginate=true&page=${this.currentPage}${
            query ? `&q=${encodeURIComponent(query)}` : ""
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        this.affiliations = data.data.data;
        this.from = data.data.from;
        this.to = data.data.to;
        this.totalPages = data.data.total;
        this.currentPage = data.data.current_page;
        this.isLoadingModal = false;
        authStore.isLoading = false;
      } catch (err) {
        this.isLoadingModal = false;
        authStore.isLoading = false;
        console.log(err);
      }
    },

    async getAffiliation() {
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}setting/admin/get/std_affiliation`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        this.affiliation = data.data;
        console.log(this.affiliation);

        this.isLoadingModal = false;
        return data.data;
      } catch (err) {
        this.isLoadingModal = false;
        console.log(err);
      }
    },
    async getInfluencer(id) {
      this.influencer = null;
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}influencer/admin/get/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        this.influencer = data.data;
        console.log(this.influencer);

        this.isLoadingModal = false;
        return data.data;
      } catch (err) {
        this.isLoadingModal = false;
        console.log(err);
      }
    },
    async addAffiliation(affiliation) {
      try {
        this.isLoadingModal = true;
        this.isAddAffiliationSuccess = false;
        this.isAddAffiliationFail = false;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.post(
          `${process.env.VUE_APP_API_BASE}influencer/admin/store`,
          affiliation,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isAddAffiliationSuccess = true;
        this.affiliations = data.data;
        // this.from = data.data.meta.from
        // this.to = data.data.meta.to
        // this.totalPages = data.data.meta.total;
        // this.currentPage = data.data.meta.current_page;
        this.isLoadingModal = false;
      } catch (err) {
        this.isAddAffiliationFail = true;
        console.log(err);
        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
      }
    },

    async updateAffiliation(affiliation, affiliationId) {
      try {
        this.isLoadingModal = true;
        this.isEditAffiliationSuccess = false;
        this.isEditAffiliationFail = false;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.post(
          `${process.env.VUE_APP_API_BASE}influencer/admin/update/${affiliationId}`,
          affiliation,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isEditAffiliationSuccess = true;
        this.affiliations = data.data;
        // this.from = data.data.meta.from
        // this.to = data.data.meta.to
        // this.totalPages = data.data.meta.total;
        // this.currentPage = data.data.meta.current_page;
        this.isLoadingModal = false;
      } catch (err) {
        this.isEditAffiliationFail = true;
        console.log(err);
        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
      }
    },

    async blockAffiliation(affiliationId) {
      this.isLoadingModal = true;
      this.isFail = false;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}influencer/admin/block/${affiliationId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        await this.getAffiliations();
      } catch (err) {
        this.isLoadingModal = false;
        this.isFail = true;
        console.log(err);
      }
    },
    async unblockAffiliation(affiliationId) {
      this.isLoadingModal = true;
      this.isFail = false;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}influencer/admin/unblock/${affiliationId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        await this.getAffiliations();
      } catch (err) {
        this.isLoadingModal = false;
        this.isFail = true;
        console.log(err);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getAffiliations();
      }
    },

    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getAffiliations();
      }
    },
  },
});
