<template>
  <div
    class="text-black text-center p-4 shadow-md mx-auto my-6 md:my-0 md:mx-6 w-full flex flex-col justify-between break-words max-w-xs md:min-w-[250px]"
  >
    <div class="font-normal text-4xl">{{ price }} DA</div>
    <div class="text-center mt-4 text-xl">And get</div>
    <div class="my-3">{{ title }}</div>
    <div>
      <div
        v-for="(item, index) in myOptions"
        :key="index"
        class="my-3 flex items-center justify-center"
      >
        <svg
          width="18"
          class="mr-2"
          height="15"
          viewBox="0 0 18 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_37_2307)">
            <path
              d="M7.06079 14.002C6.56644 14.002 6.09823 13.8134 5.79821 13.4884L1.73773 9.10421C1.61219 8.96881 1.52054 8.81453 1.46802 8.65017C1.41549 8.48581 1.40312 8.3146 1.43162 8.14634C1.46012 7.97807 1.52892 7.81604 1.63409 7.66952C1.73927 7.52299 1.87875 7.39485 2.04457 7.2924C2.21033 7.18952 2.3993 7.11439 2.60066 7.07131C2.80201 7.02823 3.0118 7.01804 3.21798 7.04134C3.42416 7.06463 3.6227 7.12094 3.8022 7.20705C3.9817 7.29316 4.13864 7.40737 4.26402 7.54314L6.93578 10.4257L13.6533 1.61087C13.876 1.31983 14.231 1.11287 14.6402 1.0354C15.0495 0.95793 15.4796 1.01627 15.8363 1.19762C16.5784 1.57465 16.8069 2.3733 16.3432 2.98064L8.40633 13.3909C8.27084 13.5694 8.08409 13.7182 7.86229 13.8244C7.64048 13.9305 7.39032 13.9908 7.13352 14.0001C7.10852 14.002 7.08579 14.002 7.06079 14.002Z"
              fill="#1B1F2A"
            />
          </g>
          <defs>
            <clipPath id="clip0_37_2307">
              <rect
                width="16.12"
                height="13.52"
                fill="white"
                transform="translate(0.940033 0.742188)"
              />
            </clipPath>
          </defs>
        </svg>
        {{ item }}
      </div>
    </div>

    <div class="my-3">
      <button
        @click="editPlan(id)"
        class="btn bg-cyan shadow-none px-10 font-semibold"
      >
        Edit
      </button>
    </div>
  </div>
</template>
<script>
import { usePlansStore } from "@/store/plans";

export default {
  setup() {
    const plansStore = usePlansStore();
    return { plansStore };
  },
  props: ["price", "id", "description", "options", "title", "type"],
  mounted() {
    this.myOptions = Array.isArray(this.options)
      ? this.options
      : JSON.parse(this.options);
  },
  data() {
    return {
      myOptions: [],
    };
  },
  methods: {
    editPlan() {
      const plan = {
        id: this.id,
        price: this.price,
        description: this.description,
        options: this.myOptions,
        title: this.title,
      };
      this.plansStore.editedPlan = plan;
      this.$router.push(`/admin/subscription/${this.id}`);
    },
  },
};
</script>
<style></style>
