<template>
    <div>
        <LoaderModal v-if="reviewsTeachersAndCoursesStore.isLoadingModal" />
        <div
            class="relative min-w-0 break-words w-full mb-6 shadow-sm mt-4 rounded-xl border border-[#e8e8e8c9] bg-white"
        >
            <div class="block w-full overflow-x-auto">
                <div
                    v-if="
                        !reviewsTeachersAndCoursesStore?.coursesReviews.length >
                        0
                    "
                    class="flex flex-col justify-center items-center py-10"
                >
                    <img :src="noRecords" alt="noRecords" class="w-2/6" />
                    <div class="text-xl text-[#747373]">
                        No records at the moment !
                    </div>
                </div>
                <table
                    class="items-center w-full bg-transparent border border-[#e8e8e8c9] rounded-xl overflow-hidden"
                    v-else
                >
                    <thead class="">
                        <tr class="border-[#e8e8e8c9] border">
                            <th
                                class="text-purple px-6 flex items-center align-middle py-6 uppercase -0 font-medium text-left"
                            >
                                The student
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
                            >
                                Date
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
                            >
                                The course
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
                            >
                                The comment
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
                            >
                                Stars
                            </th>

                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
                            >
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(
                                course, index
                            ) in reviewsTeachersAndCoursesStore.coursesReviews"
                            :key="index"
                        >
                            <td
                                class="align-middle p-4 text-purple flex items-center"
                            >
                                <img
                                    v-if="!course.student.user.image?.url"
                                    :src="avatarUser"
                                    class="w-10 h-10 mr-2"
                                    alt="dfsd"
                                />
                                <img
                                    v-if="course.student.user.image?.url"
                                    :src="report.student.user.image?.url"
                                    class="w-10 h-10 mr-2"
                                    alt="dfsd"
                                />
                                {{
                                    course.student.user.first_name +
                                    " " +
                                    course.student.user.last_name
                                }}
                            </td>
                            <td class="align-middle p-4">
                                {{ course.created_at.slice(0, 10) }}
                            </td>
                            <td class="align-middle p-4 text-center">
                                {{ course.commentable?.title }}
                            </td>
                            <td class="align-middle p-4 text-center">
                                {{ course.content }}
                            </td>
                            <td class="align-middle whitespace-nowrap p-4">
                                <rating-stars
                                    :review="
                                        parseInt(course.commentable?.total_rate)
                                    "
                                />
                            </td>

                            <td
                                class="align-middle text-right"
                                @click="
                                    reviewsTeachersAndCoursesStore.showDeleteModal = true
                                "
                            >
                                <img
                                    :src="deleteReview"
                                    class="cursor-pointer mx-auto"
                                    alt="deleteReview"
                                />
                                <DeleteCoursesTeacherReviewsModal
                                    v-if="
                                        reviewsTeachersAndCoursesStore.showDeleteModal
                                    "
                                    :reviewId="course.id"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="flex justify-between items-center px-2 py-2">
            <div>
                Showing {{ reviewsTeachersAndCoursesStore.from }}-{{
                    reviewsTeachersAndCoursesStore.to
                }}
                from {{ reviewsTeachersAndCoursesStore.totalRecords }} data
            </div>
            <div class="flex items-center">
                <div v-if="reviewsTeachersAndCoursesStore.currentPage != 1">
                    <img
                        @click="reviewsTeachersAndCoursesStore.previousPage()"
                        :src="forward"
                        class="cursor-pointer"
                        alt="forward"
                    />
                </div>

                <div
                    v-if="reviewsTeachersAndCoursesStore.currentPage > 1"
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
                    :class="{
                        'text-gray border border-gray': true,
                        'bg-cyan text-white border-cyan': false,
                    }"
                    @click="reviewsTeachersAndCoursesStore.previousPage()"
                >
                    {{ reviewsTeachersAndCoursesStore.currentPage - 1 }}
                </div>

                <div
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12"
                    :class="
                        true
                            ? 'bg-cyan text-white border-cyan'
                            : ' text-gray border border-gray'
                    "
                >
                    {{ reviewsTeachersAndCoursesStore.currentPage }}
                </div>

                <div
                    v-if="
                        reviewsTeachersAndCoursesStore.currentPage <
                        reviewsTeachersAndCoursesStore.totalPages
                    "
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
                    :class="{
                        'text-gray border border-gray': true,
                        'bg-cyan text-white border-cyan': false,
                    }"
                    @click="reviewsTeachersAndCoursesStore.nextPage()"
                >
                    {{ reviewsTeachersAndCoursesStore.currentPage + 1 }}
                </div>

                <div
                    v-if="
                        reviewsTeachersAndCoursesStore.currentPage <
                        reviewsTeachersAndCoursesStore.totalPages
                    "
                >
                    <img
                        class="cursor-pointer"
                        @click="reviewsTeachersAndCoursesStore.nextPage()"
                        :src="back"
                        alt="back"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DeleteCoursesTeacherReviewsModal from "@/components/modals/reviews/DeleteCoursesTeacherReviewsModal.vue";
import checkbox from "../../assets/icons/cards/checkbox.svg";
import separator from "../../assets/icons/cards/separator.svg";
import deleteReview from "../../assets/icons/cards/deleteReview.svg";
import avatarUser from "../../assets/icons/cards/avatarUser.svg";
import back from "../../assets/icons/cards/back.svg";
import forward from "../../assets/icons/cards/forward.svg";

import noRecords from "../../assets/images/noRecords.svg";
import RatingStars from "../cards/partials/RatingStars.vue";
import { useReviewsTeachersAndCourses } from "@/store/reviewsTeachersAndCourses";
import LoaderModal from "../modals/LoaderModal.vue";
export default {
    components: {
        DeleteCoursesTeacherReviewsModal,
        RatingStars,
        LoaderModal,
    },
    data() {
        return {};
    },
    setup() {
        const reviewsTeachersAndCoursesStore = useReviewsTeachersAndCourses();
        return {
            forward,
            back,
            checkbox,
            avatarUser,
            deleteReview,
            separator,
            noRecords,
            reviewsTeachersAndCoursesStore,
        };
    },
    async mounted() {
        this.reviewsTeachersAndCoursesStore.showDeleteModal = false;
        await this.reviewsTeachersAndCoursesStore.getCoursesReviews();
    },
    props: {
        color: {
            default: "light",
            validator: function (value) {
                // The value must match one of these strings
                return ["light", "dark"].indexOf(value) !== -1;
            },
        },
    },
};
</script>
