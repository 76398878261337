import { defineStore } from "pinia";
import axios from "@/axios/axios";

export const useProfileStore = defineStore("profileStore", {
  state: () => ({
    reviews: [],
    isSuccessEdit: false,
    isFailUpdate: false,
    isLoading: false,
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    showDeleteModal: false,
  }),
  actions: {
    async updateProfileData() {
      try {
        this.isLoading = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}reviews/getUserReviews`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.reviews = data.userReviews;
        this.isLoading = false;
        this.isSuccessEdit = true;
      } catch (err) {
        this.isFailUpdate = true;

        this.isLoading = false;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
});
