<template>
  <div class="w-full mt-4">
    <div
      class="flex justify-between items-center bg-purple text-white font-bold text-3xl p-5 rounded-t-xl border-[#c9c9c9] border-b-0 border">
      <span> Quizzes </span>
      <svg v-if="!isValid" xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 512 512">
        <circle cx="256" cy="256" r="248" stroke="#00DDAF" fill="none" stroke-width="16" />
      </svg>
      <svg v-else xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 512 512">
        <path
          d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zM227.3 387.3l184-184c6.2-6.2 6.2-16.4 0-22.6l-22.6-22.6c-6.2-6.2-16.4-6.2-22.6 0L216 308.1l-70.1-70.1c-6.2-6.2-16.4-6.2-22.6 0l-22.6 22.6c-6.2 6.2-6.2 16.4 0 22.6l104 104c6.2 6.2 16.4 6.2 22.6 0z"
          fill="#00DDAF" />
      </svg>
    </div>
    <form @submit.prevent="null" class="w-full border-[#c9c9c9] border border-t-0 p-4 rounded-b-xl">
      <div class="md:flex">
        <div class="md:w-6/12 md:mr-6 my-3">
          <p class="text-purple mb-1 text-left">Quizz title</p>
          <div class="flex items-center justify-between border-[#c9c9c9] border rounded-md py-3 newPassword-container"
            :class="[
              v$.newQuizTitle.$error ? 'border-red-500 border-[3px]' : '',
            ]">
            <input class="text-gray-700 px-2 leading-tight focus:outline-none w-full newPassword-input" type="text"
              v-model="newQuizTitle" name="newQuizeTitle" />
          </div>
          <p class="text-red-500" v-if="v$.newQuizTitle.$error">
            The title is required
          </p>
        </div>
        <div class="md:w-6/12 md:mr-6 my-3">
          <p class="text-purple mb-1 text-left">Number of questions</p>
          <input type="number" :min="quizzsStore.quizzs.length" v-model="newQuizQuestionsNumber"
            class="w-full md:mr-6 py-[10px] border-[#c9c9c9] border rounded-md newPassword-container outline-none px-1" />
          <p class="text-red-500" v-if="v$.newQuizQuestionsNumber.$error">
            The number of questions is required
          </p>
        </div>
      </div>
      <div>
        <button :disabled="quizzsStore.quizzs.length >= newQuizQuestionsNumber" :class="`btn btn bg-cyan font-bold my-2 mx-2 ${quizzsStore.quizzs.length >= newQuizQuestionsNumber
            ? 'opacity-50'
            : 'opacity-100'
          }`" @click="quizzsStore.createQcm()">
          Add a QCM
        </button>
        <!-- <button :disabled="quizzsStore.quizzs.length >= newQuizQuestionsNumber" :class="`btn btn bg-cyan font-bold my-2 mx-2 ${quizzsStore.quizzs.length >= newQuizQuestionsNumber
          ? 'opacity-50'
          : 'opacity-100'
          }`" @click="quizzsStore.createQcs()">
          Add a QCS
        </button> -->
        <button :disabled="quizzsStore.quizzs.length >= newQuizQuestionsNumber" :class="`btn btn bg-cyan font-bold my-2 mx-2 ${quizzsStore.quizzs.length >= newQuizQuestionsNumber
            ? 'opacity-50'
            : 'opacity-100'
          }`" @click="quizzsStore.createFreeQuizz">
          Add a Free quizz
        </button>
      </div>

      <br />

      <div v-for="(quizz, index) in quizzsStore.quizzs" :key="index">
        <div v-if="quizz.type == 'MCQ'">
          <div class="flex items-center">
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Quizz title</p>
              <div
                class="flex items-center justify-between border-[#c9c9c9] border rounded-md py-3 newPassword-container">
                <input class="text-gray-700 px-2 leading-tight focus:outline-none w-full newPassword-input" type="text"
                  v-model="quizz.question" name="newPassword" />
              </div>
            </div>
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Type</p>
              <div
                class="flex items-center justify-between border-[#c9c9c9] border rounded-md py-3 newPassword-container">
                <input class="text-gray-700 px-2 leading-tight focus:outline-none w-full newPassword-input" type="text"
                  value="QCM" readonly name="QCM" />
              </div>
            </div>
            <button class="mx-2 translate-y-2" @click="quizzsStore.RemoveQuizz(index)">
              <svg width="19" class="cursor-pointer" height="19" viewBox="0 0 19 19" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="11" width="16" height="2" fill="#FF6666" />
              </svg>
            </button>
          </div>

          <p class="text-purple mb-1 text-left">Add options</p>
          <div class="md:mr-6 my-3">
            <div v-for="(answer, index) in quizz.answers" :key="index"
              class="flex items-center justify-between border-[#c9c9c9] border rounded-md py-3 newPassword-container mb-4">
              <input class="text-gray-700 px-2 leading-tight focus:outline-none w-full newPassword-input"
                v-model="answer.title" :name="'Option ' + (index + 1)" />
              <button v-if="!answer.is_correct" class="mx-2 btn btn bg-red-600 font-bold"
                @click="quizzsStore.setQcmOptionAsCorrect(quizz, answer)">
                incorrect
              </button>
              <button v-if="answer.is_correct" class="mx-2 btn btn bg-cyan font-bold"
                @click="quizzsStore.setQcmOptionAsIncorrect(quizz, answer)">
                correct
              </button>
              <button class="mx-2" @click="quizzsStore.createQcmOption(quizz)">
                <svg width="19" class="cursor-pointer" height="19" viewBox="0 0 19 19" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.33252 8V1.33333C8.33252 0.596954 8.92947 0 9.66585 0C10.4022 0 10.9992 0.596954 10.9992 1.33333V8H17.6659C18.4022 8 18.9992 8.59695 18.9992 9.33333C18.9992 10.0697 18.4022 10.6667 17.6659 10.6667H10.9992V17.3333C10.9992 18.0697 10.4022 18.6667 9.66585 18.6667C8.92947 18.6667 8.33252 18.0697 8.33252 17.3333V10.6667H1.66585C0.929473 10.6667 0.33252 10.0697 0.33252 9.33333C0.33252 8.59695 0.929473 8 1.66585 8H8.33252Z"
                    fill="#00DDAF" />
                </svg>
              </button>
              <button v-if="quizz.answers.length > 1" class="mx-2" @click="quizzsStore.deleteQcmOption(quizz, index)">
                <svg width="19" class="cursor-pointer" height="19" viewBox="0 0 19 19" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect x="4" y="11" width="16" height="2" fill="#FF6666" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div v-if="quizz.type == 'SCQ'">
          <div class="flex">
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Quizz title</p>
              <div
                class="flex items-center justify-between border-[#c9c9c9] border rounded-md py-3 newPassword-container">
                <input class="text-gray-700 px-2 leading-tight focus:outline-none w-full newPassword-input" type="text"
                  v-model="quizz.question" name="question" />
              </div>
            </div>
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Type</p>
              <div
                class="flex items-center justify-between border-[#c9c9c9] border rounded-md py-3 newPassword-container">
                <input class="text-gray-700 px-2 leading-tight focus:outline-none w-full newPassword-input" type="text"
                  value="QCS" readonly name="question type" />
              </div>
            </div>
            <button class="mx-2 translate-y-2" @click="quizzsStore.RemoveQuizz(index)">
              <svg width="19" class="cursor-pointer" height="19" viewBox="0 0 19 19" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="11" width="16" height="2" fill="#FF6666" />
              </svg>
            </button>
          </div>

          <p class="text-purple mb-1 text-left">Add options</p>
          <div class="md:mr-6 my-3">
            <div v-for="(answer, index) in quizz.answers" :key="index"
              class="flex items-center justify-between border-[#c9c9c9] border rounded-md py-3 newPassword-container mb-4">
              <input class="text-gray-700 px-2 leading-tight focus:outline-none w-full newPassword-input"
                v-model="answer.title" :name="'Option' + (index + 1)" />
              <button v-if="!answer.is_correct" class="mx-2 btn btn bg-red-600 font-bold"
                @click="quizzsStore.setQcsOptionAsCorrect(quizz, answer)">
                Incorrect
              </button>
              <button v-if="answer.is_correct" class="mx-2 btn btn bg-cyan font-bold"
                @click="quizzsStore.setQcsOptionAsIncorrect(quizz, answer)">
                Correct
              </button>
              <button class="mx-2" @click="quizzsStore.createQcsOption(quizz)">
                <svg width="19" class="cursor-pointer" height="19" viewBox="0 0 19 19" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.33252 8V1.33333C8.33252 0.596954 8.92947 0 9.66585 0C10.4022 0 10.9992 0.596954 10.9992 1.33333V8H17.6659C18.4022 8 18.9992 8.59695 18.9992 9.33333C18.9992 10.0697 18.4022 10.6667 17.6659 10.6667H10.9992V17.3333C10.9992 18.0697 10.4022 18.6667 9.66585 18.6667C8.92947 18.6667 8.33252 18.0697 8.33252 17.3333V10.6667H1.66585C0.929473 10.6667 0.33252 10.0697 0.33252 9.33333C0.33252 8.59695 0.929473 8 1.66585 8H8.33252Z"
                    fill="#00DDAF" />
                </svg>
              </button>
              <button v-if="quizz.answers.length > 1" class="mx-2" @click="quizzsStore.deleteQcsOption(quizz, index)">
                <svg width="19" class="cursor-pointer" height="19" viewBox="0 0 19 19" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect x="4" y="11" width="16" height="2" fill="#FF6666" />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div v-if="quizz.type == 'Free'">
          <div class="flex">
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Quizz title</p>
              <div
                class="flex items-center justify-between border-[#c9c9c9] border rounded-md py-3 newPassword-container">
                <input class="text-gray-700 px-2 leading-tight focus:outline-none w-full newPassword-input" type="text"
                  v-model="quizz.question" name="question" />
              </div>
            </div>
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Type</p>
              <div
                class="flex items-center justify-between border-[#c9c9c9] border rounded-md py-3 newPassword-container">
                <input class="text-gray-700 px-2 leading-tight focus:outline-none w-full newPassword-input" type="text"
                  value="FREE" readonly name="question type" />
              </div>
            </div>
            <button class="mx-2 translate-y-2" @click="quizzsStore.RemoveQuizz(index)">
              <svg width="19" class="cursor-pointer" height="19" viewBox="0 0 19 19" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="11" width="16" height="2" fill="#FF6666" />
              </svg>
            </button>
          </div>
          <p class="text-purple mb-1 text-left">Add options</p>
          <div class="md:mr-6 my-3">
            <div v-for="(answer, index) in quizz.answers" :key="index"
              class="flex items-center justify-between border-[#c9c9c9] border rounded-md py-3 newPassword-container mb-4">
              <input class="text-gray-700 px-2 leading-tight focus:outline-none w-full newPassword-input"
                v-model="answer.title" :name="'Option ' + (index + 1)" />

              <button v-if="answer.is_correct" class="mx-2 btn btn bg-cyan font-bold">
                correct
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { required, helpers, minLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useQuizzsStore } from "../../../store/quizz";

export default {
  props: {
    isValid: {
      type: Boolean,
      required: true,
    },

    courseQuizzes: {
      type: Object,
      required: false,
    },
  },

  components: {},
  data() {
    return {
      newQuizTitle: "",
      newQuizQuestionsNumber: "",
    };
  },
  mounted() {
    this.quizzsStore.$reset();
  },
  setup() {
    const quizzsStore = useQuizzsStore();
    return { v$: useVuelidate(), quizzsStore };
  },

  computed: {
    quizzs() {
      return this.quizzsStore.quizzs;
    },
  },

  watch: {
    newQuizTitle(newVal) {
      const data = {
        title: newVal,
        price: 0,
        type: "quiz",
        lesson_id: this.quizzsStore.quizzId,
        quiz: this.quizzsStore.quizzs.map((quizz) => {
          return {
            title: quizz.question,
            type: quizz.type,
            answers: quizz.answers.map((answer) => {
              return {
                title: answer.title,
                is_correct: answer.is_correct,
              };
            }),
          };
        }),
      };
      this.$emit("getCourseQuizzes", data);
      this.$emit("updateValidity", true);
      return;
    },

    courseQuizzes: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.newQuizTitle = newVal[0].title;
          this.newQuizQuestionsNumber = newVal[0].content.questions.length;
          if (newVal[0].id) this.quizzsStore.quizzId = newVal[0].id;
          this.quizzsStore.quizzs = newVal[0].content.questions.map(
            (question) => {
              return {
                id: question.id,
                question: question.title,
                type: question.type,
                answers: question.answers.map((answer) => {
                  return {
                    title: answer.title,
                    is_correct: answer.is_correct,
                  };
                }),
              };
            }
          );
        }
      },
      deep: true,
    },

    newQuizQuestionsNumber(newVal) {
      if (newVal == this.quizzsStore.quizzs.length) {
        const data = {
          title: this.newQuizTitle,
          price: 0,
          type: "quiz",
          quiz: this.quizzsStore.quizzs.map((quizz) => {
            return {
              title: quizz.title,
              type: quizz.type,
              answers: quizz.answers.map((answer) => {
                return {
                  title: answer.title,
                  is_correct:
                    answer.is_correct !== undefined ? answer.is_correct : false,
                };
              }),
            };
          }),
        };
        this.$emit("getCourseQuizzes", data);
        this.$emit("updateValidity", true);
        return;
      } else {
        this.$emit("updateValidity", false);
        return;
      }
    },

    quizzs: {
      handler(newVal) {
        if (newVal.length < this.newQuizQuestionsNumber) {
          this.$emit("updateValidity", false);
          return;
        } else {
          const data = {
            title: this.newQuizTitle,
            price: 0,
            type: "quiz",
            quiz: this.quizzsStore.quizzs.map((quizz) => {
              return {
                title: quizz.question,
                type: quizz.type,
                answers: quizz.answers.map((answer) => {
                  return {
                    title: answer.title,
                    is_correct: answer.is_correct,
                  };
                }),
              };
            }),
          };
          this.$emit("getCourseQuizzes", data);
          this.$emit("updateValidity", true);
          return;
        }
      },
      deep: true,
    },
  },
  validations() {
    return {
      newQuizTitle: {
        required: helpers.withMessage("The title is required", required),
        minLength: helpers.withMessage(
          "The title needs to be longer than 3 characters",
          minLength(3)
        ),
      },
      newQuizQuestionsNumber: { required },
    };
  },
};
</script>

<style scoped>
.upload-block {
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A0A0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
