<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <LoaderModal v-if="roomsStore.isLoadingModal" />
    <div class="my-6 mt-24 w-full">
      <div class="w-full">
        <div class="my-4">
          <h1 class="text-3xl text-black font-semibold">Rooms</h1>
          <h1 class="text-md text-gray font-semibold mt-2">
            Manage your rooms
          </h1>
        </div>
        <div
          class="bg-purple text-white font-bold text-3xl p-5 rounded-t-xl border-[#c9c9c9] border-b-0 border"
        >
          New room
        </div>
        <form class="w-full border-[#c9c9c9] border border-t-0 p-4 rounded-xl">
          <div class="md:flex">
            <div class="md:mr-6 my-3 md:w-6/12">
              <p class="text-purple mb-1 text-left">Room title</p>
              <input
                v-model="room_title"
                :class="
                  v$.room_title.$error ? 'border-red-500 border-[3px]' : ''
                "
                type="text"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p class="text-red-500 text-[1rem]" v-if="v$.room_title.$error">
                <span
                  v-for="(error, index) in v$.room_title.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </p>
            </div>
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Teacher affectation</p>
              <!-- <SelectTeacherDropdown /> -->
              <GenericSelectDropdown
                v-model="currentTeacher"
                :value="currentTeacher"
                :options="this.teachersStore.teachers.data"
                :render="(teacher) => teacher ? teacher.user.first_name + ' ' + teacher.user.last_name : 'Select an option'"
                @updateValue="getNewTeacher"
              />
            </div>
          </div>
          <div class="md:flex">
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Start date</p>
              <VueDatePicker
                v-model="start_date"
                :min-date="getToday()"
                :format="'yyyy-MM-dd HH:mm'"
                :enable-time-picker="true"
                time-picker-inline
              />

              <p class="text-red-500 text-[1rem]" v-if="v$.start_date.$error">
                <span
                  v-for="(error, index) in v$.start_date.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </p>
            </div>
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Duration</p>
              <VueDatePicker
                class="time-picker"
                v-model="duration"
                time-picker
              />

              <p class="text-red-500 text-[1rem]" v-if="v$.duration.$error">
                <span
                  v-for="(error, index) in v$.duration.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </p>
            </div>
          </div>
          <div class="md:flex">
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Price</p>
              <input
                v-model="price"
                min="0"
                type="number"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />

              <p class="text-red-500 text-[1rem]" v-if="v$.price.$error">
                <span v-for="(error, index) in v$.price.$errors" :key="index">
                  {{ error.$message }}
                </span>
              </p>
            </div>
          </div>

          <div class="my-2 mb-4 text-xl rounded-md">
            <p class="text-purple mb-1 text-left">Background</p>
            <label
              class="cursor-pointer w-fit flex items-center"
              for="inputTag"
            >
              <div
                v-if="!this.background"
                class="flex flex-col upload-block justify-center items-center p-2 h-40 w-40 rounded-md border-dashed"
              >
                <p class="text-[#8a8a8ac9] mb-1 text-center text-base mt-2">
                  Click here to select a file
                </p>

                <input
                  class="hidden"
                  hidden
                  id="inputTag"
                  @change="handleBackgroundChange"
                  type="file"
                  accept="image/*"
                />
              </div>

              <img
                v-show="this.background"
                class="h-40 w-40"
                ref="background"
              />
              <img
                @click="removeBackground"
                alt="bbb"
                :src="trash"
                class="ml-2"
                v-if="this.background"
                width="40"
              />
            </label>
            <p class="text-red-500 text-[1rem]" v-if="v$.background.$error">
              <span
                v-for="(error, index) in v$.background.$errors"
                :key="index"
              >
                {{ error.$message }}
              </span>
            </p>
          </div>
        </form>
        <div class="flex justify-start my-4">
          <button class="btn btn bg-cyan font-bold" @click="addRoom">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";
import trash from "@/assets/icons/cards/trash.svg";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
// import SelectTeacherDropdown from "@/components/cards/partials/SelectTeacherDropdown.vue";
import GenericSelectDropdown from "@/components/cards/partials/GenericSelectDropdown.vue";
import router from "@/router";
import { useTeachersStore } from "@/store/teachers";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import { useRoomsStore } from "@/store/rooms";
export default {
  data() {
    return {
      room_title: "",
      destination: "",
      duration: "",
      timing: "",
      start_date: null,
      cover: null,
      price: 0,
      background: null,
      password: "",
      showPassword: false,
      isPasswordFocus: false,
      isEmailFocus: false,
      currentTeacher: null
    };
  },
  setup() {
    const roomsStore = useRoomsStore();
    const teachersStore = useTeachersStore();

    return {
      v$: useVuelidate(),
      roomsStore,
      teachersStore,
      trash,
    };
  },
  methods: {
    getNewTeacher(nt) {
      this.currentTeacher = nt
    },
    async addRoom() {
      const result = await this.v$.$validate();
      if (!result) {
        console.log(!result);
        return;
      } else {
        const formData = new FormData();
        formData.append("title", this.room_title);
        if (this.background instanceof File)
          formData.append("background", this.background);

        formData.append("start_date", this.dateFormat(this.start_date));
        formData.append("duration", this.durationFormat(this.duration));
        formData.append(
          "teacher_id",
          this.currentTeacher?.user?.id
        );
        formData.append("price", this.price);

        // until destination is back -->
        //formData.append("destination", this.roomsStore.newDestination);

        await this.roomsStore.addRoom(formData, this.$route.params.id);
        if (this.roomsStore.isSuccess) {
          this.$swal({
            icon: "success",
            timer: 1500,
            title: "Success...",
            showConfirmButton: false,
            html: "<p class='text-green-500' > Live aded successfully<p/>",
          }).then(() => {
            router.push("/admin/rooms");
          });
        } else if (this.roomsStore.isFail) {
          this.$swal({
            icon: "error",
            title: "Error",
            showConfirmButton: true,
            timer: 4000,
            html: `<ul>
            ${this.roomsStore.errorMessages.map((error) => {
              return error.map((message) => {
                return `<li class="text-red-500">
                      ${message}
                    </li>`;
              });
            })}
          </ul>`,
          });
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
        this.roomsStore.isSuccess = false;
        this.roomsStore.isFail = false;
      }
      // perform async actions
    },

    handleBackgroundChange(event) {
      this.background = event.target.files[0];
      let outputPic = this.$refs.background;
      outputPic.src = URL.createObjectURL(this.background);
      outputPic.width = 200;
    },
    removeBackground() {
      this.$refs.background.src = null;
      this.background = null;
    },
    getToday() {
      const today = new Date();
      today.setDate(today.getDate());
      return today;
    },

    dateFormat(newDate) {
      const day = newDate.getDate();
      const month = newDate.getMonth() + 1;
      const year = newDate.getFullYear();
      const hours = newDate.getHours();
      const minutes = newDate.getMinutes();

      // Add leading zeros if necessary
      const formattedMonth = parseInt(month) < 10 ? `0${month}` : month;
      const formattedDay = parseInt(day) < 10 ? `0${day}` : day;
      const formattedHours = parseInt(hours) < 10 ? `0${hours}` : hours;
      const formattedMinutes = parseInt(minutes) < 10 ? `0${minutes}` : minutes;

      return `${year}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}`;
    },

    durationFormat(duration) {
      const hours = duration.hours;
      const minutes = duration.minutes;

      const formattedHours = parseInt(hours) < 10 ? `0${hours}` : hours;
      const formattedMinutes = parseInt(minutes) < 10 ? `0${minutes}` : minutes;

      return `${formattedHours}:${formattedMinutes}`;
    },
  },
  validations() {
    return {
      room_title: {
        required: helpers.withMessage("room title is required", required),
        minLength: helpers.withMessage(
          "room title must have at least 3 characters",
          minLength(3)
        ),
      },
      start_date: {
        required: helpers.withMessage("start date is required", required),
      },
      duration: {
        required: helpers.withMessage("duration is required", required),
      },
      background: {
        required: helpers.withMessage("background image is required", required),
      },
      price: {
        required: helpers.withMessage("price is required", required),
      },
    };
  },
  async mounted() {
    await this.teachersStore.getTeachersWithoutPagination()
  },
  components: {
    VueDatePicker,
    GenericSelectDropdown,
    LoaderModal,
  },
};
</script>

<style scoped>
.upload-block {
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A0A0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
<style>
.dp__pointer {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
</style>
