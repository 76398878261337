<template>
  <div>
    <LoaderModal v-if="teachersStore.isLoadingModal" />
    <metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | RAYHANE` : `SITE_NAME`
      }}</template>
    </metainfo>
    <div
      class="relative min-w-0 break-words w-full mb-6 shadow-md mt-4 rounded-xl border border-[#e8e8e8c9] bg-white"
    >
      <div class="block w-full overflow-x-auto">
        <div
          v-if="!teachersStore.singleTeacher?.courses?.length > 0"
          class="flex flex-col justify-center items-center py-10"
        >
          <img :src="noRecords" alt="noRecords" class="w-2/6" />
          <div class="text-xl text-[#747373]">No courses at the moment !</div>
        </div>
        <table
          class="items-center w-full bg-transparent border border-[#e8e8e8c9] rounded-xl overflow-hidden"
          v-else
        >
          <thead>
            <tr class="border-[#e8e8e8c9] border">
              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
              >
                Course title
              </th>
              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
              >
                Teacher
              </th>
              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
              >
                Destinantion
              </th>
              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
              >
                Pricing
              </th>
              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
              >
                Subscribers
              </th>
              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
              >
                Comments
              </th>
              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
              >
                total rate
              </th>
              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(course, index) in teachersStore.singleTeacher.courses"
              :key="index"
            >
              <td
                class="align-middle p-4 text-purple font-medium flex items-center"
              >
                <p>{{ course.title }}</p>
              </td>
              <td class="align-middle whitespace-nowrap text-left">
                <p>
                  {{
                    teachersStore.singleTeacher.user.first_name +
                    " " +
                    teachersStore.singleTeacher.user.last_name
                  }}
                </p>
              </td>

              <td class="align-middle whitespace-nowrap p-4 text-center">
                <p>{{ course.destination }}</p>
              </td>
              <td class="align-middle p-4 text-center">
                <p>{{ course.price }}</p>
              </td>

              <td class="align-middle text-center">
                <p>{{ course.subscribers }}</p>
              </td>
              <td class="align-middle text-center">
                <p>{{ course.comments_count }}</p>
              </td>
              <td class="align-middle text-center">
                <p>{{ parseFloat(course.total_rate.toFixed(1)) }}</p>
              </td>
              <td class="align-middle text-center">
                <CoursesDropdown :course="{ id: 1 }" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      v-if="teachersStore.singleTeacher?.courses?.length"
      class="flex justify-between items-center"
    >
      <div>
        Total of {{ teachersStore.singleTeacher?.courses?.length }} records
      </div>
    </div>
  </div>
</template>
<script>
import checkbox from "@/assets/icons/cards/checkbox.svg";
import separator from "@/assets/icons/cards/separator.svg";
import deleteReview from "@/assets/icons/cards/deleteReview.svg";
import avatarUser from "@/assets/icons/cards/avatarUser.svg";
import back from "@/assets/icons/cards/back.svg";
import forward from "@/assets/icons/cards/forward.svg";
import noRecords from "@/assets/images/noRecords.svg";
import CoursesDropdown from "../dropdowns/CoursesDropdown.vue";
import { useTeachersStore } from "@/store/teachers";
import LoaderModal from "../modals/LoaderModal.vue";
import { useMeta } from "vue-meta";

export default {
  components: { CoursesDropdown, LoaderModal },
  data() {
    return {};
  },
  async mounted() {
    await this.teachersStore.getSingleTeacher(this.$route.params.id, false);
  },
  setup() {
    const teachersStore = useTeachersStore();
    useMeta({
      title: "Courses",
      htmlAttrs: { lang: "en", amp: true },
    });
    return {
      back,
      forward,
      checkbox,
      avatarUser,
      deleteReview,
      separator,
      teachersStore,
      noRecords,
    };
  },

  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
