import { defineStore } from "pinia";
import axios from "@/axios/axios";
import { useAuthStore } from "./auth";
import moment from "moment";
export const usePaymentsStore = defineStore("paymentsStore", {
    state: () => ({
        payments: [],
        itemsPerPage: 10,
        totalPages: 0,
        from: 0,
        to: 0,
        currentPage: 1,
        isLoadingModal: false,
        isSuccess: false,
        isFail: false,
        filter: "",
        showRejectModal: false,
        showAcceptModal: false,
        rejectedPaymentId: null,
        acceptedPaymentId: null,
    }),
    actions: {
        async getPayments(query) {
            const authStore = useAuthStore();
            try {
                if (!query) {
                    this.isLoadingModal = true;
                } else {
                    authStore.isLoading = true;
                }
                const admin = localStorage.getItem("rayhane-admin");
                const token = JSON.parse(admin).token;
                const { data } = await axios.get(
                    `${
                        process.env.VUE_APP_API_BASE
                    }payment/admin/get?paginate=true&page=${this.currentPage}${
                        query ? `&q=${encodeURIComponent(query)}` : ""
                    }`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                this.payments = data.data.data?.map((payment) => {
                    return {
                        ...payment,
                        created_at: moment(payment.created_at).format(
                            "YYYY-MM-DD HH:mm"
                        ),
                    };
                });
                this.totalPages = data.data.last_page;
                this.currentPage = data.data.current_page;
                this.totalRecords = data.data.total;
                this.from = data.data.from;
                this.to = data.data.to;
                this.isLoadingModal = false;
                authStore.isLoading = false;
            } catch (err) {
                this.isLoadingModal = false;
                authStore.isLoading = false;
                console.log(err);
            }
        },
        async rejectPayment(paymentId) {
            this.isFail = false;
            this.isSuccess = false;
            this.isLoadingModal = true;
            try {
                const admin = localStorage.getItem("rayhane-admin");
                const token = JSON.parse(admin).token;
                await axios.post(
                    `${process.env.VUE_APP_API_BASE}payment/admin/reject`,
                    {
                        id: paymentId,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                this.isLoadingModal = false;
                this.isSuccess = true;
                await this.getPayments();
            } catch (err) {
                this.isLoadingModal = false;
                this.isFail = true;
                console.log(err);
            }
        },
        async acceptPayment(paymentId) {
            this.isLoadingModal = true;
            this.isSuccess = false;
            this.isFail = false;
            try {
                const admin = localStorage.getItem("rayhane-admin");
                const token = JSON.parse(admin).token;
                await axios.post(
                    `${process.env.VUE_APP_API_BASE}payment/admin/accept`,
                    {
                        id: paymentId,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                this.isLoadingModal = false;
                this.isSuccess = true;
                await this.getPayments();
            } catch (err) {
                this.isLoadingModal = false;
                this.isFail = true;
                console.log(err);
            }
        },
        async substractBalance(userType, userId, amount) {
            this.isLoadingModal = true;
            this.isSuccess = false;
            this.isFail = false;
            try {
                const admin = localStorage.getItem("rayhane-admin");
                const token = JSON.parse(admin).token;
                await axios.post(
                    `${process.env.VUE_APP_API_BASE}payment/admin/subtract`,
                    {
                        type: userType, //required in [teacher,influencer]
                        amount: amount, // integer requied min 0
                        id: userId, // user id   required
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                this.isLoadingModal = false;
                this.isSuccess = true;
            } catch (err) {
                this.isLoadingModal = false;
                this.isFail = true;
                console.log(err);
            }
        },
        async setAffiliationSetting(min_withdraw, referral_reward) {
            this.isLoadingModal = true;
            this.isSuccess = false;
            this.isFail = false;
            try {
                const admin = localStorage.getItem("rayhane-admin");
                const token = JSON.parse(admin).token;
                await axios.post(
                    `${process.env.VUE_APP_API_BASE}setting/admin/set/std_affiliation`,
                    {
                        min_withdraw, // required  integer min 0
                        referral_reward, // required  integer min 0
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                this.isLoadingModal = false;
                this.isSuccess = true;
            } catch (err) {
                this.isLoadingModal = false;
                this.isFail = true;
                console.log(err);
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.getPayments();
            }
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.getPayments();
            }
        },
    },
});
