<template>
  <div class="modal-mask">
    <div class="modal-wrapper rounded-xl">
      <div class="modal-container rounded-xl p-10">
        <div class="flex justify-evenly text-3xl mb-3">
          {{ this.isNewGroup ? this.groupsStore.loaderMessage : null }}
        </div>
        <img
          width="100"
          class="mx-auto"
          height="100"
          src="/img/loading.a9a09ae4.gif"
        />
      </div>
    </div>
  </div>
</template>
<script>
import loading from "@/assets/icons/cards/loading.gif";
import { useGroupsStore } from "@/store/groups";

export default {
  setup() {
    const groupsStore = useGroupsStore();
    return {
      loading,
      groupsStore,
    };
  },
  props: ["isNewGroup"],
};
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.8s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 500px;
  margin: 0px auto;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.loading {
  font-size: 30px;
}

.loading:after {
  content: "...";
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis-dot 1s infinite 0.3s;
  animation-fill-mode: forwards;
  width: 1.25em;
}

@keyframes ellipsis-dot {
  25% {
    content: "";
  }
  50% {
    content: ".";
  }
  75% {
    content: "..";
  }
  100% {
    content: "...";
  }
}
</style>
