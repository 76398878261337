<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full px-4">
      <div class="my-6 flex flex-row-reverse mt-24"></div>
      <club-job-offers-table />
    </div>
  </div>
</template>
<script>
import ClubJobOffersTable from "@/components/tables/ClubJobOffersTable.vue";
export default {
  components: { ClubJobOffersTable },
};
</script>
<style></style>
