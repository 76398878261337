<template>
  <LoaderModal v-if="paymentsStore.isLoadingModal" />
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <div class="my-6 mt-24 w-full">
      <div class="w-full">
        <div class="my-2">
          <h1 class="text-3xl text-black font-semibold">Affiliation</h1>
          <h1 class="text-md text-gray font-semibold">Settings</h1>
        </div>
        <div
          class="bg-purple text-white font-bold text-3xl p-5 rounded-t-xl border-[#c9c9c9] border-b-0 border"
        >
          Edit the affiliation settings for users
        </div>
        <form class="w-full border-[#c9c9c9] border border-t-0 p-4">
          <div class="">
            <div class="md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">
                Rewards from Referral Code
              </p>
              <input
                v-model="rewards"
                type="number"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p class="text-red-500" v-if="v$.rewards.$error">
                rewards is required
              </p>
            </div>
            <div class="md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Limit of Withdraw</p>
              <input
                v-model="limit"
                type="number"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p class="text-red-500" v-if="v$.limit.$error">
                You exceed the current balance
              </p>
            </div>
          </div>
        </form>
        <div class="flex justify-start my-4">
          <button
            class="btn btn bg-cyan font-bold"
            @click="setAffiliationSetting"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { usePaymentsStore } from "@/store/payments";
import router from "@/router";
import { useAffiliationsStore } from "@/store/affiliations";
import LoaderModal from "@/components/modals/LoaderModal.vue";
export default {
  data() {
    return {
      rewards: 1000,
      limit: 0,
    };
  },
  async mounted() {
    await this.affiliationsStore.getAffiliation();
    if (!this.affiliationsStore.affiliation) {
      router.push("/admin/affiliation");
    }
    this.rewards = this.affiliationsStore.affiliation.STD_REFERRAL_REWARD;
    this.limit = this.affiliationsStore.affiliation.STD_WITHDRAW_MIN;
  },
  setup() {
    const paymentsStore = usePaymentsStore();
    const affiliationsStore = useAffiliationsStore();
    return { v$: useVuelidate(), paymentsStore, affiliationsStore };
  },
  methods: {
    async setAffiliationSetting() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      await this.paymentsStore.setAffiliationSetting(this.limit, this.rewards);
      if (this.paymentsStore.isFail) {
        this.$swal({
          icon: "error",
          title: "something went wrong",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        this.$swal({
          icon: "success",
          title: `affiliation settings edited successfully`,
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          router.push("/admin/affiliation");
        });
      }
    },
  },
  validations() {
    return {
      rewards: { required },
      limit: { required },
    };
  },
  components: { LoaderModal },
};
</script>

<style scoped>
.upload-block {
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A0A0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
