<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <div class="my-6 mt-24 w-full">
      <div class="flex justify-between items-center mb-4">
        <div>
          <h1 class="text-3xl text-black font-semibold">Manage lives</h1>
          <h1 class="text-xl text-gray font-semibold mt-3">
            Manage your lives
          </h1>
        </div>
        <div>
          <router-link to="/admin/lives/new">
            <button class="btn bg-cyan font-bold">
              <span class="text-xl">+ </span> New live
            </button></router-link
          >
        </div>
      </div>

      <LivesTable />
    </div>
  </div>
</template>
<script>
import LivesTable from "@/components/tables/LivesTable.vue";
import { useLivesStore } from "@/store/lives";
export default {
  setup() {
    const livesStore = useLivesStore();
    return {
      livesStore,
    };
  },
  components: { LivesTable },
};
</script>
<style></style>
