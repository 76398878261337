<template>
  <div class="user-actions w-10 mx-auto">
    <a
      class="text-blueGray-500 block"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <div class="items-center flex justify-center">
        <img :src="actions" alt="actions" />
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-xl border border-[#e3e3e3] min-w-[140px]"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <p
        class="text-sm py-2 px-4 font-normal flex items-center w-full hover:bg-slate-200"
        href="#"
        @click="redirectToEdit(categoryId)"
      >
        <img :src="editIcon" class="mr-2" alt="editIcon" />

        Edit
      </p>
      <a
        href="#"
        @click="showDeleteModal($event)"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="deleteIcon" class="mr-2" alt="block" />

        Delete
      </a>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import actions from "@/assets/icons/cards/threeDots.svg";
import editIcon from "@/assets/icons/cards/editIcon.svg";
import reviewsIcon from "@/assets/icons/cards/reviewsIcon.svg";
import deleteIcon from "@/assets/icons/cards/deleteIcon.svg";
import { useCategoriesStore } from "@/store/categories";
export default {
  props: ["categoryId", "is_blocked", "category"],
  data() {
    return {
      dropdownPopoverShow: false,
    };
  },
  setup() {
    const categoriesStore = useCategoriesStore();
    return {
      reviewsIcon,
      categoriesStore,
      actions,
      editIcon,
      deleteIcon,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        const dropdowns = document.querySelectorAll(".user-actions");
        // Add click event listener to each dropdown component
        dropdowns.forEach(function (dropdown) {
          dropdowns.forEach(() => {
            dropdown.children[1].classList.add("hidden");
          });
        });
        this.$refs.popoverDropdownRef.classList.remove("hidden");

        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-end",
        });
      }
    },
    redirectToEdit(categoryId) {
      this.categoriesStore.editedCategory = this.category;
      this.$router.push("/admin/courses/categories/" + categoryId);
    },

    showDeleteModal() {
      this.$swal({
        title: "Do you want to save the changes?",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Save",
        denyButtonText: `Don't save`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.categoriesStore.deleteCategory(this.categoryId).then(() => {
            const dropdowns = document.querySelectorAll(".user-actions");
            // Add click event listener to each dropdown component
            dropdowns.forEach(function (dropdown) {
              dropdowns.forEach(() => {
                dropdown.children[1].classList.add("hidden");
              });
            });
            if (this.categoriesStore.deleteError) {
              this.$swal({
                icon: "error",
                title: `error deleting the category`,
                html: `</ol>${this.categoriesStore.errorMessages.map(
                  (error) => {
                    if (typeof error == "object") {
                      return error.map((message) => {
                        return `<li class="text-red-500 text-2xl">
                      ${message}
                    </li>`;
                      });
                    } else {
                      return `<li class="text-red-500 text-2xl">
                      ${error}
                    </li>`;
                    }
                  }
                )}
          </ol>`,
                showConfirmButton: true,
                timer: 4000,
              });
            } else {
              this.$swal({
                icon: "success",
                title: `category deleted successfuly`,
                showConfirmButton: true,
                timer: 4000,
              }).then(() => {
                this.categoriesStore.getCategories();
              });
            }
          });
        }
      });
    },
  },
};
</script>
