<template>
    <div>
        <DeleteOfferModal
            v-if="offersStore.showDeleteModal"
            :offerId="offersStore.singleOffer.id"
        />

        <div
            class="relative min-w-0 break-words w-full mb-6 shadow-md mt-4 rounded-xl border border-[#e8e8e8c9] bg-white"
        >
            <div class="block w-full overflow-x-auto">
                <div
                    v-if="!offersStore?.offers.length > 0"
                    class="flex flex-col justify-center items-center py-10"
                >
                    <img :src="noRecords" alt="noRecords" class="w-2/6" />
                    <div class="text-xl text-[#747373]">
                        No Offers at the moment !
                    </div>
                </div>
                <table
                    class="items-center w-full bg-transparent border border-[#e8e8e8c9] rounded-xl overflow-hidden"
                    v-else
                >
                    <thead class="">
                        <tr class="border-[#e8e8e8c9] border">
                            <th
                                class="text-purple px-4 align-middle py-6 font-medium text-left"
                            >
                                Offer title
                            </th>
                            <th
                                class="text-purple px-4 align-middle py-6 font-medium text-left"
                            >
                                Price
                            </th>
                            <th
                                class="text-purple px-4 align-middle py-6 font-medium text-left"
                            >
                                Discount
                            </th>
                            <th
                                class="text-purple px-4 align-middle py-6 font-medium text-left"
                            >
                                Destination
                            </th>
                            <th
                                class="text-purple px-4 align-middle py-6 font-medium text-center"
                            >
                                Expiration Date
                            </th>
                            <th
                                class="text-purple px-4 align-middle py-6 font-medium text-center"
                            >
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(offer, index) in offersStore.offers"
                            :key="index"
                        >
                            <td class="align-middle p-4 text-center">
                                {{ offer.title }}
                            </td>

                            <td class="align-middle p-4 text-center">
                                {{ offer.price }}
                            </td>

                            <td class="align-middle p-4 text-center">
                                <p>
                                    {{ parseFloat(offer.discount.toFixed(2)) }}
                                </p>
                            </td>
                            <td class="align-middle p-4 text-center">
                                {{ offer.destination }}
                            </td>
                            <td class="align-middle p-4 text-center">
                                {{ offer.expiration_date.slice(0, 10) }}
                            </td>
                            <!-- <td class="align-middle whitespace-nowrap p-4">
                <rating-stars :review="offer.offerable.total_rate" />
              </td> -->

                            <td
                                class="align-middle text-right"
                                @click="showDeleteModal(offer)"
                            >
                                <img
                                    :src="deleteReview"
                                    class="cursor-pointer mx-auto"
                                    alt="deleteReview"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="flex justify-between items-center px-2 py-2">
            <div>
                Showing {{ offersStore.from }}-{{ offersStore.to }} from
                {{ offersStore.totalRecords }} data
            </div>
            <div class="flex items-center">
                <div v-if="offersStore.currentPage != 1">
                    <img
                        @click="offersStore.previousPageOffers()"
                        :src="forward"
                        class="cursor-pointer"
                        alt="forward"
                    />
                </div>

                <div
                    v-if="offersStore.currentPage > 1"
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
                    :class="{
                        'text-gray border border-gray': true,
                        'bg-cyan text-white border-cyan': false,
                    }"
                    @click="offersStore.previousPage()"
                >
                    {{ offersStore.currentPage - 1 }}
                </div>

                <div
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12"
                    :class="
                        true
                            ? 'bg-cyan text-white border-cyan'
                            : ' text-gray border border-gray'
                    "
                >
                    {{ offersStore.currentPage }}
                </div>

                <div
                    v-if="offersStore.currentPage < offersStore.totalPages"
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
                    :class="{
                        'text-gray border border-gray': true,
                        'bg-cyan text-white border-cyan': false,
                    }"
                    @click="offersStore.nextPage()"
                >
                    {{ offersStore.currentPage + 1 }}
                </div>

                <div v-if="offersStore.currentPage < offersStore.totalPages">
                    <img
                        class="cursor-pointer"
                        @click="offersStore.nextPageOffers()"
                        :src="back"
                        alt="back"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import checkbox from "@/assets/icons/cards/checkbox.svg";
import separator from "@/assets/icons/cards/separator.svg";
import deleteReview from "@/assets/icons/cards/deleteReview.svg";
import avatarUser from "@/assets/icons/cards/avatarUser.svg";
import back from "@/assets/icons/cards/back.svg";
import forward from "@/assets/icons/cards/forward.svg";
import noRecords from "@/assets/images/noRecords.svg";
import { useOffersStore } from "@/store/offers";
import DeleteOfferModal from "../modals/offers/DeleteOfferModal.vue";

export default {
    data() {
        return {};
    },

    async mounted() {
        this.offersStore.showDeleteModal = false;
        await this.offersStore.getOffers();
    },

    setup() {
        const offersStore = useOffersStore();
        return {
            back,
            forward,
            checkbox,
            avatarUser,
            deleteReview,
            separator,
            offersStore,
            noRecords,
        };
    },

    methods: {
        showDeleteModal(offer) {
            this.offersStore.showDeleteModal = true;
            this.offersStore.singleOffer = offer;
        },
    },

    props: {
        color: {
            default: "light",
            validator: function (value) {
                // The value must match one of these strings
                return ["light", "dark"].indexOf(value) !== -1;
            },
        },
    },

    components: { DeleteOfferModal },
};
</script>
