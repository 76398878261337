import { defineStore } from "pinia";
import axios from "@/axios/axios";
import { v4 as uuidv4 } from "uuid";
import { useCoursesStore } from "./courses";

export const useQuizzsStore = defineStore("quizzsStore", {
  state: () => ({
    quizzs: [],
    quizzId: null,
    quizzCount: 0,
    lastestQuizz: [],
    selectedQuizz: null,
    error: "",
    errorMessages: [],
    initialQcmId: 0,
    initialQcsId: 0,
    initialFreeId: 0,
    currentQuestion: false,
    currentQuizz: {
      title: "",
      answers: [],
    },
  }),
  actions: {
    async getQuizz() {
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;

        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}auth/admin/destination`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        this.quizzs = data.data;
      } catch (err) {
        console.log(err);
      }
    },
    validateQuizzesArray() {
      this.errorMessages = [];
      // Check if the array is not empty
      if (this.quizzs.length === 0) {
        return true;
      }

      // Iterate over each quiz in the array
      for (const [index, quiz] of this.quizzs.entries()) {
        // Check if each quiz has a question with a minimum length of 3 characters
        if (!quiz.question || quiz.question.length < 3) {
          this.errorMessages.push(
            `Question ${
              index + 1
            } must have a title with a minimum length of 3 characters.`
          );
        }

        // Check if each quiz has at least one answer
        if (!quiz.answers || quiz.answers.length === 0) {
          this.errorMessages.push(
            `Quiz  ${index + 1} must have at least one answer.`
          );
        }

        // Check each answer in the quiz
        for (const [answerIndex, answer] of quiz.answers.entries()) {
          // Check if each answer has a title with a minimum length of 3 characters
          if (!answer.title || answer.title.length < 3) {
            this.errorMessages.push(
              `Answer  ${answerIndex + 1} in quiz number ${
                index + 1
              } must have a title with a minimum length of 3 characters.`
            );
          }
        }
      }

      // Return an object with error messages array and a boolean indicating validity
      return this.errorMessages.length == 0 ? true : false;
    },
    async addCourseQuizzes(quizzes) {
      this.error = null;
      const coursesStore = useCoursesStore();
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}course/admin/lesson/store/quiz`,
          quizzes,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (err) {
        for (var key in err.response.data.errors) {
          coursesStore.errorMessages.push(err.response.data.errors[key]);
        }
        console.log(err);
        throw err;
      }
    },

    async editCourseQuizzes(quizzes) {
      this.error = null;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}course/admin/lesson/update/quiz`,
          quizzes,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (err) {
        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
        console.log(err);
        throw err;
      }
    },

    checkCurrentQuizz() {
      if (
        this.currentQuizz.title.length == 0 &&
        this.currentQuizz.answers.length == 0
      ) {
        return false;
      }
      return true;
    },

    RemoveQuizz(index) {
      this.quizzs.splice(index, 1);
    },

    //QCM
    createQcm() {
      if (this.checkCurrentQuizz() && this.initialQcmId !== 0) {
        console.log("you can't add a question until validate current one");
        return;
      }
      this.currentQuizz.title = "";
      this.quizzs = [
        ...this.quizzs,
        {
          id: uuidv4(),
          question: "",
          type: "MCQ",
          answers: [
            {
              id: uuidv4(),
              title: "",
              is_correct: false,
            },
          ],
        },
      ];
    },

    createQcmOption(selectedQuizz) {
      this.quizzs
        .find((quizz) => {
          return selectedQuizz.id == quizz.id;
        })
        .answers.push({
          id: uuidv4(),
          title: "",
          is_correct: false,
        });
    },

    setQcmOptionAsCorrect(selectedQuizz, selectedAnswer) {
      this.quizzs
        .find((quizz) => {
          return selectedQuizz.id == quizz.id;
        })
        .answers.find((answer) => {
          return selectedAnswer.id == answer.id;
        }).is_correct = true;
    },

    setQcmOptionAsIncorrect(selectedQuizz, selectedAnswer) {
      this.quizzs
        .find((quizz) => {
          return selectedQuizz.id == quizz.id;
        })
        .answers.find((answer) => {
          return selectedAnswer.id == answer.id;
        }).is_correct = false;
    },

    deleteQcmOption(selectedQuizz, index) {
      this.quizzs
        .find((quizz) => {
          return selectedQuizz.id == quizz.id;
        })
        .answers.splice(index, 1);
    },

    //QCS
    createQcs() {
      if (this.checkCurrentQuizz() && this.initialQcsId !== 0) {
        console.log("you can't add a question until validate current one");
        return;
      }
      this.currentQuizz.title = "";

      this.quizzs = [
        ...this.quizzs,
        {
          id: uuidv4(),
          question: "",
          type: "SCQ",
          answers: [
            {
              id: uuidv4(),
              title: "",
              is_correct: false,
            },
          ],
        },
      ];
    },

    createQcsOption(selectedQuizz) {
      this.quizzs
        .find((quizz) => {
          return selectedQuizz.id == quizz.id;
        })
        .answers.push({
          id: uuidv4(),
          title: "",
          is_correct: false,
        });
    },

    resetOptionsToIncorrect(selectedQuizz) {
      this.quizzs.find((quizz) => {
        return selectedQuizz.id == quizz.id;
      }).answers = this.quizzs
        .find((quizz) => {
          return selectedQuizz.id == quizz.id;
        })
        .answers.map((answer) => {
          return {
            ...answer,
            is_correct: false,
          };
        });
    },

    setQcsOptionAsCorrect(selectedQuizz, selectedAnswer) {
      this.resetOptionsToIncorrect(selectedQuizz);
      this.quizzs
        .find((quizz) => {
          return selectedQuizz.id == quizz.id;
        })
        .answers.find((answer) => {
          return selectedAnswer.id == answer.id;
        }).is_correct = true;
    },

    setQcsOptionAsIncorrect(selectedQuizz, selectedAnswer) {
      this.quizzs
        .find((quizz) => {
          return selectedQuizz.id == quizz.id;
        })
        .answers.find((answer) => {
          return selectedAnswer.id == answer.id;
        }).is_correct = false;
    },

    deleteQcsOption(selectedQuizz, index) {
      this.quizzs
        .find((quizz) => {
          return selectedQuizz.id == quizz.id;
        })
        .answers.splice(index, 1);
    },

    //Free
    createFreeQuizz() {
      if (this.checkCurrentQuizz() && this.initialFreeId !== 0) {
        console.log("you can't add a question until validate current one");
        return;
      }
      this.currentQuizz.title = "example";

      this.quizzs = [
        ...this.quizzs,
        {
          id: uuidv4(),
          question: "",
          type: "Free",
          answers: [
            {
              id: uuidv4(),
              title: "",
              is_correct: true,
            },
          ],
        },
      ];
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getQuizzs();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getQuizzs();
      }
    },
  },
});
