<template>
  <div
    class="flex justify-center items-center p-2 rounded-xl"
    :class="isSelected ? 'bg-[#00DDAF]' : 'bg-white'"
  >
    <svg
      width="21"
      height="21"
      viewBox="0 0 30 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75002 16.75C9.63798 16.75 9.52595 16.7664 9.41908 16.8011C8.81158 16.9984 8.17267 17.125 7.50001 17.125C6.82736 17.125 6.18845 16.9984 5.58048 16.8011C5.47361 16.7664 5.36205 16.75 5.25001 16.75C2.34095 16.75 -0.0154541 19.1162 1.46904e-05 22.0291C0.00657719 23.26 1.01861 24.25 2.25001 24.25H12.75C13.9814 24.25 14.9935 23.26 15 22.0291C15.0155 19.1162 12.6591 16.75 9.75002 16.75ZM7.50001 15.25C9.98533 15.25 12 13.2353 12 10.75C12 8.26469 9.98533 6.25 7.50001 6.25C5.0147 6.25 3.00001 8.26469 3.00001 10.75C3.00001 13.2353 5.0147 15.25 7.50001 15.25ZM27.75 0.25H9.75002C8.50923 0.25 7.50001 1.29297 7.50001 2.57453V4.75C8.59783 4.75 9.61408 5.06781 10.5 5.58438V3.25H27V16.75H24V13.75H18V16.75H14.4263C15.3216 17.5323 15.9788 18.5655 16.2867 19.75H27.75C28.9908 19.75 30 18.707 30 17.4255V2.57453C30 1.29297 28.9908 0.25 27.75 0.25Z"
        :fill="color"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: ["color", "isSelected"],
};
</script>
<style></style>
