<template>
  <div class="modal-mask">
    <div class="modal-wrapper rounded-xl">
      <div class="modal-container rounded-xl p-10">
        <div class="float-right -mt-5 -mr-5">
          <button @click="ticketsStore.showCloseModal = false">
            <img :src="exitIcon" alt="exit" />
          </button>
        </div>
        <img class="hidden" :src="loading" width="100" height="100" />
        <div class="text-center font-medium text-black my-8">
          <slot name="body">
            Do you really want to close this discussion?
          </slot>
        </div>
        <div class="flex justify-evenly">
          <button
            class="btn-outline"
            @click="ticketsStore.showCloseModal = false"
          >
            Cancel
          </button>
          <button class="btn" @click="closeDiscussion">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import exitIcon from "@/assets/icons/cards/exit.svg";
import loading from "@/assets/icons/cards/loading.gif";
import router from "@/router";
import { useTicketStore } from "@/store/tickets";
export default {
  setup() {
    const ticketsStore = useTicketStore();
    return {
      exitIcon,
      ticketsStore,
      loading,
    };
  },
  methods: {
    async closeDiscussion() {
      this.$emit("close");

      await this.ticketsStore.closeDiscussion(
        this.ticketsStore.singleTicket.id
      );
      if (this.ticketsStore.isFail) {
        this.$swal({
          icon: "error",
          title: "Something went wrong !",
          showConfirmButton: false,
          timer: 2000,
        });
      } else if (this.ticketsStore.isSuccess) {
        this.$swal({
          icon: "success",
          title: `Discussion closed successfully !`,
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          this.ticketsStore.showCloseModal = false;
          router.push("/admin/messages");
        });
      }
    },
  },
};
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.8s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 500px;
  margin: 0px auto;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
