import { defineStore } from "pinia";
import axios from "@/axios/axios";
import router from "@/router";

export const useMessagesStore = defineStore("messagesStore", {
  state: () => ({
    messages: [],
    itemsPerPage: 10,
    currentPage: 1,
    isBlockModal: false,
    isUnblockModal: false,
    isLoadingModal: false,
    isSuccess: false,
    isFail: false,
    isShowEditor: false,
    isAddMessageSuccess: false,
    isAddMessageFail: false,
    isEditMessageFail: false,
    isEditMessageSuccess: false,
    editedMessage: null,
    // theses are for filtering
    selectedPlan: null,
    selectedNationality: null,
    selectedSport: null,
    selectedDate: null,
    singleMessage: null,
  }),
  actions: {
    async getMessages() {
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(`url `, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.messages = data.messages.results;
        this.totalPages = data.messages.totalPages;
        this.currentPage = data.messages.currentPage;
        this.isLoadingModal = false;
      } catch (err) {
        console.log(err);
      }
    },
    async blockMessage(userId) {
      this.isFail = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/messages/block`,
          {
            userId: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        await this.getMessages();
      } catch (err) {
        this.isLoadingModal = false;
        this.isFail = true;
        console.log(err);
      }
    },
    async unBlockMessage(userId) {
      this.isLoadingModal = true;
      this.isFail = false;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/messages/unblock`,
          {
            userId: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        await this.getMessages();
      } catch (err) {
        this.isLoadingModal = false;
        this.isFail = true;
        console.log(err);
      }
    },
    async getOneMessage(userId) {
      this.isLoadingModal = true;
      this.isFail = false;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}admin/messages/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.singleMessage = data.user;
      } catch (err) {
        this.isLoadingModal = false;
        router.push("/admin/joueurs");
        console.log(err);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getMessage();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getMessage();
      }
    },
  },
});
