import { defineStore } from "pinia";
import axios from "@/axios/axios";
import { useAuthStore } from "./auth";

export const useGroupsStore = defineStore("groupsStore", {
  state: () => ({
    groups: [],
    coursesCount: 0,
    itemsPerPage: 10,
    totalPages: 0,
    from: 0,
    to: 0,
    currentPage: 1,
    courseContent: [],
    isLoadingModal: false,
    isFailModal: false,
    pauseError: false,
    addError: false,
    addSuccess: false,
    isUpdateError: false,
    isUpdateSuccess: false,
    isShowEditor: false,
    errorMessages: [],
    errorMessage: "",
    selectedCourse: null,
    newCourse: null,
    editedGroup: null,
    showDeleteModal: false,
    deleteError: false,
    loaderMessage: "",
  }),
  actions: {
    async addGroup(group) {
      this.errorMessage = null;
      this.addError = false;
      this.addSuccess = false;
      this.isLoadingModal = true;

      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const res = await axios.post(
          `${process.env.VUE_APP_API_BASE}group/admin/store`,
          group,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // this.isLoadingModal = false;
        // this.addSuccess = true;
        return res.data.data;
      } catch (err) {
        // this.addError = true;
        // this.isLoadingModal = false;
        this.errorMessage = err.response.data.errors;
        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
        console.log(err);
      }
    },

    async getGroups(query) {
      this.errorMessage = null;
      const authStore = useAuthStore();

      try {
        if (!query) {
          this.isLoadingModal = true;
        } else {
          authStore.isLoading = true;
        }
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}group/admin?paginate=true&page=${
            this.currentPage
          }&q=${query ? `&q=${encodeURIComponent(query)}` : ""}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.groups = data.data.data;
        this.from = data.data.from;
        this.to = data.data.to;
        this.totalPages = data.data.total;
        this.currentPage = data.data.current_page;
        this.isLoadingModal = false;
        authStore.isLoading = false;
      } catch (err) {
        this.isLoadingModal = false;
        authStore.isLoading = false;
        this.errorMessage = err.response.data.errors;
        console.log(err);
      }
    },

    async getGroup(groupId) {
      this.errorMessage = null;
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}group/admin/${groupId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.editedGroup = data.data;
        const newData = {
          ...data.data,
          option: data.data.option?.map((o) => o.id),
        };
        console.log({
          ...data.data,
          option: data.data.option?.map((o) => o.id),
        });
        return newData;
      } catch (err) {
        this.isLoadingModal = false;
        this.errorMessage = err.response.data.errors;
        console.log(err);
      }
    },

    async deleteGroup(groupId) {
      this.errorMessage = null;
      this.deleteError = false;
      this.isLoadingModal = true;
      this.pauseError = false;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.delete(
          `${process.env.VUE_APP_API_BASE}group/admin/${groupId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        await this.getGroups();
      } catch (err) {
        this.isLoadingModal = false;
        this.deleteError = true;
        this.pauseError = true;
      }
    },

    async updateGroup(group, groupId) {
      this.errorMessages = [];
      this.errorMessage = null;
      this.isUpdateError = false;
      this.isUpdateSuccess = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const res = await axios.post(
          `${process.env.VUE_APP_API_BASE}group/admin/update/${groupId}`,
          group,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isUpdateSuccess = true;
        return res.data.data;
      } catch (err) {
        this.errorMessage = err.response.data.errors;
        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
        console.log(err);
        this.isUpdateError = true;
        this.isLoadingModal = false;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getGroups();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getGroups();
      }
    },
  },
});
