<template>
  <!-- Header -->
  <div class="pt-28 pb-40">
    <div class="md:flex justify-between xl:mx-10">
      <div
        class="border border-cyan border-opacity-20 rounded-xl p-4 py-6 w-full mx-3 md:text-left text-center my-4 md:my-0"
      >
        <div><img :src="solde" alt="icon" class="mx-auto md:mx-0" /></div>
        <div class="mt-2">
          Sold <br />
          <span class="font-bold text-xl text-black">{{
            statsStore.sold
          }}</span>
        </div>
      </div>
      <div
        class="border border-cyan border-opacity-20 rounded-xl p-4 py-6 w-full mx-3 md:text-left text-center my-4 md:my-0"
      >
        <div>
          <img :src="takwinStudents" alt="icon" class="mx-auto md:mx-0" />
        </div>
        <div class="mt-2">
          Takwin Students <br />
          <span class="font-bold text-xl text-black">{{
            statsStore.takwin_students
          }}</span>
        </div>
      </div>
      <div
        class="border border-cyan border-opacity-20 rounded-xl p-4 py-6 w-full mx-3 md:text-left text-center my-4 md:my-0"
      >
        <div>
          <img :src="coursesStudents" alt="icon" class="mx-auto md:mx-0" />
        </div>
        <div class="mt-2">
          Courses Students <br />
          <span class="font-bold text-xl text-black">{{
            statsStore.courses_students
          }}</span>
        </div>
      </div>
      <div
        class="border border-cyan border-opacity-20 rounded-xl p-4 py-6 w-full mx-3 md:text-left text-center my-4 md:my-0"
      >
        <div><img :src="teachers" alt="icon" class="mx-auto md:mx-0" /></div>
        <div class="mt-2">
          Teachers <br />
          <span class="font-bold text-xl text-black">{{
            statsStore.teachers
          }}</span>
        </div>
      </div>
    </div>
    <div><LineChart :key="chartKey" /></div>
  </div>
</template>

<script>
import { useStatsStore } from "@/store/stats";
import solde from "@/assets/icons/cards/solde.svg";
import takwinStudents from "@/assets/icons/cards/takwinStudents.svg";
import coursesStudents from "@/assets/icons/cards/coursesStudents.svg";
import teachers from "@/assets/icons/cards/teachers.svg";
import LineChart from "../charts/LineChart.vue";
export default {
  async mounted() {
    await this.statsStore.getGeneralStats();
    this.chartKey += 1;
  },
  data() {
    return {
      chartKey: 0,
    };
  },
  setup() {
    const statsStore = useStatsStore();
    const getStatIconColor = (increased) => {
      return increased ? "bg-green-100" : "bg-red-100";
    };
    return {
      solde,
      takwinStudents,
      coursesStudents,
      teachers,
      statsStore,
      getStatIconColor,
    };
  },
  components: { LineChart },
};
</script>

<style scoped>
.dashboard-header {
  background-size: contain;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 1300px) {
  .dashboard-header {
    background-size: cover;
  }
}
</style>
