<template>
    <div
        class="relative min-w-0 break-words w-full mb-6 shadow-lg mt-4 rounded-xl border border-[#e8e8e8c9] bg-white"
    >
        <LoaderModal v-if="reportsStore.isLoadingModal" />
        <div class="block w-full overflow-x-auto">
            <div
                v-if="!reportsStore.reports?.length > 0"
                class="flex flex-col justify-center items-center py-10"
            >
                <img :src="noRecords" alt="noRecords" class="w-2/6" />
                <div class="text-xl text-[#747373]">
                    No reports at the moment !
                </div>
            </div>

            <table
                class="items-center w-full bg-transparent border border-[#e8e8e8c9] rounded-xl overflow-hidden"
                v-else
            >
                <thead class="">
                    <tr class="border-[#e8e8e8c9] border">
                        <th
                            class="text-purple px-4 align-middle py-6 uppercase -0 font-medium text-center"
                        >
                            The student
                        </th>
                        <th
                            class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
                        >
                            Email
                        </th>
                        <th
                            class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
                        >
                            Phone number
                        </th>
                        <th
                            class="text-purple px-4 align-middle py-6 uppercase font-medium text-center"
                        >
                            Date
                        </th>
                        <th
                            class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
                        >
                            Comment
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(report, index) in reportsStore.reports"
                        :key="index"
                    >
                        <td class="align-middle p-4 text-purple">
                            <div class="flex items-center justify-center">
                                <img
                                    v-if="!report.student?.user?.image?.url"
                                    :src="avatarUser"
                                    class="w-10 h-10 mr-2"
                                    alt="dfsd"
                                />
                                <img
                                    v-if="report.student?.user?.image?.url"
                                    :src="report.student?.user?.image?.url"
                                    class="w-10 h-10 mr-2"
                                    alt="dfsd"
                                />
                                <p v-if="report.student?.user">
                                    {{
                                        report.student?.user?.first_name +
                                        " " +
                                        report.student?.user?.last_name
                                    }}
                                </p>

                                <p v-else>[DELETED USER]</p>
                            </div>
                        </td>
                        <td class="align-middle p-4 text-purple">
                            {{ report.student?.user?.email }}
                        </td>
                        <td class="align-middle p-4 text-purple">
                            {{ report.student?.user?.phone }}
                        </td>
                        <td class="align-middle p-4 text-center">
                            {{ report.created_at.slice(0, 10) }}
                        </td>

                        <td class="align-middle whitespace-wrap p-4 w-80">
                            {{ report.content }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="flex justify-between items-center px-2 py-2">
            <div>
                Showing {{ reportsStore.from }}-{{ reportsStore.to }} from
                {{ reportsStore.totalRecords }} data
            </div>
            <div class="flex items-center">
                <div v-if="reportsStore.currentPage != 1">
                    <img
                        @click="reportsStore.previousPage()"
                        :src="forward"
                        class="cursor-pointer"
                        alt="forward"
                    />
                </div>

                <div
                    v-if="reportsStore.currentPage > 1"
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
                    :class="{
                        'text-gray border border-gray': true,
                        'bg-cyan text-white border-cyan': false,
                    }"
                    @click="reportsStore.previousPage()"
                >
                    {{ reportsStore.currentPage - 1 }}
                </div>

                <div
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12"
                    :class="
                        true
                            ? 'bg-cyan text-white border-cyan'
                            : ' text-gray border border-gray'
                    "
                >
                    {{ reportsStore.currentPage }}
                </div>

                <div
                    v-if="reportsStore.currentPage < reportsStore.totalPages"
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
                    :class="{
                        'text-gray border border-gray': true,
                        'bg-cyan text-white border-cyan': false,
                    }"
                    @click="reportsStore.nextPage()"
                >
                    {{ reportsStore.currentPage + 1 }}
                </div>

                <div v-if="reportsStore.currentPage < reportsStore.totalPages">
                    <img
                        class="cursor-pointer"
                        @click="reportsStore.nextPage()"
                        :src="back"
                        alt="back"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import checkbox from "@/assets/icons/cards/checkbox.svg";
import separator from "@/assets/icons/cards/separator.svg";
import deleteReview from "@/assets/icons/cards/deleteReview.svg";
import avatarUser from "@/assets/icons/cards/avatarUser.svg";
import noRecords from "@/assets/images/noRecords.svg";
import LoaderModal from "../modals/LoaderModal.vue";
import back from "@/assets/icons/cards/back.svg";
import forward from "@/assets/icons/cards/forward.svg";

import { useReportsStore } from "@/store/reports";
export default {
    components: { LoaderModal },
    data() {
        return {};
    },
    setup() {
        const reportsStore = useReportsStore();
        return {
            checkbox,
            avatarUser,
            deleteReview,
            separator,
            reportsStore,
            noRecords,
            back,
            forward,
        };
    },
    async mounted() {
        await this.reportsStore.getReports();
    },
    props: {
        color: {
            default: "light",
            validator: function (value) {
                // The value must match one of these strings
                return ["light", "dark"].indexOf(value) !== -1;
            },
        },
    },
};
</script>
