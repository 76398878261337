<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <LoaderModal v-if="plansStore.isLoadingModal" /><metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | RAYHANE` : `SITE_NAME`
      }}</template>
    </metainfo>
    <div class="my-6 mt-24 w-full">
      <div class="w-full">
        <div class="my-2">
          <h1 class="text-3xl text-black font-semibold">Subscription</h1>
          <h1 class="text-md text-gray font-semibold">
            Manage your subdcription
          </h1>
        </div>
        <div
          class="bg-purple text-white font-bold text-3xl p-5 rounded-t-xl border-[#c9c9c9] border-b-0 border"
        >
          Edit subscription
        </div>
        <form class="w-full border-[#c9c9c9] border border-t-0 p-4">
          <div>
            <div class="md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Subscription title</p>
              <input
                v-model="title"
                type="text"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p class="text-red-500" v-if="v$.title.$error">
                You should add a subscription
              </p>
            </div>
            <div class="md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Subscription price</p>
              <input
                v-model="price"
                type="text"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p class="text-red-500" v-if="v$.price.$error">
                You should add a price
              </p>
            </div>
          </div>
          <div>
            <div class="md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Subscription description</p>
              <input
                v-model="description"
                type="text"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p class="text-red-500" v-if="v$.description.$error">
                You should add a description
              </p>
            </div>
            <div class="md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Add options</p>
              <div
                class="flex items-center justify-between border-[#c9c9c9] border rounded-md py-3 password-container"
              >
                <input
                  class="text-gray-700 px-2 leading-tight focus:outline-none w-full password-input"
                  :type="'text'"
                  v-model="singleOption"
                />

                <svg
                  @click="addOption"
                  v-if="singleOption.length > 0"
                  width="17"
                  height="19"
                  class="mr-4 cursor-pointer"
                  viewBox="0 0 17 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.25765 8V1.19728C7.25765 0.53604 7.79369 0 8.45493 0C9.11617 0 9.65221 0.53604 9.65221 1.19728V8H15.5025C16.2389 8 16.8359 8.59695 16.8359 9.33333C16.8359 10.0697 16.2389 10.6667 15.5025 10.6667H9.65221V17.4694C9.65221 18.1306 9.11617 18.6667 8.45493 18.6667C7.79369 18.6667 7.25765 18.1306 7.25765 17.4694V10.6667H1.40731C0.670929 10.6667 0.0739746 10.0697 0.0739746 9.33333C0.0739746 8.59695 0.670928 8 1.40731 8H7.25765Z"
                    fill="#00DDAF"
                  />
                </svg>
              </div>
              <p class="text-red-500" v-if="options.length <= 0">
                You should add options
              </p>
            </div>
            <div
              class="border-[#c9c9c9] border rounded-md py-3 mr-6"
              v-if="options.length > 0"
            >
              <p class="text-black font-semibold p-2">Options</p>
              <div
                class="p-2 flex justify-between my-3"
                v-for="(option, index) in options"
                :key="index"
              >
                {{ option }}
                <svg
                  @click="removeOption(index)"
                  class="cursor-pointer"
                  width="19"
                  height="24"
                  viewBox="0 0 19 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.33333 21.3333C1.33333 22.8 2.53333 24 4 24H14.6667C16.1333 24 17.3333 22.8 17.3333 21.3333V5.33333H1.33333V21.3333ZM4 8H14.6667V21.3333H4V8ZM14 1.33333L12.6667 0H6L4.66667 1.33333H0V4H18.6667V1.33333H14Z"
                    fill="#E54545"
                  />
                </svg>
              </div>
            </div>
          </div>
        </form>
        <div class="flex justify-start my-4">
          <button class="btn btn bg-cyan font-bold" @click="updatePlan">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { usePlansStore } from "@/store/plans";
import router from "@/router";
import { useMeta } from "vue-meta";
import LoaderModal from "@/components/modals/LoaderModal.vue";
export default {
  data() {
    return {
      title: "",
      price: "",
      singleOption: "",
      options: [],
      description: "",
      id: null,
      optionsError: false,
    };
  },
  mounted() {
    if (!this.plansStore.editedPlan) {
      router.push("/admin/subscription");
    } else {
      this.id = this.plansStore.editedPlan.id;
      this.title = this.plansStore.editedPlan.title;
      this.price = this.plansStore.editedPlan.price;
      this.options = this.plansStore.editedPlan.options;
      this.description = this.plansStore.editedPlan.description;
    }
  },
  props: ["plan"],
  setup() {
    useMeta({
      title: "Edit subscription",
      htmlAttrs: { lang: "en", amp: true },
    });
    const plansStore = usePlansStore();
    return { v$: useVuelidate(), plansStore };
  },
  methods: {
    addOption() {
      this.options.push(this.singleOption);
      this.singleOption = "";
      this.optionsError = false;
    },
    removeOption(removedIndex) {
      this.options = this.options.filter((item, index) => {
        return index !== removedIndex;
      });
    },
    async updatePlan() {
      const result = await this.v$.$validate();
      if (this.options.length > 0) {
        this.optionsError = false;
      } else {
        this.optionsError = true;
      }
      if (!result || this.optionsError) {
        return;
      } else {
        await this.plansStore.editPlan({
          id: this.id,
          title: this.title,
          price: this.price,
          options: this.options,
          description: this.description,
        });
        if (this.plansStore.isSuccess) {
          this.$swal({
            icon: "success",
            timer: 1500,
            title: "Plan updated successfuly...",
            showConfirmButton: false,
          }).then(() => {
            this.plansStore.getPlans();
            router.push("/admin/subscription");
          });
        } else if (this.plansStore.isFail) {
          this.$swal({
            icon: "error",
            title: "Something wrong has happened...",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.plansStore.isSuccess = false;
        this.plansStore.isFail = false;
      }
    },
  },
  validations() {
    return {
      title: { required },
      price: { required },
      description: { required },
    };
  },
  components: { LoaderModal },
};
</script>

<style scoped>
.upload-block {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A0A0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
