<template>
  <Listbox as="div" v-model="selectedOption">
    <div class="relative">
      <ListboxButton
        class="relative w-full cursor-default rounded-md bg-white py-3 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
      >
        <span class="flex items-center">
          <span class="ml-3 block truncate">{{ render(selectedOption) }}</span>
        </span>
        <span
          class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2"
        >
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            as="template"
            v-for="(option, index) in options"
            :key="index"
            :value="option"
            v-slot="{ active, selected }"
          >
            <li
              :class="[
                active ? 'bg-indigo-600 ] text-white' : 'text-gray-900',
                'relative cursor-default select-none py-2 pl-3 pr-9',
              ]"
              @click="() => handleSelection(option)"
            >
              <div class="flex items-center">
                <span
                  :class="[
                    selected ? 'font-semibold' : 'font-normal',
                    'ml-3 block truncate',
                  ]"
                >
                  {{ render(option) }}
                </span>
              </div>

              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-indigo-600',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
<script>
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
export default {
  components: {
    CheckIcon,
    ChevronUpDownIcon,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
  },

  props: {
    value: {
      type: Object,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    render: {
      type: Function,
      default: null,
    },
  },

  data() {
    return { selectedOption: null };
  },

  watch: {
    value: {
      handler(newVal) {
        this.selectedOption = newVal;
      },
    },
  },
  methods: {
    handleSelection(newOpt) {
      this.selectedOption = newOpt;
      this.$emit("updateValue", newOpt, this.selectedOption != "");
    },
  },
};
</script>
