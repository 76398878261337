<template>
  <div>
    <metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | RAYHANE` : `SITE_NAME`
      }}</template>
    </metainfo>
    <div class="flex flex-wrap mt-4">
      <DeleteAddModal v-if="adsStore.showDeleteModal" />
      <div class="my-6 mt-24 w-full">
        <div class="flex justify-between items-center">
          <div>
            <h1 class="text-3xl text-black font-semibold">Manage ads</h1>
            <h1 class="text-xl text-gray font-semibold">Manage your ads</h1>
          </div>
          <div class="flex items-center">
            <router-link to="/admin/ads/new">
              <button class="btn bg-cyan font-bold">
                <span class="">+ </span> New Ad
              </button></router-link
            >
          </div>
        </div>
        <loader-modal v-if="adsStore.isLoadingModal" />
        <div class="w-full">
          <div class="flex flex-row-reverse mt-4"></div>
          <ads-table />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AdsTable from "@/components/tables/AdsTable.vue";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import DeleteAddModal from "@/components/modals/ads/DeleteAddModal.vue";
import { useAdsStore } from "@/store/ads";
import { useMeta } from "vue-meta";

export default {
  name: "dashboard-page",
  components: {
    AdsTable,
    LoaderModal,
    DeleteAddModal,
  },
  setup() {
    useMeta({
      title: "Ads",
      htmlAttrs: { lang: "en", amp: true },
    });
    const adsStore = useAdsStore();
    return {
      adsStore,
    };
  },
  data() {
    return {};
  },
};
</script>
