<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <div class="my-6 mt-24 w-full">
      <div class="flex justify-between items-center">
        <div>
          <h1 class="text-3xl text-black font-semibold">Courses</h1>
          <h1 class="text-xl text-gray font-semibold">
            Manage your categories
          </h1>
        </div>
        <div class="flex items-center">
          <router-link to="/admin/courses/categories/new">
            <button class="btn bg-cyan font-bold">
              <span class="">+ </span> New Category
            </button></router-link
          >
        </div>
      </div>
      <CategoriesTable />
    </div>
  </div>
</template>
<script>
import CategoriesTable from "@/components/tables/CategoriesTable.vue";

export default { components: { CategoriesTable } };
</script>
<style></style>
