<template>
  <div
    class="flex justify-center items-center p-2 rounded-xl"
    :class="isSelected ? 'bg-[#00DDAF]' : 'bg-white'"
  >
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 0.0994873C5.47974 0.0994873 0.599487 4.97974 0.599487 11C0.599487 17.0202 5.47974 21.9005 11.5 21.9005C17.5202 21.9005 22.4005 17.0202 22.4005 11C22.4005 4.97974 17.5202 0.0994873 11.5 0.0994873ZM12.4412 16.91V18.4452H10.9727V16.9272C8.56299 16.5972 7.50774 14.6172 7.50774 14.6172L9.00774 13.3625C9.00774 13.3625 9.96549 15.0297 11.698 15.0297C12.655 15.0297 13.381 14.5175 13.381 13.6422C13.381 11.5962 7.86849 11.8445 7.86849 8.04874C7.86849 6.39874 9.17349 5.20999 10.972 4.92874V3.39499H12.4405V4.92874C13.6945 5.09374 15.1795 5.75374 15.1795 7.17349V8.26249H13.2325V7.73449C13.2325 7.18999 12.5387 6.82699 11.7625 6.82699C10.7725 6.82699 10.0472 7.32199 10.0472 8.01499C10.0472 10.1105 15.5597 9.59899 15.5597 13.5762C15.5597 15.2112 14.3387 16.6287 12.4412 16.91Z"
        :fill="color"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: ["color", "isSelected"],
};
</script>
<style></style>
