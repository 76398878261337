<template>
  <div class="permissions-filter">
    <div>
      <a
        class="text-blueGray-500 block"
        href="#"
        ref="popperElementRef"
        v-on:click="toggleDropdown($event)"
      >
      </a>
      <button
        class="border border-[#c6c6c6] w-full h-10 p-2 rounded-xl float-right"
        @click="toggleDropdown"
        v-if="!dropdownPopoverShow"
      >
        <img :src="filterModOpen" alt="filterModOpen" />
      </button>
      <button
        class="border border-[#c6c6c6] w-full h-10 p-2 rounded-xl float-right"
        @click="toggleDropdown"
        v-if="dropdownPopoverShow"
      >
        <img :src="filterModClosed" alt="filterModClosed" />
      </button>
    </div>

    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left list-none text-left rounded shadow-lg min-w-48 w-full"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <div
        @click="togglePermission(index)"
        v-for="(permission, index) in permissions"
        :key="index"
        :class="permission.hasPermission ? 'bg-blue-100' : ''"
        class="px-2 py-3 cursor-pointer flex items-center"
      >
        <input type="checkbox" :checked="permission.hasPermission" />
        <p class="ml-2">{{ permission.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import filterModClosed from "@/assets/icons/cards/filterModClosed.svg";
import filterModOpen from "@/assets/icons/cards/filterModOpen.svg";
import permissionCheck from "@/assets/icons/cards/permissionCheck.svg";
import { useModeratorsStore } from "@/store/moderators";
export default {
  props: {
    mod: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      dropdownPopoverShow: false,
      permissions: [
        {
          name: "Manage statistics",
          hasPermission: false,
          value: "manage_statistics",
        },
        {
          name: "Manage teachers",
          hasPermission: false,
          value: "manage_teachers",
        },
        { name: "Manage users", hasPermission: false, value: "manage_users" },
        {
          name: "Manage groups",
          hasPermission: false,
          value: "manage_groups",
        },
        {
          name: "Manage courses",
          hasPermission: false,
          value: "manage_courses",
        },
        {
          name: "Manage Lives & Rooms",
          hasPermission: false,
          value: "manage_lives_rooms",
        },
        {
          name: "Manage Offers",
          hasPermission: false,
          value: "manage_offers",
        },
        {
          name: "Manage subscriptions",
          hasPermission: false,
          value: "manage_subscriptions",
        },
        {
          name: "Manage landing page",
          hasPermission: false,
          value: "manage_landing_page",
        },
        {
          name: "Manage reports",
          hasPermission: false,
          value: "manage_reports",
        },
        { name: "Manage ads", hasPermission: false, value: "manage_ads" },
        {
          name: "Manage influencers",
          hasPermission: false,
          value: "manage_influencers",
        },
        {
          name: "Manage promotions",
          hasPermission: false,
          value: "manage_promotions",
        },
        {
          name: "Manage tickets",
          hasPermission: false,
          value: "manage_tickets",
        },
        {
          name: "Manage affiliation",
          hasPermission: false,
          value: "manage_affiliation",
        },
        { name: "Manage pages", hasPermission: false, value: "manage_pages" },
        {
          name: "Manage codes of discount",
          hasPermission: false,
          value: "manage_codes_of_discount",
        },
        {
          name: "Manage settings",
          hasPermission: false,
          value: "manage_settings",
        },
        {
          name: "Manage employees",
          hasPermission: false,
          value: "manage_employees",
        },
        {
          name: "Manage payments",
          hasPermission: false,
          value: "manage_payments",
        },
      ],
    };
  },

  watch: {
    mod: {
      handler(newVal) {
        this.permissions = this.permissions.map((permission) => {
          return {
            ...permission,
            hasPermission: newVal.permissions.some((selectedPermission) => {
              return permission.value == selectedPermission.name;
            }),
          };
        });
        const result = [];
        for (let index = 0; index < this.permissions.length; index++) {
          if (this.permissions[index].hasPermission) {
            result.push(this.permissions[index].value);
          }
        }
        this.moderatorsStore.newModerator.permissions = result;
      },
    },
  },

  setup() {
    const moderatorsStore = useModeratorsStore();
    return {
      moderatorsStore,
      filterModOpen,
      filterModClosed,
      permissionCheck,
    };
  },

  methods: {
    toggleDropdown: function (event) {
      console.log(this.$refs.popoverDropdownRef);

      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        const dropdowns = document.querySelectorAll(".career-filter");
        // Add click event listener to each dropdown component
        dropdowns.forEach(function (dropdown) {
          dropdowns.forEach(() => {
            dropdown.children[1].classList.add("hidden");
          });
        });
        this.$refs.popoverDropdownRef.classList.remove("hidden");

        this.dropdownPopoverShow = true;
        console.log(this.$refs.popperElementRef);
        createPopper(
          this.$refs.popoverDropdownRef,
          this.$refs.popperElementRef,
          {
            placement: "bottom-end",
          }
        );
      }
    },

    togglePermission(index) {
      this.permissions[index].hasPermission =
        !this.permissions[index].hasPermission;
      let result = [];
      for (let index = 0; index < this.permissions.length; index++) {
        if (this.permissions[index].hasPermission) {
          result.push(this.permissions[index].value);
        }
      }
      this.moderatorsStore.newModerator.permissions = result;
      this.moderatorsStore.newPermissions = result;
    },
  },
};
</script>
