<template>
  <div
    class="relative min-w-0 break-words w-full mb-6 shadow-lg mt-4 rounded-xl border border-[#e8e8e8c9] bg-white"
  >
    <div class="block w-full overflow-x-auto">
      <div
        v-if="!landingPageStore.reviews.length > 0"
        class="flex flex-col justify-center items-center py-10"
      >
        <img :src="noRecords" alt="noRecords" class="w-2/6" />
        <div class="text-xl text-[#747373]">No reviews at the moment !</div>
      </div>
      <table
        class="items-center w-full bg-transparent border border-[#e8e8e8c9] rounded-xl overflow-hidden"
        v-else
      >
        <thead class="">
          <tr class="border-[#e8e8e8c9] border">
            <th
              class="text-purple px-4 align-middle py-6 uppercase -0 font-medium text-left"
            >
              The client
            </th>
            <th
              class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
            >
              Added date
            </th>
            <th
              class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
            >
              The comment
            </th>
            <th
              class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
            >
              Stars
            </th>
            <th
              class="text-purple px-4 align-middle py-6 uppercase font-medium text-center"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(review, index) in landingPageStore.reviews" :key="index">
            <td class="align-middle p-4 text-purple flex items-center">
              <img
                v-if="!review.img"
                :src="avatarUser"
                class="w-10 h-10 mr-2"
                alt="dfsd"
              />
              <img
                v-if="review.img"
                :src="review.img"
                class="w-10 h-10 mr-2"
                alt="dfsd"
              />
              {{ review.full_name }}
            </td>
            <td class="align-middle p-4">NOT YET</td>

            <td class="align-middle p-4 text-purple flex items-center">
              {{ review.comment }}
            </td>

            <td class="align-middle whitespace-nowrap p-4">
              <rating-stars :review="review.stars" />
            </td>

            <td class="align-middle text-right">
              <img
                @click="openDeleteModal(review.id)"
                :src="deleteReview"
                class="cursor-pointer mx-auto"
                alt="deleteReview"
              />
              <DeleteLandingPageReviewModal
                v-if="landingPageStore.showDeleteModal"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <div class="flex justify-between items-center px-2 py-2">
        <div>
          Showing {{ landingPageStore.from }}-{{ landingPageStore.to }} from
          {{ landingPageStore.totalRecords }} data
        </div>
        <div class="flex items-center">
          <div v-if="landingPageStore.currentPage != 1">
            <img
              @click="landingPageStore.previousPage()"
              :src="forward"
              class="cursor-pointer"
              alt="forward"
            />
          </div>

          <div
            class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12"
            :class="
              true
                ? 'bg-cyan text-white border-cyan'
                : ' text-gray border border-gray'
            "
          >
            {{ landingPageStore.currentPage }}
          </div>

          <div
            v-if="
              landingPageStore.currentPage + 1 <= landingPageStore.totalPages
            "
          >
            <img
              class="cursor-pointer"
              @click="landingPageStore.nextPage()"
              :src="back"
              alt="back"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DeleteLandingPageReviewModal from "@/components/modals/landingPage/DeleteLandingPageReviewModal.vue";
import checkbox from "@/assets/icons/cards/checkbox.svg";
import separator from "@/assets/icons/cards/separator.svg";
import deleteReview from "@/assets/icons/cards/deleteReview.svg";
import avatarUser from "@/assets/icons/cards/avatarUser.svg";
import noRecords from "@/assets/images/noRecords.svg";
import RatingStars from "../cards/partials/RatingStars.vue";
import { useLandingPageStore } from "@/store/landingPage";
export default {
  components: {
    DeleteLandingPageReviewModal,
    RatingStars,
  },
  data() {
    return {};
  },
  setup() {
    const landingPageStore = useLandingPageStore();
    return {
      checkbox,
      avatarUser,
      deleteReview,
      separator,
      landingPageStore,
      noRecords,
    };
  },
  methods: {
    openDeleteModal(reviewId) {
      this.landingPageStore.showDeleteModal = true;
      this.landingPageStore.deletedReviewId = reviewId;
    },
  },
  async mounted() {
    await this.landingPageStore.getLandingPageReviews();
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
