<template>
  <div
    class="flex justify-center items-center p-2 rounded-xl"
    :class="isSelected ? 'bg-[#00DDAF]' : 'bg-white'"
  >
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3679 3.08762C14.5127 2.16433 13.3183 1.65588 11.9999 1.65588C10.6745 1.65588 9.47614 2.16125 8.62492 3.07883C7.76447 4.00652 7.34523 5.26731 7.44367 6.62873C7.63879 9.31467 9.68268 11.4996 11.9999 11.4996C14.3172 11.4996 16.3575 9.31511 16.5557 6.62961C16.6555 5.28049 16.2336 4.02234 15.3679 3.08762Z"
        :fill="color"
      />
      <path
        d="M19.7343 21.3424H4.26557C4.0631 21.345 3.86259 21.3025 3.67862 21.2179C3.49466 21.1333 3.33187 21.0087 3.20209 20.8533C2.91645 20.5118 2.80131 20.0456 2.88657 19.574C3.25747 17.5165 4.41498 15.7882 6.23432 14.5748C7.85063 13.4977 9.89804 12.9049 11.9999 12.9049C14.1018 12.9049 16.1493 13.4982 17.7656 14.5748C19.5849 15.7877 20.7424 17.5161 21.1133 19.5736C21.1986 20.0451 21.0834 20.5114 20.7978 20.8529C20.6681 21.0084 20.5053 21.133 20.3213 21.2177C20.1374 21.3024 19.9368 21.345 19.7343 21.3424Z"
        :fill="color"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: ["color", "isSelected"],
};
</script>
<style></style>
