<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <CourseLoaderModal :isNewCourse="true" v-if="coursesStore.isLoadingModal" />
    <div class="my-6 mt-24 w-full">
      <InformationForm
        :courseInfo="courseInfo"
        @getCourseInfo="getCourseInfo"
        @updateValidity="updateCourseInfoValidity"
        :isValid="courseInfoValid"
      />
      <ContentForm
        :courseContent="courseContent"
        @getCourseContent="getCourseContent"
        @updateValidity="updateCourseContentValidity"
        :isValid="courseContentValid"
      />
      <QuizzesNumber
        :courseQuizzes="courseQuizzes"
        @getCourseQuizzes="getCourseQuizzes"
        @updateValidity="updateCourseQuizzesValidity"
        :isValid="courseQuizzesValid"
      />
      <div v-if="quizzesStore.errorMessages.length > 0">
        <p
          class="text-red-500"
          v-for="(err, index) in quizzesStore.errorMessages"
          :key="index"
        >
          {{ err }}
        </p>
      </div>

      <button
        :disabled="!(courseContentValid && courseInfoValid)"
        :class="`btn btn bg-cyan font-bold my-2 ${
          !(courseContentValid && courseInfoValid)
            ? 'opacity-50'
            : 'opacity-100'
        }`"
        @click="updateCourse"
      >
        Save
      </button>
    </div>
  </div>
</template>
<script>
import ContentForm from "@/components/cards/courses/ContentForm.vue";
import InformationForm from "@/components/cards/courses/InformationForm.vue";
import QuizzesNumber from "@/components/cards/courses/QuizzesNumber.vue";
import CourseLoaderModal from "@/components/modals/CourseLoaderModal.vue";
import { useCategoriesStore } from "@/store/categories";
import { useCoursesStore } from "@/store/courses";
import { useDestinationsStore } from "@/store/destinations";
import { useQuizzsStore } from "@/store/quizz";
import { useTeachersStore } from "@/store/teachers";
export default {
  setup() {
    const categoriesStore = useCategoriesStore();
    const coursesStore = useCoursesStore();
    const quizzesStore = useQuizzsStore();
    const teachersStore = useTeachersStore();
    const destinationsStore = useDestinationsStore();
    return {
      categoriesStore,
      destinationsStore,
      coursesStore,
      quizzesStore,
      teachersStore,
    };
  },

  data() {
    return {
      courseInfoValid: false,
      courseContentValid: false,
      courseQuizzesValid: false,
      courseInfoFormData: null,
      courseContentFormData: null,
      courseQuizzesFormData: null,
      courseInfo: null,
      courseContent: null,
      courseQuizzes: null,
    };
  },

  async mounted() {
    const course = await this.coursesStore.getCourse(this.$route.params.id);
    this.courseInfo = {
      course_title: course.title,
      description: course.description,
      price: course.price,
      cover: course.image ? course.image.url : null,
      teacher: course.teacher,
      destination: course.destination,
      category: course.category,
      // cover: course.image ? await this.coursesStore.getCourseImage(course.image.url) : null,
    };
    await Promise.all([
      this.teachersStore.getTeachersWithoutPagination(),
      this.categoriesStore.getCategories(),
      this.destinationsStore.getDestinations(),
    ]);
    this.courseContent = course.lessons.filter((lesson) => {
      return lesson.type == "attachment";
    });

    this.courseQuizzes = course.lessons.filter((lesson) => {
      return lesson.type == "quiz";
    });
  },

  methods: {
    updateCourseInfoValidity(isFormValid) {
      this.courseInfoValid = isFormValid;
    },

    updateCourseContentValidity(isFormValid) {
      this.courseContentValid = isFormValid;
    },

    updateCourseQuizzesValidity(isFormValid) {
      this.courseQuizzesValid = isFormValid;
    },

    getCourseInfo(formData) {
      this.courseInfoFormData = formData;
    },

    getCourseContent(formData) {
      this.courseContentFormData = formData;
    },

    getCourseQuizzes(formData) {
      this.courseQuizzesFormData = formData;
    },

    async updateCourse() {
      try {
        this.coursesStore.loaderMessage = "Updating course main data";
        await this.coursesStore.updateCourse(
          this.courseInfoFormData,
          this.$route.params.id
        );
        this.coursesStore.isLoadingModal = true;
        this.coursesStore.loaderMessage = "Updating lessons";
        await Promise.all(
          this.courseContentFormData.map(async (element) => {
            if (!element.get("id")) {
              if (!element.get("course_id"))
                element.append("course_id", this.$route.params.id);
              const { data } = await this.coursesStore.addLesson(element);
              element.append("id", data.id);
            } else if (element.get("to_remove") === "false") {
              element.set("lesson_id", element.get("id"));
              const formData = new FormData();
              for (const [key, value] of element.entries()) {
                if (["id", "to_remove", "course_id"].includes(key)) continue;
                formData.append(key, value);
              }
              await this.coursesStore.updateLesson(formData);
            } else {
              await this.coursesStore.removeLesson(element.get("id"));
            }
          })
        );
        const initCourseInfo = this.courseContentFormData.map((content) => {
          return {
            order: content.get("order"),
            id: content.get("id"),
          };
        });
        const newOrder = this.courseContentFormData.map((content, index) => {
          return {
            order: index,
            id: content.get("id"),
          };
        });
        const lessonsOrderChanged = !initCourseInfo.every((content, index) => {
          return content.order == index;
        });
        if (lessonsOrderChanged) {
          this.coursesStore.loaderMessage = "Reordering lessons";
          await this.coursesStore.reorderLessons(newOrder);
        }
        // this.coursesStore.loaderMessage = "Updating quizzes";
        // await this.quizzesStore.editCourseQuizzes(this.courseQuizzesFormData);
        this.coursesStore.isLoadingModal = false;
        this.$swal({
          icon: "success",
          title: "Course updated successfully",
          willClose: () => {
            this.coursesStore.addError = false;
            this.coursesStore.addSuccess = false;
            this.$router.push({ path: "/admin/courses" });
          },
        });
      } catch (error) {
        console.log(error);
        this.$swal({
          icon: "danger",
          title: "Error accured while updating the course !",
          html: `<ul>
            ${this.coursesStore.errorMessages.map((error) => {
              return error.map((message) => {
                return `<li class="text-red-500">
                      ${message}
                    </li>`;
              });
            })}
          ${this.quizzesStore.errorMessages.map((error) => {
            return error.map((message) => {
              return `<li class="text-red-500">
                      ${message}
                    </li>`;
            });
          })}
          </ul>`,
          willClose: async () => {
            this.coursesStore.errorMessages = [];
            this.quizzesStore.errorMessages = [];
          },
        });
      }
    },
  },

  components: {
    InformationForm,
    ContentForm,
    QuizzesNumber,
    CourseLoaderModal,
  },
};
</script>
<style></style>
