<template>
  <div></div>
</template>
<script>
import { useUsersStore } from "@/store/users";

export default {
  setup() {
    const userStore = useUsersStore();
    const { isEditUserFail } = useUsersStore();
    const { isEditUserSuccess } = useUsersStore();
    return {
      userStore,
      isEditUserFail,
      isEditUserSuccess,
    };
  },
  mounted() {
    if (this.userStore.isEditUserFail) {
      this.$swal({
        icon: "danger",
        title: "Error accured while edditing the user !",
        html: `<ul>
            ${this.userStore.errorMessages.map((error) => {
          return error.map((message) => {
            return `<li class="text-red-500">
                      ${message}
                    </li>`
          })
        })}
          </ul>`,
        willClose: () => {
          this.userStore.isEditUserFail = false;
          this.userStore.isEditUserSuccess = false;
          this.userStore.isShowEditor = false;
          this.userStore.errorMessages = []
        },
      });
    }
  },
};
</script>
