<template>
  <div>
    <metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | RAYHANE` : `SITE_NAME`
      }}</template>
    </metainfo>
    <div class="flex flex-wrap mt-4">
      <div class="my-6 mt-24 w-full">
        <div class="flex justify-between items-center">
          <div>
            <h1 class="text-3xl text-black font-semibold">COD</h1>
            <h1 class="text-xl text-gray font-semibold">Manage the CODs</h1>
          </div>
          <div class="flex items-center">
            <router-link to="/admin/cods/new">
              <button class="btn bg-cyan font-bold">
                <span class="">+ </span> New COD
              </button></router-link
            >
          </div>
        </div>
        <loader-modal v-if="codsStore.isLoadingModal" />
        <div class="w-full">
          <div class="flex flex-row-reverse mt-4"></div>
          <cods-table />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CodsTable from "@/components/tables/CodsTable.vue";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import { useMeta } from "vue-meta";
import { useCodsStore } from "@/store/cods";
export default {
  name: "dashboard-page",
  components: {
    CodsTable,
    LoaderModal,
  },
  setup() {
    const codsStore = useCodsStore();
    useMeta({
      title: "Cod's",
      htmlAttrs: { lang: "en", amp: true },
    });
    return {
      codsStore,
    };
  },
  data() {
    return {};
  },
};
</script>
