<template>
  <Listbox as="div" v-if="selectedDestination" v-model="selectedDestination">
    <div class="relative">
      <ListboxButton
        class="relative w-full cursor-default rounded-md bg-white py-3 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
        <span class="flex items-center">
          <span class="ml-3 block truncate">{{ selectedDestination }}</span>
        </span>
        <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
        leave-to-class="opacity-0">
        <ListboxOptions
          class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          <ListboxOption as="template" v-for="destination in destinationsStore.destinations" :key="destination.id"
            :value="destination" v-slot="{ active, selectedDestination }">
            <li :class="[
              active ? 'bg-indigo-600 ] text-white' : 'text-gray-900',
              'relative cursor-default select-none py-2 pl-3 pr-9',
            ]">
              <div class="flex items-center">
                <span :class="[
                  selectedDestination ? 'font-semibold' : 'font-normal',
                  'ml-3 block truncate',
                ]">{{ destination }}</span>
              </div>

              <span v-if="selectedDestination" :class="[
                active ? 'text-white' : 'text-indigo-600',
                'absolute inset-y-0 right-0 flex items-center pr-4',
              ]">
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
<script>
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import { useDestinationsStore } from "@/store/destinations";
import { useCategoriesStore } from "@/store/categories";
export default {
  components: {
    CheckIcon,
    ChevronUpDownIcon,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
  },
  props: ["defaultDestination"],
  setup() {
    const destinationsStore = useDestinationsStore();
    const categoriesStore = useCategoriesStore();
    return { destinationsStore, categoriesStore };
  },
  data() {
    return { selectedDestination: null, destinations: [] };
  },

  computed: {
    selectedCategory() {
      return this.categoriesStore.selectedCategory;
    },
  },

  watch: {
    selectedCategory(newVal) {
      this.destinations = [newVal.destination];
      this.selectedDestination = this.destinations[0];
    },

    selectedDestination(newDestination) {
      if (newDestination) {
        this.destinationsStore.selectedDestination = newDestination;
        if (this.categoriesStore.editedCategory?.destination) {
          this.categoriesStore.editedCategory.destination = newDestination;
        }
      }
    },
  },

  async mounted() {
    if (this.destinationsStore.destinations.length == 0) {
      await this.destinationsStore.getDestinations();
      this.selectedDestination = this.destinationsStore.destinations[0];
    } else {
      this.selectedDestination = this.destinationsStore.destinations[0];
    }
    if (this.defaultDestination) {
      this.selectedDestination = this.defaultDestination;
    }
  },
};
</script>
