import { createApp } from "vue";
import App from "./App.vue";
import "./assets/tailwind.css";
import router from "./router";
import { createPinia } from "pinia";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { createMetaManager } from "vue-meta";

createApp(App)
  .use(createPinia())
  .use(router)
  .use(VueSweetalert2)
  .use(createMetaManager())
  .mount("#app");
