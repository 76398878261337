<template>
  <div
    class="flex justify-center items-center p-2 rounded-xl"
    :class="isSelected ? 'bg-[#00DDAF]' : 'bg-white'"
  >
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 10C2 7.74566 2.89553 5.58365 4.48959 3.98959C6.08365 2.39553 8.24566 1.5 10.5 1.5C12.7543 1.5 14.9163 2.39553 16.5104 3.98959C18.1045 5.58365 19 7.74566 19 10V12H16.75C16.5511 12 16.3603 12.079 16.2197 12.2197C16.079 12.3603 16 12.5511 16 12.75V19.25C16 19.664 16.336 20 16.75 20H17.5C18.2956 20 19.0587 19.6839 19.6213 19.1213C20.1839 18.5587 20.5 17.7956 20.5 17V10C20.5 4.477 16.023 0 10.5 0C4.977 0 0.5 4.477 0.5 10V17C0.5 17.7956 0.816071 18.5587 1.37868 19.1213C1.94129 19.6839 2.70435 20 3.5 20H4.25C4.44891 20 4.63968 19.921 4.78033 19.7803C4.92098 19.6397 5 19.4489 5 19.25V12.75C5 12.5511 4.92098 12.3603 4.78033 12.2197C4.63968 12.079 4.44891 12 4.25 12H2V10ZM11.25 9.75C11.25 9.55109 11.171 9.36032 11.0303 9.21967C10.8897 9.07902 10.6989 9 10.5 9C10.3011 9 10.1103 9.07902 9.96967 9.21967C9.82902 9.36032 9.75 9.55109 9.75 9.75V20.25C9.75 20.4489 9.82902 20.6397 9.96967 20.7803C10.1103 20.921 10.3011 21 10.5 21C10.6989 21 10.8897 20.921 11.0303 20.7803C11.171 20.6397 11.25 20.4489 11.25 20.25V9.75ZM7.25 12C7.44891 12 7.63968 12.079 7.78033 12.2197C7.92098 12.3603 8 12.5511 8 12.75V17.25C8 17.4489 7.92098 17.6397 7.78033 17.7803C7.63968 17.921 7.44891 18 7.25 18C7.05109 18 6.86032 17.921 6.71967 17.7803C6.57902 17.6397 6.5 17.4489 6.5 17.25V12.75C6.5 12.5511 6.57902 12.3603 6.71967 12.2197C6.86032 12.079 7.05109 12 7.25 12ZM14.5 12.75C14.5 12.5511 14.421 12.3603 14.2803 12.2197C14.1397 12.079 13.9489 12 13.75 12C13.5511 12 13.3603 12.079 13.2197 12.2197C13.079 12.3603 13 12.5511 13 12.75V17.25C13 17.4489 13.079 17.6397 13.2197 17.7803C13.3603 17.921 13.5511 18 13.75 18C13.9489 18 14.1397 17.921 14.2803 17.7803C14.421 17.6397 14.5 17.4489 14.5 17.25V12.75Z"
        :fill="color"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: ["color", "isSelected"],
};
</script>
<style></style>
