<template>
  <nav
    class="absolute top-0 left-0 w-full bg-white md:flex-row md:flex-nowrap md:justify-start flex items-center p-4"
  >
    <div
      class="w-full mx-auto items-center flex justify-end md:flex-nowrap flex-wrap md:px-2 px-4"
    >
      <form
        v-if="showSearchInput"
        class="md:flex flex-row items-center mr-3 xl:w-4/12 mb-4 w-full sm:w-auto"
      >
        <div
          class="relative hidden md:flex w-full flex-wrap items-stretch border border-gray rounded-xl focus:outline-none"
        >
          <span
            class="z-10 h-full leading-snug font-normal text-center text-blueGray-300 absolute bg-transparent rounded-xl text-base items-center justify-center w-8 pl-2 py-3"
          >
            <svg
              class="mr-2"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <ellipse
                cx="12.6014"
                cy="10.8849"
                rx="9.10146"
                ry="8.88476"
                stroke="#0B2568"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.9316 17.5258L22.4999 21.0001"
                stroke="#0B2568"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>

          <input
            type="text"
            @input="getSearchedResults"
            v-model="authStore.search"
            placeholder="Type here..."
            class="border-0 px-3 py-3 relative border-[lightGray] bg-white rounded-xl text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
          />
        </div>
        <div>
          <svg
            v-if="authStore.isLoading"
            aria-hidden="true"
            class="w-8 h-8 ml-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="white"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="#01DDAF"
            />
          </svg>
        </div>
      </form>

      <!-- User -->
      <div class="flex items-center">
        <div>
          <img
            @click="$router.push('/admin/notifications')"
            :src="notifications"
            v-if="!isNewNotifications"
            alt="notifications"
            class="cursor-pointer mx-2"
          />
        </div>
        <div v-if="authStore.user?.permissions.includes('manage_tickets')">
          <img
            @click="$router.push('/admin/messages')"
            :src="messagesIcon"
            alt="messagesIcon"
            class="cursor-pointer mx-2 pt-1"
          />
        </div>

        <div class="mx-6 flex text-purple">
          <svg
            class="mr-2"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.4905 3.7835C18.3503 2.55244 16.7577 1.87451 14.9999 1.87451C13.2327 1.87451 11.6348 2.54834 10.4999 3.77178C9.35261 5.00869 8.79362 6.68974 8.92487 8.50498C9.18503 12.0862 11.9102 14.9995 14.9999 14.9995C18.0895 14.9995 20.81 12.0868 21.0743 8.50615C21.2073 6.70732 20.6448 5.02978 19.4905 3.7835Z"
              fill="#0C2568"
            />
            <path
              d="M25.3124 28.1232H4.68741C4.41744 28.1267 4.15009 28.07 3.90481 27.9572C3.65952 27.8444 3.44247 27.6783 3.26944 27.4711C2.88858 27.0158 2.73506 26.3941 2.84874 25.7654C3.34327 23.0221 4.88663 20.7176 7.31241 19.0998C9.46748 17.6637 12.1974 16.8732 14.9999 16.8732C17.8024 16.8732 20.5323 17.6642 22.6874 19.0998C25.1132 20.717 26.6565 23.0215 27.1511 25.7648C27.2647 26.3935 27.1112 27.0152 26.7304 27.4705C26.5574 27.6778 26.3404 27.844 26.0951 27.9569C25.8498 28.0699 25.5824 28.1267 25.3124 28.1232Z"
              fill="#0C2568"
            />
          </svg>
          {{
            authStore?.user?.user?.first_name
              ? authStore.user.user.first_name
              : "..."
          }}
        </div>
        <div class="mx-6 flex text-purple cursor-pointer" @click="logout">
          <svg
            class="mr-2"
            width="25"
            height="26"
            viewBox="0 0 25 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.5 0.5C3.50544 0.5 2.55161 0.895088 1.84835 1.59835C1.14509 2.30161 0.75 3.25544 0.75 4.25V21.75C0.75 22.7446 1.14509 23.6984 1.84835 24.4017C2.55161 25.1049 3.50544 25.5 4.5 25.5H12C12.9946 25.5 13.9484 25.1049 14.6517 24.4017C15.3549 23.6984 15.75 22.7446 15.75 21.75V4.25C15.75 3.25544 15.3549 2.30161 14.6517 1.59835C13.9484 0.895088 12.9946 0.5 12 0.5H4.5ZM17.3663 7.11625C17.6007 6.88191 17.9185 6.75027 18.25 6.75027C18.5815 6.75027 18.8993 6.88191 19.1338 7.11625L24.1338 12.1163C24.3681 12.3507 24.4997 12.6685 24.4997 13C24.4997 13.3315 24.3681 13.6493 24.1338 13.8838L19.1338 18.8838C18.898 19.1115 18.5822 19.2374 18.2545 19.2346C17.9268 19.2317 17.6132 19.1003 17.3815 18.8685C17.1497 18.6368 17.0183 18.3232 17.0154 17.9955C17.0126 17.6678 17.1386 17.352 17.3663 17.1163L20.2325 14.25H9.5C9.16848 14.25 8.85054 14.1183 8.61612 13.8839C8.3817 13.6495 8.25 13.3315 8.25 13C8.25 12.6685 8.3817 12.3505 8.61612 12.1161C8.85054 11.8817 9.16848 11.75 9.5 11.75H20.2325L17.3663 8.88375C17.1319 8.64934 17.0003 8.33146 17.0003 8C17.0003 7.66855 17.1319 7.35066 17.3663 7.11625Z"
              fill="#0C2568"
            />
          </svg>
          Logout
        </div>
      </div>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script>
import adminLogo from "@/assets/icons/navbar/adminLogo.svg";
import notifications from "@/assets/icons/navbar/notification.svg";
import messagesIcon from "@/assets/icons/navbar/messagesIcon.svg";
import { useAuthStore } from "@/store/auth";
import { useNotificationsStore } from "@/store/notifications";
import config from "../../../config";
import { useTeachersStore } from "@/store/teachers";
import { useUsersStore } from "@/store/users";
import { useCoursesStore } from "@/store/courses";
import { useTicketStore } from "@/store/tickets";
import { useAdsStore } from "@/store/ads";
import { usePaymentsStore } from "@/store/payments";
import { useOffersStore } from "@/store/offers";
import { useCodsStore } from "@/store/cods";
import { useAffiliationsStore } from "@/store/affiliations";
import { useModeratorsStore } from "@/store/moderators";
export default {
  components: {},
  data() {
    return { config, isNewNotifications: false };
  },
  methods: {
    logout() {
      this.authStore.disconnect();
    },
    async getSearchedResults(e) {
      if (e.target.value.length >= 2 || e.target.value.length == 0) {
        switch (this.$route.path) {
          case "/admin/teachers":
            await this.teachersStore.getTeachers(e.target.value);
            break;
          case "/admin/users":
            await this.usersStore.getUsers(e.target.value);
            break;
          case "/admin/courses":
            await this.coursesStore.getCourses(e.target.value);
            break;
          case "/admin/messages":
            await this.ticketsStore.getTickets(e.target.value);
            break;
          case "/admin/subscribers":
            await this.paymentsStore.getPayments(e.target.value);
            break;
          case "/admin/offers":
            await this.offersStore.getOffers(e.target.value);
            break;
          case "/admin/offers/promo":
            await this.offersStore.getPromoCodes(e.target.value);
            break;
          case "/admin/cods":
            await this.codsStore.getCods(e.target.value);
            break;
          case "/admin/affiliation":
            await this.affiliationsStore.getAffiliations(e.target.value);
            break;
          case "/admin/settings":
            await this.moderatorsStore.getModerators(e.target.value);
            break;
          case "/admin/ads":
            await this.adsStore.getAds(e.target.value);
            break;
          default:
            return false;
        }
      }
    },
  },

  computed: {
    showSearchInput() {
      switch (this.$route.path) {
        case "/admin/teachers":
          return true;
        case "/admin/users":
          return true;
        case "/admin/courses":
          return true;
        case "/admin/messages":
          return true;
        case "/admin/subscribers":
          return true;
        case "/admin/offers":
          return true;
        case "/admin/offers/promo":
          return true;
        case "/admin/cods":
          return true;
        case "/admin/affiliation":
          return true;
        case "/admin/settings":
          return true;
        case "/admin/ads":
          return true;
        default:
          return false;
      }
    },
  },

  setup() {
    const authStore = useAuthStore();
    const notificationsStore = useNotificationsStore();
    const teachersStore = useTeachersStore();
    const usersStore = useUsersStore();
    const coursesStore = useCoursesStore();
    const ticketsStore = useTicketStore();
    const adsStore = useAdsStore();
    const paymentsStore = usePaymentsStore();
    const offersStore = useOffersStore();
    const codsStore = useCodsStore();
    const affiliationsStore = useAffiliationsStore();
    const moderatorsStore = useModeratorsStore();
    return {
      authStore,
      notifications,
      adminLogo,
      messagesIcon,
      notificationsStore,
      teachersStore,
      usersStore,
      coursesStore,
      ticketsStore,
      paymentsStore,
      adsStore,
      offersStore,
      codsStore,
      affiliationsStore,
      moderatorsStore,
    };
  },
};
</script>
