<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <LoaderModal v-if="notificationStore.isLoading" />
    <div class="my-6 mt-24 w-full">
      <div class="w-full">
        <div class="my-4">
          <h1 class="text-3xl text-black font-semibold">Notifications</h1>
          <h1 class="text-md text-gray font-semibold mt-2">
            Send notifications
          </h1>
        </div>
        <div
          class="bg-purple text-white font-bold text-3xl p-5 rounded-t-xl border-[#c9c9c9] border-b-0 border"
        >
          New notification
        </div>
        <form class="w-full border-[#c9c9c9] border border-t-0 p-4 rounded-xl">
          <div class="md:flex">
            <div class="md:mr-6 my-3 md:w-9/12">
              <p class="text-purple mb-1 text-left">Notification title</p>
              <input
                v-model="notification_title"
                :class="
                  v$.notification_title.$error ? 'border-red-500 border-[3px]' : ''
                "
                type="text"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p class="text-red-500 text-[1rem]" v-if="v$.notification_title.$error">
                <span
                  v-for="(error, index) in v$.notification_title.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </p>
            </div>
          </div>

          <div class="md:flex">
            <div class="md:mr-6 my-3 md:w-9/12">
              <p class="text-purple mb-1 text-left">Text</p>
              <textarea
                v-model="text"
                :class="
                  v$.text.$error ? 'border-red-500 border-[3px]' : ''
                "
                type="text"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3 min-h-[200px] resize-none"
              >
              </textarea>
              <p class="text-red-500 text-[1rem]" v-if="v$.text.$error">
                <span
                  v-for="(error, index) in v$.text.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </p>
            </div>
          </div>

          <div class="md:flex justify-start">
            <div class="my-2 mb-4 text-xl rounded-md">
              <p class="text-purple mb-1 text-left">Image</p>
              <label
                class="cursor-pointer w-fit flex items-center"
                for="inputTag"
              >
                <div
                  v-if="!this.background"
                  class="flex flex-col upload-block justify-center items-center p-2 h-40 w-40 rounded-md border-dashed"
                >
                  <p class="text-[#8a8a8ac9] mb-1 text-center text-base mt-2">
                    Click here to select a file
                  </p>

                  <input
                    class="hidden"
                    hidden
                    id="inputTag"
                    @change="handleBackgroundChange"
                    type="file"
                    accept="image/*"
                  />
                </div>

                <img
                  v-show="this.background"
                  class="h-40 w-40"
                  ref="background"
                />
                <img
                  @click="removeBackground"
                  alt="bg"
                  :src="trash"
                  class="ml-2"
                  v-if="this.background"
                  width="40"
                />
              </label>
              <p class="text-red-500 text-[1rem]" v-if="v$.background.$error">
                <span
                  v-for="(error, index) in v$.background.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </p>
            </div>
          </div>
        </form>
        <div class="flex justify-start my-4">
          <button class="btn btn bg-cyan font-bold" @click="addNotification">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";
import { useNotificationsStore } from "@/store/notifications";
import trash from "@/assets/icons/cards/trash.svg";
import "@vuepic/vue-datepicker/dist/main.css";
import router from "@/router";
import LoaderModal from "@/components/modals/LoaderModal.vue";

export default {
  data() {
    return {
      notification_title: "",
      text: "",
      background: null,
    };
  },
  setup() {
    const notificationStore = useNotificationsStore();

    return {
      v$: useVuelidate(),
      notificationStore,
      trash,
    };
  },
  methods: {
    async addNotification() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      } else {
        const formData = new FormData();
        formData.append("title", this.notification_title);
        formData.append("content", this.text);
        if (this.background instanceof File)
          formData.append("image", this.background);

        await this.notificationStore.addNotification(formData);
        if (this.notificationStore.isSuccess) {
          this.$swal({
            icon: "success",
            timer: 1500,
            title: "Success...",
            showConfirmButton: false,
            html: "<p class='text-green-500' > Notification aded successfully<p/>",
          }).then(() => {
            router.push("/admin/notifications");
          });
        } else if (this.notificationStore.isFail) {
          this.$swal({
            icon: "error",
            title: "Error",
            showConfirmButton: true,
            timer: 4000,
            html: `<ul>
            ${this.notificationStore.errorMessages.map((error) => {
              return error.map((message) => {
                return `<li class="text-red-500">
                      ${message}
                    </li>`;
              });
            })}
          </ul>`,
          });
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
        this.notificationStore.isSuccess = false;
        this.notificationStore.isFail = false;
      }
      // perform async actions
    },
    handleBackgroundChange(event) {
      this.background = event.target.files[0];
      let outputPic = this.$refs.background;
      outputPic.src = URL.createObjectURL(this.background);
      outputPic.width = 200;
    },
    removeBackground() {
      this.$refs.background.src = null;
      this.background = null;
    },
  },
  validations() {
    return {
      notification_title: {
        required: helpers.withMessage("notification title is required", required),
        minLength: helpers.withMessage(
          "notification title must have at least 3 characters",
          minLength(3)
        ),
      },
      text: {
        required: helpers.withMessage("notification text is required", required),
        minLength: helpers.withMessage(
          "notification text must have at least 3 characters",
          minLength(3)
        ),
      },
      background: {
        required: helpers.withMessage("notification image is required", required),
      },
    };
  },
  components: {
    LoaderModal,
  },
};
</script>

<style scoped>
.upload-block {
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A0A0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
<style>
.dp__pointer {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
</style>
