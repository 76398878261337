<template>
    <div class="w-full">
        <div class="my-2">
            <h1 class="text-3xl text-black font-semibold">Moderators</h1>
            <h1 class="text-md text-gray font-semibold">Add new moderator</h1>
        </div>
        <div
            class="bg-purple text-white font-bold text-3xl p-5 rounded-t-xl border-[#c9c9c9] border-b-0 border"
        >
            New moderator
        </div>
        <form class="w-full border-[#c9c9c9] border border-t-0 p-4 rounded-xl">
            <div><p class="text-purple mb-1 text-left">Picture</p></div>
            <div class="my-2 mb-4 text-xl rounded-md">
                <label
                    class="cursor-pointer w-fit flex items-center"
                    for="inputTag"
                >
                    <div
                        v-if="!cover"
                        class="flex flex-col upload-block justify-center items-center p-2 h-40 w-40 rounded-md border-dashed"
                    >
                        <p
                            class="text-[#6a6a6ac9] mb-1 text-center text-base mt-2"
                        >
                            Click here to select file
                        </p>

                        <input
                            class="hidden"
                            hidden
                            id="inputTag"
                            @change="handleCoverChange"
                            type="file"
                            accept="image/*"
                        />
                    </div>

                    <img v-show="cover" class="h-40 w-40" ref="output" />
                    <img
                        @click="removeImage"
                        :src="trash"
                        class="ml-2"
                        v-if="cover"
                        width="40"
                    />
                </label>
            </div>
            <p class="text-red-500" v-if="v$.cover.$error">
                Picture is required
            </p>
            <div class="md:flex">
                <div class="md:mr-6 my-3 md:w-6/12">
                    <p class="text-purple mb-1 text-left">Full name</p>
                    <input
                        v-model="first_name"
                        type="text"
                        class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
                    />
                    <p class="text-red-500" v-if="v$.first_name.$error">
                        First name is required
                    </p>
                </div>
                <div class="md:mr-6 my-3 md:w-6/12">
                    <p class="text-purple mb-1 text-left">Last name</p>
                    <input
                        v-model="last_name"
                        type="text"
                        class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
                    />
                    <p class="text-red-500" v-if="v$.last_name.$error">
                        Last name is required
                    </p>
                </div>
                <div class="md:w-6/12 md:mr-6 my-3">
                    <p class="text-purple mb-1 text-left">Role</p>
                    <input
                        v-model="role"
                        type="text"
                        class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
                    />
                    <p class="text-red-500" v-if="v$.role.$error">
                        Role is required
                    </p>
                </div>
            </div>
            <div class="md:flex">
                <div class="md:w-6/12 md:mr-6 my-3">
                    <p class="text-purple mb-1 text-left">Email address</p>
                    <input
                        v-model="email"
                        type="text"
                        class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
                    />
                    <p class="text-red-500" v-if="v$.email.$error">
                        Enter a valid email address
                    </p>
                </div>
                <div class="md:w-6/12 md:mr-6 my-3">
                    <p class="text-purple mb-1 text-left">Phone number</p>
                    <input
                        v-model="phone_number"
                        type="tel"
                        maxlength="10"
                        class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
                    />
                    <p class="text-red-500" v-if="v$.phone_number.$error">
                        Enter a valid phone number
                    </p>
                </div>
            </div>
            <div class="md:flex">
                <div class="md:w-6/12 md:mr-6 my-3">
                    <p class="text-purple mb-1 text-left">Permissions</p>
                    <div class="border-[#c9c9c9]">
                        <FilterParametersPermissions />
                    </div>

                    <p class="text-red-500" v-if="permissionsError">
                        Enter at least one permission
                    </p>
                </div>
                <div class="md:w-6/12 md:mr-6 my-3">
                    <p class="text-purple mb-1 text-left">Set password</p>
                    <div
                        class="flex items-center justify-between border-[#c9c9c9] border rounded-md py-3 password-container"
                        :class="[
                            isPasswordFocus
                                ? 'border-primary border-[3px]'
                                : '',
                            v$.password.$error
                                ? 'border-red-500 border-[3px]'
                                : '',
                        ]"
                    >
                        <input
                            @focus="isPasswordFocus = true"
                            @blur="isPasswordFocus = false"
                            class="text-gray-700 px-2 leading-tight focus:outline-none w-full password-input"
                            :type="showPassword ? 'text' : 'password'"
                            v-model="password"
                            name="password"
                        />

                        <svg
                            class="mx-2 cursor-pointer"
                            @click="showPassword = true"
                            v-if="!showPassword"
                            width="23"
                            height="15"
                            viewBox="0 0 23 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <!-- ... your existing code ... -->
                        </svg>

                        <svg
                            class="mx-2 cursor-pointer"
                            @click="showPassword = false"
                            v-if="showPassword"
                            width="23"
                            height="19"
                            viewBox="0 0 23 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <!-- ... your existing code ... -->
                        </svg>
                    </div>
                    <p class="text-red-500" v-if="v$.password.$error">
                        Password must be at least 8 characters
                    </p>
                </div>
            </div>
        </form>
        <div class="flex justify-start my-4">
            <button class="btn btn bg-cyan font-bold" @click="addMod">
                Save
            </button>
        </div>
    </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { useModeratorsStore } from "@/store/moderators";
import trash from "@/assets/icons/cards/trash.svg";
import { required, email, minLength, helpers } from "@vuelidate/validators";
import FilterParametersPermissions from "../dropdowns/filters/parameters/FilterParametersPermissions.vue";
export default {
    data() {
        return {
            first_name: "",
            last_name: "",
            role: "",
            phone_number: "",
            email: "",
            cover: null,
            password: "",
            showPassword: false,
            isPasswordFocus: false,
            isEmailFocus: false,
            permissions: [],
            permissionsError: false,
        };
    },
    setup() {
        const moderatorsStore = useModeratorsStore();
        return { v$: useVuelidate(), moderatorsStore, trash };
    },
    methods: {
        handleCoverChange(event) {
            this.cover = event.target.files[0];
            let outputPic = this.$refs.output;
            outputPic.src = URL.createObjectURL(this.cover);
            outputPic.width = 200;
        },

        removeImage() {
            this.$refs.output.src = null;
            this.cover = null;
        },
        async addMod() {
            this.permissionsError = false;
            if (this.moderatorsStore.newModerator?.permissions?.length == 0) {
                this.permissionsError = true;
            }
            const result = await this.v$.$validate();

            if (!result || this.permissionsError) {
                window.scrollTo({ top: 0, behavior: "smooth" });

                return;
            } else {
                const formData = new FormData();
                formData.append("first_name", this.first_name);
                formData.append("last_name", this.last_name);
                formData.append("function", this.role);
                formData.append("email", this.email);
                formData.append("password", this.password);
                formData.append("phone", this.phone_number);
                this.permissions =
                    this.moderatorsStore.newModerator.permissions;
                this.permissions.forEach((item) =>
                    formData.append("permissions[]", item)
                );

                formData.append("image", this.cover);
                await this.moderatorsStore.addModerator(formData);
                if (this.moderatorsStore.isSuccess) {
                    this.$swal({
                        icon: "success",
                        timer: 1500,
                        title: "Success...",
                        showConfirmButton: false,
                        html:
                            "<p class='text-red-500' >" +
                            this.moderatorsStore.errorsMessages +
                            "<p/>",
                    }).then(() => {
                        location.reload();
                    });
                    this.$router.push("/admin/settings");
                } else if (this.moderatorsStore.isFail) {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        showConfirmButton: true,
                        timer: 4000,
                        html:
                            "<p class='text-red-500' >" +
                            this.moderatorsStore.errorsMessages +
                            "<p/>",
                    });
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }
                this.moderatorsStore.isSuccess = false;
                this.moderatorsStore.isFail = false;
                this.moderatorsStore.errorsMessages = "";
            }
        },
    },

    validations() {
        const phoneSchema = helpers.regex(/^(00213|\+213|0)(5|6|7)[0-9]{8}$/);

        return {
            first_name: { required, minLength: minLength(3) },
            last_name: { required, minLength: minLength(3) },
            email: { required, email },
            cover: { required },
            role: { required, minLength: minLength(3) },
            phone_number: { required, phoneSchema },
            password: { required, minLength: minLength(8) },
        };
    },
    components: { FilterParametersPermissions },
};
</script>

<style scoped>
.upload-block {
    /* dashed border */
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A0A0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
