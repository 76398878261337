<template>
  <div></div>
</template>
<script>
export default {
  name: "dashboard-page",

  components: {},
};
</script>
