<template>
  <div>
    <div class="flex justify-between items-center">
      <div>
        <h1 class="text-3xl text-black font-semibold">Courses</h1>
        <h1 class="text-xl text-gray font-semibold">Manage your courses</h1>
      </div>
    </div>
    <div
      class="relative min-w-0 break-words w-full mb-6 shadow-sm mt-4 rounded-xl border border-[#e8e8e8c9] bg-white"
    >
      <div class="block w-full overflow-x-auto">
        <table
          class="items-center w-full bg-transparent border border-[#e8e8e8c9] rounded-xl overflow-hidden"
          v-if="true"
        >
          <thead class="">
            <tr class="border-[#e8e8e8c9] border">
              <th
                class="text-purple px-6 flex items-center align-middle py-6 uppercase -0 font-medium text-left"
              >
                The student
              </th>
              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
              >
                Date
              </th>

              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
              >
                The comment
              </th>
              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
              >
                Stars
              </th>

              <th
                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="">
              <td class="align-middle p-4 text-purple flex items-center">
                <img :src="avatarUser" class="w-10 h-10 mr-2" alt="dfsd" />
                Samanta William
              </td>
              <td class="align-middle p-4">20/12/2002</td>
              <td class="align-middle p-4 text-center">Best course ever</td>

              <td class="align-middle whitespace-nowrap p-4">
                <rating-stars :review="2" />
              </td>

              <td class="align-middle text-right">
                <img
                  @click="reviewsStore.showDeleteModal = true"
                  :src="deleteReview"
                  class="cursor-pointer mx-auto"
                  alt="deleteReview"
                />
                <DeleteReviewModal
                  v-if="reviewsStore.showDeleteModal"
                  :reviewId="1"
                />
              </td>
            </tr>
            <tr class="">
              <td class="align-middle p-4 text-purple flex items-center">
                <img :src="avatarUser" class="w-10 h-10 mr-2" alt="dfsd" />
                Samanta William
              </td>
              <td class="align-middle p-4">20/12/2002</td>

              <td class="align-middle p-4 text-center">Best course ever</td>

              <td class="align-middle whitespace-nowrap p-4">
                <rating-stars :review="3" />
              </td>

              <td class="align-middle text-right">
                <img
                  @click="reviewsStore.showDeleteModal = true"
                  :src="deleteReview"
                  class="cursor-pointer mx-auto"
                  alt="deleteReview"
                />
                <DeleteReviewModal
                  v-if="reviewsStore.showDeleteModal"
                  :reviewId="1"
                />
              </td>
            </tr>
            <tr class="">
              <td class="align-middle p-4 text-purple flex items-center">
                <img :src="avatarUser" class="w-10 h-10 mr-2" alt="dfsd" />
                Samanta William
              </td>
              <td class="align-middle p-4">20/12/2002</td>

              <td class="align-middle p-4 text-center">Best course ever</td>

              <td class="align-middle whitespace-nowrap p-4">
                <rating-stars :review="4" />
              </td>

              <td class="align-middle text-right">
                <img
                  @click="reviewsStore.showDeleteModal = true"
                  :src="deleteReview"
                  class="mx-auto cursor-pointer"
                  alt="deleteReview"
                />
                <DeleteReviewModal
                  v-if="reviewsStore.showDeleteModal"
                  :reviewId="1"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="flex flex-col justify-center items-center py-10">
          <img :src="noRecords" alt="noRecords" class="w-2/6" />
          <div class="text-xl text-[#747373]">No records at the moment !</div>
        </div>
      </div>
    </div>
    <div class="flex justify-between items-center">
      <div>
        Showing {{ reviewsStore.from }}-{{ reviewsStore.to }} from
        {{ reviewsStore.reviews.length }} data
      </div>
      <div class="flex items-center">
        <div v-if="reviewsStore.currentPage != 1">
          <img
            @click="reviewsStore.previousPage()"
            :src="forward"
            class="cursor-pointer"
            alt="forward"
          />
        </div>

        <div
          class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12"
          :class="
            true
              ? 'bg-cyan text-white border-cyan'
              : ' text-gray border border-gray'
          "
        >
          {{ reviewsStore.currentPage }}
        </div>

        <div v-if="reviewsStore.currentPage + 1 <= reviewsStore.totalPages">
          <img
            class="cursor-pointer"
            @click="reviewsStore.nextPage()"
            :src="back"
            alt="back"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DeleteReviewModal from "@/components/modals/reviews/DeleteReviewModal.vue";
import checkbox from "../../assets/icons/cards/checkbox.svg";
import separator from "../../assets/icons/cards/separator.svg";
import deleteReview from "../../assets/icons/cards/deleteReview.svg";
import avatarUser from "../../assets/icons/cards/avatarUser.svg";
import back from "../../assets/icons/cards/back.svg";
import forward from "../../assets/icons/cards/forward.svg";

import noRecords from "../../assets/images/noRecords.svg";
import RatingStars from "../cards/partials/RatingStars.vue";
import { useReviewsStore } from "@/store/reviews";
export default {
  components: {
    DeleteReviewModal,
    RatingStars,
  },
  data() {
    return {};
  },
  setup() {
    const reviewsStore = useReviewsStore();
    return {
      forward,
      back,
      checkbox,
      avatarUser,
      deleteReview,
      separator,
      reviewsStore,
      noRecords,
    };
  },

  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
