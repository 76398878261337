<template>
  <div>
    <metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | RAYHANE` : `SITE_NAME`
      }}</template>
    </metainfo>
    <div class="flex flex-wrap mt-4 self-stretch items-stretch">
      <div class="my-6 mt-24 w-full">
        <CardEditProfile />
      </div>
    </div>
  </div>
</template>
<script>
import CardEditProfile from "../../components/cards/CardEditProfile.vue";
import { useMeta } from "vue-meta";

export default {
  components: {
    CardEditProfile,
  },
  setup() {
    useMeta({
      title: "Profile",
      htmlAttrs: { lang: "en", amp: true },
    });
  },
};
</script>
<style></style>
