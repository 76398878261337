<template>
  <div class="w-full mb-4">
    <div class="my-2 flex justify-between">
      <div>
        <h1 class="text-3xl text-black font-semibold">Courses</h1>
        <h1 class="text-md text-gray font-semibold">New Course</h1>
      </div>
      <div v-if="coursesStore.newCourse">ADDED</div>
    </div>
    <div
      class="flex justify-between items-center bg-purple text-white font-bold text-3xl p-5 rounded-t-xl border-[#c9c9c9] border-b-0 border"
    >
      <span> Informations of the course </span>

      <svg
        v-if="!isValid"
        xmlns="http://www.w3.org/2000/svg"
        height="32"
        width="32"
        viewBox="0 0 512 512"
      >
        <circle
          cx="256"
          cy="256"
          r="248"
          stroke="#00DDAF"
          fill="none"
          stroke-width="16"
        />
      </svg>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        height="32"
        width="32"
        viewBox="0 0 512 512"
      >
        <path
          d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zM227.3 387.3l184-184c6.2-6.2 6.2-16.4 0-22.6l-22.6-22.6c-6.2-6.2-16.4-6.2-22.6 0L216 308.1l-70.1-70.1c-6.2-6.2-16.4-6.2-22.6 0l-22.6 22.6c-6.2 6.2-6.2 16.4 0 22.6l104 104c6.2 6.2 16.4 6.2 22.6 0z"
          fill="#00DDAF"
        />
      </svg>
    </div>
    <form class="w-full border-[#c9c9c9] border border-t-0 p-4 rounded-b-xl">
      <div>
        <p class="text-purple mb-1 text-left">Cover</p>
      </div>
      <div class="my-2 mb-4 text-xl rounded-md">
        <label class="cursor-pointer w-fit flex items-center" for="InfoInput">
          <div
            v-if="!form.cover"
            class="flex flex-col upload-block justify-center items-center p-2 h-40 w-60 rounded-md border-dashed"
          >
            <p class="text-black mb-1 text-center text-base mt-2">
              Click here to select file
            </p>

            <input
              class="hidden"
              hidden
              id="InfoInput"
              @change="handleCoverChange"
              type="file"
              accept="image/jpeg,image/png,image/jpg"
            />
          </div>

          <img v-show="form.cover" class="h-40 w-60" ref="output" />
          <img
            @click="removeImage"
            :src="trash"
            class="ml-2"
            v-if="form.cover"
            width="40"
          />
        </label>
        <p class="text-red-500" v-if="v$.form?.cover?.$error">
          Cover is required
        </p>
      </div>
      <div class="md:flex">
        <div class="md:mr-6 my-3 md:w-4/12">
          <p class="text-purple mb-1 text-left">Course title</p>
          <input
            v-model="form.course_title"
            type="text"
            class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
          />
          <p class="text-red-500" v-if="v$.form?.course_title?.$error">
            Title must be at least 3 characters
          </p>
        </div>
        <div class="md:w-8/12 md:mr-6 my-3">
          <p class="text-purple mb-1 text-left">Teacher affectation</p>

          <!--<SelectTeacherDropdown @input="setActiveTeacher" />-->
          <GenericSelectDropdown
            v-model="form.teacher"
            :value="form.teacher"
            :options="this.teachersStore.teachers.data"
            :render="(teacher) => teacher ? teacher.user.first_name + ' ' + teacher.user.last_name : 'Select an option'"
            @updateValue="getNewTeacher"
          />
          <p class="text-red-500" v-if="v$.form?.teacher?.$error">
            Teacher is required!
          </p>
        </div>
        <!-- <div class="md:w-4/12 md:mr-6 my-3">
          <p class="text-purple mb-1 text-left">Level</p>
          <SelectLevelDropdown />
        </div> -->
      </div>
      <div class="md:flex">
        <div class="md:w-4/12 md:mr-6 my-3">
          <p class="text-purple mb-1 text-left">Price</p>
          <input
            v-model="form.price"
            min="0"
            type="number"
            class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
          />
          <p class="text-red-500" v-if="v$.form?.price?.$error">
            Price is required
          </p>
        </div>
        <div class="md:w-8/12 md:mr-6 my-3">
          <p class="text-purple mb-1 text-left">Destination</p>

          <!--<SelectDestinationsDropdown />-->
          <GenericSelectDropdown
            v-model="form.destination"
            :value="form.destination"
            :options="destinationsStore.destinations"
            :render="(destination) => destination ?? 'Select an option'"
            @updateValue="getNewDestination"
            />
            <p class="text-red-500" v-if="v$.form?.destination?.$error">
              Destination is required!
            </p>
        </div>
        <div class="md:w-4/12 md:mr-6 my-3">
          <p class="text-purple mb-1 text-left">Category</p>

          <!--<SelectCategoryDropdown />-->
          <GenericSelectDropdown
            v-model="form.category"
            :value="form.category"
            :options="this.categoriesStore.categories"
            :render="(category) => category ? category.name : 'Select an option'"
            @updateValue="getNewCategory"
            />
            <p class="text-red-500" v-if="v$.form?.category?.$error">
              Category is required!
            </p>
        </div>
      </div>
      <div class="">
        <div class="my-3">
          <p class="text-purple mb-1 text-left">Description</p>
          <textarea
            name="description"
            class="w-full focus:outline-purple p-2 border border-purple rounded-xl"
            id=""
            cols="30"
            rows="5"
            v-model="form.description"
          ></textarea>
        </div>
      </div>
      <!-- <button type="submit">validate</button> -->
    </form>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { useDestinationsStore } from "../../../store/destinations";
import { useTeachersStore } from "../../../store/teachers";
import { useCategoriesStore } from "../../../store/categories";
import { useCoursesStore } from "../../../store/courses";
import { useLevelsStore } from "../../../store/levels";
import trash from "@/assets/icons/cards/trash.svg";
import { required, minLength } from "@vuelidate/validators";
// import SelectTeacherDropdown from "../partials/SelectTeacherDropdown.vue";
// import SelectDestinationsDropdown from "../partials/SelectDestinationsDropdown.vue";
// import SelectCategoryDropdown from "../partials/SelectCategoryDropdown.vue";
import GenericSelectDropdown from "../partials/GenericSelectDropdown.vue";
// import SelectLevelDropdown from "../partials/SelectLevelDropdown.vue";

export default {
  props: {
    isValid: {
      type: Boolean,
      required: true,
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    courseInfo: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      form: {
        course_title: "",
        description: "",
        price: 0,
        cover: null,
        teacher: null,
        category: null,
        destination: null,
      },
      currentTeacher: null,
      currentCategory: null,
      currentDestination: null,
    };
  },

  setup() {
    const destinationsStore = useDestinationsStore();
    const teachersStore = useTeachersStore();
    const categoriesStore = useCategoriesStore();
    const coursesStore = useCoursesStore();
    const levelsStore = useLevelsStore();
    return {
      v$: useVuelidate(),
      destinationsStore,
      trash,
      teachersStore,
      categoriesStore,
      coursesStore,
      levelsStore,
    };
  },
  watch: {
    currentTeacher: {
      handler(newTeacher) {
        this.form = {
          ...this.form,
          teacher_id: newTeacher?.user?.id
        }
      }
    },
    currentCategory: {
      handler(newCategory) {
        this.form = {
          ...this.form,
          category_id: newCategory?.id
        }
      }
    },
    currentDestination: {
      handler(newDestination) {
        this.form = {
          ...this.form,
          destination: newDestination
        }
      }
    },
    courseInfo: {
      handler() {
        this.form = {
          course_title: this.courseInfo.course_title
            ? this.courseInfo.course_title
            : "",
          description: this.courseInfo.description
            ? this.courseInfo.description
            : "",
          price: this.courseInfo.price ? this.courseInfo.price : 0,
          cover: this.courseInfo.cover ? this.courseInfo.cover : null,
          destination: this.courseInfo.destination ? this.courseInfo.destination : null,
          category: this.courseInfo.category ? this.courseInfo.category : null,
          teacher: this.courseInfo.teacher ? this.courseInfo.teacher : null
        };
        if (this.courseInfo.cover) {
          this.$refs.output.src = this.form.cover;
          this.$refs.output.width = 200;
        }
      },
      deep: true,
    },
    form: {
      async handler() {
        const result = await this.v$.$validate();
        if (
          !result
        ) {
          this.$emit("updateValidity", false);
          return;
        } else {
          this.addCourse();
          this.$emit("updateValidity", true);
          return;
        }
      },
      deep: true,
    },
  },

  methods: {
    getNewTeacher(nt) {
      this.form.teacher = nt
    },
    getNewCategory(nc) {
      this.form.category = nc
    },
    getNewDestination(nd) {
      this.form.destination = nd
    },
    handleCoverChange(event) {
      this.form.cover = event.target.files[0];
      let outputPic = this.$refs.output;
      // this.categoriesStore.editedCategory.image = this.cover;
      outputPic.src = URL.createObjectURL(this.form.cover);
      outputPic.width = 200;
    },
    removeImage() {
      this.$refs.output.src = null;
      this.form.cover = null;
      // this.categoriesStore.editedCategory.image = null;
    },
    addCourse() {
      const formData = new FormData();
      formData.append("title", this.form.course_title);
      formData.append("price", parseInt(this.form.price));
      formData.append("level", "Intermediate");
      formData.append("destination", this.form.destination);
      formData.append("category_id", this.form.category.id);
      formData.append("teacher_id", this.form.teacher.user.id);
      formData.append("description", this.form.description);
      if (typeof this.form.cover !== "string") formData.append("image", this.form.cover);
      this.$emit("getCourseInfo", formData);
    },
  },

  validations() {
    return {
      form: {
        course_title: { required, minLength: minLength(3) },
        price: { required },
        teacher: { required },
        destination: { required },
        category: { required },
      },
    };
  },
  components: {
    // SelectTeacherDropdown,
    // SelectDestinationsDropdown,
    // SelectCategoryDropdown,
    GenericSelectDropdown,
    // SelectLevelDropdown,
  },
};
</script>

<style scoped>
.upload-block {
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A0A0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
