<template>
  <div></div>
</template>
<script>
import { useTeachersStore } from "@/store/teachers";

export default {
  setup() {
    const teacherStore = useTeachersStore();
    const { isEditTeacherFail } = useTeachersStore();
    const { isEditTeacherSuccess } = useTeachersStore();
    return {
      teacherStore,
      isEditTeacherFail,
      isEditTeacherSuccess,
    };
  },
  mounted() {
    if (this.teacherStore.isEditTeacherFail) {
      this.$swal({
        icon: "danger",
        title: "Error accured while edditing the teacher !",
        html: `<ul>
            ${this.teacherStore.errorMessages.map((error) => {
          return error.map((message) => {
            return `<li class="text-red-500">
                      ${message}
                    </li>`
          })
        })}
          </ul>`,
        willClose: () => {
          this.teacherStore.isEditTeacherFail = false;
          this.teacherStore.isEditTeacherSuccess = false;
          this.teacherStore.isShowEditor = false;
          this.teacherStore.errorMessages = []
        },
      });
    }
  },
};
</script>
