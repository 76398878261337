import { defineStore } from "pinia";
import axios from "@/axios/axios";

export const useSubjectsStore = defineStore("subjectsStore", {
  state: () => ({
    subjects: [],
    lastestSubject: [],
    subjectsCount: 0,
    itemsPerPage: 10,
    totalPages: 0,
    to: 0,
    from: 0,
    currentPage: 1,
    isSuccess: false,
    isFail: false,
    isLoadingModal: false,
    isFailModal: false,
    deleteError: false,
    addError: false,
    addSuccess: false,
    isUpdateError: false,
    isUpdateSuccess: false,
    isShowEditor: false,
    errorMessages: [],
    errorMessage: "",
    editedSubject: null,
    selectedSubject: null,
    showDeleteModal: false,
  }),
  actions: {
    async getSubjects() {
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;

        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}module/admin/get`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.subjects = data;
        this.isLoadingModal = false;
      } catch (err) {
        console.log(err);
        this.isLoadingModal = false;
      }
    },
  },
});
