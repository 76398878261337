<template>
  <div class="user-actions w-10 mx-auto">
    <a
      class="text-blueGray-500 block"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <div class="items-center flex justify-center">
        <img :src="actions" alt="actions" />
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-xl border border-[#e3e3e3] min-w-[140px]"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <a
        href="javascript:void(0);"
        @click="toggleDelete(cod)"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="deleteIcon" class="mr-2" alt="block" />

        Delete
      </a>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import actions from "@/assets/icons/cards/threeDots.svg";
import reviewsIcon from "@/assets/icons/cards/reviewsIcon.svg";
import deleteIcon from "@/assets/icons/cards/deleteIcon.svg";
import { useCodsStore } from "@/store/cods";
export default {
  props: ["cod"],
  data() {
    return {
      dropdownPopoverShow: false,
      showBlockModal: false,
      showUnBlockModal: false,
    };
  },
  setup() {
    const codsStore = useCodsStore();
    return {
      reviewsIcon,
      codsStore,
      actions,
      deleteIcon,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        const dropdowns = document.querySelectorAll(".user-actions");
        // Add click event listener to each dropdown component
        dropdowns.forEach(function (dropdown) {
          dropdowns.forEach(() => {
            dropdown.children[1].classList.add("hidden");
          });
        });
        this.$refs.popoverDropdownRef.classList.remove("hidden");

        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-end",
        });
      }
    },
    toggleDelete(cod) {
      this.$swal({
        title: "Do you want to Delete this code?",
        showCancelButton: true,
        confirmButtonColor: "#00DDAF",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm!",
        cancelButtonText: "Cancel!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.codsStore.deleteCod(cod.id).then(() => {
            console.log(this.codsStore.isFail);
            console.log(this.codsStore.isSuccess);
            if (this.codsStore.isFail) {
              this.$swal("something wrong happened try again !", "", "warning");
            } else if (this.codsStore.isSuccess) {
              this.$swal("Cod deleted successfully!", "", "success").then(
                () => {
                  // this.codsStore.getCods();
                }
              );
            }
          });
        }
      });
      const dropdowns = document.querySelectorAll(".news-action");
      // Add click event listener to each dropdown component
      dropdowns.forEach(function (dropdown) {
        dropdowns.forEach(() => {
          dropdown.children[1].classList.add("hidden");
        });
      });
    },
  },
};
</script>
