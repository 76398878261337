import { createRouter, createWebHistory } from "vue-router";
// layouts
import AdminLayout from "../layouts/AdminLayout.vue";
import LoginLayout from "../layouts/LoginLayout.vue";
// views

///1-login
import LoginView from "../views/login/LoginView.vue";

///2-dashboard
import CodsView from "@/views/dashboard/CodsView.vue";
import EditCourseView from "@/views/dashboard/EditCourseView.vue";
import EditGroupView from "@/views/dashboard/EditGroupView.vue";
import NewCodView from "@/views/dashboard/NewCodView.vue";
import TeacherBalanceView from "@/views/dashboard/TeacherBalanceView.vue";
import AffiliationSettingsView from "../views/dashboard/AffiliationSettingsView.vue";
import AffiliationView from "../views/dashboard/AffiliationView.vue";
import CategoriesView from "../views/dashboard/CategoriesView.vue";
import CoursesView from "../views/dashboard/CoursesView.vue";
import DashboardView from "../views/dashboard/DashboardView.vue";
import EditInfluencerView from "../views/dashboard/EditInfluencerView.vue";
import EditLiveView from "../views/dashboard/EditLiveView.vue";
import EditModeratorView from "../views/dashboard/EditModeratorView.vue";
import EditPromoCodeView from "../views/dashboard/EditPromoCodeView.vue";
import EditRoomView from "../views/dashboard/EditRoomView.vue";
import EditSubscriptionView from "../views/dashboard/EditSubscriptionView.vue";
import EditTeachersView from "../views/dashboard/EditTeachersView.vue";
import EditUserView from "../views/dashboard/EditUserView.vue";
import LandingPagePartnersView from "../views/dashboard/LandingPagePartnersView.vue";
import LandingPageReviewsView from "../views/dashboard/LandingPageReviewsView.vue";
import LivesView from "../views/dashboard/LivesView.vue";
import ManageAdsView from "../views/dashboard/ManageAdsView.vue";
import MessagesView from "../views/dashboard/MessagesView.vue";
import NewAdView from "../views/dashboard/NewAdView.vue";
import NewCategoryView from "../views/dashboard/NewCategoryView.vue";
import NewCourseView from "../views/dashboard/NewCourseView.vue";
import NewGroupView from "../views/dashboard/NewGroupView.vue";
import NewInfluencerView from "../views/dashboard/NewInfluencerView.vue";
import NewLandingPageReviewsView from "../views/dashboard/NewLandingPageReviewsView.vue";
import NewLiveView from "../views/dashboard/NewLiveView.vue";
import NewNotificationsView from "../views/dashboard/NewNotificationsView.vue";
import NewOfferView from "../views/dashboard/NewOfferView.vue";
import NewPromoCodeView from "../views/dashboard/NewPromoCodeView.vue";
import NewRoomView from "../views/dashboard/NewRoomView.vue";
import NewTeacherView from "../views/dashboard/NewTeacherView.vue";
import NewUserView from "../views/dashboard/NewUserView.vue";
import NotificationsView from "../views/dashboard/NotificationsView.vue";
import OffersView from "../views/dashboard/OffersView.vue";
import ProfileView from "../views/dashboard/ProfileView.vue";
import PromoCodesView from "../views/dashboard/PromoCodesView.vue";
import ReportsView from "../views/dashboard/ReportsView.vue";
import ReviewsView from "../views/dashboard/ReviewsView.vue";
import RoomsView from "../views/dashboard/RoomsView.vue";
import SettingsView from "../views/dashboard/SettingsView.vue";
import SingleCategoryView from "../views/dashboard/SingleCategoryView.vue";
import SingleCourseReviewsView from "../views/dashboard/SingleCourseReviewsView.vue";
import SingleMessageView from "../views/dashboard/SingleMessageView.vue";
import SingleRoomView from "../views/dashboard/SingleRoomView.vue";
import SingleTeacherCoursesView from "../views/dashboard/SingleTeacherCoursesView.vue";
import SingleTeacherReviews from "../views/dashboard/SingleTeacherReviews.vue";
import SubscribersView from "../views/dashboard/SubscribersView.vue";
import SubscriptionView from "../views/dashboard/SubscriptionView.vue";
import TeachersView from "../views/dashboard/TeachersView.vue";
import UsersView from "../views/dashboard/UsersView.vue";

import { useAuthStore } from "@/store/auth";
import GroupsView from "@/views/dashboard/GroupsView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/auth",
  },
  {
    path: "/auth",
    name: "auth",
    redirect: "/auth/login",
    component: LoginLayout,
    children: [
      {
        path: "/auth/login",
        name: "login",
        component: LoginView,
      },
    ],
  },
  {
    meta: { requiresAuth: true },
    path: "/admin",
    redirect: "/admin/dashboard",
    component: AdminLayout,
    children: [
      {
        meta: { requiresAuth: true },
        path: "/admin/dashboard",
        component: DashboardView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/teachers",
        component: TeachersView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/teachers/balance/:id",
        component: TeacherBalanceView,
      },

      {
        meta: { requiresAuth: true },
        path: "/admin/teachers/:id",
        component: EditTeachersView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/teachers/new",
        component: NewTeacherView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/teachers/:id/reviews",
        component: SingleTeacherReviews,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/teachers/:id/courses",
        component: SingleTeacherCoursesView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/users",
        component: UsersView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/users/:id",
        component: EditUserView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/users/new",
        component: NewUserView,
      },
      //groups
      {
        meta: { requiresAuth: true },
        path: "/admin/groups",
        component: GroupsView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/groups/new",
        component: NewGroupView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/groups/:id",
        component: EditGroupView,
      },
      //courses
      {
        meta: { requiresAuth: true },
        path: "/admin/courses",
        component: CoursesView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/courses/:id",
        component: EditCourseView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/courses/new",
        component: NewCourseView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/courses/:id/reviews",
        component: SingleCourseReviewsView,
      },
      //categories
      {
        meta: { requiresAuth: true },
        path: "/admin/courses/categories",
        component: CategoriesView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/courses/categories/:id",
        component: SingleCategoryView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/courses/categories/new",
        component: NewCategoryView,
      },

      {
        meta: { requiresAuth: true },
        path: "/admin/lives",
        component: LivesView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/lives/new",
        component: NewLiveView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/lives/:id",
        component: EditLiveView,
      },

      {
        meta: { requiresAuth: true },
        path: "/admin/rooms",
        component: RoomsView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/rooms/new",
        component: NewRoomView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/rooms/:id",
        component: EditRoomView,
      },

      {
        meta: { requiresAuth: true },
        path: "/admin/room/:id",
        component: SingleRoomView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/reports",
        component: ReportsView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/subscription",
        component: SubscriptionView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/subscription/:id",
        component: EditSubscriptionView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/subscribers",
        component: SubscribersView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/offers",
        component: OffersView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/cods",
        component: CodsView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/cods/new",
        component: NewCodView,
      },

      {
        meta: { requiresAuth: true },
        path: "/admin/offers/new",
        component: NewOfferView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/offers/promo",
        component: PromoCodesView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/offers/promo/new",
        component: NewPromoCodeView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/offers/promo/:id",
        component: EditPromoCodeView,
      },

      {
        meta: { requiresAuth: true },
        path: "/admin/affiliation",
        component: AffiliationView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/affiliation/settings",
        component: AffiliationSettingsView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/affiliation/:id",
        component: EditInfluencerView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/affiliation/newInfluencer",
        component: NewInfluencerView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/ads",
        component: ManageAdsView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/ads/new",
        component: NewAdView,
      },

      {
        meta: { requiresAuth: true },
        path: "/admin/reviewsAndComments",
        component: ReviewsView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/settings",
        component: SettingsView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/settings/moderators/:id",
        component: EditModeratorView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/profile",
        component: ProfileView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/notifications",
        component: NotificationsView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/notifications/new",
        component: NewNotificationsView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/messages",
        component: MessagesView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/messages/:id",
        component: SingleMessageView,
      },

      {
        meta: { requiresAuth: true },
        path: "/admin/landingPage/partners",
        component: LandingPagePartnersView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/landingPage/reviews",
        component: LandingPageReviewsView,
      },
      {
        meta: { requiresAuth: true },
        path: "/admin/landingPage/reviews/new",
        component: NewLandingPageReviewsView,
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
const checkPermissions = (permissions, route) => {
  if (
    route.startsWith("/auth/login") ||
    route.startsWith("/admin/profile") ||
    route.startsWith("/admin/notifications")
  ) {
    return true;
  } else if (route.startsWith("/admin/dashboard")) {
    return permissions.includes("manage_statistics");
    //////
  } else if (route.startsWith("/admin/settings")) {
    return permissions.includes("manage_employees");
    //////
  } else if (route.startsWith("/admin/users")) {
    return permissions.includes("manage_users");
    //////
  } else if (route.startsWith("/admin/teachers")) {
    return permissions.includes("manage_teachers");
    //////
  } else if (route.startsWith("/admin/teachers/balance")) {
    return permissions.includes("manage_payments");
    //////
  } else if (
    route.startsWith("/admin/reviewsAndComments") ||
    route.startsWith("/admin/courses")
  ) {
    return permissions.includes("manage_courses");
    //////
  } else if (route.startsWith("/admin/groups")) {
    return permissions.includes("manage_groups");
    ////
  } else if (
    route.startsWith("/admin/lives") ||
    route.startsWith("/admin/rooms")
  ) {
    return permissions.includes("manage_lives_rooms");
    //////
  } else if (route.startsWith("/admin/subscription")) {
    return permissions.includes("manage_subscriptions");
    //////
  } else if (route.startsWith("/admin/ads")) {
    return permissions.includes("manage_ads");
    //////
  } else if (route.startsWith("/admin/reports")) {
    return permissions.includes("manage_reports");
    //////
  } else if (route.startsWith("/admin/affiliation")) {
    return permissions.includes("manage_affiliation");
    //////
  } else if (route.startsWith("/admin/affiliation/newInfluencer")) {
    return permissions.includes("manage_influencers");
    //////
  } else if (route.startsWith("/admin/offers")) {
    return permissions.includes("manage_promotions");
    //////
  } else if (route.startsWith("/admin/messages")) {
    return permissions.includes("manage_tickets");
    //////
  } else if (route.startsWith("/admin/cods")) {
    return permissions.includes("manage_codes_of_discount");
    //////
  } else if (route.startsWith("/admin/subscribers")) {
    return permissions.includes("manage_payments");
    //////
  } else if (route.startsWith("/admin/landingPage")) {
    return permissions.includes("manage_landing_page");
  } else {
    return false;
  }
};
router.beforeEach((to, from, next) => {
  const rayhaneAdmin = localStorage.getItem("rayhane-admin");
  const authStore = useAuthStore();
  // clear search bar :
  authStore.search = "";
  const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);
  const isAuthenticated = authStore.user || rayhaneAdmin ? true : false;
  authStore.toggleCollapseShow("hidden");
  try {
    if (!isAuthenticated && to.path.includes("auth")) {
      return next();
    }
    if (to.path.includes("auth") && isAuthenticated) {
      return next({ path: "/admin/profile" });
    }
    if (requiresAuth && !isAuthenticated) {
      return next("/auth/login");
    } else {
      authStore.user = JSON.parse(rayhaneAdmin);
      const hasPermission = checkPermissions(
        JSON.parse(rayhaneAdmin).permissions,
        to.path
      );
      if (hasPermission) {
        return next();
      } else {
        return next({ path: "/admin/dashboard" });
      }
    }
  } catch (error) {
    console.log(error);
  }
});
export default router;
