<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <LoaderModal v-if="usersStore.isLoadingModal" />
    <EditUserSuccessModal v-if="usersStore.isEditUserSuccess" />
    <EditUserFailModal v-if="usersStore.isEditUserFail" />
    <div class="my-6 mt-24 w-full">
      <div class="w-full">
        <div class="my-2">
          <h1 class="text-3xl text-black font-semibold">Users</h1>
          <h1 class="text-md text-gray font-semibold mt-2">
            Manage your users
          </h1>
        </div>
        <div
          class="bg-purple text-white font-bold text-3xl p-5 rounded-t-xl border-[#c9c9c9] border-b-0 border"
        >
          New user
        </div>
        <form class="w-full border-[#c9c9c9] border border-t-0 p-4 rounded-xl">
          <div>
            <p class="text-purple mb-1 text-left">Picture</p>
          </div>
          <div class="my-2 mb-4 text-xl rounded-md">
            <label
              class="cursor-pointer w-fit flex items-center"
              for="inputTag"
            >
              <div
                v-if="!cover"
                class="flex flex-col upload-block justify-center items-center p-2 h-40 w-40 rounded-md border-dashed"
              >
                <p class="text-[#9c9c9c9c] mb-1 text-center text-base mt-2">
                  Click here to select file
                </p>

                <input
                  class="hidden"
                  hidden
                  id="inputTag"
                  @change="handleCoverChange"
                  type="file"
                  accept="image/*"
                />
              </div>

              <img v-show="cover" class="h-40 w-40" ref="output" />
              <img
                @click="removeImage"
                :src="trash"
                class="ml-2"
                v-if="cover"
                width="40"
              />
            </label>
            <span
              class="text-red-500"
              v-for="(error, index) in v$.cover.$errors"
              :key="index"
            >
              {{ error.$message }}
            </span>
          </div>
          <div class="md:flex">
            <div class="md:mr-6 my-3 md:w-6/12">
              <p class="text-purple mb-1 text-left">First name</p>
              <input
                v-model="first_name"
                :class="
                  v$.first_name.$error ? 'border-red-500 border-[3px]' : ''
                "
                type="text"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <span
                class="text-red-500"
                v-for="(error, index) in v$.first_name.$errors"
                :key="index"
              >
                {{ error.$message }}
              </span>
            </div>
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Last name</p>
              <input
                v-model="last_name"
                type="text"
                :class="
                  v$.last_name.$error ? 'border-red-500 border-[3px]' : ''
                "
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <span
                class="text-red-500"
                v-for="(error, index) in v$.last_name.$errors"
                :key="index"
              >
                {{ error.$message }}
              </span>
            </div>
          </div>
          <div class="md:flex">
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Phone number</p>
              <input
                v-model="phone_number"
                type="tel"
                maxlength="10"
                :class="
                  v$.phone_number.$error ? 'border-red-500 border-[3px]' : ''
                "
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <span
                class="text-red-500"
                v-for="(error, index) in v$.phone_number.$errors"
                :key="index"
              >
                {{ error.$message }}
              </span>
            </div>
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Add Solde</p>
              <input
                v-model="balance"
                type="number"
                :class="v$.balance.$error ? 'border-red-500 border-[3px]' : ''"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <span
                class="text-red-500"
                v-for="(error, index) in v$.balance.$errors"
                :key="index"
              >
                {{ error.$message }}
              </span>
            </div>
          </div>

          <div class="md:flex">
            <div class="md:w-full md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Study level</p>
              <SelectDestinationsDropdown />
            </div>
          </div>
          <div v-if="destinationsStore.selectedDestination === 'Takwin'">
            <div class="md:flex">
              <div class="md:mr-6 my-3 md:w-6/12">
                <p class="text-purple mb-1 text-left">Ad info first name</p>
                <input
                  v-model="ad_info_first_name"
                  :class="
                    v$.ad_info_first_name.$error
                      ? 'border-red-500 border-[3px]'
                      : ''
                  "
                  type="text"
                  class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
                />
                <span
                  class="text-red-500"
                  v-for="(error, index) in v$.ad_info_first_name.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </div>
              <div class="md:w-6/12 md:mr-6 my-3">
                <p class="text-purple mb-1 text-left">Ad info last name</p>
                <input
                  v-model="ad_info_last_name"
                  type="text"
                  :class="
                    v$.ad_info_last_name.$error
                      ? 'border-red-500 border-[3px]'
                      : ''
                  "
                  class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
                />
                <span
                  class="text-red-500"
                  v-for="(error, index) in v$.ad_info_last_name.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </div>
            </div>
            <div class="md:flex">
              <div class="md:mr-6 my-3 md:w-6/12">
                <p class="text-purple mb-1 text-left">Ad info Birth date</p>
                <input
                  v-model="ad_info_birth_date"
                  :class="
                    v$.ad_info_birth_date.$error
                      ? 'border-red-500 border-[3px]'
                      : ''
                  "
                  type="date"
                  class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
                />
                <span
                  class="text-red-500"
                  v-for="(error, index) in v$.ad_info_birth_date.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </div>
              <div class="md:w-6/12 md:mr-6 my-3">
                <p class="text-purple mb-1 text-left">Ad info birth place</p>
                <input
                  v-model="ad_info_birth_place"
                  type="text"
                  :class="
                    v$.ad_info_birth_place.$error
                      ? 'border-red-500 border-[3px]'
                      : ''
                  "
                  class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
                />
                <span
                  class="text-red-500"
                  v-for="(error, index) in v$.ad_info_birth_place.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </div>
            </div>
            <div class="md:flex">
              <div class="md:mr-6 my-3 md:w-6/12">
                <p class="text-purple mb-1 text-left">Ad info wilaya</p>
                <input
                  v-model="ad_info_wilaya"
                  :class="
                    v$.ad_info_wilaya.$error
                      ? 'border-red-500 border-[3px]'
                      : ''
                  "
                  type="text"
                  class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
                />
                <span
                  class="text-red-500"
                  v-for="(error, index) in v$.ad_info_wilaya.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </div>
              <div class="md:w-6/12 md:mr-6 my-3">
                <p class="text-purple mb-1 text-left">Ad info commune</p>
                <input
                  v-model="ad_info_commune"
                  type="text"
                  :class="
                    v$.ad_info_commune.$error
                      ? 'border-red-500 border-[3px]'
                      : ''
                  "
                  class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
                />
                <span
                  class="text-red-500"
                  v-for="(error, index) in v$.ad_info_commune.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </div>
            </div>
            <div class="md:flex">
              <div class="md:mr-6 my-3 md:w-6/12">
                <p class="text-purple mb-1 text-left">Ad info address</p>
                <input
                  v-model="ad_info_address"
                  :class="
                    v$.ad_info_address.$error
                      ? 'border-red-500 border-[3px]'
                      : ''
                  "
                  type="text"
                  class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
                />
                <span
                  class="text-red-500"
                  v-for="(error, index) in v$.ad_info_address.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </div>
              <div class="md:w-6/12 md:mr-6 my-3">
                <p class="text-purple mb-1 text-left">Ad info study level</p>
                <input
                  v-model="ad_info_study_level"
                  type="text"
                  :class="
                    v$.ad_info_study_level.$error
                      ? 'border-red-500 border-[3px]'
                      : ''
                  "
                  class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
                />
                <span
                  class="text-red-500"
                  v-for="(error, index) in v$.ad_info_study_level.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </div>
            </div>
          </div>
        </form>
        <div class="flex justify-start my-4">
          <button class="btn btn bg-cyan font-bold" @click="addUser">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  minLength,
  helpers,
  integer,
  requiredIf,
} from "@vuelidate/validators";
import SelectDestinationsDropdown from "@/components/cards/partials/SelectDestinationsDropdown.vue";
import { useDestinationsStore } from "@/store/destinations";
import { useUsersStore } from "@/store/users";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import trash from "@/assets/icons/cards/trash.svg";
import EditUserSuccessModal from "@/components/modals/users/EditUserSuccessModal.vue";
import EditUserFailModal from "@/components/modals/users/EditUserFailModal.vue";

export default {
  data() {
    return {
      first_name: "",
      last_name: "",
      phone_number: null,
      email: "",
      balance: 0,
      showPassword: false,
      isPasswordFocus: false,
      cover: null,
      //For takwin destination
      ad_info_first_name: "",
      ad_info_last_name: "",
      ad_info_birth_date: "",
      ad_info_birth_place: "",
      ad_info_wilaya: "",
      ad_info_commune: "",
      ad_info_address: "",
      ad_info_study_level: "",
    };
  },

  async mounted() {
    await this.usersStore.getOneUser(this.$route.params.id);
    this.first_name = this.usersStore.singleUser.user.first_name;
    this.last_name = this.usersStore.singleUser.user.last_name;
    this.phone_number = this.usersStore.singleUser.user.phone;
    this.email = this.usersStore.singleUser.user.email;
    this.balance = this.usersStore.singleUser.balance;
    this.cover = this.usersStore.singleUser.user.image
      ? this.usersStore.singleUser.user.image.url
      : null;
    let outputPic = this.$refs.output;
    outputPic.src = this.cover;
    outputPic.width = 200;
    if (this.usersStore.singleUser.additional_information) {
      this.ad_info_first_name =
        this.usersStore.singleUser.additional_information.first_name;
      this.ad_info_last_name =
        this.usersStore.singleUser.additional_information.last_name;
      this.ad_info_birth_date =
        this.usersStore.singleUser.additional_information.birth_date;
      this.ad_info_birth_place =
        this.usersStore.singleUser.additional_information.birth_place;
      this.ad_info_wilaya =
        this.usersStore.singleUser.additional_information.wilaya;
      this.ad_info_commune =
        this.usersStore.singleUser.additional_information.commune;
      this.ad_info_address =
        this.usersStore.singleUser.additional_information.address;
      this.ad_info_study_level =
        this.usersStore.singleUser.additional_information.study_level;
    }
  },

  setup() {
    const usersStore = useUsersStore();
    const destinationsStore = useDestinationsStore();
    return { v$: useVuelidate(), usersStore, destinationsStore, trash };
  },

  methods: {
    handleCoverChange(event) {
      this.cover = event.target.files[0];
      let outputPic = this.$refs.output;
      // this.categoriesStore.editedCategory.image = this.cover;
      outputPic.src = URL.createObjectURL(this.cover);
      outputPic.width = 200;
    },

    removeImage() {
      this.$refs.output.src = null;
      this.cover = null;
      // this.categoriesStore.editedCategory.image = null;
    },

    async addUser() {
      const result = await this.v$.$validate();

      if (!result) {
        return;
      } else {
        const formData = new FormData();
        formData.append("first_name", this.first_name);
        formData.append("last_name", this.last_name);
        formData.append("balance", this.balance);
        formData.append("phone", this.phone_number);
        if (this.cover instanceof File) formData.append("image", this.cover);
        formData.append(
          "study_year",
          this.destinationsStore.selectedDestination
        );
        if (this.destinationsStore.selectedDestination == "Takwin") {
          formData.append("ad_info_first_name", this.ad_info_first_name);
          formData.append("ad_info_last_name", this.ad_info_last_name);
          formData.append("ad_info_birth_date", this.ad_info_birth_date);
          formData.append("ad_info_birth_place", this.ad_info_birth_place);
          formData.append("ad_info_wilaya", this.ad_info_wilaya);
          formData.append("ad_info_commune", this.ad_info_commune);
          formData.append("ad_info_address", this.ad_info_address);
          formData.append("ad_info_study_level", this.ad_info_study_level);
        }
        this.usersStore.updateUser(formData, this.$route.params.id);
      }
    },
  },

  validations() {
    return {
      first_name: {
        required: helpers.withMessage("First name is required", required),
        minLength: helpers.withMessage(
          "First name must must longer than 3 characters",
          minLength(3)
        ),
      },
      last_name: {
        required: helpers.withMessage("Last name is required", required),
        minLength: helpers.withMessage(
          "Last name must must longer than 3 characters",
          minLength(3)
        ),
      },
      balance: {
        required: helpers.withMessage("Balance is required", required),
        integer: helpers.withMessage("Balance must be an integer", integer),
      },
      phone_number: {
        required: helpers.withMessage("Phone number is required", required),
        regex: helpers.withMessage(
          "Invalid phone number",
          helpers.regex(/^(05|06|07)[0-9]{8}$/)
        ),
      },

      ad_info_first_name: {
        required: requiredIf(() => {
          return this.destinationsStore.selectedDestination == "Takwin";
        }),
      },
      ad_info_last_name: {
        required: requiredIf(() => {
          return this.destinationsStore.selectedDestination == "Takwin";
        }),
      },
      ad_info_birth_date: {
        required: requiredIf(() => {
          return this.destinationsStore.selectedDestination == "Takwin";
        }),
      },
      ad_info_birth_place: {
        required: requiredIf(() => {
          return this.destinationsStore.selectedDestination == "Takwin";
        }),
      },
      ad_info_wilaya: {
        required: requiredIf(() => {
          return this.destinationsStore.selectedDestination == "Takwin";
        }),
      },
      ad_info_commune: {
        required: requiredIf(() => {
          return this.destinationsStore.selectedDestination == "Takwin";
        }),
      },
      ad_info_address: {
        required: requiredIf(() => {
          return this.destinationsStore.selectedDestination == "Takwin";
        }),
      },
      ad_info_study_level: {
        required: requiredIf(() => {
          return this.destinationsStore.selectedDestination == "Takwin";
        }),
      },
      cover: {},
    };
  },

  components: {
    SelectDestinationsDropdown,
    LoaderModal,
    EditUserSuccessModal,
    EditUserFailModal,
  },
};
</script>

<style scoped>
.upload-block {
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A0A0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
