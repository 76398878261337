import { defineStore } from "pinia";
import axios from "@/axios/axios";
import { useAuthStore } from "./auth";

export const useOffersStore = defineStore("offersStore", {
  state: () => ({
    offers: [],
    promoCodes: [],
    landingPagesCount: 0,
    itemsPerPage: 10,
    errorsMessages: "",
    currentPage: 1,
    to: 0,
    from: 0,
    totalPages: 0,
    totalRecords: 0,
    isFail: false,
    isSuccess: false,
    isLoadingModal: false,
    editedOffer: null,
    showDeleteModal: false,
    deletedId: null,
    singleOffer: null,
    newDestination: null,
    // for creating a new offer :
    coursesOffers: [],
    errorMessages: [],
  }),
  actions: {
    async getPromoCodes(query) {
      const authStore = useAuthStore();
      this.errorMessage = null;
      try {
        if (!query) {
          this.isLoadingModal = true;
        } else {
          authStore.isLoading = true;
        }
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${
            process.env.VUE_APP_API_BASE
          }promotion/admin/get?paginate=true&page=${this.currentPage}${
            query ? `&q=${encodeURIComponent(query)}` : ""
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.promoCodes = data.data.data;
        this.totalPages = data.data.last_page;
        this.currentPage = data.data.current_page;
        this.totalRecords = data.data.total;
        this.from = data.data.from;
        this.to = data.data.to;
        this.isLoadingModal = false;
        authStore.isLoading = false;
      } catch (err) {
        this.isLoadingModal = false;
        authStore.isLoading = false;
        this.errorMessage = err.response.data.errors;
        console.log(err);
      }
    },

    async getOffers(query) {
      const authStore = useAuthStore();
      this.errorMessage = null;
      try {
        if (!query) {
          this.isLoadingModal = true;
        } else {
          authStore.isLoading = true;
        }
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}offer/admin/get?paginate=true&page=${
            this.currentPage
          }&q=${query ? `&q=${encodeURIComponent(query)}` : ""}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.offers = data.data.data;
        this.totalPages = data.data.last_page;
        this.currentPage = data.data.current_page;
        this.totalRecords = data.data.total;
        this.from = data.data.from;
        this.to = data.data.to;
        this.isLoadingModal = false;
        authStore.isLoading = false;
      } catch (err) {
        this.isLoadingModal = false;
        authStore.isLoading = false;
        this.errorMessage = err.response.data.errors;
        console.log(err);
      }
    },

    async getSingleOffer(offerId) {
      this.errorMessage = null;
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}promotion/admin/get/${offerId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.singleOffer = data.data;
        this.isLoadingModal = false;
      } catch (err) {
        this.isLoadingModal = false;
        console.log(err);
      }
    },

    async deletePromoCode(offerId) {
      console.log(offerId);
      this.errorMessage = null;
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}promotion/admin/delete/${offerId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
      } catch (err) {
        this.isLoadingModal = false;
        console.log(err);
      }
    },

    async deleteOffer(offerId) {
      this.errorMessage = null;
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}offer/admin/delete/${offerId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.getOffers();
      } catch (err) {
        this.isLoadingModal = false;
        console.log(err);
      }
    },

    async addPromoCode(promoCode) {
      this.errorMessage = null;
      this.errorMessages = [];
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}promotion/admin/store`,
          promoCode,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
      } catch (err) {
        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
        this.isLoadingModal = false;
        this.isFail = true;
        console.log(err);
      }
    },

    async editPromoCode(promoCode, promoCodeId) {
      this.errorMessages = [];
      this.errorMessage = null;
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}promotion/admin/update/${promoCodeId}`,
          promoCode,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
      } catch (err) {
        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
        this.isLoadingModal = false;
        this.isFail = true;
        console.log(err);
      }
    },
    async newOffer(offer) {
      this.errorMessages = [];
      this.errorMessage = null;
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}offer/admin/store`,
          offer,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
      } catch (err) {
        this.isLoadingModal = false;
        this.errorMessage = err.response.data.errors;
        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
        this.isFail = true;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getPromoCodes();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getPromoCodes();
      }
    },
    nextPageOffers() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getOffers();
      }
    },
    previousPageOffers() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getOffers();
      }
    },
  },
});
