<template>
  <!-- <div v-if="options.length > 0"> -->
  <div
    class="py-1 px-[1px] relative w-full cursor-default rounded-md bg-white text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
  >
    <Multiselect
      class="focus:outline-none border-none"
      v-model="value"
      mode="tags"
      :close-on-select="false"
      :searchable="true"
      :create-option="true"
      :options="options"
      spinner
    />
  </div>
  <!-- </div>
  <div class="text-red-500" v-else>No courses in the selected destination</div> -->
</template>

<script>
import { useOptionsStore } from "@/store/options";
import Multiselect from "@vueform/multiselect";
export default {
  components: {
    Multiselect,
  },
  setup() {
    const optionsStore = useOptionsStore();
    return { optionsStore };
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    formValue: {
      type: Array,
      Required: true,
    },
  },
  data() {
    return {
      value: [],
    };
  },

  watch: {
    formValue: {
      handler(newVal) {
        this.value = newVal;
      },
    },
    value: {
      handler(opt) {
        this.$emit("getOptions", opt);
      },
    },
  },

  async mounted() {
    console.log(this.formValue);
  },
};
</script>

<style>
/* For the active state */
.multiselect.is-active {
  box-shadow: none !important;
  border: none !important;
}
.multiselect {
  box-shadow: none !important;
  border: none !important;
  font-size: 0.875rem !important;
}
.multiselect-option {
  font-size: 0.875rem !important;
}

.multiselect-tag {
  font-weight: 500 !important;
}
</style>
