import { defineStore } from "pinia";
import axios from "@/axios/axios";
import { useAuthStore } from "./auth";
export const useModeratorsStore = defineStore("moderatorsStore", {
  state: () => ({
    appSettings: "",
    moderators: [],
    errorsMessages: [],
    moderatorsCount: 0,
    itemsPerPage: 10,
    totalPages: 0,
    to: 0,
    from: 0,
    currentPage: 1,
    deletedModeratorId: null,
    showAddModal: false,
    showDeleteModal: false,
    showEditModal: false,
    isLoadingModal: false,
    isFailModal: false,
    isShowEditor: false,
    addError: false,
    addSuccess: false,
    editedModerator: null,
    isSuccess: false,
    isFail: false,
    newModerator: {
      first_name: "",
      last_name: "",
      email_moderator: "",
      password: "",
      phone: "",
      permissions: "",
      profile_pic: null,
    },
    //testing
    newPermissions: [],
  }),

  actions: {
    async getModerators(query) {
      const authStore = useAuthStore();
      try {
        if (!query) {
          this.isLoadingModal = true;
        } else {
          authStore.isLoading = true;
        }
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${
            process.env.VUE_APP_API_BASE
          }employee/admin/get?paginate=true&page=${this.currentPage}${
            query ? `&q=${encodeURIComponent(query)}` : ""
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.moderators = data.data.data;
        this.totalPages = data.data.last_page;
        this.from = data.data.from;
        this.to = data.data.to;
        this.moderators = this.moderators.filter((mod) => {
          const modEmail = mod.user.email.trim();
          const adminEmail = JSON.parse(admin).user.email.trim();
          return modEmail !== adminEmail;
        });
        authStore.isLoading = false;
        this.isLoadingModal = false;
      } catch (err) {
        this.isLoadingModal = false;
        authStore.isLoading = false;
      }
    },

    async getModerator(moderatorId) {
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}employee/admin/get/${moderatorId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.editedModerator = data.data;
        this.isLoadingModal = false;
        return data.data;
      } catch (err) {
        this.isLoadingModal = false;
      }
    },

    async updateModerator(moderator, moderatorId) {
      this.isFail = false;
      this.isLoadingModal = true;

      this.newPermissions.forEach((item) => {
        console.log(item);
        moderator.append("permissions[]", item);
      });

      for (var pair of moderator.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const newMod = await axios.post(
          `${process.env.VUE_APP_API_BASE}employee/admin/update/${moderatorId}`,
          moderator,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        this.editedModerator = null;
        this.showEditModal = false;

        console.log(newMod);
      } catch (err) {
        this.isFail = true;
        this.isLoadingModal = false;
      }
    },

    async addModerator(moderator) {
      this.isFail = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}employee/admin/store`,
          moderator,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        this.showAddModal = false;
      } catch (err) {
        if (err.response.status == 422) {
          Object.values(err.response.data.errors).forEach(
            (val) => (this.errorsMessages = this.errorsMessages + "<br>" + val)
          );
        } else {
          this.errorsMessages.push("something went wrong");
        }
        this.isFail = true;
        this.isLoadingModal = false;
      }
    },
    async deleteModerator() {
      this.isFail = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}employee/admin/delete/${this.deletedModeratorId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        this.showDeleteModal = false;
        this.deletedModeratorId = null;
        await this.getModerators();
      } catch (err) {
        this.isFail = true;
        this.showDeleteModal = false;
        this.isLoadingModal = false;
        this.deletedModeratorId = null;
      }
    },
    async getAppSettings() {
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}setting/admin/get/app-setting`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.appSettings = data.data.ACTIVE_SIDE;
      } catch (err) {
        this.isLoadingModal = false;
      }
    },
    async setAppSettings(newSettings) {
      this.isLoadingModal = true;
      if (newSettings == this.appSettings) {
        this.isLoadingModal = false;

        return;
      }
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}setting/admin/set/app-setting`,
          {
            active_side: newSettings,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.appSettings = newSettings;
      } catch (err) {
        this.isLoadingModal = false;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getModerators()
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getModerators()
      }
    },
  },
});
