<template>
  <div>
    <metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | RAYHANE` : `SITE_NAME`
      }}</template>
    </metainfo>
    <div class="flex flex-wrap mt-4">
      <div class="my-6 mt-24 w-full">
        <div class="flex justify-between items-center">
          <div>
            <h1 class="text-3xl text-black font-semibold">Courses</h1>
            <h1 class="text-xl text-gray font-semibold">Manage your Courses</h1>
          </div>
          <div class="flex items-center">
            <router-link to="/admin/courses/categories">
              <button class="btn bg-cyan font-bold mr-4">Categories</button>
            </router-link>
            <router-link to="/admin/courses/new">
              <button class="btn bg-cyan font-bold">
                <span class="">+ </span> New Course
              </button></router-link
            >
          </div>
        </div>
        <loader-modal v-if="coursesStore.isLoadingModal" />
        <div class="w-full">
          <div class="flex flex-row-reverse mt-4"></div>
          <courses-table />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CoursesTable from "@/components/tables/CoursesTable.vue";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import { useCoursesStore } from "@/store/courses";
import { useMeta } from "vue-meta";

export default {
  name: "dashboard-page",
  components: {
    CoursesTable,
    LoaderModal,
  },
  setup() {
    useMeta({
      title: "Courses",
      htmlAttrs: { lang: "en", amp: true },
    });
    const coursesStore = useCoursesStore();
    return {
      coursesStore,
    };
  },
  data() {
    return {};
  },
};
</script>
