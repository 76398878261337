import { defineStore } from "pinia";
import axios from "@/axios/axios";

export const useLevelsStore = defineStore("levelsStore", {
  state: () => ({
    levels: ["Beginner", "Intermediate", "Advanced"],
    selectedLevel: "Beginner",
    levelsCount: 0,
  }),
  actions: {
    async setLevel(levels) {
      this.addError = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}admin/levels/`,
          levels,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this.isLoadingModal = false;
        this.addSuccess = true;
      } catch (err) {
        this.addError = true;
        this.isLoadingModal = false;
        console.log(err);
      }
    },
  },
});
