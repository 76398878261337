<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | RAYHANE` : `SITE_NAME`
      }}</template>
    </metainfo>

    <div class="my-6 mt-24 w-full">
      <TeachersTable />
    </div>
  </div>
</template>
<script>
import TeachersTable from "@/components/tables/TeachersTable.vue";
import { useMeta } from "vue-meta";

export default {
  components: { TeachersTable },
  setup() {
    useMeta({
      title: "Teachers",
      htmlAttrs: { lang: "en", amp: true },
    });
  },
};
</script>
<style></style>
