<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <div class="my-6 mt-24 w-full">
      <div class="flex justify-between items-center mb-4">
        <div>
          <h1 class="text-3xl text-black font-semibold">Manage rooms</h1>
          <h1 class="text-xl text-gray font-semibold mt-3">
            Manage your rooms
          </h1>
        </div>
        <div>
          <router-link to="/admin/rooms/new">
            <button class="btn bg-cyan font-bold">
              <span class="text-xl">+ </span> New room
            </button></router-link
          >
        </div>
      </div>

      <RoomsTable />
    </div>
  </div>
</template>
<script>
import RoomsTable from "@/components/tables/RoomsTable.vue";
import { useRoomsStore } from "@/store/rooms";
export default {
  setup() {
    const roomsStore = useRoomsStore();
    return {
      roomsStore,
    };
  },
  components: { RoomsTable },
};
</script>
<style></style>
