<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | RAYHANE` : `SITE_NAME`
    }}</template>
  </metainfo>
  <LoaderModal v-if="paymentsStore.isLoadingModal" />
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <div class="my-6 mt-24 w-full">
      <div class="w-full">
        <div class="my-2">
          <h1 class="text-3xl text-black font-semibold">Teachers</h1>
          <h1 class="text-md text-gray font-semibold">Teacher payment</h1>
        </div>
        <div
          class="bg-purple text-white font-bold text-3xl p-5 rounded-t-xl border-[#c9c9c9] border-b-0 border"
        >
          {{
            this.teachersStore.singleTeacher?.user?.first_name +
            " " +
            this.teachersStore.singleTeacher?.user?.last_name
          }}
        </div>
        <form class="w-full border-[#c9c9c9] border border-t-0 p-4">
          <div class="">
            <div class="md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Actual solde</p>
              <input
                v-model="sold"
                readonly
                type="number"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p class="text-red-500" v-if="v$.sold.$error">sold is required</p>
            </div>
            <div class="md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Solde to take of</p>
              <input
                v-model="take_of_sold"
                type="number"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p class="text-red-500" v-if="v$.take_of_sold.$error">
                You exceed the current balance
              </p>
            </div>
          </div>
        </form>
        <div class="flex justify-start my-4">
          <button class="btn btn bg-cyan font-bold" @click="subtractBalance">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { required, maxValue } from "@vuelidate/validators";
import { useTeachersStore } from "@/store/teachers";
import { useMeta } from "vue-meta";
import router from "@/router";
import { usePaymentsStore } from "@/store/payments";
import LoaderModal from "@/components/modals/LoaderModal.vue";
export default {
  data() {
    return {
      sold: 0,
      take_of_sold: 0,
    };
  },
  setup() {
    const teachersStore = useTeachersStore();
    const paymentsStore = usePaymentsStore();
    useMeta({
      title: "Teachers",
      htmlAttrs: { lang: "en", amp: true },
    });
    return { v$: useVuelidate(), teachersStore, paymentsStore };
  },
  async mounted() {
    await this.teachersStore.getSingleTeacher(this.$route.params.id, false);
    if (!this.teachersStore.singleTeacher) {
      router.push("/admin/teachers");
    }
    this.sold = this.teachersStore.singleTeacher.balance;
  },
  methods: {
    async subtractBalance() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      await this.paymentsStore.substractBalance(
        "teacher",
        this.$route.params.id,
        this.take_of_sold
      );
      if (this.paymentsStore.isFail) {
        this.$swal({
          icon: "error",
          title: "something went wrong",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        this.$swal({
          icon: "success",
          title: `Balance edited successfully`,
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          router.push("/admin/teachers");
        });
      }
    },
  },
  validations() {
    return {
      sold: { required },
      take_of_sold: { maxValue: maxValue(this.sold), required },
    };
  },
  components: { LoaderModal },
};
</script>

<style scoped>
.upload-block {
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A0A0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
