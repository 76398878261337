<template>
  <div>
    <LoaderModal v-if="moderatorsStore.isLoadingModal" />
    <DeleteModeratorModal v-if="moderatorsStore.showDeleteModal" />
    <metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | RAYHANE` : `SITE_NAME`
      }}</template>
    </metainfo>
    <div v-if="!isModeratorEditor" class="flex flex-wrap mt-4">
      <div class="w-full px-4">
        <div class="my-6 mt-24">
          <div class="flex justify-between items-center">
            <div>
              <h1 class="text-3xl text-black font-semibold">Moderators</h1>
              <h1 class="text-xl text-gray font-semibold">
                Manage your moderators
              </h1>
            </div>
            <div>
              <button
                @click="openModeratorEditor"
                class="btn bg-cyan font-bold"
              >
                <span class="text-xl">+ </span> New Moderator
              </button>
            </div>
          </div>

          <div
            class="grid xl:grid-cols-3 2xl:grid-cols-4 gap-10 gap-x-0 my-8"
            v-if="moderatorsStore.moderators.length > 0"
          >
            <ModeratorCard
              v-for="(moderator, index) in moderatorsStore.moderators"
              :key="index"
              :first_name="moderator.user.first_name"
              :last_name="moderator.user.last_name"
              :title="moderator.user.title"
              :phone="moderator.user.phone"
              :email="moderator.user.email"
              :moderator="moderator"
              :profilePic="moderator.user.image.url"
            />
          </div>
          <div v-else class="flex flex-col justify-center items-center py-10">
            <img :src="noRecords" alt="noRecords" class="w-2/6" />
            <div class="text-xl text-[#747373]">No records at the moment !</div>
          </div>
        </div>
        <div class="flex justify-between items-center">
          <div>
            Showing {{ moderatorsStore.from }}-{{ moderatorsStore.to }} from
            {{ moderatorsStore.moderators.length }} data
          </div>
          <div class="flex items-center">
            <div v-if="moderatorsStore.currentPage != 1">
              <img
                @click="moderatorsStore.previousPage()"
                :src="forward"
                class="cursor-pointer"
                alt="forward"
              />
            </div>

            <div
              class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12"
              :class="
                true
                  ? 'bg-cyan text-white border-cyan'
                  : ' text-gray border border-gray'
              "
            >
              {{ moderatorsStore.currentPage }}
            </div>

            <div
              v-if="
                moderatorsStore.currentPage + 1 <= moderatorsStore.totalPages
              "
            >
              <img
                class="cursor-pointer"
                @click="moderatorsStore.nextPage()"
                :src="back"
                alt="back"
              />
            </div>
          </div>
        </div>

        <div
          class="xl:w-10/12 my-4"
          v-if="authStore.user?.permissions.includes('manage_employees')"
        >
          <h1 class="text-3xl text-black font-semibold">App settings</h1>
          <div class="md:flex my-4">
            <div
              @click="moderatorsStore.setAppSettings('Takwin')"
              class="border cursor-pointer border-cyan border-opacity-[0.4] rounded-xl p-4 py-6 w-full mx-3 text-center flex flex-col justify-center items-center my-10 md:my-0"
              :class="
                moderatorsStore.appSettings == 'Takwin'
                  ? 'border-opacity-80 shadow-cyan shadow-2xl'
                  : ''
              "
            >
              <img :src="settings3" alt="icon" />
              <div class="text-2xl font-semibold mt-2">Takwin only</div>
            </div>
            <div
              @click="moderatorsStore.setAppSettings('course')"
              class="border border-cyan cursor-pointer border-opacity-[0.4] rounded-xl p-4 py-6 w-full mx-3 text-center flex flex-col justify-center items-center my-10 md:my-0"
              :class="
                moderatorsStore.appSettings == 'course'
                  ? 'border-opacity-80 shadow-cyan shadow-xl'
                  : ''
              "
            >
              <img :src="settings2" alt="icon" />
              <div class="text-2xl font-semibold mt-2">Courses only</div>
            </div>
            <div
              @click="moderatorsStore.setAppSettings('all')"
              class="border border-cyan cursor-pointer border-opacity-[0.4] rounded-xl p-4 py-6 w-full mx-3 text-center flex flex-col justify-center items-center my-10 md:my-0"
              :class="
                moderatorsStore.appSettings == 'all'
                  ? 'border-opacity-80 shadow-cyan shadow-xl'
                  : ''
              "
            >
              <img :src="settings1" alt="icon" />
              <div class="text-2xl font-semibold mt-2">Takwin & Courses</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex flex-wrap mt-4 self-stretch items-stretch"
      v-if="isModeratorEditor"
    >
      <div class="my-6 mt-24 w-full">
        <AddModeratorCard />
      </div>
    </div>
  </div>
</template>
<script>
import ModeratorCard from "@/components/cards/partials/ModeratorCard.vue";
import settings1 from "@/assets/icons/cards/settings1.svg";
import settings2 from "@/assets/icons/cards/settings2.svg";
import settings3 from "@/assets/icons/cards/settings3.svg";
import { useModeratorsStore } from "@/store/moderators";
import AddModeratorCard from "@/components/cards/AddModeratorCard.vue";
import DeleteModeratorModal from "@/components/modals/settings/DeleteModeratorModal.vue";
import back from "@/assets/icons/cards/back.svg";
import forward from "@/assets/icons/cards/forward.svg";
import noRecords from "@/assets/images/noRecords.svg";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import { useAuthStore } from "@/store/auth";
import { useMeta } from "vue-meta";

export default {
  name: "moderators-page",
  setup() {
    const moderatorsStore = useModeratorsStore();
    const authStore = useAuthStore();
    useMeta({
      title: "Settings",
      htmlAttrs: { lang: "en", amp: true },
    });
    return {
      moderatorsStore,
      settings1,
      settings2,
      settings3,
      back,
      forward,
      noRecords,
      authStore,
    };
  },
  data() {
    return { isModeratorEditor: false };
  },
  async mounted() {
    await this.moderatorsStore.getModerators();
    await this.moderatorsStore.getAppSettings();
  },
  methods: {
    openModeratorEditor() {
      this.isModeratorEditor = true;
    },
  },
  components: {
    ModeratorCard,
    AddModeratorCard,
    DeleteModeratorModal,
    LoaderModal,
  },
};
</script>
