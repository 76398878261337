<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <LoaderModal v-if="offersStore.isLoadingModal || coursesStore.isLoadingModal" />
    <div class="my-6 mt-24 w-full">
      <div class="w-full">
        <div class="my-2">
          <h1 class="text-3xl text-black font-semibold">
            Offers & Promo codes
          </h1>
          <h1 class="text-md text-gray font-semibold mt-2">
            Manage your offers
          </h1>
        </div>
        <div
          class="bg-purple text-white font-bold text-3xl p-5 rounded-t-xl border-[#c9c9c9] border-b-0 border"
        >
          New offer
        </div>
        <form class="w-full border-[#c9c9c9] border border-t-0 p-4 rounded-xl">
          <div>
            <p class="text-purple mb-1 text-left">Picture</p>
          </div>
          <div class="my-2 mb-4 text-xl rounded-md">
            <label
              class="cursor-pointer w-fit flex items-center"
              for="inputTag"
            >
              <div
                v-if="!this.cover"
                class="flex flex-col upload-block justify-center items-center p-2 h-40 w-40 rounded-md border-dashed"
              >
                <p class="text-[#8a8a8ac9] mb-1 text-center text-base mt-2">
                  Click here to select a file
                </p>

                <input
                  class="hidden"
                  hidden
                  id="inputTag"
                  @change="handleCoverChange"
                  type="file"
                  accept="image/*"
                />
              </div>

              <img v-show="this.cover" class="h-40 w-40" ref="output" />
              <img
                @click="removeImage"
                alt="bbb"
                :src="trash"
                class="ml-2"
                v-if="this.cover"
                width="40"
              />
            </label>
            <p class="text-red-500 text-[1rem]" v-if="v$.cover.$error">
              <span v-for="(error, index) in v$.cover.$errors" :key="index">
                {{ error.$message }}
              </span>
            </p>
          </div>
          <div class="md:flex">
            <div class="md:mr-6 my-3 md:w-6/12">
              <p class="text-purple mb-1 text-left">Offer title</p>
              <input
                v-model="title"
                :class="v$.title.$error ? 'border-red-500 border-[3px]' : ''"
                type="text"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p class="text-red-500 text-[1rem]" v-if="v$.title.$error">
                <span v-for="(error, index) in v$.title.$errors" :key="index">
                  {{ error.$message }}
                </span>
              </p>
            </div>

            <!-- // until destination is back -->
            <div class="md:w-6/12 md:mr-6 my-3" v-if="false">
              <p class="text-purple mb-1 text-left">Destination</p>
              <SelectDestinationsDropdown class="w-[100%]" />

              <p
                class="text-red-500"
                v-if="!destinationsStore.selectedDestination"
              >
                You should select a destination
              </p>
            </div>
          </div>
          <div>
            <div class="md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Expiration date</p>
              <VueDatePicker
                v-model="date"
                :min-date="getTomorrow()"
                :format="'yyyy-MM-dd'"
                :class="v$.date.$error ? 'border-red-500 border-[3px]' : ''"
                class='rounded-md'
                :enable-time-picker="false"
              ></VueDatePicker>
              <p class="text-red-500 text-[1rem]" v-if="v$.date.$error">
                <span v-for="(error, index) in v$.date.$errors" :key="index">
                  {{ error.$message }}
                </span>
              </p>
            </div>
          </div>
          <div>
            <div class="md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Courses included</p>
              <SelectMultipleCoursesDropdown class="w-[100%]" />

              <!-- // until destination is back -->
              <!-- <p class="text-red-500" v-if="checkSelectedCourses">
                You should add at least one course
              </p> -->
            </div>
          </div>

          <div
            class="md:flex"
            v-for="(course, index) in offersStore.coursesOffers"
            :key="index"
          >
            <div class="md:mr-6 my-3 md:w-4/12">
              <p class="text-purple mb-1 text-left">The selected course</p>
              <input
                :value="course?.title"
                type="text"
                readonly
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
            </div>
            <div class="md:w-4/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Old Price</p>
              <input
                :value="course?.price"
                readonly
                type="number"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
            </div>
            <div class="md:w-4/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">New Price</p>
              <input
                :value="course.new_price"
                @input="(e) => setNewPrice(e, course.id)"
                type="number"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p
                class="text-red-500"
                v-if="
                  typeof course.new_price !== 'number' ||
                  course.new_price < 0 ||
                  !course.new_price
                "
              >
                price is required and must be a positive number
              </p>
            </div>
          </div>
        </form>
        <div class="flex justify-start my-4">
          <button class="btn btn bg-cyan font-bold" @click="addMod">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, minLength } from "@vuelidate/validators";
import { useOffersStore } from "@/store/offers";
import SelectMultipleCoursesDropdown from "@/components/cards/partials/SelectMultipleCoursesDropdown.vue";
import SelectDestinationsDropdown from "@/components/cards/partials/SelectDestinationsDropdown.vue";
import trash from "@/assets/icons/cards/trash.svg";
import { useDestinationsStore } from "@/store/destinations";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import router from "@/router";
import { useCoursesStore } from "@/store/courses";

export default {
  data() {
    return {
      title: "",
      cover: null,
      date: null,
    };
  },
  setup() {
    const offersStore = useOffersStore();
    const coursesStore = useCoursesStore();

    const destinationsStore = useDestinationsStore();
    return { v$: useVuelidate(), offersStore, destinationsStore, coursesStore, trash };
  },
  computed: {
    checkSelectedCourses() {
      if (this.offersStore.coursesOffers.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    setNewPrice(e, courseId) {
      for (
        let index = 0;
        index < this.offersStore.coursesOffers.length;
        index++
      ) {
        if (this.offersStore.coursesOffers[index].id == courseId) {
          this.offersStore.coursesOffers[index].new_price = parseFloat(
            e.target.value
          );
        }
      }
    },
    handleCoverChange(event) {
      this.cover = event.target.files[0];
      let outputPic = this.$refs.output;
      // this.categoriesStore.editedCategory.image = this.cover;
      outputPic.src = URL.createObjectURL(this.cover);
      outputPic.width = 200;
    },

    removeImage() {
      this.$refs.output.src = null;
      this.cover = null;
      // this.categoriesStore.editedCategory.image = null;
    },
    async addMod() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      } else {
        let formData = new FormData();
        formData.append("title", this.title);

        // until destination is back -->
        // formData.append(
        //   "destination",
        //   this.destinationsStore.selectedDestination
        // );

        formData.append("expiration_date", this.dateFormat(this.date));
        formData.append("image", this.cover);
        this.offersStore.coursesOffers.forEach((course, index) => {
          Object.entries(course).forEach(([key, value]) => {
            if (key == "new_price") {
              formData.append(`courses[${index}][${key}]`, value);
            }
            if (key == "id") {
              formData.append(`courses[${index}][course_id]`, value);
            }
            if (key == "price") {
              formData.append(`courses[${index}][old_price]`, value);
            }
          });
        });
        await this.offersStore.newOffer(formData);
        if (this.offersStore.isSuccess) {
          this.$swal({
            icon: "success",
            timer: 1500,
            title: "Offer added successfully...",
            showConfirmButton: false,
          }).then(() => {
            router.push("/admin/offers");
          });
        } else {
          this.$swal({
            icon: "danger",
            title: "Error accured while adding the offer !",
            html: `<ul>
            ${this.offersStore.errorMessages.map((error) => {
              return error.map((message) => {
                return `<li class="text-red-500">
                      ${message}
                    </li>`;
              });
            })}
          </ul>`,
            showConfirmButton: false,
            showCloseButton: true,
          });
        }
        this.offersStore.isSuccess = false;
        this.offersStore.isFail = false;
      }
      // perform async actions
    },
    dateFormat(newDate) {
      const day = newDate.getDate();
      const month = newDate.getMonth() + 1;
      const year = newDate.getFullYear();
      return `${year}-${month}-${day}`;
    },

    getTomorrow() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return this.dateFormat(tomorrow);
    },
  },
  validations() {
    return {
      title: {
        required: helpers.withMessage("Title is required", required),
        minLength: helpers.withMessage(
          "Title must be at least 3 characters",
          minLength(3)
        ),
      },
      date: {
        required: helpers.withMessage("Expiration date is required", required),
      },
      cover: { required: helpers.withMessage("Image is required", required) },
    };
  },
  components: {
    SelectMultipleCoursesDropdown,
    SelectDestinationsDropdown,
    VueDatePicker,
    LoaderModal,
  },
};
</script>

<style scoped>
.upload-block {
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A0A0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
