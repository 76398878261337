<template>
  <div
    class="flex justify-center items-center p-2 rounded-xl"
    :class="isSelected ? 'bg-[#00DDAF]' : 'bg-white'"
  >
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 0C5.531 0 0.5 1.546 0.5 4.5C0.5 7.454 5.531 9 10.5 9C15.47 9 20.5 7.454 20.5 4.5C20.5 1.546 15.47 0 10.5 0ZM5.5 9.938V12.938C6.737 13.237 8.105 13.42 9.5 13.479V10.479C8.15248 10.4265 6.81305 10.2453 5.5 9.938ZM11.5 10.478V13.478C12.8476 13.4266 14.1871 13.2454 15.5 12.937V9.937C14.1871 10.2454 12.8476 10.4266 11.5 10.478ZM17.5 9.297V12.297C19.301 11.542 20.5 10.44 20.5 9V6C20.5 7.44 19.301 8.542 17.5 9.297ZM3.5 12.297V9.297C1.7 8.542 0.5 7.439 0.5 6V9C0.5 10.439 1.7 11.542 3.5 12.297Z"
        :fill="color"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: ["color", "isSelected"],
};
</script>
<style></style>
