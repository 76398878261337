<template>
    <div>
        <LoaderModal v-if="teachersStore.isLoadingModal" />
        <metainfo>
            <template v-slot:title="{ content }">{{
                content ? `${content} | RAYHANE` : `SITE_NAME`
            }}</template>
        </metainfo>
        <div class="flex justify-between items-center">
            <div>
                <h1 class="text-3xl text-black font-semibold">Teachers</h1>
                <h1 class="text-xl text-gray font-semibold">
                    Comments & Reviews about the teacher
                </h1>
            </div>
        </div>
        <div
            class="relative min-w-0 break-words w-full mb-6 shadow-lg mt-4 rounded-xl border border-[#e8e8e8c9] bg-white"
        >
            <div class="block w-full overflow-x-auto">
                <div
                    v-if="!teachersStore.singleTeacher?.comments?.length > 0"
                    class="flex flex-col justify-center items-center py-10"
                >
                    <img :src="noRecords" alt="noRecords" class="w-2/6" />
                    <div class="text-xl text-[#747373]">
                        No reviews at the moment !
                    </div>
                </div>

                <table
                    v-else
                    class="items-center w-full bg-transparent border border-[#e8e8e8c9] rounded-xl overflow-hidden"
                >
                    <thead>
                        <tr class="border-[#e8e8e8c9] border">
                            <th
                                class="text-purple px-6 flex items-center align-middle py-6 uppercase -0 font-medium text-left"
                            >
                                The student
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
                            >
                                Date
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
                            >
                                The teacher
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
                            >
                                The comment
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
                            >
                                Stars
                            </th>

                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
                            >
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(comment, index) in teachersStore
                                .singleTeacher.comments"
                            :key="index"
                        >
                            <td
                                class="align-middle p-4 text-purple flex items-center"
                            >
                                <img
                                    :src="avatarUser"
                                    class="w-10 h-10 mr-2"
                                    alt="dfsd"
                                />
                                {{ comment.student_id }}
                            </td>
                            <td class="align-middle p-4">
                                {{ comment.created_at.slice(0, 10) }}
                            </td>
                            <td
                                class="align-middle p-4 text-purple flex items-center"
                            >
                                <img
                                    v-if="
                                        !teachersStore.singleTeacher.user.image
                                            ?.url
                                    "
                                    :src="avatarUser"
                                    class="w-10 h-10 mr-2"
                                    alt="dfsd"
                                />
                                <img
                                    v-else
                                    :src="
                                        teachersStore.singleTeacher.user.image
                                            ?.url
                                    "
                                    class="w-10 h-10 mr-2"
                                    alt="dfsd"
                                />
                                {{
                                    teachersStore.singleTeacher.user
                                        .first_name +
                                    " " +
                                    teachersStore.singleTeacher.user.last_name
                                }}
                            </td>
                            <td class="align-middle p-4 text-left">
                                {{ comment.content }}
                            </td>

                            <td class="align-middle whitespace-nowrap p-4">
                                <rating-stars
                                    :review="parseInt(comment.stars)"
                                />
                            </td>

                            <td class="align-middle text-right">
                                <img
                                    @click="reviewsStore.showDeleteModal = true"
                                    :src="deleteReview"
                                    class="cursor-pointer mx-auto"
                                    alt="deleteReview"
                                />
                                <DeleteReviewModal
                                    v-if="reviewsStore.showDeleteModal"
                                    :reviewId="comment.id"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="flex justify-between items-center">
            <div>
                Showing {{ teachersStore.from }}-{{ teachersStore.to }} from
                {{ teachersStore.totalPages }} data
            </div>
            <div class="flex items-center">
                <div v-if="teachersStore.currentPage > 1">
                    <img
                        class="cursor-pointer"
                        :src="forward"
                        alt="forward"
                        @click="teachersStore.previousPage()"
                    />
                </div>

                <div
                    v-if="teachersStore.currentPage > 1"
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
                    :class="{
                        'text-gray border border-gray': true,
                        'bg-cyan text-white border-cyan': false,
                    }"
                    @click="teachersStore.previousPage()"
                >
                    {{ teachersStore.currentPage - 1 }}
                </div>

                <div
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12"
                    :class="
                        true
                            ? 'bg-cyan text-white border-cyan'
                            : ' text-gray border border-gray'
                    "
                >
                    {{ teachersStore.currentPage }}
                </div>

                <div
                    v-if="
                        teachersStore.currentPage + 1 <=
                        Math.ceil(
                            teachersStore.totalPages /
                                teachersStore.itemsPerPage
                        )
                    "
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
                    :class="{
                        'text-gray border border-gray': true,
                        'bg-cyan text-white border-cyan': false,
                    }"
                    @click="teachersStore.nextPage()"
                >
                    {{ teachersStore.currentPage + 1 }}
                </div>

                <div
                    v-if="
                        teachersStore.currentPage + 1 <=
                        Math.ceil(
                            teachersStore.totalPages /
                                teachersStore.itemsPerPage
                        )
                    "
                >
                    <img
                        class="cursor-pointer"
                        :src="back"
                        alt="back"
                        @click="teachersStore.nextPage()"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DeleteReviewModal from "@/components/modals/reviews/DeleteReviewModal.vue";
import checkbox from "@/assets/icons/cards/checkbox.svg";
import separator from "@/assets/icons/cards/separator.svg";
import deleteReview from "@/assets/icons/cards/deleteReview.svg";
import avatarUser from "@/assets/icons/cards/avatarUser.svg";
import back from "../../assets/icons/cards/back.svg";
import forward from "../../assets/icons/cards/forward.svg";
import noRecords from "@/assets/images/noRecords.svg";
import RatingStars from "../cards/partials/RatingStars.vue";
import { useReviewsStore } from "@/store/reviews";
import { useTeachersStore } from "@/store/teachers";
import { useMeta } from "vue-meta";
import LoaderModal from "../modals/LoaderModal.vue";
export default {
    components: {
        DeleteReviewModal,
        RatingStars,
        LoaderModal,
    },
    data() {
        return {};
    },
    async mounted() {
        await this.teachersStore.getSingleTeacher(this.$route.params.id, false);
    },
    setup() {
        const reviewsStore = useReviewsStore();
        const teachersStore = useTeachersStore();
        useMeta({
            title: "Reviews",
            htmlAttrs: { lang: "en", amp: true },
        });
        return {
            forward,
            back,
            checkbox,
            avatarUser,
            deleteReview,
            separator,
            reviewsStore,
            noRecords,
            teachersStore,
        };
    },

    props: {
        color: {
            default: "light",
            validator: function (value) {
                // The value must match one of these strings
                return ["light", "dark"].indexOf(value) !== -1;
            },
        },
    },
};
</script>
