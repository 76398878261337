<template>
  <nav
    class="md:left-0 shadow-2xl md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden flex flex-wrap items-center justify-between relative md:w-72 z-10 py-4 px-4 bg-[#F5F5F5]"
  >
    <div
      class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
    >
      <!-- Toggler -->
      <button
        class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
        type="button"
        v-on:click="authStore.toggleCollapseShow('bg-white m-2 py-3 px-6')"
      >
        <img :src="menuBurger" alt="menuBurger" />
      </button>
      <!-- Brand -->
      <router-link
        class="hidden md:flex justify-center items-center text-left md:pb-2 text-blueGray-600 mr-0 whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
        to="/"
      >
        <img :src="logo" class="inline" alt="logo" />
      </router-link>

      <!-- Collapse -->
      <div
        class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded"
        v-bind:class="authStore.collapseShow"
      >
        <!-- Collapse header -->
        <div
          class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
        >
          <div class="flex flex-wrap">
            <div class="w-6/12">
              <router-link
                class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                to="/"
              >
                RAYHAN ADMIN
              </router-link>
            </div>
            <div class="w-6/12 flex justify-end">
              <button
                type="button"
                class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                v-on:click="authStore.toggleCollapseShow('hidden')"
              >
                <img :src="closeMenu" alt="closeMenu" />
              </button>
            </div>
          </div>
        </div>

        <!-- Navigation -->

        <ul
          class="md:flex-col md:min-w-full mt-4 flex flex-col list-none text-[#999999]"
        >
          <li
            v-if="authStore.user?.permissions.includes('manage_statistics')"
            class="items-center"
          >
            <router-link
              to="/admin/dashboard"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="px-2 py-2 my-1 font-bold flex items-center rounded-xl"
                :class="[
                  isActive || $route.path.includes('dashboard')
                    ? 'text-black bg-white font-semibold rounded-xl shadow-xl'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <dashboard-icon
                  class="mx-2"
                  :isSelected="
                    isActive || $route.path.includes('dashboard') ? true : false
                  "
                  :color="
                    isActive || $route.path.includes('dashboard')
                      ? 'white'
                      : '#00DDAF '
                  "
                />

                Dashboard
              </a>
            </router-link>
          </li>
          <li
            v-if="authStore.user?.permissions.includes('manage_teachers')"
            class="items-center"
          >
            <router-link
              to="/admin/teachers"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="px-2 py-2 my-1 font-bold flex items-center rounded-xl"
                :class="[
                  isActive || $route.path.includes('teachers')
                    ? 'text-black bg-white font-semibold rounded-xl shadow-xl  '
                    : ' hover:text-blueGray-500',
                ]"
              >
                <teacher-icon
                  class="mx-2"
                  :isSelected="
                    isActive || $route.path.includes('teachers') ? true : false
                  "
                  :color="
                    isActive || $route.path.includes('teachers')
                      ? 'white'
                      : '#00DDAF '
                  "
                />
                Teachers
              </a>
            </router-link>
          </li>
          <li
            v-if="authStore.user?.permissions.includes('manage_users')"
            class="items-center"
          >
            <router-link
              to="/admin/users"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="px-2 py-2 my-1 font-bold flex items-center rounded-xl"
                :class="[
                  isActive || $route.path.includes('users')
                    ? 'text-black bg-white font-semibold rounded-xl shadow-xl'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <users-icon
                  class="mx-2"
                  :isSelected="
                    isActive || $route.path.includes('users') ? true : false
                  "
                  :color="
                    isActive || $route.path.includes('users')
                      ? 'white'
                      : '#00DDAF '
                  "
                />
                Users
              </a>
            </router-link>
          </li>
          <li
            v-if="authStore.user?.permissions.includes('manage_groups')"
            class="items-center"
          >
            <router-link
              to="/admin/groups"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="px-2 py-2 my-1 font-bold flex items-center rounded-xl"
                :class="[
                  isActive ||
                  ($route.path.includes('groups') &&
                    !$route.path.includes('teacher'))
                    ? 'text-black bg-white font-semibold rounded-xl shadow-xl'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <GroupIcon
                  class="mx-2"
                  :isSelected="
                    isActive ||
                    ($route.path.includes('groups') &&
                      !$route.path.includes('teacher'))
                      ? true
                      : false
                  "
                  :color="
                    isActive ||
                    ($route.path.includes('groups') &&
                      !$route.path.includes('teacher'))
                      ? 'white'
                      : '#00DDAF '
                  "
                />
                Groups
              </a>
            </router-link>
          </li>
          <li
            v-if="authStore.user?.permissions.includes('manage_courses')"
            class="items-center"
          >
            <router-link
              to="/admin/courses"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="px-2 py-2 my-1 font-bold flex items-center rounded-xl"
                :class="[
                  isActive ||
                  ($route.path.includes('courses') &&
                    !$route.path.includes('teacher'))
                    ? 'text-black bg-white font-semibold rounded-xl shadow-xl'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <courses-icon
                  class="mx-2"
                  :isSelected="
                    isActive ||
                    ($route.path.includes('courses') &&
                      !$route.path.includes('teacher'))
                      ? true
                      : false
                  "
                  :color="
                    isActive ||
                    ($route.path.includes('courses') &&
                      !$route.path.includes('teacher'))
                      ? 'white'
                      : '#00DDAF '
                  "
                />
                Courses
              </a>
            </router-link>
          </li>
          <li
            v-if="authStore.user?.permissions.includes('manage_lives') || true"
            class="items-center cursor-pointer"
            @click="toogleLives"
          >
            <a
              :href="href"
              class="px-2 py-2 my-1 font-bold flex items-center bg-[#F5F5F5] rounded-xl"
            >
              <lives-icon
                class="mx-2"
                :isSelected="
                  isActive || $route.path.includes('live') ? true : false
                "
                :color="
                  isActive || $route.path.includes('live')
                    ? 'white'
                    : '#00DDAF '
                "
              />
              Lives & Rooms
              <svg
                :class="!showLives ? 'rotate-180' : 'rotate-0'"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.207 16.207C13.0195 16.3944 12.7652 16.4998 12.5 16.4998C12.2348 16.4998 11.9805 16.3944 11.793 16.207L6.136 10.55C6.04049 10.4577 5.96431 10.3474 5.9119 10.2254C5.85949 10.1034 5.83191 9.97216 5.83075 9.83938C5.8296 9.7066 5.8549 9.57492 5.90518 9.45202C5.95546 9.32913 6.02972 9.21747 6.12361 9.12358C6.2175 9.02969 6.32915 8.95544 6.45205 8.90515C6.57494 8.85487 6.70662 8.82957 6.8394 8.83073C6.97218 8.83188 7.1034 8.85947 7.22541 8.91187C7.34741 8.96428 7.45776 9.04047 7.55 9.13598L12.5 14.086L17.45 9.13598C17.6386 8.95382 17.8912 8.85302 18.1534 8.8553C18.4156 8.85758 18.6664 8.96275 18.8518 9.14816C19.0372 9.33357 19.1424 9.58438 19.1447 9.84658C19.147 10.1088 19.0462 10.3614 18.864 10.55L13.207 16.207Z"
                  fill="#00DDAF"
                />
              </svg>
            </a>
          </li>
          <div class="pl-10" v-if="showLives">
            <li class="items-center">
              <router-link
                to="/admin/lives"
                v-slot="{ href, navigate, isActive }"
              >
                <a
                  :href="href"
                  @click="navigate"
                  class="px-2 py-2 my-1 font-bold flex items-center rounded-xl"
                  :class="[
                    isActive || $route.path.includes('lives')
                      ? 'text-black bg-white font-semibold rounded-xl shadow-xl'
                      : ' hover:text-blueGray-500',
                  ]"
                >
                  <live-icon
                    class="mx-2"
                    :isSelected="
                      isActive || $route.path.includes('lives') ? true : false
                    "
                    :color="
                      isActive || $route.path.includes('lives')
                        ? 'white'
                        : '#00DDAF '
                    "
                  />
                  Live
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                to="/admin/rooms"
                v-slot="{ href, navigate, isActive }"
              >
                <a
                  :href="href"
                  @click="navigate"
                  class="px-2 py-2 my-1 font-bold flex items-center rounded-xl"
                  :class="[
                    isActive || $route.path.includes('rooms')
                      ? 'text-black bg-white font-semibold rounded-xl shadow-xl'
                      : ' hover:text-blueGray-500',
                  ]"
                >
                  <rooms-icon
                    class="mx-2"
                    :isSelected="
                      isActive || $route.path.includes('rooms') ? true : false
                    "
                    :color="
                      isActive || $route.path.includes('rooms')
                        ? 'white'
                        : '#00DDAF '
                    "
                  />
                  Rooms
                </a>
              </router-link>
            </li>
          </div>

          <li
            v-if="authStore.user?.permissions.includes('manage_reports')"
            class="items-center"
          >
            <router-link
              to="/admin/reports"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="px-2 py-2 my-1 font-bold flex items-center rounded-xl"
                :class="[
                  isActive || $route.path.includes('reports')
                    ? 'text-black bg-white font-semibold rounded-xl shadow-xl'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <reports-icon
                  class="mx-2"
                  :isSelected="
                    isActive || $route.path.includes('reports') ? true : false
                  "
                  :color="
                    isActive || $route.path.includes('reports')
                      ? 'white'
                      : '#00DDAF '
                  "
                />
                Reports
              </a>
            </router-link>
          </li>
          <li
            v-if="
              authStore.user?.permissions.includes('manage_subscriptions') ||
              authStore.user?.permissions.includes('manage_promotions') ||
              authStore.user?.permissions.includes('manage_affiliation') ||
              authStore.user?.permissions.includes('manage_payments') ||
              authStore.user?.permissions.includes('manage_codes_of_discount')
            "
            class="items-center cursor-pointer"
            @click="toogleSubscriptions"
          >
            <a
              :href="href"
              class="px-2 py-2 my-1 font-bold flex items-center bg-[#F5F5F5] rounded-xl"
            >
              <subscriptions-icon
                class="mx-2"
                :isSelected="false"
                :color="'#00DDAF '"
              />
              Subscriptions
              <svg
                :class="!showSubscriptions ? 'rotate-180' : 'rotate-0'"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.207 16.207C13.0195 16.3944 12.7652 16.4998 12.5 16.4998C12.2348 16.4998 11.9805 16.3944 11.793 16.207L6.136 10.55C6.04049 10.4577 5.96431 10.3474 5.9119 10.2254C5.85949 10.1034 5.83191 9.97216 5.83075 9.83938C5.8296 9.7066 5.8549 9.57492 5.90518 9.45202C5.95546 9.32913 6.02972 9.21747 6.12361 9.12358C6.2175 9.02969 6.32915 8.95544 6.45205 8.90515C6.57494 8.85487 6.70662 8.82957 6.8394 8.83073C6.97218 8.83188 7.1034 8.85947 7.22541 8.91187C7.34741 8.96428 7.45776 9.04047 7.55 9.13598L12.5 14.086L17.45 9.13598C17.6386 8.95382 17.8912 8.85302 18.1534 8.8553C18.4156 8.85758 18.6664 8.96275 18.8518 9.14816C19.0372 9.33357 19.1424 9.58438 19.1447 9.84658C19.147 10.1088 19.0462 10.3614 18.864 10.55L13.207 16.207Z"
                  fill="#00DDAF"
                />
              </svg>
            </a>
          </li>
          <div class="pl-10" v-if="showSubscriptions">
            <li
              v-if="
                authStore.user?.permissions.includes('manage_subscriptions')
              "
              class="items-center"
            >
              <router-link
                to="/admin/subscription"
                v-slot="{ href, navigate, isActive }"
              >
                <a
                  :href="href"
                  @click="navigate"
                  class="px-2 py-2 my-1 font-bold flex items-center rounded-xl"
                  :class="[
                    isActive || $route.path.includes('subscription')
                      ? 'text-black bg-white font-semibold rounded-xl shadow-xl'
                      : ' hover:text-blueGray-500',
                  ]"
                >
                  <subscription-icon
                    class="mx-2"
                    :isSelected="isActive ? true : false"
                    :color="isActive ? 'white' : '#00DDAF '"
                  />
                  Subscription
                </a>
              </router-link>
            </li>
            <li
              v-if="authStore.user?.permissions.includes('manage_payments')"
              class="items-center"
            >
              <router-link
                to="/admin/subscribers"
                v-slot="{ href, navigate, isActive }"
              >
                <a
                  :href="href"
                  @click="navigate"
                  class="px-2 py-2 my-1 font-bold flex items-center rounded-xl"
                  :class="[
                    isActive || $route.path.includes('subscribers')
                      ? 'text-black bg-white font-semibold rounded-xl shadow-xl'
                      : ' hover:text-blueGray-500',
                  ]"
                >
                  <subscribers-icon
                    class="mx-2"
                    :isSelected="
                      isActive || $route.path.includes('subscribers')
                        ? true
                        : false
                    "
                    :color="
                      isActive || $route.path.includes('subscribers')
                        ? 'white'
                        : '#00DDAF '
                    "
                  />
                  Payments
                </a>
              </router-link>
            </li>
            <li class="items-center">
              <router-link
                to="/admin/offers"
                v-slot="{ href, navigate, isActive }"
              >
                <a
                  :href="href"
                  @click="navigate"
                  class="px-2 py-2 my-1 font-bold flex items-center rounded-xl"
                  :class="[
                    isActive || $route.path.includes('offers')
                      ? 'text-black bg-white font-semibold rounded-xl shadow-xl'
                      : ' hover:text-blueGray-500',
                  ]"
                >
                  <offers-icon
                    class="mx-2"
                    :isSelected="
                      isActive || $route.path.includes('offers') ? true : false
                    "
                    :color="
                      isActive || $route.path.includes('offers')
                        ? 'white'
                        : '#00DDAF '
                    "
                  />
                  Offers
                </a>
              </router-link>
            </li>
            <li
              v-if="authStore.user?.permissions.includes('manage_affiliation')"
              class="items-center"
            >
              <router-link
                to="/admin/affiliation"
                v-slot="{ href, navigate, isActive }"
              >
                <a
                  :href="href"
                  @click="navigate"
                  class="px-2 py-2 my-1 font-bold flex items-center rounded-xl"
                  :class="[
                    isActive || $route.path.includes('affiliation')
                      ? 'text-black bg-white font-semibold rounded-xl shadow-xl'
                      : ' hover:text-blueGray-500',
                  ]"
                >
                  <affiliation-marketers-icon
                    class="mx-2"
                    :isSelected="
                      isActive || $route.path.includes('affiliation')
                        ? true
                        : false
                    "
                    :color="
                      isActive || $route.path.includes('affiliation')
                        ? 'white'
                        : '#00DDAF '
                    "
                  />
                  Aff Marketers
                </a>
              </router-link>
            </li>
            <li
              v-if="
                authStore.user?.permissions.includes('manage_codes_of_discount')
              "
              class="items-center"
            >
              <router-link
                to="/admin/cods"
                v-slot="{ href, navigate, isActive }"
              >
                <a
                  :href="href"
                  @click="navigate"
                  class="px-2 py-2 my-1 font-bold flex items-center rounded-xl"
                  :class="[
                    isActive || $route.path.includes('cods')
                      ? 'text-black bg-white font-semibold rounded-xl shadow-xl'
                      : ' hover:text-blueGray-500',
                  ]"
                >
                  <cods-icon
                    class="mx-2"
                    :isSelected="
                      isActive || $route.path.includes('cods') ? true : false
                    "
                    :color="
                      isActive || $route.path.includes('cods')
                        ? 'white'
                        : '#00DDAF '
                    "
                  />
                  CODs
                </a>
              </router-link>
            </li>
          </div>

          <li
            v-if="authStore.user?.permissions.includes('manage_landing_page')"
            class="items-center"
          >
            <router-link
              to="/admin/landingPage/partners"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="px-2 py-2 my-1 font-bold flex items-center rounded-xl"
                :class="[
                  isActive || $route.path.includes('landingPage')
                    ? 'text-black bg-white font-semibold rounded-xl shadow-xl'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <landing-page-icon
                  class="mx-2"
                  :isSelected="
                    isActive || $route.path.includes('landingPage')
                      ? true
                      : false
                  "
                  :color="
                    isActive || $route.path.includes('landingPage')
                      ? 'white'
                      : '#00DDAF '
                  "
                />
                Landing Page
              </a>
            </router-link>
          </li>
          <li
            v-if="authStore.user?.permissions.includes('manage_ads')"
            class="items-center"
          >
            <router-link to="/admin/ads" v-slot="{ href, navigate, isActive }">
              <a
                :href="href"
                @click="navigate"
                class="px-2 py-2 my-1 font-bold flex items-center rounded-xl"
                :class="[
                  isActive || $route.path.includes('ads')
                    ? 'text-black bg-white font-semibold rounded-xl shadow-xl'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <ads-icon
                  class="mx-2"
                  :isSelected="
                    isActive || $route.path.includes('ads') ? true : false
                  "
                  :color="
                    isActive || $route.path.includes('ads')
                      ? 'white'
                      : '#00DDAF '
                  "
                />
                Manage Ads
              </a>
            </router-link>
          </li>
          <li
            v-if="authStore.user?.permissions.includes('manage_courses')"
            class="items-center"
          >
            <router-link
              to="/admin/reviewsAndComments"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="px-2 py-2 my-1 font-bold flex items-center rounded-xl"
                :class="[
                  isActive || $route.path.includes('reviewsAndComments')
                    ? 'text-black bg-white font-semibold rounded-xl shadow-xl'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <reviews-icon
                  class="mx-2"
                  :isSelected="
                    isActive ||
                    ($route.path.includes('reviewsAndComments') &&
                      !$route.path.includes('teachers'))
                      ? true
                      : false
                  "
                  :color="
                    isActive ||
                    ($route.path.includes('reviewsAndComments') &&
                      !$route.path.includes('teachers'))
                      ? 'white'
                      : '#00DDAF '
                  "
                />
                Reviews & Comments
              </a>
            </router-link>
          </li>

          <li
            v-if="authStore.user?.permissions.includes('manage_employees')"
            class="items-center"
          >
            <router-link
              to="/admin/settings"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="px-2 py-2 my-1 font-bold flex items-center rounded-xl"
                :class="[
                  isActive || $route.path.includes('settings')
                    ? 'text-black bg-white font-semibold rounded-xl shadow-xl'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <settings-icon
                  class="mx-2"
                  :isSelected="
                    isActive || $route.path.includes('settings') ? true : false
                  "
                  :color="
                    isActive || $route.path.includes('settings')
                      ? 'white'
                      : '#00DDAF '
                  "
                />
                Settings
              </a>
            </router-link>
          </li>
          <li class="items-center">
            <router-link
              to="/admin/profile"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="px-2 py-2 my-1 font-bold flex items-center rounded-xl"
                :class="[
                  isActive || $route.path.includes('profile')
                    ? 'text-black bg-white font-semibold rounded-xl shadow-xl'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <profile-icon
                  class="mx-2"
                  :isSelected="
                    isActive || $route.path.includes('profile') ? true : false
                  "
                  :color="
                    isActive || $route.path.includes('profile')
                      ? 'white'
                      : '#00DDAF '
                  "
                />
                Profile
              </a>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import logo from "@/assets/images/logo.png";
import DashboardIcon from "../../assets/icons/sidebar/DashboardIcon.vue";
import TeacherIcon from "../../assets/icons/sidebar/TeacherIcon.vue";
import UsersIcon from "../../assets/icons/sidebar/UsersIcon.vue";
import CoursesIcon from "../../assets/icons/sidebar/CoursesIcon.vue";
import GroupIcon from "../../assets/icons/sidebar/GroupIcon.vue";
import LivesIcon from "../../assets/icons/sidebar/LivesIcon.vue";
import LiveIcon from "../../assets/icons/sidebar/LiveIcon.vue";
import RoomsIcon from "../../assets/icons/sidebar/RoomsIcon.vue";
import ReportsIcon from "../../assets/icons/sidebar/ReportsIcon.vue";
import SubscriptionsIcon from "../../assets/icons/sidebar/SubscriptionsIcon.vue";
import SubscriptionIcon from "../../assets/icons/sidebar/SubscriptionIcon.vue";
import SubscribersIcon from "../../assets/icons/sidebar/SubscribersIcon.vue";
import OffersIcon from "../../assets/icons/sidebar/OffersIcon.vue";
import AffiliationMarketersIcon from "../../assets/icons/sidebar/AffiliationMarketersIcon.vue";
import LandingPageIcon from "../../assets/icons/sidebar/LandingPageIcon.vue";
import ReviewsIcon from "../../assets/icons/sidebar/ReviewsIcon.vue";
import AdsIcon from "../../assets/icons/sidebar/AdsIcon.vue";
import CodsIcon from "../../assets/icons/sidebar/CodsIcon.vue";

import SettingsIcon from "../../assets/icons/sidebar/SettingsIcon.vue";
import ProfileIcon from "../../assets/icons/sidebar/ProfileIcon.vue";
import menuBurger from "@/assets/icons/sidebar/menuBurger.svg";
import closeMenu from "@/assets/icons/sidebar/closeMenu.svg";
import { useAuthStore } from "@/store/auth";
import { router } from "../../router/index";

export default {
  components: {
    DashboardIcon,
    TeacherIcon,
    UsersIcon,
    CoursesIcon,
    GroupIcon,
    LivesIcon,
    LiveIcon,
    RoomsIcon,
    ReportsIcon,
    SubscriptionsIcon,
    SubscriptionIcon,
    SubscribersIcon,
    OffersIcon,
    AffiliationMarketersIcon,
    LandingPageIcon,
    AdsIcon,
    ReviewsIcon,
    SettingsIcon,
    ProfileIcon,
    CodsIcon,
  },
  data() {
    return {
      collapseShow: "hidden",
      showLives: false,
      isActive: false,
      href: null,
      showSubscriptions: false,
    };
  },
  setup() {
    const authStore = useAuthStore();
    return {
      logo,
      menuBurger,
      authStore,
      closeMenu,
    };
  },
  methods: {
    toggleCollapseShow: function (classes) {
      this.collapseShow = classes;
    },
    toogleLives() {
      router.push("/admin/lives");
      this.showLives = !this.showLives;
    },
    toogleSubscriptions() {
      router.push("/admin/subscription");
      this.showSubscriptions = !this.showSubscriptions;
    },
  },
};
</script>
<style scoped>
.svg-active {
  fill: white !important;
}
.svg-inactive {
  fill: #00ddaf !important;
}
.box-example2 {
  box-shadow: inset 0px 7px 9px -7px rgb(0 0 0 / 40%);
  height: 80px;
  border: 1px dashed grey;
  position: relative;
  margin-top: 8px;
}
nav::after {
  box-shadow: inset -7px 0px 090px -7px rgb(0 0 0 / 40%) !important;
}
</style>
