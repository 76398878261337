import { defineStore } from "pinia";
import axios from "@/axios/axios";
import { useAuthStore } from "./auth";

export const useCodsStore = defineStore("codsStore", {
  state: () => ({
    cods: [],
    lastestCod: [],
    codsCount: 0,
    itemsPerPage: 10,
    totalPages: 0,
    from: 0,
    to: 0,
    currentPage: 1,
    isLoadingModal: false,
    isFail: false,
    isSuccess: false,
    newCod: null,
    showDeleteModal: false,
    selectedStudent: null,
  }),
  actions: {
    async addCod(cod) {
      this.errorMessage = null;
      this.isFail = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const res = await axios.post(
          `${process.env.VUE_APP_API_BASE}cod/admin/store`,
          cod,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        return res.data.data;
      } catch (err) {
        this.isFail = true;
        this.isLoadingModal = false;
        this.errorMessage = err.response.data.errors;
        console.log(err);
      }
    },

    async getCods(query) {
      const authStore = useAuthStore();
      this.errorMessage = null;
      try {
        if (!query) {
          this.isLoadingModal = true;
        } else {
          authStore.isLoading = true;
        }
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}cod/admin/get?paginate=true&page=${
            this.currentPage
          }&q=${query ? `&q=${encodeURIComponent(query)}` : ""}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        this.cods = data.data.data;
        this.from = data.data.from;
        this.to = data.data.to;
        this.totalPages = Math.ceil(data.data.total / data.data.per_page);
        this.currentPage = data.data.current_page;
        this.isLoadingModal = false;
        authStore.isLoading = false;
      } catch (err) {
        this.isLoadingModal = false;
        authStore.isLoading = false;

        this.errorMessage = err.response.data.errors;
        console.log(err);
      }
    },

    async deleteCod(codId) {
      this.isSuccess = false;
      this.isFail = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}cod/admin/delete/${codId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isSuccess = false;
        this.isLoadingModal = false;
        await this.getCods();
      } catch (err) {
        this.isLoadingModal = false;
        this.isFail = true;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getCods();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getCods();
      }
    },
  },
});
