<template>
  <div>
    <div class="flex flex-col mt-4">
      <div class="my-6 mt-24 w-full"><SingleCourseReviewsTable /></div>
    </div>
  </div>
</template>
<script>
import SingleCourseReviewsTable from "@/components/tables/SingleCourseReviewsTable.vue";

export default {
  components: { SingleCourseReviewsTable },
  setup() {
    return {};
  },
  data() {
    return {};
  },
};
</script>
