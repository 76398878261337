<template>
  <div></div>
</template>
<script>
import { useTeachersStore } from "@/store/teachers";
export default {
  setup() {
    const teacherStore = useTeachersStore();
    const { isEditTeacherFail } = useTeachersStore();
    const { isEditTeacherSuccess } = useTeachersStore();
    return {
      teacherStore,
      isEditTeacherFail,
      isEditTeacherSuccess,
    };
  },
  mounted() {
    if (this.teacherStore.isEditTeacherSuccess) {
      this.$swal({
        icon: "success",
        title: "Teacher edited successfully",
        showConfirmButton: false,
        timer: 1500,
        willClose: () => {
          this.teacherStore.isEditTeacherFail = false;
          this.teacherStore.isEditTeacherSuccess = false;
          this.teacherStore.isShowEditor = false;
          this.$router.push({ path: '/admin/teachers' })
        },
      });
    }
  },
};
</script>
