import { defineStore } from "pinia";
import axios from "@/axios/axios";
import { useAuthStore } from "./auth";
export const useTeachersStore = defineStore("teachersStore", {
  state: () => ({
    teachers: [],
    itemsPerPage: 10,
    totalPages: 0,
    from: 0,
    to: 0,
    currentPage: 1,
    isBlockModal: false,
    isUnblockModal: false,
    isLoadingModal: false,
    isAddTeacherSuccess: false,
    isAddTeacherFail: false,
    isEditTeacherSuccess: false,
    isEditTeacherFail: false,
    isSuccess: false,
    isFail: false,
    errorMessages: [],
    isShowEditor: false,
    editedTeacher: null,
    showDeleteModal: false,
    // theses are for filtering
    selectedTeacher: null,
    selectedNationality: null,
    selectedSport: null,
    selectedDate: null,
    singleTeacher: null,
  }),
  actions: {
    async getTeachers(query) {
      const authStore = useAuthStore();
      try {
        if (!query) {
          this.isLoadingModal = true;
        } else {
          authStore.isLoading = true;
        }
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${
            process.env.VUE_APP_API_BASE
          }teacher/admin/get?paginate=true&page=${
            this.currentPage
          }&comments=${true}${
            query ? `&q=${encodeURIComponent(query)}` : ""
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.teachers = data.data.data;
        this.from = data.data.meta.from;
        this.to = data.data.meta.to;
        this.totalPages = data.data.meta.total;
        this.currentPage = data.data.meta.current_page;
        this.isLoadingModal = false;
        authStore.isLoading = false;
      } catch (err) {
        this.isLoadingModal = false;
        authStore.isLoading = false;
        console.log(err);
      }
    },

    async getSingleTeacher(id, isWithComments) {
      this.singleTeacher = null;
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}teacher/admin/get/${id}?comments=${isWithComments}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.singleTeacher = data.data;
        this.isLoadingModal = false;
      } catch (err) {
        this.isLoadingModal = false;
        console.log(err);
      }
    },

    async addTeacher(teacher) {
      try {
        this.errorMessages = [];
        this.isLoadingModal = true;
        this.isAddTeacherFail = false;
        this.isAddTeacherSuccess = false;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.post(
          `${process.env.VUE_APP_API_BASE}teacher/admin/store`,
          teacher,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.singleTeacher = data.data;
        this.isAddTeacherSuccess = true;
        this.isLoadingModal = false;
        return data.data;
      } catch (err) {
        this.isAddTeacherFail = true;
        this.isLoadingModal = false;
        console.log(err.response.data.errors);

        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
      }
    },

    async editTeacher(teacher, teacherId) {
      try {
        this.errorMessages = [];
        this.isLoadingModal = true;
        this.isEditTeacherFail = false;
        this.isEditTeacherSuccess = false;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.post(
          `${process.env.VUE_APP_API_BASE}teacher/admin/update/${teacherId}`,
          teacher,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.singleTeacher = data.data;
        this.isEditTeacherSuccess = true;
        this.isLoadingModal = false;
        return data.data;
      } catch (err) {
        this.isEditTeacherFail = true;
        this.isLoadingModal = false;
        console.log(err);
        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
      }
    },

    async getTeachersWithoutPagination() {
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}teacher/admin/get?comments=false`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        this.teachers = data.data;
      } catch (err) {
        console.log(err);
      }
    },

    async blockTeacher(teacher) {
      this.isFail = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}teacher/admin/block/${teacher.id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        await this.getTeachers();
      } catch (err) {
        this.isLoadingModal = false;
        this.isFail = true;
        console.log(err);
      }
    },
    async unBlockTeacher(teacher) {
      this.isFail = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}teacher/admin/unblock/${teacher.id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        await this.getTeachers();
      } catch (err) {
        this.isLoadingModal = false;
        this.isFail = true;
        console.log(err);
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getTeachers();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getTeachers();
      }
    },
  },
});
