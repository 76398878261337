<template>
  <router-link
    :to="
      notification.type == 'ticket'
        ? `/admin/messages/${notification.data.ticket_id}`
        : '/admin/reports'
    "
    @click="markAsRead"
  >
    <div
      class="md:flex items-center justify-between shadow-md rounded-xl w-full p-2 md:p-4 my-3 cursor-pointer md:px-10 text-center md:text-left"
      :class="
        notification.read_at
          ? 'bg-none font-[300]'
          : 'bg-[#E3EEF4] font-semibold'
      "
    >
      <div class="flex justify-center md:justify-start">
        <img
          class="rounded-xl mr-2 items-center"
          :src="notificationItem"
          alt="notificationItem"
        />
        <div class="flex items-center font-semibold">
          {{ notification.type }} sent
        </div>
      </div>
      <div class="text-gray">
        {{ notification.type == "ticket" ? "Teacher" : "Student" }}
        <span
          :class="
            notification.read_at
              ? 'bg-none font-[300]'
              : 'bg-[#E3EEF4] font-semibold'
          "
          class="text-black"
          >{{
            notification.data.user.first_name +
            " " +
            notification.data.user.last_name
          }}</span
        >
        sent a {{ notification.type == "ticket" ? "ticket" : "report" }}
      </div>
      <div class="text-gray">{{ formattedCreatedAt }}</div>
    </div>
  </router-link>
</template>
<script>
import exitBlack from "@/assets/icons/cards/exitBlack.svg";
import notificationItem from "@/assets/icons/cards/notificationItem.svg";
import { useNotificationsStore } from "@/store/notifications";
import { RouterLink } from "vue-router";

export default {
  props: ["notification"],
  setup() {
    const notificationsStore = useNotificationsStore();
    return { notificationsStore, notificationItem, exitBlack };
  },
  methods: {
    markAsRead() {
      this.notificationsStore.updateNotification(this.notification.id);
    },
  },
  computed: {
    formattedCreatedAt() {
      const createdAt = new Date(this.notification.created_at);
      const now = new Date();

      const timeDifference = now - createdAt;

      // Calculate minutes, hours, and days
      const minutes = Math.floor(timeDifference / (1000 * 60));
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

      // If less than a minute ago
      if (minutes < 1) {
        return "Less than a minute ago";
      }

      // If within the last hour
      if (hours === 0) {
        return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
      }

      // If same day, show HH:MM
      if (days === 0) {
        return createdAt.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });
      }

      // If yesterday
      if (days === 1) {
        return "Yesterday";
      }

      // Otherwise, show days ago
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    },
  },

  components: { RouterLink },
};
</script>
<style></style>
