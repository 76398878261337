import { defineStore } from "pinia";
import axios from "@/axios/axios";

export const useCategoriesStore = defineStore("categoriesStore", {
  state: () => ({
    categories: [],
    lastestCategory: [],
    categorysCount: 0,
    itemsPerPage: 10,
    totalPages: 0,
    to: 0,
    from: 0,
    currentPage: 1,
    isSuccess: false,
    isFail: false,
    isLoadingModal: false,
    isFailModal: false,
    deleteError: false,
    addError: false,
    addSuccess: false,
    isUpdateError: false,
    isUpdateSuccess: false,
    isShowEditor: false,
    errorMessages: [],
    errorMessage: "",
    editedCategory: null,
    selectedCategory: null,
    showDeleteModal: false,
  }),
  actions: {
    async getCategories() {
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;

        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}category/admin/get?paginate=true&page=${this.currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.categories = data.data.data;
        this.totalPages = data.data.last_page;
        this.from = data.data.from;
        this.to = data.data.to;
        this.isLoadingModal = false;
      } catch (err) {
        console.log(err);
        this.isLoadingModal = false;
      }
    },
    async addCategory(newCategory) {
      this.isFail = false;
      this.isSuccess = false;
      this.error = null;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}category/admin/store`,
          newCategory,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        this.isFail = false;
        //  this.isSuccessEdit = true;
        //  this.user = response.data.user;
      } catch (err) {
        this.errorMessage = err.response.data.errors;
        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
        console.log(err);
        this.isFail = true;

        this.isLoadingModal = false;
        this.isFailEdit = true;
      }
    },
    async deleteCategory(categoryId) {
      this.isFail = false;
      this.isSuccess = false;
      this.errorMessages = [];
      this.errorMessage = null;
      this.isLoadingModal = true;
      this.deleteError = false;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}category/admin/delete/${categoryId}`,
          { id: categoryId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isSuccess = true;
        this.isLoadingModal = false;
      } catch (err) {
        this.errorMessage = err.response.data.errors;

        if (err.response.status == 400) {
          console.log(true, err.response.data.message);
          this.errorMessages.push(err.response.data.message);
        } else {
          for (var key in err.response.data.errors) {
            this.errorMessages.push(err.response.data.errors[key]);
          }
        }
        this.isFail = true;
        this.isLoadingModal = false;
        this.deleteError = true;
      }
    },
    async updateCategory(category, categoryId) {
      this.isFail = false;
      this.isSuccess = false;
      this.isLoadingModal = true;
      this.isUpdateError = false;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}category/admin/update/${categoryId}`,
          category,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        this.isUpdateSuccess = true;
      } catch (err) {
        this.errorMessage = err.response.data.errors;
        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
        this.isFail = true;
        console.log(err);
        this.isLoadingModal = false;
        this.isUpdateError = true;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getCategories();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getCategories();
      }
    },
  },
});
