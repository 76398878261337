<template>
    <div>
        <LoaderModal v-if="coursesStore.isLoadingModal" />
        <div
            class="relative min-w-0 break-words w-full mb-6 shadow-md mt-4 rounded-xl border border-[#e8e8e8c9] bg-white"
        >
            <div class="block w-full overflow-x-auto">
                <!-- News table -->
                <table
                    class="items-center w-full bg-transparent border border-[#e8e8e8c9] rounded-xl overflow-hidden"
                    v-if="coursesStore.courses.length > 0"
                >
                    <thead class="">
                        <tr class="border-[#e8e8e8c9] border">
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
                            >
                                Course title
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
                            >
                                Teacher
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
                            >
                                Destinantion
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
                            >
                                Pricing
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
                            >
                                Subscribers
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
                            >
                                Comments
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
                            >
                                total rate
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
                            >
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(course, index) in coursesStore.courses"
                            :key="index"
                        >
                            <td
                                class="align-middle p-4 text-purple font-medium flex items-center"
                            >
                                <p>{{ course.title }}</p>
                            </td>
                            <td
                                class="align-middle whitespace-nowrap text-left"
                            >
                                <p>
                                    {{
                                        course.teacher.user.first_name +
                                        " " +
                                        course.teacher.user.last_name
                                    }}
                                </p>
                            </td>

                            <td
                                class="align-middle whitespace-nowrap p-4 text-center"
                            >
                                <p>{{ course.destination }}</p>
                            </td>
                            <td class="align-middle p-4 text-center">
                                <p>{{ course.price }} DA</p>
                            </td>

                            <td class="align-middle text-center">
                                <p>{{ course.subscribers }}</p>
                            </td>
                            <td class="align-middle text-center">
                                <p>{{ course.comments_count }}</p>
                            </td>
                            <td class="align-middle text-center">
                                <p>
                                    {{
                                        parseFloat(course.total_rate.toFixed(1))
                                    }}
                                </p>
                            </td>
                            <td class="align-middle text-center">
                                <CoursesDropdown :course="{ id: course.id }" />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div
                    v-else
                    class="flex flex-col justify-center items-center py-10"
                >
                    <img :src="noRecords" alt="noRecords" class="w-2/6" />
                    <div class="text-xl text-[#747373]">
                        No records at the moment !
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-between items-center">
            <div>
                Showing {{ coursesStore.from }}-{{ coursesStore.to }} from
                {{ coursesStore.totalPages }} data
            </div>
            <div class="flex items-center">
                <div v-if="coursesStore.currentPage != 1">
                    <img
                        @click="coursesStore.previousPage()"
                        :src="forward"
                        class="cursor-pointer"
                        alt="forward"
                    />
                </div>
                <div
                    v-if="coursesStore.currentPage > 1"
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
                    :class="{
                        'text-gray border border-gray': true,
                        'bg-cyan text-white border-cyan': false,
                    }"
                    @click="coursesStore.previousPage()"
                >
                    {{ coursesStore.currentPage - 1 }}
                </div>

                <div
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12"
                    :class="
                        true
                            ? 'bg-cyan text-white border-cyan'
                            : ' text-gray border border-gray'
                    "
                >
                    {{ coursesStore.currentPage }}
                </div>

                <div
                    v-if="
                        coursesStore.currentPage + 1 <=
                        Math.ceil(
                            coursesStore.totalPages / coursesStore.itemsPerPage
                        )
                    "
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
                    :class="{
                        'text-gray border border-gray': true,
                        'bg-cyan text-white border-cyan': false,
                    }"
                    @click="coursesStore.nextPage()"
                >
                    {{ coursesStore.currentPage + 1 }}
                </div>

                <div
                    v-if="
                        coursesStore.currentPage + 1 <=
                        Math.ceil(
                            coursesStore.totalPages / coursesStore.itemsPerPage
                        )
                    "
                >
                    <img
                        class="cursor-pointer"
                        @click="coursesStore.nextPage()"
                        :src="back"
                        alt="back"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import checkbox from "@/assets/icons/cards/checkbox.svg";
import separator from "@/assets/icons/cards/separator.svg";
import deleteReview from "@/assets/icons/cards/deleteReview.svg";
import avatarUser from "@/assets/icons/cards/avatarUser.svg";
import back from "@/assets/icons/cards/back.svg";
import forward from "@/assets/icons/cards/forward.svg";

import noRecords from "@/assets/images/noRecords.svg";
import { useCoursesStore } from "@/store/courses";
import CoursesDropdown from "../dropdowns/CoursesDropdown.vue";
import LoaderModal from "../modals/LoaderModal.vue";
export default {
    components: { CoursesDropdown, LoaderModal },
    data() {
        return {
            courses: [],
        };
    },
    async mounted() {
        await this.coursesStore.getCourses();
    },
    setup() {
        const coursesStore = useCoursesStore();
        return {
            back,
            forward,
            checkbox,
            avatarUser,
            deleteReview,
            separator,
            coursesStore,
            noRecords,
        };
    },

    props: {
        color: {
            default: "light",
            validator: function (value) {
                // The value must match one of these strings
                return ["light", "dark"].indexOf(value) !== -1;
            },
        },
    },
};
</script>
