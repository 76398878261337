<template>
  <div class="max-w-[1728px] lg:mx-auto">
    <metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | RAYHANE` : `SITE_NAME`
      }}</template>
    </metainfo>
    <side-bar />
    <div class="relative xl:ml-72 2xl:ml-68 md:ml-80 bg-blueGray-100">
      <admin-navbar />
      <header-stats v-if="$route.path == '/admin/dashboard'" />
      <div
        class="px-4 md:px-5 mx-auto w-full"
        :class="
          $route.path == '/admin/dashboard' || $route.path == '/admin/news'
            ? '-m-24'
            : ''
        "
      >
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import AdminNavbar from "@/components/navbars/AdminNavbar.vue";
import SideBar from "@/components/sidebars/SideBar.vue";
import { useMeta } from "vue-meta";

import HeaderStats from "@/components/headers/HeaderStats.vue";
export default {
  name: "admin-layout",
  setup() {
    useMeta({
      title: "Dashboard",
      htmlAttrs: { lang: "en", amp: true },
    });
  },
  components: {
    AdminNavbar,
    SideBar,
    HeaderStats,
  },
};
</script>
