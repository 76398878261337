<template>
    <div>
        <DeleteUserModal
            v-if="usersStore.isDeleteModal"
            :userId="usersStore?.selectedUser.id"
        />
        <BlockUserModal
            v-if="usersStore.isBlockModal"
            :userId="usersStore?.selectedUser.id"
        />
        <UnBlockUserModal
            v-if="usersStore.isUnblockModal"
            :userId="usersStore?.selectedUser.id"
        />
        <LoaderModal
            v-if="usersStore.isLoadingModal"
            :userId="usersStore?.selectedUser?.id"
        />
        <div class="flex justify-between items-center">
            <div>
                <h1 class="text-3xl text-black font-semibold">Users</h1>
                <h1 class="text-xl text-gray font-semibold">
                    Manage your users
                </h1>
            </div>
            <div class="flex items-center">
                <router-link to="/admin/users/new">
                    <button class="btn bg-cyan font-bold">
                        <span class="">+ </span> New user
                    </button></router-link
                >
            </div>
        </div>
        <div
            class="relative min-w-0 break-words w-full mb-6 shadow-lg mt-4 rounded-xl border border-[#e8e8e8c9] bg-white"
        >
            <div class="block w-full overflow-x-auto">
                <div
                    v-if="!usersStore.users?.length > 0"
                    class="flex flex-col justify-center items-center py-10"
                >
                    <img :src="noRecords" alt="noRecords" class="w-2/6" />
                    <div class="text-xl text-[#747373]">
                        No reports at the moment !
                    </div>
                </div>
                <table
                    class="items-center w-full bg-transparent border border-[#e8e8e8c9] rounded-xl overflow-hidden"
                    v-else
                >
                    <thead class="">
                        <tr class="border-[#e8e8e8c9] border">
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase -0 font-medium text-left"
                            >
                                User name
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
                            >
                                Phone
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
                            >
                                Email
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
                            >
                                Balance
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
                            >
                                Type
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
                            >
                                Courses
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
                            >
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(student, index) in usersStore.users"
                            :key="index"
                            :class="
                                !!student?.user?.status == 'inactive'
                                    ? 'bg-red-100'
                                    : ''
                            "
                        >
                            <td
                                class="align-middle p-4 text-purple flex items-center"
                            >
                                <img
                                    :src="
                                        student?.user?.image
                                            ? student?.user?.image.url
                                            : avatarUser
                                    "
                                    class="w-10 h-10 mr-2"
                                    alt="dfsd"
                                />
                                {{
                                    student?.user?.first_name +
                                    " " +
                                    student?.user?.last_name
                                }}
                            </td>
                            <td
                                class="align-middle p-4 text-purple items-center"
                            >
                                {{ student?.user?.phone }}
                            </td>
                            <td
                                :class="
                                    student?.user?.email ? '' : 'text-red-500'
                                "
                                class="align-middle p-4 text-purple items-center"
                            >
                                {{
                                    student?.user?.email
                                        ? student?.user?.email
                                        : "Not assigned yet"
                                }}
                            </td>
                            <td class="align-middle p-4 text-center">
                                {{ student.balance }} DA
                            </td>
                            <td class="align-middle p-4 text-center"></td>
                            <td class="align-middle p-4 text-center"></td>

                            <td class="align-middle text-center">
                                <UsersDropdown
                                    :user="{
                                        id: student?.user?.id,
                                        status: student?.user?.status,
                                    }"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="flex justify-between items-center">
            <div>
                Showing {{ usersStore.from }}-{{ usersStore.to }} from
                {{ usersStore.totalPages }} data
            </div>
            <div class="flex items-center">
                <div v-if="usersStore.currentPage != 1">
                    <img
                        @click="usersStore.previousPage()"
                        :src="forward"
                        class="cursor-pointer"
                        alt="forward"
                    />
                </div>

                <div
                    v-if="usersStore.currentPage > 1"
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
                    :class="{
                        'text-gray border border-gray': true,
                        'bg-cyan text-white border-cyan': false,
                    }"
                    @click="usersStore.previousPage()"
                >
                    {{ usersStore.currentPage - 1 }}
                </div>

                <div
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12"
                    :class="
                        true
                            ? 'bg-cyan text-white border-cyan'
                            : ' text-gray border border-gray'
                    "
                >
                    {{ usersStore.currentPage }}
                </div>

                <div
                    v-if="
                        usersStore.currentPage + 1 <=
                        Math.ceil(
                            usersStore.totalPages / usersStore.itemsPerPage
                        )
                    "
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
                    :class="{
                        'text-gray border border-gray': true,
                        'bg-cyan text-white border-cyan': false,
                    }"
                    @click="usersStore.nextPage()"
                >
                    {{ usersStore.currentPage + 1 }}
                </div>

                <div
                    v-if="
                        usersStore.currentPage + 1 <=
                        Math.ceil(
                            usersStore.totalPages / usersStore.itemsPerPage
                        )
                    "
                >
                    <img
                        class="cursor-pointer"
                        @click="usersStore.nextPage()"
                        :src="back"
                        alt="back"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import checkbox from "@/assets/icons/cards/checkbox.svg";
import separator from "@/assets/icons/cards/separator.svg";
import deleteIcon from "@/assets/icons/cards/deleteIcon.svg";
import avatarUser from "@/assets/icons/cards/avatarUser.svg";
import back from "@/assets/icons/cards/back.svg";
import forward from "@/assets/icons/cards/forward.svg";

import noRecords from "@/assets/images/noRecords.svg";
import { useUsersStore } from "@/store/users";
import UsersDropdown from "../dropdowns/UsersDropdown.vue";
import BlockUserModal from "../modals/users/BlockUserModal.vue";
import UnBlockUserModal from "../modals/users/UnBlockUserModal.vue";
import LoaderModal from "../modals/LoaderModal.vue";
import DeleteUserModal from "../modals/users/DeleteUserModal.vue";
export default {
    components: {
        UsersDropdown,
        BlockUserModal,
        UnBlockUserModal,
        LoaderModal,
        DeleteUserModal,
    },
    data() {
        return {};
    },
    setup() {
        const usersStore = useUsersStore();
        return {
            back,
            forward,
            checkbox,
            avatarUser,
            deleteIcon,
            separator,
            usersStore,
            noRecords,
        };
    },

    async mounted() {
        await this.usersStore.getUsers();
    },

    props: {
        color: {
            default: "light",
            validator: function (value) {
                // The value must match one of these strings
                return ["light", "dark"].indexOf(value) !== -1;
            },
        },
    },
};
</script>
