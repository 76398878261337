<template>
  <div v-if="options.length > 0">
    <Multiselect
      v-model="value"
      mode="tags"
      :close-on-select="false"
      :searchable="true"
      :create-option="true"
      :options="options"
      spinner
    />
  </div>
  <div class="text-red-500" v-else>No courses in the selected destination</div>
</template>

<script>
import { useDestinationsStore } from "@/store/destinations";
import { useCoursesStore } from "@/store/courses";

import Multiselect from "@vueform/multiselect";
import { useOffersStore } from "@/store/offers";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      value: [],
      options: [],
    };
  },
  setup() {
    const coursesStore = useCoursesStore();
    const destinationsStore = useDestinationsStore();
    const offersStore = useOffersStore();
    return { coursesStore, destinationsStore, offersStore };
  },
  watch: {
    "destinationsStore.selectedDestination": {
      immediate: true,
      handler() {
        this.getCourses();
      },
    },
    value() {
      this.offersStore.coursesOffers = this.value;
    },
  },
  methods: {
    async getCourses() {
      this.value = [];
      await this.coursesStore.getCourses();
      this.options = this.coursesStore.courses;
      // this.options = this.options.filter((opt) => {
      //   return opt.destination == this.destinationsStore.selectedDestination;
      // });
      this.options = this.options.map((option) => {
        return {
          ...option,
          value: { ...option, new_price: null },
          label: option.title,
        };
      });
      this.offersStore.coursesOffers = this.value;
    },
  },

  async mounted() {
    await this.coursesStore.getCourses();
    this.selectedDestination = this.destinationsStore.selectedDestination;
    this.options = this.coursesStore.courses;

    // until destination is back -->
    // this.options = this.options.filter((opt) => {
    //   return opt.destination == this.selectedDestination;
    // });

    this.options = this.options.map((option) => {
      return {
        ...option,
        value: { ...option, new_price: null },
        label: option.title,
      };
    });
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
