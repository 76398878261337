import { defineStore } from "pinia";
import axios from "@/axios/axios";

export const useReviewsStore = defineStore("ReviewsStore", {
  state: () => ({
    reviews: [],
    isLoadingModal: false,
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    showDeleteModal: false,
  }),
  actions: {
    async getReviews() {
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}reviews/getUserReviews`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.reviews = data.userReviews;
        this.isLoadingModal = false;
        this.showDeleteModal = false;
      } catch (err) {
        this.showDeleteModal = false;
        this.isLoadingModal = false;
      }
    },
    async deleteReview(reviewId) {
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}teacher/admin/comment/delete/${reviewId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        this.isLoadingModal = false;
        this.showDeleteModal = false;
      } catch (err) {
        this.showDeleteModal = false;

        this.isLoadingModal = false;
      }
    },

    async updateReview(newsId) {
      this.isLoadingModal = true;
      this.pauseError = false;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/news/unpause`,
          {
            newsId: newsId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        await this.getNews();
      } catch (err) {
        this.isLoadingModal = false;
        this.pauseError = true;
        console.log(err);
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getReviews()
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getReviews()
      }
    },
  },
});
