<template>
    <div>
        <metainfo>
            <template v-slot:title="{ content }">{{
                content ? `${content} | RAYHANE` : `SITE_NAME`
            }}</template>
        </metainfo>
        <div class="flex flex-wrap mt-4">
            <div class="w-full px-4">
                <div class="my-6 mt-24">
                    <div class="flex justify-between items-center">
                        <div>
                            <h1 class="text-3xl text-black font-semibold">
                                Affiliation
                            </h1>
                            <h1 class="text-xl text-gray font-semibold mt-3">
                                Manage the affiliations
                            </h1>
                        </div>

                        <div class="flex items-center">
                            <router-link to="/admin/affiliation/newInfluencer">
                                <button class="btn bg-cyan font-bold mr-4">
                                    <span class="">+ </span> New Influencer
                                </button></router-link
                            >
                            <router-link to="/admin/affiliation/settings">
                                <button class="btn bg-cyan font-bold">
                                    Afffiliation settings
                                </button></router-link
                            >
                        </div>
                    </div>
                    <AffiliationsTable />
                    <div class="flex justify-between items-center">
                        <div>
                            Showing {{ affiliationsStore.from }}-{{
                                affiliationsStore.to
                            }}
                            from {{ affiliationsStore.totalPages }} data
                        </div>
                        <div class="flex items-center">
                            <div v-if="affiliationsStore.currentPage != 1">
                                <img
                                    @click="affiliationsStore.previousPage()"
                                    :src="forward"
                                    class="cursor-pointer"
                                    alt="forward"
                                />
                            </div>

                            <div
                                v-if="affiliationsStore.currentPage > 1"
                                class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
                                :class="{
                                    'text-gray border border-gray': true,
                                    'bg-cyan text-white border-cyan': false,
                                }"
                                @click="affiliationsStore.previousPage()"
                            >
                                {{ affiliationsStore.currentPage - 1 }}
                            </div>

                            <div
                                class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12"
                                :class="
                                    true
                                        ? 'bg-cyan text-white border-cyan'
                                        : ' text-gray border border-gray'
                                "
                            >
                                {{ affiliationsStore.currentPage }}
                            </div>

                            <div
                                v-if="
                                    affiliationsStore.currentPage + 1 <=
                                    Math.ceil(
                                        affiliationsStore.totalPages /
                                            affiliationsStore.itemsPerPage
                                    )
                                "
                                class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
                                :class="{
                                    'text-gray border border-gray': true,
                                    'bg-cyan text-white border-cyan': false,
                                }"
                                @click="affiliationsStore.nextPage()"
                            >
                                {{ affiliationsStore.currentPage + 1 }}
                            </div>

                            <div
                                v-if="
                                    affiliationsStore.currentPage + 1 <=
                                    Math.ceil(
                                        affiliationsStore.totalPages /
                                            affiliationsStore.itemsPerPage
                                    )
                                "
                            >
                                <img
                                    class="cursor-pointer"
                                    @click="affiliationsStore.nextPage()"
                                    :src="back"
                                    alt="back"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AffiliationsTable from "../../components/tables/AffiliationsTable.vue";
import back from "@/assets/icons/cards/back.svg";
import forward from "@/assets/icons/cards/forward.svg";
import { useAffiliationsStore } from "@/store/affiliations";
import { useMeta } from "vue-meta";

export default {
    components: { AffiliationsTable },
    setup() {
        useMeta({
            title: "Affiliations",
            htmlAttrs: { lang: "en", amp: true },
        });
        const affiliationsStore = useAffiliationsStore();
        return { affiliationsStore, forward, back };
    },
};
</script>
<style></style>
