<template>
    <div>
        <LoaderModal v-if="paymentsStore.isLoadingModal" />
        <AcceptPaymentModal v-if="paymentsStore.showAcceptModal" />
        <RejectPaymentModal v-if="paymentsStore.showRejectModal" />
        <div class="flex justify-between items-center">
            <div>
                <h1 class="text-3xl text-black font-semibold">Payments</h1>
                <h1 class="text-xl text-gray font-semibold">
                    Manage the payments
                </h1>
            </div>
        </div>
        <div
            class="relative min-w-0 break-words w-full mb-6 shadow-lg mt-4 rounded-xl border border-[#e8e8e8c9] bg-white"
        >
            <div class="block w-full overflow-x-auto">
                <div
                    v-if="!paymentsStore.payments?.length > 0"
                    class="flex flex-col justify-center items-center py-10"
                >
                    <img :src="noRecords" alt="noRecords" class="w-2/6" />
                    <div class="text-xl text-[#747373]">
                        No payments at the moment !
                    </div>
                </div>
                <table
                    class="items-center w-full bg-transparent border border-[#e8e8e8c9] rounded-xl overflow-hidden"
                    v-else
                >
                    <thead class="">
                        <tr class="border-[#e8e8e8c9] border">
                            <th
                                class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
                            >
                                Date
                            </th>
                            <th
                                class="text-purple px-4 flex items-center align-middle py-6 uppercase font-medium text-left"
                            >
                                User
                            </th>
                            <th
                                class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
                            >
                                Payment type
                            </th>

                            <th
                                class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
                            >
                                Payment method
                            </th>

                            <th
                                class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
                            >
                                Item
                            </th>
                            <th
                                class="text-purple px-4 align-middle py-6 uppercase font-medium text-center"
                            >
                                Amount
                            </th>
                            <th
                                class="text-purple px-4 align-middle py-6 uppercase font-medium text-center"
                            >
                                Promo code
                            </th>
                            <th
                                class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
                            >
                                Study year
                            </th>

                            <th
                                class="text-purple px-4 align-middle py-6 uppercase font-medium text-center"
                            >
                                Status
                            </th>
                            <th
                                class="text-purple px-4 align-middle py-6 uppercase font-medium text-center"
                            >
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(payment, index) in paymentsStore.payments"
                            :key="index"
                        >
                            <td
                                class="align-middle p-4 text-purple items-center"
                            >
                                {{ payment.created_at }}
                            </td>
                            <td class="align-middle p-4 text-purple">
                                <div
                                    class="flex items-center"
                                    v-if="!!payment?.student?.user"
                                >
                                    {{
                                        payment?.student?.user?.first_name +
                                        " " +
                                        payment?.student?.user?.last_name
                                    }}
                                </div>
                                <div class="flex items-center" v-else>
                                    Student
                                </div>
                            </td>
                            <td
                                class="align-middle p-4 text-purple items-center"
                            >
                                {{ payment.type }}
                            </td>
                            <td class="align-middle p-4 text-left max-w-sm">
                                {{ payment.payment_method }}
                            </td>
                            <td class="align-middle p-4 text-left max-w-sm">
                                {{
                                    payment.item?.type
                                        ? payment.item?.type
                                        : "Not assigned"
                                }}
                            </td>

                            <td
                                class="align-middle whitespace-nowrap p-4 text-center"
                            >
                                {{ payment.amount }} DA
                            </td>

                            <td
                                class="align-middle whitespace-nowrap p-4 text-center"
                            >
                                {{ payment?.promotion ?? "/" }}
                            </td>

                            <td class="align-middle text-center">
                                {{ payment.student.study_year }}
                            </td>
                            <td
                                class="align-middle text-center text-cyan"
                                v-if="payment.status == 'paid'"
                            >
                                {{ payment.status }}
                            </td>
                            <td
                                class="align-middle text-center text-red-400"
                                v-if="payment.status == 'canceled'"
                            >
                                {{ payment.status }}
                            </td>
                            <td
                                class="align-middle text-center text-orange-400"
                                v-if="payment.status == 'unpaid'"
                            >
                                {{ payment.status }}
                            </td>
                            <td
                                class="align-middle text-center text-red-400"
                                v-if="payment.status == 'rejected'"
                            >
                                {{ payment.status }}
                            </td>
                            <td
                                v-if="payment.status !== 'canceled'"
                                class="align-middle text-center"
                            >
                                <SubscribersDropdown :payment="payment" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="flex justify-between items-center px-2 py-2">
            <div>
                Showing {{ paymentsStore.from }}-{{ paymentsStore.to }} from
                {{ paymentsStore.totalRecords }} data
            </div>
            <div class="flex items-center">
                <div v-if="paymentsStore.currentPage != 1">
                    <img
                        @click="paymentsStore.previousPage()"
                        :src="forward"
                        class="cursor-pointer"
                        alt="forward"
                    />
                </div>

                <div
                    v-if="paymentsStore.currentPage > 1"
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
                    :class="{
                        'text-gray border border-gray': true,
                        'bg-cyan text-white border-cyan': false,
                    }"
                    @click="paymentsStore.previousPage()"
                >
                    {{ paymentsStore.currentPage - 1 }}
                </div>

                <div
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12"
                    :class="
                        true
                            ? 'bg-cyan text-white border-cyan'
                            : ' text-gray border border-gray'
                    "
                >
                    {{ paymentsStore.currentPage }}
                </div>

                <div
                    v-if="paymentsStore.currentPage < paymentsStore.totalPages"
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
                    :class="{
                        'text-gray border border-gray': true,
                        'bg-cyan text-white border-cyan': false,
                    }"
                    @click="paymentsStore.nextPage()"
                >
                    {{ paymentsStore.currentPage + 1 }}
                </div>

                <div
                    v-if="paymentsStore.currentPage < paymentsStore.totalPages"
                >
                    <img
                        class="cursor-pointer"
                        @click="paymentsStore.nextPage()"
                        :src="back"
                        alt="back"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import checkbox from "@/assets/icons/cards/checkbox.svg";
import separator from "@/assets/icons/cards/separator.svg";
import deleteIcon from "@/assets/icons/cards/deleteIcon.svg";
import avatarUser from "@/assets/icons/cards/avatarUser.svg";
import back from "@/assets/icons/cards/back.svg";
import forward from "@/assets/icons/cards/forward.svg";
import noRecords from "@/assets/images/noRecords.svg";
import { usePaymentsStore } from "@/store/payments";
import SubscribersDropdown from "../dropdowns/SubscribersDropdown.vue";
import LoaderModal from "../modals/LoaderModal.vue";
import RejectPaymentModal from "../modals/subscriptions/RejectPaymentModal.vue";
import AcceptPaymentModal from "../modals/subscriptions/AcceptPaymentModal.vue";
export default {
    components: {
        SubscribersDropdown,
        LoaderModal,
        RejectPaymentModal,
        AcceptPaymentModal,
    },
    data() {
        return {};
    },
    setup() {
        const paymentsStore = usePaymentsStore();
        return {
            back,
            forward,
            checkbox,
            avatarUser,
            deleteIcon,
            separator,
            paymentsStore,
            noRecords,
        };
    },
    async mounted() {
        await this.paymentsStore.getPayments();
    },
    props: {
        color: {
            default: "light",
            validator: function (value) {
                // The value must match one of these strings
                return ["light", "dark"].indexOf(value) !== -1;
            },
        },
    },
};
</script>
