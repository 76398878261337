<template>
  <div>
    <metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | RAYHANE` : `SITE_NAME`
      }}</template>
    </metainfo>
    <div class="flex flex-wrap mt-4">
      <div class="w-full px-4">
        <div class="my-6 mt-24">
          <ReportsTable />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ReportsTable from "../../components/tables/ReportsTable.vue";
import back from "@/assets/icons/cards/back.svg";
import forward from "@/assets/icons/cards/forward.svg";
import { useMeta } from "vue-meta";

export default {
  components: { ReportsTable },
  setup() {
    useMeta({
      title: "Reports",
      htmlAttrs: { lang: "en", amp: true },
    });
    return { forward, back };
  },
};
</script>
<style></style>
