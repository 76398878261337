<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <LoaderModal v-if="notificationsStore.isLoading" /><metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | RAYHANE` : `SITE_NAME`
      }}</template>
    </metainfo>
    <div class="my-6 mt-24 w-full">
      <div class="flex justify-between items-center mb-4 w-full">
        <div>
          <h1 class="text-3xl text-black font-semibold">Notifications</h1>
          <h1 class="text-xl text-gray font-semibold mt-3">
            Reply to messages from teachers
          </h1>
        </div>
        <router-link
          class="btn bg-cyan font-bold my-4"
          to="/admin/notifications/new"
        >
          Add notification
        </router-link>
      </div>

      <div
        v-for="(notification, index) in notificationsStore.notifications"
        :key="index"
      >
        <NotificationCard :notification="notification" />
      </div>
    </div>
    <button
      class="mx-auto btn bg-cyan font-bold my-4"
      v-if="notificationsStore.currentPage !== notificationsStore.totalPages"
      @click="notificationsStore.nextPage()"
    >
      show older notifications
    </button>
    <p
      class="mx-auto btn bg-white shadow-none text-cyan font-bold my-4 text-xl"
      v-if="notificationsStore.currentPage == notificationsStore.totalPages"
    >
      You're all caught up !
    </p>
  </div>
</template>
<script>
import { useNotificationsStore } from "@/store/notifications";
import NotificationCard from "@/components/cards/NotificationCard.vue";
import { useMeta } from "vue-meta";
import LoaderModal from "@/components/modals/LoaderModal.vue";
export default {
  setup() {
    useMeta({
      title: "Notifications",
      htmlAttrs: { lang: "en", amp: true },
    });
    const notificationsStore = useNotificationsStore();
    return {
      notificationsStore,
    };
  },
  async mounted() {
    await this.notificationsStore.getNotifications();
  },
  components: { NotificationCard, LoaderModal },
};
</script>
<style></style>
