<template>
  <div></div>
</template>
<script>
import { useUsersStore } from "@/store/users";
export default {
  setup() {
    const userStore = useUsersStore();
    const { isAddUserFail } = useUsersStore();
    const { isAddUserSuccess } = useUsersStore();
    return {
      userStore,
      isAddUserFail,
      isAddUserSuccess,
    };
  },
  mounted() {
    if (this.userStore.isAddUserSuccess) {
      this.$swal({
        icon: "success",
        title: "User added successfully",
        showConfirmButton: false,
        timer: 1500,
        willClose: () => {
          this.userStore.isAddUserFail = false;
          this.userStore.isAddUserSuccess = false;
          this.$router.push({ path: '/admin/users' })
        },
      });
    }
  },
};
</script>
