import { defineStore } from "pinia";
import axios from "@/axios/axios";

export const useStatsStore = defineStore("statsStore", {
  state: () => ({
    sold: 0,
    takwin_students: 0,
    courses_students: 0,
    teachers: 0,
    influencers: 0,
    employees: 0,
    incomes: [],
    isLoadingModal: false,
  }),
  actions: {
    async getCurrentYearStats() {
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}stats/admin/get/chart?year=2024`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        this.incomes = data.data.incomes;
        this.isLoadingModal = false;
      } catch (err) {
        this.isLoadingModal = false;

        console.error(err);
      }
    },
    async getGeneralStats() {
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}stats/admin/get/general`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.sold = data.data.sold;
        this.takwin_students = data.data.takwin_students;
        this.courses_students = data.data.courses_students;
        this.teachers = data.data.teachers;
        this.influencers = data.data.influencers;
        this.isLoadingModal = false;
      } catch (err) {
        this.isLoadingModal = false;
        console.error(err);
      }
    },
  },
});
