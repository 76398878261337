<template>
    <div>
        <div
            class="relative min-w-0 break-words w-full mb-6 shadow-md mt-4 rounded-xl border border-[#e8e8e8c9] bg-white"
        >
            <LoaderModal v-if="livesStore.isLoadingModal" />
            <DeleteLiveModal v-if="livesStore.showDeleteModal" />
            <div class="block w-full overflow-x-auto">
                <table
                    class="items-center w-full bg-transparent border border-[#e8e8e8c9] rounded-xl overflow-hidden"
                    v-if="livesStore.lives.length > 0"
                >
                    <thead class="">
                        <tr class="border-[#e8e8e8c9] border">
                            <th
                                class="text-purple px-4 align-middle py-6 font-medium text-left"
                            >
                                Live title
                            </th>
                            <th
                                class="text-purple px-4 align-middle py-6 font-medium text-left"
                            >
                                Timing
                            </th>
                            <th
                                class="text-purple px-4 align-middle py-6 font-medium text-left"
                            >
                                Duration
                            </th>
                            <th
                                class="text-purple px-4 align-middle py-6 font-medium text-left"
                            >
                                Destinantion
                            </th>
                            <th
                                class="text-purple px-4 align-middle py-6 font-medium text-left"
                            >
                                Teacher name
                            </th>
                            <th
                                class="text-purple px-4 align-middle py-6 font-medium text-center"
                            >
                                Status
                            </th>

                            <th
                                class="text-purple px-4 align-middle py-6 font-medium text-center"
                            >
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(live, index) in livesStore.lives"
                            :key="index"
                        >
                            <td class="align-middle p-4 text-black">
                                <p>{{ live.title }}</p>
                            </td>
                            <td
                                class="align-middle whitespace-nowrap text-left"
                            >
                                <p>{{ live.start_date }}</p>
                            </td>

                            <td
                                class="align-middle whitespace-nowrap p-4 text-left"
                            >
                                <p>{{ live.duration }}</p>
                            </td>
                            <td class="align-middle p-4 text-left">
                                <p>{{ live.destination }}</p>
                            </td>
                            <td class="align-middle p-4 text-purple">
                                <div class="flex items-center">
                                    {{
                                        live.teacher.first_name +
                                        " " +
                                        live.teacher.last_name
                                    }}
                                </div>
                            </td>

                            <td class="align-middle text-center">
                                <div
                                    class="rounded-md px-4 text-green bg-lightGreen mx-auto my-2"
                                >
                                    {{ live.status }}
                                </div>
                            </td>

                            <td class="align-middle text-center">
                                <LivesDropdown :live="{ id: live.id }" />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div
                    v-else
                    class="flex flex-col justify-center items-center py-10"
                >
                    <img :src="noRecords" alt="noRecords" class="w-2/6" />
                    <div class="text-xl text-[#747373]">
                        No records at the moment !
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-between items-center px-2 py-2">
            <div>
                Showing {{ livesStore.from }}-{{ livesStore.to }} from
                {{ livesStore.totalRecords }} data
            </div>
            <div class="flex items-center">
                <div v-if="livesStore.currentPage != 1">
                    <img
                        @click="livesStore.previousPageOffers()"
                        :src="forward"
                        class="cursor-pointer"
                        alt="forward"
                    />
                </div>

                <div
                    v-if="livesStore.currentPage > 1"
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
                    :class="{
                        'text-gray border border-gray': true,
                        'bg-cyan text-white border-cyan': false,
                    }"
                    @click="livesStore.previousPage()"
                >
                    {{ livesStore.currentPage - 1 }}
                </div>

                <div
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12"
                    :class="
                        true
                            ? 'bg-cyan text-white border-cyan'
                            : ' text-gray border border-gray'
                    "
                >
                    {{ livesStore.currentPage }}
                </div>

                <div
                    v-if="livesStore.currentPage < livesStore.totalPages"
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
                    :class="{
                        'text-gray border border-gray': true,
                        'bg-cyan text-white border-cyan': false,
                    }"
                    @click="livesStore.nextPage()"
                >
                    {{ livesStore.currentPage + 1 }}
                </div>

                <div v-if="livesStore.currentPage < livesStore.totalPages">
                    <img
                        class="cursor-pointer"
                        @click="livesStore.nextPageOffers()"
                        :src="back"
                        alt="back"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import checkbox from "@/assets/icons/cards/checkbox.svg";
import separator from "@/assets/icons/cards/separator.svg";
import deleteReview from "@/assets/icons/cards/deleteReview.svg";
import avatarUser from "@/assets/icons/cards/avatarUser.svg";
import back from "@/assets/icons/cards/back.svg";
import forward from "@/assets/icons/cards/forward.svg";

import noRecords from "@/assets/images/noRecords.svg";
import { useLivesStore } from "@/store/lives";
import LivesDropdown from "../dropdowns/LivesDropdown.vue";
import DeleteLiveModal from "../modals/lives/DeleteLiveModal.vue";
import LoaderModal from "../modals/LoaderModal.vue";

export default {
    components: { LivesDropdown, DeleteLiveModal, LoaderModal },
    data() {
        return {};
    },
    async mounted() {
        await this.livesStore.getLives();
    },
    setup() {
        const livesStore = useLivesStore();
        return {
            back,
            forward,
            checkbox,
            avatarUser,
            deleteReview,
            separator,
            livesStore,
            noRecords,
        };
    },

    props: {
        color: {
            default: "light",
            validator: function (value) {
                // The value must match one of these strings
                return ["light", "dark"].indexOf(value) !== -1;
            },
        },
    },
};
</script>
