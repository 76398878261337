<template>
  <div
    class="h-[500px] mx-10 border border-cyan border-opacity-20 p-4 pb-20 my-10"
  >
    <span class="font-medium text-xl">Statistics</span> <br />
    Revenue

    <Line
      :data="chartData"
      :options="{
        responsive: true,
        maintainAspectRatio: false,
      }"
    />
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "vue-chartjs";
import { useStatsStore } from "@/store/stats";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default {
  components: {
    Line,
  },
  setup() {
    const statsStore = useStatsStore();
    return { statsStore };
  },
  async mounted() {
    await this.statsStore.getCurrentYearStats();
    this.chartData.datasets[0].data = this.statsStore.incomes;
  },

  data() {
    return {
      chartData: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            label: "DZD",
            backgroundColor: "#00DDAF",
            data: this.statsStore.incomes,
          },
        ],
      },
    };
  },
};
</script>
