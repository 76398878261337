<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <div class="my-6 mt-24 w-full">
      <NewCategoryForm />
    </div>
  </div>
</template>
<script>
import NewCategoryForm from "@/components/cards/courses/NewCategoryForm.vue";

export default { components: { NewCategoryForm } };
</script>
<style></style>
