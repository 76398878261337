<template>
  <div></div>
</template>
<script>
import { useAffiliationsStore } from "../../../store/affiliations";
export default {
  setup() {
    const affiliationStore = useAffiliationsStore();
    const { isEditAffiliationFail, isEditAffiliationSuccess } =
      useAffiliationsStore();
    return {
      affiliationStore,
      isEditAffiliationFail,
      isEditAffiliationSuccess,
    };
  },
  mounted() {
    if (this.affiliationStore.isEditAffiliationSuccess) {
      this.$swal({
        icon: "success",
        title: "Influencer updated successfuly",
        timer: 1500,
        willClose: () => {
          this.affiliationStore.isEditAffiliationFail = false;
          this.affiliationStore.isEditAffiliationSuccess = false;
          this.$router.push("/admin/affiliation");
        },
      });

    }
  },
};
</script>
