<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <EditTeacherSuccessModal v-if="teachersStore.isEditTeacherSuccess" />
    <EditTeacherFailModal v-if="teachersStore.isEditTeacherFail" />
    <LoaderModal v-if="teachersStore.isLoadingModal" />
    <metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | RAYHANE` : `SITE_NAME`
      }}</template>
    </metainfo>
    <div class="my-6 mt-24 w-full">
      <div class="w-full">
        <div class="my-2">
          <h1 class="text-3xl text-black font-semibold">Teachers</h1>
          <h1 class="text-md text-gray font-semibold mt-2">
            Manage your teachers
          </h1>
        </div>
        <div
          class="bg-purple text-white font-bold text-3xl p-5 rounded-t-xl border-[#c9c9c9] border-b-0 border"
        >
          Edit teacher
        </div>
        <form class="w-full border-[#c9c9c9] border border-t-0 p-4 rounded-xl">
          <div>
            <p class="text-purple mb-1 text-left">Picture</p>
          </div>
          <div class="my-2 mb-4 text-xl rounded-md">
            <label
              class="cursor-pointer w-fit flex items-center"
              for="inputTag"
            >
              <div
                v-if="!this.form.cover"
                class="flex flex-col upload-block justify-center items-center p-2 h-40 w-40 rounded-md border-dashed"
              >
                <p class="text-[#8a8a8ac9] mb-1 text-center text-base mt-2">
                  Click here to select a file
                </p>

                <input
                  class="hidden"
                  hidden
                  id="inputTag"
                  @change="handleCoverChange"
                  type="file"
                  accept="image/*"
                />
              </div>

              <img v-show="this.form.cover" class="h-40 w-40" ref="output" />
              <img
                @click="removeImage"
                :src="trash"
                class="ml-2"
                v-if="this.form.cover"
                width="40"
              />
            </label>
            <p class="text-red-500" v-if="v$.form.cover.$error">
              <span
                v-for="(error, index) in v$.form.cover.$errors"
                :key="index"
              >
                {{ error.$message }}
              </span>
            </p>
          </div>
          <div class="md:flex">
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">email</p>
              <input
                v-model="form.email"
                type="email"
                :class="
                  v$.form.email.$error ? 'border-red-500 border-[3px]' : ''
                "
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />

              <p class="text-red-500" v-if="v$.form.email.$error">
                <span
                  v-for="(error, index) in v$.form.email.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </p>
            </div>
          </div>

          <div class="md:flex">
            <div class="md:mr-6 my-3 md:w-6/12">
              <p class="text-purple mb-1 text-left">Teacher’s first name</p>
              <input
                v-model="form.first_name"
                :class="
                  v$.form.first_name.$error ? 'border-red-500 border-[3px]' : ''
                "
                type="text"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p class="text-red-500" v-if="v$.form.first_name.$error">
                <span
                  v-for="(error, index) in v$.form.first_name.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </p>
            </div>
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Teacher’s last name</p>
              <input
                v-model="form.last_name"
                :class="
                  v$.form.last_name.$error ? 'border-red-500 border-[3px]' : ''
                "
                type="text"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p class="text-red-500" v-if="v$.form.last_name.$error">
                <span
                  v-for="(error, index) in v$.form.last_name.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </p>
            </div>
          </div>

          <div class="md:flex">
            <div class="md:mr-6 my-3 md:w-6/12">
              <p class="text-purple mb-1 text-left">Fonction</p>
              <input
                v-model="form.fonction"
                :class="
                  v$.form.fonction.$error ? 'border-red-500 border-[3px]' : ''
                "
                type="text"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p class="text-red-500" v-if="v$.form.fonction.$error">
                <span
                  v-for="(error, index) in v$.form.fonction.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </p>
            </div>
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Phone</p>
              <input
                v-model="form.phone"
                :class="
                  v$.form.phone.$error ? 'border-red-500 border-[3px]' : ''
                "
                type="text"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p class="text-red-500" v-if="v$.form.phone.$error">
                <span
                  v-for="(error, index) in v$.form.phone.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </p>
            </div>
          </div>

          <div class="md:flex">
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Youtube link</p>
              <div
                class="flex items-center justify-between border-[#c9c9c9] border rounded-md py-3 newPassword-container"
                :class="
                  v$.form.youtube_link.$error
                    ? 'border-red-500 border-[3px]'
                    : ''
                "
              >
                <input
                  class="text-gray-700 px-2 leading-tight focus:outline-none w-full newPassword-input"
                  type="text"
                  v-model="form.youtube_link"
                  name="newPassword"
                />
                <svg
                  width="18"
                  height="18"
                  class="mx-2 cursor-pointer"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_202_16568)">
                    <path
                      d="M16.1895 7.84598L8.652 15.4022C7.1895 16.864 4.8105 16.864 3.34875 15.4022C1.887 13.9405 1.88625 11.5607 3.34875 10.0982L11.3197 2.15948C12.1973 1.28198 13.6245 1.28198 14.502 2.15948C15.3788 3.03698 15.3795 4.46348 14.502 5.34098L8.12175 11.689C7.82925 11.9815 7.35375 11.9815 7.06125 11.689C6.76875 11.3965 6.76875 10.921 7.06125 10.6285L12.381 5.34098L11.3197 4.28048L6 9.56723C5.121 10.4462 5.12175 11.872 6 12.7502C6.87825 13.6285 8.30325 13.6285 9.1815 12.7502L15.5618 6.40223C16.2945 5.66948 16.6597 4.71023 16.6597 3.75023C16.6597 1.67348 14.9752 0.000976562 12.9097 0.000976562C11.9497 0.000976562 10.9905 0.366976 10.2577 1.09973L2.2875 9.03698C1.263 10.063 0.75 11.4062 0.75 12.7502C0.75 15.6475 3.0975 18.0002 6 18.0002C7.344 18.0002 8.68725 17.4872 9.7125 16.4627L17.25 8.90648L16.1895 7.84598Z"
                      fill="#CDD2D8"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_202_16568">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <p class="text-red-500" v-if="v$.form.youtube_link.$error">
                <span
                  v-for="(error, index) in v$.form.youtube_link.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </p>
            </div>
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Balance</p>
              <input
                v-model="form.balance"
                type="number"
                :class="
                  v$.form.balance.$error ? 'border-red-500 border-[3px]' : ''
                "
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />

              <p class="text-red-500" v-if="v$.form.balance.$error">
                <span
                  v-for="(error, index) in v$.form.balance.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </p>
            </div>
          </div>
          <div class="md:flex">
            <div class="md:w-full md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Percentage</p>
              <input
                v-model="form.percentage"
                type="number"
                :class="
                  v$.form.percentage.$error ? 'border-red-500 border-[3px]' : ''
                "
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />

              <p class="text-red-500" v-if="v$.form.percentage.$error">
                <span
                  v-for="(error, index) in v$.form.percentage.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </p>
            </div>
          </div>
          <div class="md:flex">
            <div class="md:mr-6 my-3 md:w-6/12">
              <p class="text-purple mb-1 text-left">
                Threshhold for number of students reached
              </p>
              <input
                v-model="form.affiliation_max"
                :class="
                  v$.form.affiliation_max.$error
                    ? 'border-red-500 border-[3px]'
                    : ''
                "
                type="number"
                maxlength="3"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p class="text-red-500" v-if="v$.form.affiliation_max.$error">
                <span
                  v-for="(error, index) in v$.form.affiliation_max.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </p>
            </div>
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">
                Percentage of reached number
              </p>
              <input
                v-model="form.affiliation_percentage"
                :class="
                  v$.form.affiliation_percentage.$error
                    ? 'border-red-500 border-[3px]'
                    : ''
                "
                type="number"
                maxlength="3"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p
                class="text-red-500"
                v-if="v$.form.affiliation_percentage.$error"
              >
                <span
                  v-for="(error, index) in v$.form.affiliation_percentage
                    .$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </span>
              </p>
            </div>
          </div>
        </form>
        <div class="flex justify-start my-4">
          <button class="btn btn bg-cyan font-bold" @click="save">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  decimal,
  integer,
  between,
  minLength,
  helpers,
  minValue,
} from "@vuelidate/validators";
import EditTeacherSuccessModal from "@/components/modals/teachers/EditTeacherSuccessModal.vue";
import trash from "@/assets/icons/cards/trash.svg";
import EditTeacherFailModal from "@/components/modals/teachers/EditTeacherFailModal.vue";
import { useTeachersStore } from "@/store/teachers";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import { useMeta } from "vue-meta";

export default {
  data() {
    return {
      form: {
        email: "",
        password: null,
        first_name: "",
        last_name: "",
        phone: "",
        fonction: "",
        youtube_link: "",
        percentage: 0,
        balance: 0,
        cover: null,
        affiliation_max: 0,
        affiliation_percentage: 0,
      },
      showPassword: false,
    };
  },

  async mounted() {
    const teacherId = this.$route.params.id;
    await this.teachersStore.getSingleTeacher(teacherId, false);
    this.form = {
      email: this.teachersStore.singleTeacher.user.email,
      first_name: this.teachersStore.singleTeacher.user.first_name,
      last_name: this.teachersStore.singleTeacher.user.last_name,
      phone: this.teachersStore.singleTeacher.user.phone,
      fonction: this.teachersStore.singleTeacher.function,
      youtube_link: this.teachersStore.singleTeacher.youtube_link,
      percentage: this.teachersStore.singleTeacher.percentage,
      balance: this.teachersStore.singleTeacher.balance,
      affiliation_max: this.teachersStore.singleTeacher.affiliation_max,
      affiliation_percentage:
        this.teachersStore.singleTeacher.affiliation_percentage,
      cover: this.teachersStore.singleTeacher.user.image
        ? this.teachersStore.singleTeacher.user.image.url
        : null,
    };
    if (this.teachersStore.singleTeacher.user.image) {
      this.$refs.output.src = this.teachersStore.singleTeacher.user.image.url;
      this.$refs.output.width = 200;
    }
  },

  setup() {
    useMeta({
      title: "Edit teacher",
      htmlAttrs: { lang: "en", amp: true },
    });
    const teachersStore = useTeachersStore();
    return { v$: useVuelidate(), teachersStore, trash };
  },

  methods: {
    handleCoverChange(event) {
      this.form.cover = event.target.files[0];
      let outputPic = this.$refs.output;
      // this.categoriesStore.editedCategory.image = this.cover;
      outputPic.src = URL.createObjectURL(this.form.cover);
      outputPic.width = 200;
    },

    removeImage() {
      this.$refs.output.src = null;
      this.form.cover = null;
      // this.categoriesStore.editedCategory.image = null;
    },

    async save() {
      try {
        const result = await this.v$.$validate();
        if (!result) {
          return;
        } else {
          let formData = new FormData();
          formData.append("email", this.form.email);
          formData.append("first_name", this.form.first_name);
          formData.append("last_name", this.form.last_name);
          formData.append("function", this.form.fonction);
          formData.append("phone", this.form.phone);
          formData.append("percentage", this.form.percentage);
          formData.append("youtube_link", this.form.youtube_link);
          formData.append("balance", this.form.balance);
          formData.append("affiliation_max", this.form.affiliation_max);
          formData.append(
            "affiliation_percentage",
            this.form.affiliation_percentage
          );
          console.log(typeof this.form.cover);
          // if (typeof this.form.cover == "string") {
          // }
          formData.append("image", this.form.cover);
          const teacherId = this.$route.params.id;
          await this.teachersStore.editTeacher(formData, teacherId);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  validations() {
    return {
      form: {
        email: {
          required: helpers.withMessage("Email is required", required),
          email: helpers.withMessage("Email is invalid", email),
        },

        first_name: {
          required: helpers.withMessage("First name is required", required),
          minLength: helpers.withMessage(
            "First name must be longer then 3 characters",
            minLength(3)
          ),
        },
        last_name: {
          required: helpers.withMessage("Last name is required", required),
          minLength: helpers.withMessage(
            "Last name must be longer then 3 characters",
            minLength(3)
          ),
        },
        phone: {
          required: helpers.withMessage("Phone is required", required),
          regex: helpers.withMessage(
            "Invalid phone number",
            helpers.regex(/^(05|06|07)[0-9]{8}$/)
          ),
        },
        fonction: {
          required: helpers.withMessage("Fonction is required", required),
          minLength: helpers.withMessage(
            "Fonction must be longer then 3 characters",
            minLength(3)
          ),
        },
        youtube_link: {
          regex: helpers.withMessage(
            "Invalid youtube link",
            helpers.regex(/https:\/\/www\.youtube\.com\/(.+)/)
          ),
        },
        percentage: {
          required: helpers.withMessage("Percentage is required", required),
          integer: helpers.withMessage(
            "Percentage must be an integer",
            integer
          ),
          betweenValue: helpers.withMessage(
            "Percentage must be superior than 0 and inferior than 100",
            between(0, 100)
          ),
        },
        balance: {
          required: helpers.withMessage("Balance is required", required),
          decimal: helpers.withMessage("Balance must be a decimal", decimal),
          minValueValue: helpers.withMessage(
            "Balance must be superior than 0",
            minValue(0)
          ),
        },
        affiliation_max: {
          required: helpers.withMessage(
            "affiliation max is required",
            required
          ),
          integer: helpers.withMessage(
            "this value must be an integer",
            integer
          ),
          betweenValue: helpers.withMessage(
            "Percentage must be superior than 0 and inferior than 100",
            between(0, 100)
          ),
        },
        affiliation_percentage: {
          required: helpers.withMessage(
            "affiliation percentage max is required",
            required
          ),
          integer: helpers.withMessage(
            "this value must be an integer",
            integer
          ),
          betweenValue: helpers.withMessage(
            "affiliation percentage must be superior than 0 and inferior than 100",
            between(0, 100)
          ),
        },
        cover: { required: helpers.withMessage("Cover is required", required) },
      },
    };
  },
  components: { EditTeacherSuccessModal, EditTeacherFailModal, LoaderModal },
};
</script>

<style scoped>
.upload-block {
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A0A0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
