<template>
  <div>
    <div class="relative min-w-0 break-words w-full mb-6 shadow-md mt-4 rounded-xl border border-[#e8e8e8c9] bg-white">
      <LoaderModal v-if="offersStore.isLoadingModal" />
      <div class="block w-full overflow-x-auto">
        <div v-if="!offersStore?.promoCodes.length > 0" class="flex flex-col justify-center items-center py-10">
          <img :src="noRecords" alt="noRecords" class="w-2/6" />
          <div class="text-xl text-[#747373]">
            No promo codes at the moment !
          </div>
        </div>
        <table class="items-center w-full bg-transparent border border-[#e8e8e8c9] rounded-xl overflow-hidden" v-else>
          <thead class="">
            <tr class="border-[#e8e8e8c9] border">
              <th class="text-purple px-4 align-middle py-6 font-medium text-left">
                Promo code title
              </th>
              <th class="text-purple px-4 align-middle py-6 font-medium text-left">
                Code
              </th>
              <th class="text-purple px-4 align-middle py-6 font-medium text-left">
                Discount
              </th>
              <th class="text-purple px-4 align-middle py-6 font-medium text-left">
                Expiration date
              </th>
              <th class="text-purple px-4 align-middle py-6 font-medium text-center">
                Status
              </th>
              <th class="text-purple px-4 align-middle py-6 font-medium text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(promoCode, index) in offersStore.promoCodes" :key="index">
              <td class="align-middle p-4 text-black flex items-center">
                <p>{{ promoCode.title }}</p>
              </td>

              <td class="align-middle whitespace-nowrap text-left">
                <p>{{ promoCode.code }}</p>
              </td>

              <td class="align-middle whitespace-nowrap p-4 text-left">
                <p>{{ promoCode.discount }}</p>
              </td>
              <td class="align-middle p-4 text-left">
                <p>{{ promoCode.expiration_date.slice(0, 10) }}</p>
              </td>

              <td class="align-middle text-center">
                <div :class="promoCode.status == 'active' ? 'text-cyan' : 'text-yellow'
                  " class="rounded-md px-4 w-8/12 mx-auto my-2">
                  {{ promoCode.status }}
                </div>
              </td>
              <td class="align-middle text-center">
                <PromoCodesDropdown :offer="promoCode" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="flex justify-between items-center px-2 py-2">
      <div>
        Showing {{ offersStore.from }}-{{ offersStore.to }} from
        {{ offersStore.totalRecords }} data
      </div>
      <div class="flex items-center">
        <div v-if="offersStore.currentPage != 1">
          <img @click="offersStore.previousPage()" :src="forward" class="cursor-pointer" alt="forward" />
        </div>

        <div class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12" :class="true
          ? 'bg-cyan text-white border-cyan'
          : ' text-gray border border-gray'
          ">
          {{ offersStore.currentPage }}
        </div>

        <div v-if="offersStore.currentPage + 1 <= offersStore.totalPages">
          <img class="cursor-pointer" @click="offersStore.nextPage()" :src="back" alt="back" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import checkbox from "@/assets/icons/cards/checkbox.svg";
import separator from "@/assets/icons/cards/separator.svg";
import deleteReview from "@/assets/icons/cards/deleteReview.svg";
import avatarUser from "@/assets/icons/cards/avatarUser.svg";
import back from "@/assets/icons/cards/back.svg";
import forward from "@/assets/icons/cards/forward.svg";
import noRecords from "@/assets/images/noRecords.svg";
import PromoCodesDropdown from "../dropdowns/PromoCodesDropdown.vue";
import { useOffersStore } from "@/store/offers";
import LoaderModal from "../modals/LoaderModal.vue";
export default {
  components: { PromoCodesDropdown, LoaderModal },
  data() {
    return {};
  },
  setup() {
    const offersStore = useOffersStore();
    return {
      back,
      forward,
      checkbox,
      avatarUser,
      deleteReview,
      separator,
      offersStore,
      noRecords,
    };
  },
  async mounted() {
    this.offersStore.showDeleteModal = false;
    await this.offersStore.getPromoCodes();
  },
};
</script>
