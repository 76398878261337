<template>
  <div
    class="relative min-w-0 break-words w-full mb-6 shadow-lg mt-4 rounded-xl border border-[#e8e8e8c9] bg-white"
  >
    <LoaderModal v-if="affiliationsStore.isLoadingModal" />
    <div class="block w-full overflow-x-auto">
      <!-- News table -->
      <table
        class="items-center w-full bg-transparent border border-[#e8e8e8c9] rounded-xl overflow-hidden"
        v-if="affiliationsStore.affiliations.length > 0"
      >
        <thead class="">
          <tr class="border-[#e8e8e8c9] border">
            <th
              class="text-purple px-4 flex items-center align-middle py-6 uppercase -0 font-medium text-left"
            >
              User name
            </th>
            <th
              class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
            >
              Phone number
            </th>
            <th
              class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
            >
              Email
            </th>
            <th
              class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
            >
              Balance from affiliation
            </th>
            <th
              class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
            >
              Type
            </th>

            <th
              class="text-purple px-4 align-middle py-6 uppercase font-medium text-center"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(affiliation, index) in affiliationsStore.affiliations"
            :key="index"
            :class="affiliation.user.status == 'inactive' ? 'bg-red-100' : ''"
          >
            <td class="align-middle p-4 text-purple flex items-center">
              <img
                :src="
                  affiliation.user.image
                    ? affiliation.user.image.url
                    : avatarUser
                "
                class="w-10 h-10 mr-2"
                alt="avatar"
              />
              {{
                affiliation.user.first_name + " " + affiliation.user.last_name
              }}
            </td>
            <td class="align-middle p-4">{{ affiliation.user.phone }}</td>
            <td class="align-middle p-4">{{ affiliation.user.email }}</td>
            <td class="align-middle p-4">{{ affiliation.balance }} DA</td>

            <td class="align-middle whitespace-nowrap p-4">
              {{ affiliation.user.type }}
            </td>

            <td class="align-middle text-right">
              <AffiliationDropdown
                :affiliation="{
                  id: affiliation.user.id,
                  status: affiliation.user.status,
                }"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="flex flex-col justify-center items-center py-10">
        <img :src="noRecords" alt="noRecords" class="w-2/6" />
        <div class="text-xl text-[#747373]">No records at the moment !</div>
      </div>
    </div>
  </div>
</template>
<script>
import checkbox from "@/assets/icons/cards/checkbox.svg";
import separator from "@/assets/icons/cards/separator.svg";
import deleteReview from "@/assets/icons/cards/deleteReview.svg";
import avatarUser from "@/assets/icons/cards/avatarUser.svg";
import noRecords from "@/assets/images/noRecords.svg";
import { useAffiliationsStore } from "@/store/affiliations";
import AffiliationDropdown from "../dropdowns/AffiliationDropdown.vue";
import LoaderModal from "../modals/LoaderModal.vue";

export default {
  components: { AffiliationDropdown, LoaderModal },
  data() {
    return {};
  },
  setup() {
    const affiliationsStore = useAffiliationsStore();
    return {
      checkbox,
      avatarUser,
      deleteReview,
      separator,
      affiliationsStore,
      noRecords,
    };
  },

  async mounted() {
    await this.affiliationsStore.getAffiliations();
  },

  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
