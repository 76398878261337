import { defineStore } from "pinia";
import axios from "@/axios/axios";

export const useDestinationsStore = defineStore("destinationsStore", {
  state: () => ({
    destinations: [],
    lastestDestinations: [],
    selectedDestination: null,
    coursesCount: 0,
    itemsPerPage: 10,
    totalPages: 0,
    to: 0,
    from: 0,
    currentPage: 1,
    isLoadingModal: false,
    isFailModal: false,
    pauseError: false,
    addError: false,
    addSuccess: false,
    isUpdateError: false,
    isUpdateSuccess: false,
    isShowEditor: false,
    errorMessage: "",
    editedDestinations: null,
    showDeleteModal: false,
  }),
  actions: {
    async getDestinations() {
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}auth/admin/destination`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.destinations = data.data;
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoadingModal = false;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getDestinationss();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getDestinationss();
      }
    },
  },
});
