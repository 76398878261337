import { defineStore } from "pinia";
import axios from "@/axios/axios";

export const useNotificationsStore = defineStore("notificationsStore", {
  state: () => ({
    notifications: [],
    isLoading: false,
    isFail: false,
    isSuccess: false,
    errorMessage: "",
    errorMessages: [],
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    to: 0,
    from: 0,
    totalRecords: 0,
  }),
  actions: {
    async getNotifications() {
      try {
        this.isLoading = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}auth/admin/notifications?paginate=true&page=${this.currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.notifications = [...this.notifications, ...data.data.data];
        this.totalPages = data.data.last_page;
        this.currentPage = data.data.current_page;
        this.totalRecords = data.data.total;
        this.from = data.data.from;
        this.to = data.data.to;
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    async addNotification(notification) {
      this.errorMessage = null;
      this.isFail = false;
      this.isLoading = true;

      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}notification/admin/send`,
          notification,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoading = false;
        this.isSuccess = true;
        this.getNotifications();
      } catch (err) {
        this.errorMessages = [];
        console.log(err);
        this.isFail = true;
        this.isLoading = false;
        this.errorMessage = err.response.data.errors;
        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
      }
    },
    async updateNotification(notificationId) {
      this.isLoading = true;
      this.pauseError = false;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const formData = new FormData();
        formData.append("ids[]", notificationId);
        const currentDate = new Date();
        const isoFormattedDate = currentDate.toISOString();

        this.notifications.map((notif) => {
          notif.id == notificationId
            ? (notif.read_at = isoFormattedDate)
            : null;
        });

        const x = await axios.post(
          `${process.env.VUE_APP_API_BASE}auth/admin/notifications/read`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(x);
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        this.pauseError = true;
        console.log(err);
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getNotifications();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getNotifications();
      }
    },
  },
});
