<template>
  <div
    class="relative min-w-0 break-words w-full mb-6 shadow-lg mt-4 rounded-xl border border-[#e8e8e8c9] bg-white"
  >
    <LoaderModal v-if="ticketStore.isLoadingModal" />

    <div class="block w-full overflow-x-auto">
      <div
        v-if="ticketStore.tickets.length == 0"
        class="flex flex-col justify-center items-center py-10"
      >
        <img :src="noRecords" alt="noRecords" class="w-2/6" />
        <div class="text-xl text-[#747373]">No tickets at the moment !</div>
      </div>

      <table
        class="items-center w-full bg-transparent border border-[#e8e8e8c9] rounded-xl overflow-hidden"
        v-else
      >
        <thead>
          <tr class="border-[#e8e8e8c9] border">
            <th
              class="text-purple px-4 align-middle py-6 uppercase -0 font-medium text-left"
            >
              Teacher’s name
            </th>
            <th
              class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
            >
              Service
            </th>
            <th
              class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
            >
              Object
            </th>
            <th
              class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
            >
              Importance
            </th>
            <th
              class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
            >
              Last update
            </th>
            <th
              class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
            >
              Status
            </th>
            <th
              class="text-purple px-4 align-middle py-6 uppercase font-medium text-center"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(ticket, index) in ticketStore.tickets" :key="index">
            <td class="align-middle p-4 text-black">
              <div class="flex items-center">
                <img
                  v-if="!ticket.teacher.user.image?.url"
                  :src="avatarUser"
                  class="w-10 h-10 mr-2"
                  alt="dfsd"
                />
                <img
                  v-if="ticket.teacher.user.image?.url"
                  :src="ticket.teacher.user.image?.url"
                  class="w-10 h-10 mr-2"
                  alt="dfsd"
                />
                {{
                  ticket.teacher.user.first_name +
                  " " +
                  ticket.teacher.user.last_name
                }}
              </div>
            </td>
            <td class="align-middle p-4 text-black">{{ ticket.service }}</td>
            <td class="align-middle p-4 text-black">
              {{ ticket.subject }}
            </td>
            <td
              v-if="ticket.importance == 'normal'"
              class="align-middle p-4 text-left text-cyan"
            >
              {{ ticket.importance.toUpperCase() }}
            </td>
            <td
              v-if="ticket.importance == 'important'"
              class="align-middle p-4 text-left text-orange-500"
            >
              {{ ticket.importance.toUpperCase() }}
            </td>
            <td
              v-if="ticket.importance == 'urgent'"
              class="align-middle p-4 text-left text-red-500 blink"
            >
              {{ ticket.importance.toUpperCase() }}
            </td>
            <td class="align-middle p-4 text-left">
              {{ ticket.updated_at.slice(0, 10) }}
            </td>
            <td
              class="align-middle whitespace-wrap p-4"
              :class="ticket.status == 'open' ? 'text-cyan' : 'text-red-500'"
            >
              {{ ticket.status }}
            </td>
            <td class="align-middle whitespace-wrap p-4">
              <div class="flex items-center justify-center">
                <router-link :to="`/admin/messages/${ticket.id}`">
                  <img :src="showMessage" class="w-10 h-10 mr-2" alt="dfsd" />
                </router-link>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex justify-between items-center px-2 py-2">
      <div>
        Showing {{ ticketStore.from }}-{{ ticketStore.to }} from
        {{ ticketStore.totalRecords }} data
      </div>
      <div class="flex items-center">
        <div v-if="ticketStore.currentPage != 1">
          <img
            @click="ticketStore.previousPage()"
            :src="forward"
            class="cursor-pointer"
            alt="forward"
          />
        </div>

        <div
          class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12"
          :class="
            true
              ? 'bg-cyan text-white border-cyan'
              : ' text-gray border border-gray'
          "
        >
          {{ ticketStore.currentPage }}
        </div>

        <div v-if="ticketStore.currentPage + 1 <= ticketStore.totalPages">
          <img
            class="cursor-pointer"
            @click="ticketStore.nextPage()"
            :src="back"
            alt="back"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import checkbox from "@/assets/icons/cards/checkbox.svg";
import back from "@/assets/icons/cards/back.svg";
import forward from "@/assets/icons/cards/forward.svg";

import separator from "@/assets/icons/cards/separator.svg";
import showMessage from "@/assets/icons/cards/showMessage.svg";
import avatarUser from "@/assets/icons/cards/avatarUser.svg";
import noRecords from "@/assets/images/noRecords.svg";
import { useTicketStore } from "@/store/tickets";
import LoaderModal from "../modals/LoaderModal.vue";
export default {
  components: { LoaderModal },
  data() {
    return {};
  },
  setup() {
    const ticketStore = useTicketStore();
    return {
      checkbox,
      avatarUser,
      showMessage,
      separator,
      ticketStore,
      noRecords,
      forward,
      back,
    };
  },
  async mounted() {
    await this.ticketStore.getTickets();
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
<style>
.blink {
  animation: blink-live 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes blink-live {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
