<template>
  <div
    class="flex justify-center items-center p-2 rounded-xl"
    :class="isSelected ? 'bg-[#00DDAF]' : 'bg-white'"
  >
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 6H2.5V4H18.5V6ZM16.5 0H4.5V2H16.5V0ZM20.5 10V18C20.5 18.5304 20.2893 19.0391 19.9142 19.4142C19.5391 19.7893 19.0304 20 18.5 20H2.5C1.97005 19.9984 1.46227 19.7872 1.08753 19.4125C0.712805 19.0377 0.501583 18.5299 0.5 18V10C0.501583 9.47005 0.712805 8.96227 1.08753 8.58753C1.46227 8.2128 1.97005 8.00158 2.5 8H18.5C19.0299 8.00158 19.5377 8.2128 19.9125 8.58753C20.2872 8.96227 20.4984 9.47005 20.5 10ZM12.427 15.042L14.75 13.056L11.691 12.8L10.5 10L9.309 12.8L6.25 13.056L8.573 15.042L7.873 18L10.5 16.428L13.127 18L12.427 15.042Z"
        :fill="color"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: ["color", "isSelected"],
};
</script>
<style></style>
