<template>
  <div>
    <metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | RAYHANE` : `SITE_NAME`
      }}</template>
    </metainfo>
    <div class="flex flex-wrap mt-4">
      <div class="w-full px-4">
        <div class="my-6 mt-24">
          <div class="flex items-center">
            <div
              class="font-semibold text-lg mr-3 flex items-center cursor-pointer"
              :class="
                reviewsTeachersAndCoursesStore.showReviewsTeachers
                  ? 'text-black border-b-4 border-black py-2'
                  : 'text-gray '
              "
              @click="reviewsTeachersAndCoursesStore.showReviewsTeachers = true"
            >
              Reviews about teachers
              <span
                class="text-white bg-cyan font-semibold flex items-center justify-center p-1 rounded-xl mx-1 h-8 w-8"
              >
                {{
                  reviewsTeachersAndCoursesStore.teachersReviews.length
                }}</span
              >
            </div>
            <div
              @click="
                reviewsTeachersAndCoursesStore.showReviewsTeachers = false
              "
              :class="
                !reviewsTeachersAndCoursesStore.showReviewsTeachers
                  ? 'text-black  border-b-4 border-black py-2'
                  : 'text-gray '
              "
              class="font-semibold text-lg mr-3 flex items-center cursor-pointer"
            >
              Reviews about courses
              <span
                class="text-white bg-cyan font-semibold flex items-center justify-center p-1 rounded-xl mx-1 h-8 w-8"
              >
                {{ reviewsTeachersAndCoursesStore.coursesReviews.length }}</span
              >
            </div>
          </div>
          <ReviewsTeachersTable
            v-if="reviewsTeachersAndCoursesStore.showReviewsTeachers"
          />
          <ReviewsCoursesTable
            v-if="!reviewsTeachersAndCoursesStore.showReviewsTeachers"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import back from "@/assets/icons/cards/back.svg";
import forward from "@/assets/icons/cards/forward.svg";
import ReviewsCoursesTable from "@/components/tables/ReviewsCoursesTable.vue";
import ReviewsTeachersTable from "@/components/tables/ReviewsTeachersTable.vue";
import { useReviewsTeachersAndCourses } from "@/store/reviewsTeachersAndCourses";
import { useMeta } from "vue-meta";
export default {
  components: { ReviewsTeachersTable, ReviewsCoursesTable },
  setup() {
    useMeta({
      title: "Reviews",
      htmlAttrs: { lang: "en", amp: true },
    });
    const reviewsTeachersAndCoursesStore = useReviewsTeachersAndCourses();
    return { forward, back, reviewsTeachersAndCoursesStore };
  },
  async mounted() {
    await this.reviewsTeachersAndCoursesStore.getTeachersReviews();
    await this.reviewsTeachersAndCoursesStore.getCoursesReviews();
  }
};
</script>
<style></style>
