<template>
  <div
    class="flex justify-center items-center p-2 rounded-xl"
    :class="isSelected ? 'bg-[#00DDAF]' : 'bg-white'"
  >
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.1 1.59999C20.0999 1.39552 20.0476 1.19447 19.9479 1.01592C19.8483 0.837366 19.7047 0.687231 19.5308 0.579761C19.3568 0.472291 19.1583 0.41105 18.954 0.40185C18.7498 0.39265 18.5465 0.435796 18.3636 0.527194L9.01562 5.19999H4.50002C3.54525 5.19999 2.62957 5.57928 1.95444 6.25441C1.27931 6.92954 0.900024 7.84522 0.900024 8.79999C0.900024 9.75477 1.27931 10.6704 1.95444 11.3456C2.62957 12.0207 3.54525 12.4 4.50002 12.4H4.83602L6.96123 18.7792C7.04084 19.0182 7.19369 19.2262 7.39808 19.3735C7.60248 19.5208 7.84807 19.6001 8.10002 19.6H9.30002C9.61828 19.6 9.92351 19.4736 10.1486 19.2485C10.3736 19.0235 10.5 18.7183 10.5 18.4V13.1416L18.3636 17.0728C18.5465 17.1642 18.7498 17.2073 18.954 17.1981C19.1583 17.1889 19.3568 17.1277 19.5308 17.0202C19.7047 16.9128 19.8483 16.7626 19.9479 16.5841C20.0476 16.4055 20.0999 16.2045 20.1 16V1.59999Z"
        :fill="color"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: ["color", "isSelected"],
};
</script>
<style></style>
