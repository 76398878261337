<template>
  <div>
    <LoaderModal v-if="groupsStore.isLoadingModal" />
    <div
      class="relative min-w-0 break-words w-full mb-6 shadow-md mt-4 rounded-xl border border-[#e8e8e8c9] bg-white"
    >
      <div class="block w-full overflow-x-auto">
        <table
          class="items-center w-full bg-transparent border border-[#e8e8e8c9] rounded-xl overflow-hidden"
          v-if="groupsStore.groups.length > 0"
        >
          <thead>
            <tr class="border-[#e8e8e8c9] border">
              <th
                class="text-purple pl-4 align-middle py-6 uppercase font-medium text-left"
              >
                Name
              </th>
              <th
                class="text-purple pl-4 align-middle py-6 uppercase font-medium text-left"
              >
                Time
              </th>
              <th
                class="text-purple pl-4 align-middle py-6 uppercase font-medium text-left"
              >
                Teacher
              </th>
              <th
                class="text-purple pl-4 align-middle py-6 uppercase font-medium text-left"
              >
                Subject
              </th>
              <th
                class="text-purple pl-4 align-middle py-6 uppercase font-medium text-left"
              >
                Grade
              </th>
              <th
                class="text-purple pl-4 align-middle py-6 uppercase font-medium text-left"
              >
                Option
              </th>
              <th
                class="text-purple pl-4 align-middle py-6 uppercase font-medium text-left"
              >
                Price per session
              </th>
              <th
                class="text-purple pl-4 align-middle py-6 uppercase font-medium text-left"
              >
                Prof percentage
              </th>
              <th
                class="text-purple pl-4 align-middle py-6 uppercase font-medium text-left"
              >
                Session per month
              </th>
              <th
                class="text-purple pl-4 align-middle py-6 uppercase font-medium text-left"
              >
                Occupied seats
              </th>
              <th
                class="text-purple pl-4 align-middle py-6 uppercase font-medium text-left"
              >
                Max seats
              </th>
              <th
                class="text-purple pl-4 align-middle py-6 uppercase font-medium text-center"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(group, index) in groupsStore.groups" :key="index">
              <td class="align-middle p-4 text-purple font-medium">
                <p>{{ group.name }}</p>
              </td>
              <td class="align-middle whitespace-nowrap p-4">
                <p>{{ group.group_date }}</p>
              </td>
              <td class="align-middle whitespace-nowrap text-left">
                <p>
                  {{
                    group?.teacher?.user.first_name +
                    " " +
                    group?.teacher?.user.last_name
                  }}
                </p>
              </td>
              <td class="align-middle whitespace-nowrap p-4">
                <p>{{ group.module?.name }}</p>
              </td>
              <td class="align-middle whitespace-nowrap p-4">
                <p>{{ group.study_year }}</p>
              </td>
              <td class="align-middle whitespace-nowrap p-4">
                <div
                  v-for="(option, index) in group.option"
                  :key="index"
                  className="inline-flex items-center rounded-full bg-lime-300 me-1 px-3 py-1 text-sm font-medium text-muted-foreground"
                >
                  <span className="text-xs font-medium">{{ option.name }}</span>
                </div>
              </td>
              <td class="align-middle whitespace-nowrap p-4">
                <p>{{ group.price_per_session }} DA</p>
              </td>
              <td class="align-middle whitespace-nowrap p-4">
                <p>{{ group.prof_percentage }} %</p>
              </td>
              <td class="align-middle whitespace-nowrap p-4">
                <p>{{ group.sessions_per_month }}</p>
              </td>
              <td class="align-middle whitespace-nowrap p-4">
                <p>{{ group.seats_occupied }}</p>
              </td>
              <td class="align-middle whitespace-nowrap p-4">
                <p>{{ group.max_seats }}</p>
              </td>
              <td class="align-middle whitespace-nowrap p-4">
                <GroupsDropdown :groupId="group.id" />
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="flex flex-col justify-center items-center py-10">
          <img :src="noRecords" alt="noRecords" class="w-2/6" />
          <div class="text-xl text-[#747373]">No records at the moment!</div>
        </div>
      </div>
    </div>
    <div class="flex justify-between items-center">
      <div>
        Showing {{ groupsStore.from }}-{{ groupsStore.to }} of
        {{ groupsStore.totalPages }} items
      </div>
      <div class="flex items-center">
        <div v-if="groupsStore.currentPage != 1">
          <img
            @click="groupsStore.previousPage()"
            :src="forward"
            class="cursor-pointer"
            alt="forward"
          />
        </div>
        <div
          v-if="groupsStore.currentPage > 1"
          class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
          :class="{
            'text-gray border border-gray': true,
            'bg-cyan text-white border-cyan': false,
          }"
          @click="groupsStore.previousPage()"
        >
          {{ groupsStore.currentPage - 1 }}
        </div>

        <div
          class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12"
          :class="
            true
              ? 'bg-cyan text-white border-cyan'
              : 'text-gray border border-gray'
          "
        >
          {{ groupsStore.currentPage }}
        </div>

        <div
          v-if="
            groupsStore.currentPage + 1 <=
            Math.ceil(groupsStore.totalPages / groupsStore.itemsPerPage)
          "
          class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
          :class="{
            'text-gray border border-gray': true,
            'bg-cyan text-white border-cyan': false,
          }"
          @click="groupsStore.nextPage()"
        >
          {{ groupsStore.currentPage + 1 }}
        </div>

        <div
          v-if="
            groupsStore.currentPage + 1 <=
            Math.ceil(groupsStore.totalPages / groupsStore.itemsPerPage)
          "
        >
          <img
            class="cursor-pointer"
            @click="groupsStore.nextPage()"
            :src="back"
            alt="back"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import avatarUser from "@/assets/icons/cards/avatarUser.svg";
import back from "@/assets/icons/cards/back.svg";
import checkbox from "@/assets/icons/cards/checkbox.svg";
import deleteReview from "@/assets/icons/cards/deleteReview.svg";
import forward from "@/assets/icons/cards/forward.svg";
import separator from "@/assets/icons/cards/separator.svg";
import noRecords from "@/assets/images/noRecords.svg";
import { useGroupsStore } from "@/store/groups";
import GroupsDropdown from "../dropdowns/GroupsDropdown.vue";
import LoaderModal from "../modals/LoaderModal.vue";

export default {
  components: {
    GroupsDropdown,
    LoaderModal,
  },
  data() {
    return {
      groups: [],
    };
  },
  async mounted() {
    await this.groupsStore.getGroups();
  },
  setup() {
    const groupsStore = useGroupsStore();
    return {
      back,
      forward,
      checkbox,
      avatarUser,
      deleteReview,
      separator,
      groupsStore,
      noRecords,
    };
  },

  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
