<template>
  <div class="user-actions w-10 mx-auto">
    <a
      class="text-blueGray-500 acceptIcon"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <div class="items-center flex justify-center">
        <img :src="actions" alt="actions" />
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-xl border border-[#e3e3e3] min-w-[140px]"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        acceptIcon: dropdownPopoverShow,
      }"
    >
      <a
        href="javascript:void(0);"
        v-if="payment.status == 'unpaid'"
        @click="showAcceptModal()"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="acceptIcon" class="mr-2" alt="acceptIcon" />

        Accept
      </a>
      <a
        href="javascript:void(0);"
        v-if="payment.status == 'unpaid'"
        @click="showRejectModal()"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="refuseIcon" class="mr-2" alt="refuseIcon" />

        Refuse
      </a>

      <a v-if="payment.passbook?.url" :href="payment.passbook?.url"
        target="_blank"
        class="text-sm py-2 px-4 font-normal flex items-center w-full hover:bg-slate-200"
        :to="'#'"
      >
        <img :src="showReceiptIcon" class="mr-2" alt="acceptIcon" />
        Show receipt
      </a>
      <p v-else class="text-sm py-2 px-4 font-normal flex items-center w-full hover:bg-slate-200">
        No receipt found!
      </p>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import actions from "@/assets/icons/cards/threeDots.svg";
import editIcon from "@/assets/icons/cards/editIcon.svg";
import reviewsIcon from "@/assets/icons/cards/reviewsIcon.svg";
import deleteIcon from "@/assets/icons/cards/deleteIcon.svg";
import showReceiptIcon from "@/assets/icons/cards/showReceiptIcon.svg";
import acceptIcon from "@/assets/icons/cards/acceptIcon.svg";
import refuseIcon from "@/assets/icons/cards/refuseIcon.svg";
import { usePaymentsStore } from "@/store/payments";

export default {
  props: ["payment"],
  data() {
    return {
      dropdownPopoverShow: false,
      showBlockModal: false,
      showUnBlockModal: false,
    };
  },
  setup() {
    const paymentsStore = usePaymentsStore();
    return {
      refuseIcon,
      showReceiptIcon,
      acceptIcon,
      reviewsIcon,
      paymentsStore,
      actions,
      editIcon,
      deleteIcon,
    };
  },
  methods: {
    showRejectModal() {
      this.paymentsStore.rejectedPaymentId = this.payment.id;
      this.paymentsStore.showRejectModal = true;
      console.log(this.paymentsStore.rejectedPaymentId);
    },
    showAcceptModal() {
      this.paymentsStore.acceptedPaymentId = this.payment.id;
      this.paymentsStore.showAcceptModal = true;
      console.log(this.paymentsStore.acceptedPaymentId);
    },
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        const dropdowns = document.querySelectorAll(".user-actions");
        // Add click event listener to each dropdown component
        dropdowns.forEach(function (dropdown) {
          dropdowns.forEach(() => {
            dropdown.children[1].classList.add("hidden");
          });
        });
        this.$refs.popoverDropdownRef.classList.remove("hidden");

        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-end",
        });
      }
    },
  },
};
</script>
