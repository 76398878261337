<template>
  <div
    class="flex justify-center items-center p-2 rounded-xl"
    :class="isSelected ? 'bg-[#00DDAF]' : 'bg-white'"
  >
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8086 9.84399H12.6562C12.0968 9.84399 11.5603 9.62176 11.1647 9.22617C10.7691 8.83059 10.5469 8.29406 10.5469 7.73462V1.58228C10.5469 1.53566 10.5284 1.49094 10.4954 1.45798C10.4624 1.42501 10.4177 1.40649 10.3711 1.40649H6.32812C5.5822 1.40649 4.86683 1.70281 4.33939 2.23026C3.81194 2.7577 3.51563 3.47307 3.51562 4.21899V18.2815C3.51563 19.0274 3.81194 19.7428 4.33939 20.2702C4.86683 20.7977 5.5822 21.094 6.32812 21.094H16.1719C16.9178 21.094 17.6332 20.7977 18.1606 20.2702C18.6881 19.7428 18.9844 19.0274 18.9844 18.2815V10.0198C18.9844 9.97315 18.9659 9.92844 18.9329 9.89548C18.8999 9.86251 18.8552 9.84399 18.8086 9.84399Z"
        :fill="color"
      />
      <path
        d="M18.4228 8.289L12.103 1.96922C12.0907 1.95701 12.0751 1.94869 12.0581 1.94533C12.0411 1.94197 12.0234 1.94371 12.0074 1.95033C11.9914 1.95696 11.9777 1.96816 11.9681 1.98255C11.9584 1.99694 11.9532 2.01386 11.9531 2.03119V7.73573C11.9531 7.92221 12.0272 8.10105 12.1591 8.23291C12.2909 8.36477 12.4698 8.43885 12.6562 8.43885H18.3608C18.3781 8.43878 18.395 8.43359 18.4094 8.42392C18.4238 8.41426 18.435 8.40056 18.4416 8.38455C18.4483 8.36853 18.45 8.35092 18.4466 8.33392C18.4433 8.31692 18.435 8.30129 18.4228 8.289Z"
        :fill="color"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: ["color", "isSelected"],
};
</script>
<style></style>
