<template>
  <div class="user-actions w-10 mx-auto">
    <a
      class="text-blueGray-500 block"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <div class="items-center flex justify-center">
        <img :src="actions" alt="actions" />
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-xl border border-[#e3e3e3] min-w-[140px]"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <router-link
        class="text-sm py-2 px-4 font-normal flex items-center w-full hover:bg-slate-200"
        :to="'/admin/groups/' + groupId"
      >
        <img :src="editIcon" class="mr-2" alt="editIcon" />
        Edit
      </router-link>
      <a
        href="javascript:void(0);"
        @click="toggleDelete(groupId)"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="deleteIcon" class="mr-2" alt="block" />
        Delete
      </a>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import actions from "@/assets/icons/cards/threeDots.svg";
import editIcon from "@/assets/icons/cards/editIcon.svg";
import reviewsIcon from "@/assets/icons/cards/reviewsIcon.svg";
import deleteIcon from "@/assets/icons/cards/deleteIcon.svg";
// import { useCoursesStore } from "@/store/courses";
import { useGroupsStore } from "@/store/groups";

export default {
  props: ["groupId"],
  data() {
    return {
      dropdownPopoverShow: false,
      showBlockModal: false,
      showUnBlockModal: false,
    };
  },
  setup() {
    // const coursesStore = useCoursesStore();
    const groupsStore = useGroupsStore();
    return {
      reviewsIcon,
      // coursesStore,
      groupsStore,
      actions,
      editIcon,
      deleteIcon,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        const dropdowns = document.querySelectorAll(".user-actions");
        dropdowns.forEach(function (dropdown) {
          dropdowns.forEach(() => {
            dropdown.children[1].classList.add("hidden");
          });
        });
        this.$refs.popoverDropdownRef.classList.remove("hidden");

        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-end",
        });
      }
    },

    toggleDelete(groupId) {
      this.groupsStore.showDeleteModal = true;
      this.$swal({
        title: "Are you sure you want to delete this group?",
        showCancelButton: true,
        confirmButtonColor: "#00DDAF",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm!",
        cancelButtonText: "Cancel!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.groupsStore.deleteGroup(groupId).then(() => {
            if (this.groupsStore.deleteError) {
              this.$swal("something wrong happened try again !", "", "warning");
            } else {
              this.dropdownPopoverShow = false;
              this.$swal("Group deleted successfully!", "", "success");
            }
          });
        }
      });
    },
  },
};
</script>
