<template>
  <div></div>
</template>
<script>
import router from "@/router";
import { useTeachersStore } from "@/store/teachers";
export default {
  setup() {
    const teacherStore = useTeachersStore();
    const { isAddTeacherFail } = useTeachersStore();
    const { isAddTeacherSuccess } = useTeachersStore();
    return {
      teacherStore,
      isAddTeacherFail,
      isAddTeacherSuccess,
    };
  },
  mounted() {
    if (this.teacherStore.isAddTeacherSuccess) {
      this.$swal({
        icon: "success",
        title: "Teacher added successfully",
        showConfirmButton: false,
        timer: 1500,
        willClose: () => {
          this.teacherStore.isAddTeacherFail = false;
          this.teacherStore.isAddTeacherSuccess = false;
          this.teacherStore.isShowEditor = false;
        },
      }).then(() => {
        router.push("/admin/teachers");
      });
    }
  },
};
</script>
