<template>
  <div>
    <metainfo>
      <template v-slot:title="{ content }">
        {{ content ? `${content} | RAYHANE` : `SITE_NAME` }}
      </template>
    </metainfo>
    <div class="flex flex-wrap mt-4">
      <div class="my-6 mt-24 w-full">
        <div class="flex justify-between items-center">
          <div>
            <h1 class="text-3xl text-black font-semibold">Groups</h1>
            <h1 class="text-xl text-gray font-semibold">Manage your Groups</h1>
          </div>
          <div class="flex items-center">
            <router-link to="/admin/groups/new">
              <button class="btn bg-cyan font-bold">+ New Group</button>
            </router-link>
          </div>
        </div>
        <loader-modal v-if="groupsStore.isLoadingModal" />
        <div class="w-full">
          <div class="flex flex-row-reverse mt-4"></div>
          <groups-table />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoaderModal from "@/components/modals/LoaderModal.vue";
import GroupsTable from "@/components/tables/GroupsTable.vue";
import { useGroupsStore } from "@/store/groups";
import { useMeta } from "vue-meta";

export default {
  name: "groups-page",
  components: {
    GroupsTable,
    LoaderModal,
  },
  setup() {
    useMeta({
      title: "Groups",
      htmlAttrs: { lang: "en", amp: true },
    });
    const groupsStore = useGroupsStore();
    return {
      groupsStore,
    };
  },
};
</script>
