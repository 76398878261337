<template>
  <div></div>
</template>
<script>
import { useAffiliationsStore } from "../../../store/affiliations";
export default {
  setup() {
    const affiliationStore = useAffiliationsStore();
    const { isEditAffiliationFail, isEditAffiliationSuccess } =
      useAffiliationsStore();
    return {
      affiliationStore,
      isEditAffiliationFail,
      isEditAffiliationSuccess,
    };
  },
  mounted() {
    if (this.affiliationStore.isEditAffiliationFail) {
      this.$swal({
        icon: "error",
        title: "Something wrong has happened",
        html: `<ul>
            ${this.affiliationStore.errorMessages.map((error) => {
          return error.map((message) => {
            return `<li class="text-red-500">
                      ${message}
                    </li>`
          })
        })}
          </ul>`,
        willClose: () => {
          this.affiliationStore.isEditAffiliationFail = false;
          this.affiliationStore.isEditAffiliationSuccess = false;
          this.affiliationStore.errorMessages = []
        },
      });
    }
  },
};
</script>
