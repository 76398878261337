<template>
  <div
    class="flex justify-center items-center p-2 rounded-xl"
    :class="isSelected ? 'bg-[#00DDAF]' : 'bg-white'"
  >
    <svg
      width="23"
      height="18"
      viewBox="0 0 23 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4_780"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="23"
        height="18"
      >
        <path
          d="M20 1H3C2.17157 1 1.5 1.67157 1.5 2.5V15.5C1.5 16.3284 2.17157 17 3 17H20C20.8284 17 21.5 16.3284 21.5 15.5V2.5C21.5 1.67157 20.8284 1 20 1Z"
          stroke="white"
          stroke-width="2"
          stroke-linejoin="round"
        />
        <path
          d="M1.5 2.5C1.5 2.10218 1.65804 1.72064 1.93934 1.43934C2.22064 1.15804 2.60218 1 3 1H20C20.3978 1 20.7794 1.15804 21.0607 1.43934C21.342 1.72064 21.5 2.10218 21.5 2.5V7H1.5V2.5Z"
          :fill="color"
          stroke="white"
          stroke-width="2"
        />
        <path
          d="M3.5 4C3.5 3.44772 3.94772 3 4.5 3C5.05228 3 5.5 3.44772 5.5 4C5.5 4.55228 5.05228 5 4.5 5C3.94772 5 3.5 4.55228 3.5 4Z"
          :fill="color"
        />
        <path
          d="M6.5 4C6.5 3.44772 6.94772 3 7.5 3C8.05228 3 8.5 3.44772 8.5 4C8.5 4.55228 8.05228 5 7.5 5C6.94772 5 6.5 4.55228 6.5 4Z"
          :fill="color"
        />
      </mask>
      <g mask="url(#mask0_4_780)">
        <path d="M-0.5 -3H23.5V21H-0.5V-3Z" :fill="color" />
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  props: ["color", "isSelected"],
};
</script>
<style></style>
