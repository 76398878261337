<template>
  <div></div>
</template>
<script>
import { useProfileStore } from "@/store/profile";
export default {
  setup() {
    const profileStore = useProfileStore();
    const { isSuccessEdit, isFailUpdate } = useProfileStore();
    return {
      profileStore,
      isSuccessEdit,
      isFailUpdate,
    };
  },
  mounted() {
    if (this.profileStore) {
      this.$swal({
        icon: "error",
        title: "this is error message",
        showConfirmButton: true,
        timer: 2000,
        willClose: () => {
          this.profileStore.isFailUpdate = false;
          this.profileStore.isSuccessEdit = false;
        },
      });
    }
  },
};
</script>
