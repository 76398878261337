<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <div class="my-6 mt-24 w-full">
      <div class="flex justify-between items-center mb-4">
        <div>
          <h1 class="text-3xl text-black font-semibold">Tickets</h1>
          <h1 class="text-xl text-gray font-semibold mt-3">
            Reply to tickets from teachers
          </h1>
        </div>
        <div></div>
      </div>

      <MessagesTable />
    </div>
  </div>
</template>
<script>
import MessagesTable from "@/components/tables/MessagesTable.vue";
import { useMessagesStore } from "@/store/messages";
export default {
  setup() {
    const messagesStore = useMessagesStore();
    return {
      messagesStore,
    };
  },
  components: { MessagesTable },
};
</script>
<style></style>
