Certainly! Here's the modified code to allow only one picture (no array):
```html
<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <div class="w-full">
      <div
        class="bg-purple text-white font-bold text-3xl p-5 rounded-t-xl border-[#c9c9c9] border-b-0 border"
      >
        Partners
      </div>
      <form class="w-full border-[#c9c9c9] border border-t-0 p-4 rounded-xl">
        <div class="">
          <div class="md:mr-6 my-3">
            <p class="text-purple mb-1 text-left">Title</p>
            <input
              v-model="title"
              :class="v$.title.$error ? 'border-red-500 border-[3px]' : ''"
              type="text"
              class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
            />
            <p class="text-red-500" v-if="v$.title.$error">
              You should add a title with at least 3 characters
            </p>
          </div>
        </div>
        <div><p class="text-purple mb-1 text-left">Image</p></div>
        <div class="my-2 mb-4 text-xl rounded-md">
          <label
            class="cursor-pointer w-fit md:flex items-center"
            for="inputTag"
          >
            <img v-show="cover" class="h-40 w-40" ref="output" />
            <button
              v-if="cover"
              @click="removeImage"
              class="m-1 p-1 bg-red-500 text-white rounded-full flex items-center px-4"
            >
              <svg
                width="16"
                height="21"
                class="mr-1"
                viewBox="0 0 16 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.39627 18.4633C1.39627 19.6855 2.39627 20.6855 3.61849 20.6855H12.5074C13.7296 20.6855 14.7296 19.6855 14.7296 18.4633V5.12999H1.39627V18.4633ZM3.61849 7.35221H12.5074V18.4633H3.61849V7.35221ZM11.9518 1.79666L10.8407 0.685547H5.28516L4.17404 1.79666H0.285156V4.01888H15.8407V1.79666H11.9518Z"
                  fill="white"
                />
              </svg>
              Delete
            </button>

            <div
              v-if="!cover"
              :class="coverError ? 'border-red-500' : 'border-slate-300'"
              class="flex flex-col justify-center items-center p-2 h-40 w-40 rounded-md border-dashed border-[3px]"
            >
              <p
                :class="coverError ? 'text-red-500' : 'text-[#8a8a8ac9]'"
                class="mb-1 text-center text-base mt-2"
              >
                Click here to select a file
              </p>

              <input
                class="hidden"
                hidden
                id="inputTag"
                @change="handleCoverChange"
                type="file"
                accept="image/*"
              />
            </div>
          </label>
          <div v-if="coverError" class="text-red-500 text-base">
            <p>Picture is required</p>
          </div>
        </div>
      </form>
      <div class="flex justify-start my-4">
        <button class="btn btn bg-cyan font-bold" @click="addPartner">
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { useLandingPageStore } from "@/store/landingPage";
export default {
  data() {
    return {
      title: "",
      cover: null,
      coverError: false,
    };
  },
  setup() {
    const landingPageStore = useLandingPageStore();
    return { v$: useVuelidate(), landingPageStore };
  },
  methods: {
    async addPartner() {
      this.coverError = false;
      const result = await this.v$.$validate();
      if (!this.cover) {
        this.coverError = true;
      }
      if (!result || this.coverError) {
        return;
      } else {
        console.log(this.cover);
        const formData = new FormData();
        formData.append("name", this.title);
        formData.append("image", this.cover);

        await this.landingPageStore.addPartner(formData);
        if (this.landingPageStore.isSuccess) {
          this.$swal({
            icon: "success",
            timer: 1500,
            title: "Success...",
            showConfirmButton: false,
            html:
              "<p class='text-red-500' >" +
              this.landingPageStore.errorsMessages +
              "<p/>",
          }).then(() => {
            location.reload();
          });
        } else if (this.landingPageStore.isFail) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            showConfirmButton: true,
            timer: 4000,
            html:
              "<p class='text-red-500' >" +
              this.landingPageStore.errorsMessages +
              "<p/>",
          });
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
        this.landingPageStore.isSuccess = false;
        this.landingPageStore.isFail = false;
        this.landingPageStore.errorsMessages = "";
      }
    },
    handleCoverChange(event) {
      this.coverError = false;
      this.cover = event.target.files[0];
      console.log(this.cover);
      let outputPic = this.$refs.output;

      outputPic.src = URL.createObjectURL(this.cover);
      outputPic.width = 200;
    },
    removeImage() {
      this.cover = null;
    },
  },
  validations() {
    return {
      title: { required, minLength: minLength(3) },
    };
  },
};
</script>

<style scoped>
.upload-block {
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A0A0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
