<template>
    <div class="flex flex-wrap mt-4 self-stretch items-stretch">
        <metainfo>
            <template v-slot:title="{ content }">{{
                content ? `${content} | RAYHANE` : `SITE_NAME`
            }}</template>
        </metainfo>
        <LoaderModal v-if="landingPageStore.isLoadingModal" />
        <DeletePartnerModal v-if="landingPageStore.showDeleteModal" />
        <div class="my-6 mt-24 w-full">
            <div v-if="landingPageStore.landingPagePartners?.length > 0">
                <carousel
                    :wrapAround="true"
                    :autoplay="2500"
                    :items-to-show="
                        landingPageStore.landingPagePartners.length > 1 ? 2 : 1
                    "
                >
                    <slide
                        v-for="(
                            partner, index
                        ) in landingPageStore.landingPagePartners"
                        :key="index"
                    >
                        <div class="flex flex-col">
                            <div class="text-3xl mb-2 uppercase">
                                {{ partner.name }}
                            </div>
                            <img
                                :src="partner.img"
                                alt="img"
                                class="w-68 h-48"
                            />
                            <button
                                @click="openDeleteModal(partner.id)"
                                class="btn bg-[#fa6060] font-bold mx-auto my-2"
                            >
                                Delete
                            </button>
                        </div>
                    </slide>

                    <template #addons>
                        <navigation />
                        <pagination />
                    </template>
                </carousel>
            </div>
            <div class="flex items-center">
                <router-link to="/admin/landingPage/partners">
                    <div
                        :class="
                            $route.fullPath == '/admin/landingPage/partners'
                                ? 'text-black border-b-4 border-black'
                                : 'text-gray'
                        "
                        class="font-semibold text-md mr-3 flex items-center cursor-pointer"
                    >
                        Partners
                    </div></router-link
                >
                <router-link to="/admin/landingPage/reviews">
                    <div
                        :class="
                            $route.fullPath == '/admin/landingPage/reviews'
                                ? 'text-black border-b-4 border-black'
                                : 'text-gray'
                        "
                        class="font-semibold text-md mr-3 flex items-center cursor-pointer"
                    >
                        Reviews
                    </div></router-link
                >
            </div>
            <PartnersCard />
        </div>
    </div>
</template>
<script>
import PartnersCard from "@/components/cards/PartnersCard.vue";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import { useLandingPageStore } from "@/store/landingPage";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import DeletePartnerModal from "@/components/modals/landingPage/DeletePartnerModal.vue";
import { useMeta } from "vue-meta";

export default {
    setup() {
        useMeta({
            title: "Landing page partners",
            htmlAttrs: { lang: "en", amp: true },
        });
        const landingPageStore = useLandingPageStore();
        return {
            landingPageStore,
        };
    },
    methods: {
        openDeleteModal(partnerId) {
            this.landingPageStore.showDeleteModal = true;
            this.landingPageStore.deletedPartnerId = partnerId;
        },
    },
    async mounted() {
        await this.landingPageStore.getLandingPagePartners();
    },
    components: {
        PartnersCard,
        LoaderModal,
        Carousel,
        Slide,
        Pagination,
        Navigation,
        DeletePartnerModal,
    },
};
</script>
<style></style>
