<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <LoaderModal v-if="adsStore.isLoadingModal || destinationsStore.isLoadingModal" /><metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | RAYHANE` : `SITE_NAME`
      }}</template>
    </metainfo>
    <div class="my-6 mt-24 w-full">
      <div class="w-full">
        <div class="my-2">
          <h1 class="text-3xl text-black font-semibold">Manage ads</h1>
          <h1 class="text-md text-gray font-semibold mt-2">Manage your ads</h1>
        </div>
        <div
          class="bg-purple text-white font-bold text-3xl p-5 rounded-t-xl border-[#c9c9c9] border-b-0 border"
        >
          New ad
        </div>
        <form class="w-full border-[#c9c9c9] border border-t-0 p-4 rounded-xl">
          <div><p class="text-purple mb-1 text-left">Picture</p></div>
          <div class="my-2 mb-4 text-xl rounded-md">
            <label
              class="cursor-pointer w-fit flex items-center"
              for="inputTag"
            >
              <div
                v-if="!cover"
                :class="coverError ? 'border-red-500' : 'border-slate-300'"
                class="flex flex-col justify-center items-center p-2 h-40 w-60 rounded-md border-dashed border-[3px]"
              >
                <p
                  :class="coverError ? 'text-red-500' : 'text-[#8a8a8ac9]'"
                  class="mb-1 text-center text-base mt-2"
                >
                  Click here to select a file
                </p>

                <input
                  class="hidden"
                  hidden
                  id="inputTag"
                  @change="handleCoverChange"
                  type="file"
                  accept="image/*"
                />
              </div>

              <img v-show="cover" class="h-40 w-60" ref="output" />
              <img
                @click="removeImage"
                :src="trash"
                class="ml-2"
                v-if="cover"
                width="40"
              />
            </label>
            <div v-if="coverError" class="text-red-500 text-base">
              <p>Picture is required</p>
            </div>
          </div>
          <div class="">
            <div class="md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Note</p>
              <input
                v-model="note"
                :class="v$.note.$error ? 'border-red-500 border-[3px]' : ''"
                type="text"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p class="text-red-500" v-if="v$.note.$error">
                You should add a note
              </p>
            </div>
          </div>
          <div>
            <div class="md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Destination</p>
              <!-- <FilterAds class="w-[100%] h-10" /> -->
              <GenericSelectDropdown
                v-model="currentDestination"
                :value="destinationsStore.selectedDestination"
                :options="destinationsStore.destinations"
                :render="(destination) => destination ?? 'Select an option'"
                @updateValue="handleSelectSubmit"
              />
              <p class="text-red-500" v-if="destinationError">
                You should add destination
              </p>
            </div>
          </div>
        </form>
        <div class="flex justify-start my-4">
          <button class="btn btn bg-cyan font-bold" @click="addMod">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useAdsStore } from "@/store/ads";
// import FilterAds from "@/components/dropdowns/filters/ads/FilterAds.vue";
import GenericSelectDropdown from "@/components/cards/partials/GenericSelectDropdown.vue";
import trash from "@/assets/icons/cards/trash.svg";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import router from "@/router";
import { useMeta } from "vue-meta";
import { useDestinationsStore } from "@/store/destinations";

export default {
  data() {
    return {
      note: "",
      cover: null,
      currentDestination: null,
      coverError: false,
    };
  },
  setup() {
    useMeta({
      title: "New Ad",
      htmlAttrs: { lang: "en", amp: true },
    });
    const adsStore = useAdsStore();
    const destinationsStore = useDestinationsStore();
    return { v$: useVuelidate(), adsStore, destinationsStore, trash };
  },
  methods: {
    removeImage() {
      this.$refs.output.src = null;
      this.cover = null;
    },
    handleSelectSubmit(newValue, isValid) {
      this.destinationError = !isValid
      this.adsStore.selectedDestination = newValue
    },
    handleCoverChange(event) {
      this.coverError = false;
      this.cover = event.target.files[0];
      let outputPic = this.$refs.output;
      outputPic.src = URL.createObjectURL(this.cover);
      outputPic.width = 200;
    },
    async addMod() {
      this.coverError = false;
      this.destinationError = false;
      const result = await this.v$.$validate();
      if (!this.cover) {
        this.coverError = true;
      }
      if (!this.adsStore.selectedDestination) {
        this.destinationError = true;
      }
      if (!result || this.coverError || this.destinationError) {
        return;
      } else {
        this.adsStore.isLoadingModal = true;
        const formData = new FormData();
        formData.append("note", this.note);
        formData.append("destination", this.currentDestination);
        formData.append("image", this.cover);
        await this.adsStore.addAd(formData);
        this.adsStore.isLoadingModal = false;
        if (this.adsStore.isSuccess) {
          this.$swal({
            icon: "success",
            timer: 1500,
            title: "Success...",
            showConfirmButton: false,
            html:
              "<p class='text-red-500' >" +
              this.adsStore.errorsMessages +
              "<p/>",
          }).then(() => {
            router.push("/admin/ads");
          });
        } else if (this.adsStore.isFail) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            showConfirmButton: true,
            timer: 4000,
            html:
              "<p class='text-red-500' >" +
              this.adsStore.errorsMessages +
              "<p/>",
          });
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.adsStore.isLoadingModal = false;
        }
        this.adsStore.isSuccess = false;
        this.adsStore.isFail = false;
        this.adsStore.errorsMessages = "";
      }
    },
  },
  validations() {
    return {
      note: { required },
    };
  },
  components: {
    // FilterAds,
    LoaderModal,
    GenericSelectDropdown
  },
  async mounted () {
    await this.destinationsStore.getDestinations();
  }
};
</script>

<style scoped>
.upload-block {
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A0A0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
