<template>
  <div>
    <div class="flex flex-wrap mt-4">
      <div class="my-6 mt-24 w-full">
        <div class="flex justify-between items-center">
          <div>
            <h1 class="text-3xl text-black font-semibold">Teachers</h1>
            <h1 class="text-xl text-gray font-semibold">
              Courses of the teacher
            </h1>
          </div>
        </div>
        <div class="w-full">
          <div class="flex flex-row-reverse mt-4"></div>
          <SingleTeacherCoursesTable />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SingleTeacherCoursesTable from "@/components/tables/SingleTeacherCoursesTable.vue";

export default { components: { SingleTeacherCoursesTable } };
</script>
<style></style>
