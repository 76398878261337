import { defineStore } from "pinia";
import axios from "@/axios/axios";

export const useLivesStore = defineStore("liveStore", {
  state: () => ({
    lives: [],
    lastestLives: [],
    livesCount: 0,
    itemsPerPage: 10,
    totalPages: 0,
    to: 0,
    from: 0,
    currentPage: 1,
    isLoadingModal: false,
    isFail: false,
    isSuccess: false,
    deletedLiveId: null,
    isUpdateError: false,
    isUpdateSuccess: false,
    isShowEditor: false,
    errorMessage: "",
    editedLives: null,
    showDeleteModal: false,
    errorMessages: [],
    singleLive: null,
  }),
  actions: {
    async addLive(live) {
      this.errorMessage = null;
      this.isFail = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const res = await axios.post(
          `${process.env.VUE_APP_API_BASE}live/admin/store`,
          live,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        this.getLives();
        console.log(res);
      } catch (err) {
        this.errorMessages = [];
        console.log(err);
        this.isFail = true;
        this.isLoadingModal = false;
        this.errorMessage = err.response.data.errors;
        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
      }
    },
    async getLives() {
      this.errorMessage = null;
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}live/admin/get?paginate=true&page=${this.currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.lives = data.data.data;
        this.totalPages = data.data.last_page;
        this.from = data.data.from;
        this.to = data.data.to;
        this.isLoadingModal = false;
      } catch (err) {
        this.isLoadingModal = false;
        this.errorMessage = err.response.data.errors;
        console.log(err);
      }
    },
    async getSingleLive(liveId) {
      this.errorMessage = null;
      this.isLoadingModal = false;
      this.isFail = false;
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}live/admin/get/${liveId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.singleLive = data.data;
        this.isLoadingModal = false;
      } catch (err) {
        this.isLoadingModal = false;
        this.isFail = true;
      }
    },
    async deleteLive(liveId) {
      this.errorMessage = null;
      this.isLoadingModal = false;
      this.isFail = false;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}live/admin/delete/${liveId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        this.showDeleteModal = false;
      } catch (err) {
        this.isLoadingModal = false;
        this.isFail = true;
      }
    },
    async updateLive(live, liveId) {
      this.isLoadingModal = true;
      this.isFail = false;
      this.isSuccess = false;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const res = await axios.post(
          `${process.env.VUE_APP_API_BASE}live/admin/update/${liveId}`,
          live,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(res);
        this.isLoadingModal = false;
        this.isSuccess = true;
      } catch (err) {
        this.errorMessages = [];
        this.isLoadingModal = false;
        this.isFail = true;
        this.errorMessage = err.response.data.errors;
        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getLives();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getLives();
      }
    },
  },
});
