<template>
  <div class="flex flex-wrap mt-4">
    <LoaderModal v-if="plansStore.isLoadingModal" />
    <metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | RAYHANE` : `SITE_NAME`
      }}</template>
    </metainfo>
    <div class="my-6 mt-24">
      <div class="flex justify-between items-center font-medium w-full">
        <div class="flex justify-between items-center">
          <div>
            <h1 class="text-3xl text-black font-semibold">Subscription</h1>
            <h1 class="text-xl text-gray font-semibold mt-3">
              Manage your subscription
            </h1>
          </div>
        </div>
      </div>
      <div class="my-6 md:flex w-full">
        <AbonnementCard
          v-for="(plan, index) in plansStore.plans"
          :price="plan.price"
          :id="plan.id"
          :title="plan.title"
          :description="plan.description"
          :options="plan.options"
          :name="plan.name"
          :key="index"
        />
      </div>
    </div>
  </div>
</template>
<script>
import AbonnementCard from "@/components/cards/AbonnementCard.vue";
import separator from "@/assets/icons/cards/separator.svg";
import { useMeta } from "vue-meta";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import { usePlansStore } from "@/store/plans";
export default {
  components: { AbonnementCard, LoaderModal },
  setup() {
    const plansStore = usePlansStore();
    useMeta({
      title: "Subscriptions",
      htmlAttrs: { lang: "en", amp: true },
    });
    return { plansStore, separator };
  },
  async mounted() {
    await this.plansStore.getPlans();
  },
};
</script>
<style></style>
