import { defineStore } from "pinia";
import axios from "@/axios/axios";
export const usePlansStore = defineStore("plansStore", {
  state: () => ({
    isLoadingModal: false,
    isFail: false,
    isSuccess: false,
    editedPlan: null,
    selectedPlan: "player",
    plans: [],
  }),

  actions: {
    async editPlan(plan) {
      console.log(plan);
      this.isFail = false;
      this.isSuccess = false;
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}plan/admin/update`,
          plan,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isSuccess = true;
        this.isLoadingModal = false;
      } catch (err) {
        console.log(err);
        this.isFail = true;
      }
    },
    async getPlans() {
      this.isFail = false;
      this.isSuccess = false;
      try {
        this.isLoadingModal = false;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const res = await axios.get(
          `${process.env.VUE_APP_API_BASE}plan/admin/get`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        this.plans = res.data.data.map((plan) => {
          if (typeof plan === "string") {
            const cleanedStringData = plan.replace("/\\/g", "");
            return JSON.parse(cleanedStringData);
          }
          return plan;
        });

        this.isLoadingModal = false;
      } catch (err) {
        console.log(err);
        this.isFail = true;
      }
    },
  },
});
