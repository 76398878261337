<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <LoaderModal v-if="offersStore.isLoadingModal" />
    <metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | RAYHANE` : `SITE_NAME`
      }}</template>
    </metainfo>
    <div class="my-6 mt-24 w-full">
      <div class="w-full">
        <div class="my-2">
          <h1 class="text-3xl text-black font-semibold">
            Offers & Promo codes
          </h1>
          <h1 class="text-md text-gray font-semibold mt-2">
            Manage your offers
          </h1>
        </div>
        <div
          class="bg-purple text-white font-bold text-3xl p-5 rounded-t-xl border-[#c9c9c9] border-b-0 border"
        >
          Edit promo code
        </div>
        <form
          @submit.prevent
          class="w-full border-[#c9c9c9] border border-t-0 p-4 rounded-xl"
        >
          <div>
            <p class="text-purple mb-1 text-left">Picture</p>
          </div>
          <div class="my-2 mb-4 text-xl rounded-md">
            <label
              class="cursor-pointer w-fit flex items-center"
              for="inputTag"
            >
              <div
                v-if="!cover"
                :class="coverError ? 'border-red-500' : 'border-slate-300'"
                class="flex flex-col justify-center items-center p-2 h-40 w-60 rounded-md border-dashed border-[3px]"
              >
                <p
                  :class="coverError ? 'text-red-500' : 'text-[#8a8a8ac9]'"
                  class="mb-1 text-center text-base mt-2"
                >
                  Click here to select a file
                </p>

                <input
                  class="hidden"
                  hidden
                  id="inputTag"
                  @change="handleCoverChange"
                  type="file"
                  accept="image/*"
                />
              </div>

              <img
                v-show="cover"
                :src="coverUrl"
                class="h-40 w-60"
                ref="output"
              />
              <img
                @click="removeImage"
                :src="trash"
                class="ml-2"
                v-if="cover"
                width="40"
              />
            </label>
            <div v-if="coverError" class="text-red-500 text-base">
              <p>Picture is required</p>
            </div>
          </div>

          <div class="md:flex">
            <div class="md:mr-6 my-3 md:w-6/12">
              <p class="text-purple mb-1 text-left">Promo code title</p>
              <input
                v-model="title"
                :class="{ 'border-red-500 border-[3px]': v$.title.$error }"
                type="text"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p class="text-red-500" v-if="v$.title.$error">
                You should add a title
              </p>
            </div>
            <!-- // until destination is back -->
            <div class="md:w-6/12 md:mr-6 my-3" v-if="false">
              <p class="text-purple mb-1 text-left">Destination</p>
              <SelectDestinationsDropdown
                v-if="offersStore.singleOffer"
                :defaultDestination="offersStore.singleOffer.destination"
                class="w-[100%]"
              />
              <p class="text-red-500" v-if="destinationError">
                You should choose a destination
              </p>
            </div>
          </div>
          <div class="md:flex">
            <div class="md:mr-6 my-3 md:w-6/12">
              <p class="text-purple mb-1 text-left">Discount percentage</p>
              <input
                v-model="discount"
                min="0"
                :class="{ 'border-red-500 border-[3px]': v$.discount.$error }"
                type="number"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p class="text-red-500" v-if="v$.discount.$error">
                You should select a price
              </p>
            </div>
            <div class="md:mr-6 my-3 md:w-6/12">
              <p class="text-purple mb-1 text-left">Expiration date</p>
              <VueDatePicker
                v-model="expirationDate"
                :min-date="getTomorrow()"
                :format="'yyyy-MM-dd'"
                :enable-time-picker="false"
              />
              <p class="text-red-500" v-if="v$.expirationDate.$error">
                You should choose an expiration date
              </p>
            </div>
          </div>

          <div class="">
            <div class="md:w-12/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Generated Code</p>
              <input
                v-model="generatedCode"
                readonly
                :class="{
                  'border-red-500 border-[3px]': v$.generatedCode.$error,
                }"
                type="text"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p class="text-red-500" v-if="v$.generatedCode.$error">
                Promo code is required
              </p>
            </div>
          </div>
          <button
            class="btn btn bg-cyan font-bold"
            @click.prevent="generatePromoCode"
          >
            Generate Promo Code
          </button>
        </form>
        <div class="flex justify-start my-4">
          <button
            class="btn btn bg-cyan font-bold ml-4"
            @click="updatePromoCode"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, minValue, maxValue } from "@vuelidate/validators";
import { useOffersStore } from "@/store/offers";
import trash from "@/assets/icons/cards/trash.svg";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import SelectDestinationsDropdown from "@/components/cards/partials/promoCodes/SelectDestinationsDropdown.vue";
import { useMeta } from "vue-meta";
import router from "@/router";
export default {
  data() {
    return {
      cover: null,
      title: "",
      destination: "",
      discount: 0,
      expirationDate: null,
      generatedCode: null,
      coverUrl: "", // Added a coverUrl property to display the image preview
      coverError: false, // Added coverError property to track if the cover is required
      destinationError: false,
    };
  },
  setup() {
    useMeta({
      title: "Edit promo code",
      htmlAttrs: { lang: "en", amp: true },
    });
    const offersStore = useOffersStore();
    return { v$: useVuelidate(), offersStore, trash };
  },

  watch: {
    expirationDate(newDate) {
      console.log(newDate);
    },
  },

  async mounted() {
    await this.offersStore.getSingleOffer(this.$route.params.id);
    console.log(this.offersStore.singleOffer);
    this.title = this.offersStore.singleOffer.title;
    this.destination = this.offersStore.singleOffer.destination;
    this.offersStore.newDestination = this.offersStore.singleOffer.destination;
    this.discount = this.offersStore.singleOffer.discount;
    this.expirationDate = new Date(
      this.offersStore.singleOffer.expiration_date
    );
    this.generatedCode = this.offersStore.singleOffer.code;
    this.cover = this.offersStore.singleOffer.image
      ? this.offersStore.singleOffer.image.url
      : "";
    this.coverUrl = this.offersStore.singleOffer.image
      ? this.offersStore.singleOffer.image.url
      : "";
    this.$refs.output.src = this.coverUrl;
    this.coverError = false;
    this.destinationError = false;
  },

  methods: {
    dateFormat(newDate) {
      const day = newDate.getDate();
      const month = newDate.getMonth() + 1;
      const year = newDate.getFullYear();
      return `${year}-${month}-${day}`;
    },

    handleCoverChange(event) {
      this.cover = event.target.files[0];
      this.coverUrl = URL.createObjectURL(this.cover);
      this.coverError = false; // Reset cover error when a new image is selected
    },

    removeImage() {
      this.$refs.output.src = null;
      this.cover = null;
    },

    getTomorrow() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow;
    },

    async generatePromoCode() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let promoCode = "";
      for (let i = 0; i < 6; i++) {
        promoCode += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return (this.generatedCode = promoCode);
    },

    async updatePromoCode() {
      this.destinationError = false;
      try {
        const result = await this.v$.$validate();

        // until destination is back -->
        // if (!this.offersStore.newDestination) {
        //   this.destinationError = true;
        //   return;
        // }
        if (!this.cover) {
          this.coverError = true;
        }
        if (!result) {
          return;
        }

        const formData = new FormData();
        formData.append("title", this.title);
        if (this.cover instanceof File) formData.append("image", this.cover);
        formData.append(
          "expiration_date",
          this.dateFormat(this.expirationDate)
        );
        formData.append("code", this.generatedCode);
        formData.append("discount", this.discount);

        // until destination is back -->
        //formData.append("destination", this.offersStore.newDestination);

        await this.offersStore.editPromoCode(formData, this.$route.params.id);
        if (this.offersStore.isSuccess) {
          this.$swal({
            icon: "success",
            timer: 1500,
            title: "Success...",
            showConfirmButton: false,
            html: "<p class='text-green-500' > Promo code updated successfully<p/>",
          }).then(() => {
            router.push("/admin/offers/promo");
          });
        } else if (this.offersStore.isFail) {
          this.$swal({
            icon: "error",
            title: "Error",
            showConfirmButton: true,
            timer: 4000,
            html:
              "<p class='text-red-500' >" +
              this.offersStore.errorMessages +
              "<p/>",
          });
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
        this.offersStore.isSuccess = false;
        this.offersStore.isFail = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  validations() {
    return {
      title: { required, minLength: minLength(1) },
      generatedCode: { required, minLength: minLength(6) },
      expirationDate: { required },
      discount: { required, minValue: minValue(0), maxValue: maxValue(100) },
    };
  },
  components: { LoaderModal, VueDatePicker, SelectDestinationsDropdown },
};
</script>

<style scoped>
.upload-block {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A0A0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
