import { defineStore } from "pinia";
import axios from "@/axios/axios";

export const useOptionsStore = defineStore("optionsStore", {
  state: () => ({
    options: [],
    optionsMulti: [],
    lastestOption: [],
    optionsCount: 0,
    itemsPerPage: 10,
    totalPages: 0,
    to: 0,
    from: 0,
    currentPage: 1,
    isSuccess: false,
    isFail: false,
    isLoadingModal: false,
    isFailModal: false,
    deleteError: false,
    addError: false,
    addSuccess: false,
    isUpdateError: false,
    isUpdateSuccess: false,
    isShowEditor: false,
    errorMessages: [],
    errorMessage: "",
    editedOption: null,
    selectedOption: null,
    showDeleteModal: false,
  }),
  actions: {
    async getOptions() {
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;

        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}option/admin/get`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.options = data;
        this.optionsMulti = data.map((option) => ({
          value: option.id,
          label: option.name,
        }));
        this.isLoadingModal = false;
      } catch (err) {
        console.log(err);
        this.isLoadingModal = false;
      }
    },
  },
});
