<template>
  <div class=""></div>
</template>
<script>
import loading from "@/assets/icons/cards/loading.gif";
import { useCoursesStore } from "@/store/courses";

export default {
  setup() {
    const coursesStore = useCoursesStore();
    return {
      loading,
      coursesStore,
    };
  },

  props: ["isNewCourse"],
  mounted() {
    this.showSweetAlert();
  },
  methods: {
    showSweetAlert() {
      this.$swal({
        html: `
  <div class="">
 
    <img
      width="100"
      class="mx-auto"
      height="100"
      src="/img/loading.a9a09ae4.gif"
    />
  </div>
`,
        buttons: false,
        closeOnClickOutside: false,
        showConfirmButton: false,
      });
    },
    closeSweetAlert() {
      this.$swal.close();
    },
  },
  beforeUnmount() {
    this.closeSweetAlert();
  },
};
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.8s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 500px;
  margin: 0px auto;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
