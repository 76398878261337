<template>
    <div>
        <div
            class="relative min-w-0 break-words w-full mb-6 shadow-md mt-4 rounded-xl border border-[#e8e8e8c9] bg-white"
        >
            <LoaderModal v-if="codsStore.isLoadingModal" />
            <div class="block w-full overflow-x-auto">
                <div
                    v-if="!codsStore?.cods.length > 0"
                    class="flex flex-col justify-center items-center py-10"
                >
                    <img :src="noRecords" alt="noRecords" class="w-2/6" />
                    <div class="text-xl text-[#747373]">
                        No codes at the moment !
                    </div>
                </div>
                <table
                    class="items-center w-full bg-transparent border border-[#e8e8e8c9] rounded-xl overflow-hidden"
                    v-else
                >
                    <thead class="">
                        <tr class="border-[#e8e8e8c9] border">
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-left"
                            >
                                User
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
                            >
                                Code
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
                            >
                                Amount
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
                            >
                                Finishing date
                            </th>
                            <th
                                class="text-purple px-6 align-middle py-6 uppercase font-medium text-center"
                            >
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(cod, index) in codsStore.cods" :key="index">
                            <td
                                class="align-middle p-4 text-purple flex items-center"
                            >
                                <img
                                    v-if="!cod?.student?.user?.image?.url"
                                    :src="avatarUser"
                                    class="w-10 h-10 mr-2"
                                    alt="dfsd"
                                />
                                <img
                                    v-if="cod?.student?.user?.image?.url"
                                    :src="cod?.student?.user?.image?.url"
                                    class="w-10 h-10 mr-2"
                                    alt="dfsd"
                                />
                                {{
                                    cod?.student?.user?.first_name +
                                    " " +
                                    cod?.student?.user?.last_name
                                }}
                            </td>
                            <td
                                class="align-middle whitespace-nowrap p-4 text-center"
                            >
                                <p>{{ cod.code }}</p>
                            </td>

                            <td class="align-middle p-4 text-center">
                                <p>{{ cod.amount }} DA</p>
                            </td>

                            <td class="align-middle text-center">
                                <p>{{ cod.expiration_date }}</p>
                            </td>

                            <td class="align-middle text-center">
                                <CodsDropdown :cod="cod" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="flex justify-between items-center">
            <div>
                Showing {{ codsStore.from }}-{{ codsStore.to }} from
                {{ codsStore.totalPages }} data
            </div>
            <div class="flex items-center">
                <div v-if="codsStore.currentPage != 1">
                    <img
                        @click="codsStore.previousPage()"
                        :src="forward"
                        class="cursor-pointer"
                        alt="forward"
                    />
                </div>

                <div
                    v-if="codsStore.currentPage > 1"
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
                    :class="{
                        'text-gray border border-gray': true,
                        'bg-cyan text-white border-cyan': false,
                    }"
                    @click="codsStore.previousPage()"
                >
                    {{ codsStore.currentPage - 1 }}
                </div>

                <div
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12"
                    :class="
                        true
                            ? 'bg-cyan text-white border-cyan'
                            : ' text-gray border border-gray'
                    "
                >
                    {{ codsStore.currentPage }}
                </div>

                <div
                    v-if="
                        codsStore.currentPage + 1 <=
                        Math.ceil(codsStore.totalPages / codsStore.itemsPerPage)
                    "
                    class="flex justify-center items-center mx-1 font-bold text-xl rounded-full w-12 h-12 cursor-pointer"
                    :class="{
                        'text-gray border border-gray': true,
                        'bg-cyan text-white border-cyan': false,
                    }"
                    @click="codsStore.nextPage()"
                >
                    {{ codsStore.currentPage + 1 }}
                </div>

                <div
                    v-if="
                        codsStore.currentPage + 1 <=
                        Math.ceil(codsStore.totalPages / codsStore.itemsPerPage)
                    "
                >
                    <img
                        class="cursor-pointer"
                        @click="codsStore.nextPage()"
                        :src="back"
                        alt="back"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import checkbox from "@/assets/icons/cards/checkbox.svg";
import separator from "@/assets/icons/cards/separator.svg";
import deleteReview from "@/assets/icons/cards/deleteReview.svg";
import avatarUser from "@/assets/icons/cards/avatarUser.svg";
import back from "@/assets/icons/cards/back.svg";
import forward from "@/assets/icons/cards/forward.svg";
import noRecords from "@/assets/images/noRecords.svg";
import { useCodsStore } from "@/store/cods";
import CodsDropdown from "../dropdowns/CodsDropdown.vue";
import LoaderModal from "../modals/LoaderModal.vue";
export default {
    components: { CodsDropdown, LoaderModal },
    data() {
        return {};
    },
    async mounted() {
        await this.codsStore.getCods();
    },
    setup() {
        const codsStore = useCodsStore();
        return {
            back,
            forward,
            checkbox,
            avatarUser,
            deleteReview,
            separator,
            codsStore,
            noRecords,
        };
    },
};
</script>
