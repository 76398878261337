import { defineStore } from "pinia";
import axios from "@/axios/axios";

export const useReviewsTeachersAndCourses = defineStore(
  "ReviewsTeachersAndCourses",
  {
    state: () => ({
      teachersReviews: [],
      coursesReviews: [],
      isLoadingModal: false,
      showReviewsTeachers: false,
      itemsPerPage: 10,
      currentPage: 1,
      to: 0,
      from: 0,
      totalPages: 0,
      totalRecords: 0,
      showDeleteModal: false,
      isFail: false,
      isSuccess: false,
    }),
    actions: {
      async getTeachersReviews() {
        try {
          this.isLoadingModal = true;
          const admin = localStorage.getItem("rayhane-admin");
          const token = JSON.parse(admin).token;
          const { data } = await axios.get(
            `${process.env.VUE_APP_API_BASE}teacher/admin/comment/get?paginate=true&page=${this.currentPage}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          this.teachersReviews = data.data.data;
          this.totalPages = data.data.meta.last_page;
          this.currentPage = data.data.meta.current_page;
          this.totalRecords = data.data.meta.total;
          this.from = data.data.meta.from;
          this.to = data.data.meta.to;
          this.isLoadingModal = false;
        } catch (err) {
          console.log(err);
          this.isLoadingModal = false;
        }
      },
      async getCoursesReviews() {
        try {
          this.isLoadingModal = true;
          const admin = localStorage.getItem("rayhane-admin");
          const token = JSON.parse(admin).token;
          const { data } = await axios.get(
            `${process.env.VUE_APP_API_BASE}course/admin/comment/get?paginate=true&page=${this.currentPage}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          this.coursesReviews = data.data.data;
          this.totalPages = data.data.last_page;
          this.currentPage = data.data.current_page;
          this.totalRecords = data.data.total;
          this.from = data.data.from;
          this.to = data.data.to;
          this.isLoadingModal = false;
        } catch (err) {
          console.log(err);

          this.isLoadingModal = false;
        }
      },
      async deleteReview(reviewId) {
        try {
          this.isFail = false;
          this.isSuccess = false;

          this.isLoadingModal = true;
          const admin = localStorage.getItem("rayhane-admin");
          const token = JSON.parse(admin).token;
          if (this.showReviewsTeachers) {
            await axios.post(
              `${process.env.VUE_APP_API_BASE}teacher/admin/comment/delete/${reviewId}`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else {
            await axios.post(
              `${process.env.VUE_APP_API_BASE}course/admin/comment/delete/${reviewId}`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
          this.isLoadingModal = false;
          this.showDeleteModal = false;
          this.isSuccess = true;

          await this.getTeachersReviews();
          await this.getCoursesReviews();
        } catch (err) {
          this.showDeleteModal = false;
          this.isLoadingModal = false;
          this.isFail = true;
        }
      },

      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
          if (this.showReviewsTeachers) {
            this.getTeachersReviews()
          } else {
            this.getCoursesReviews()
          }
        }
      },
      previousPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
          if (this.showReviewsTeachers) {
            this.getTeachersReviews()
          } else {
            this.getCoursesReviews()
          }
        }
      },
    },
  }
);
