<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <GroupLoaderModal :isNewGroup="true" v-if="groupsStore.isLoadingModal" />
    <metainfo>
      <template v-slot:title="{ content }">
        {{ content ? `${content} | RAYHANE` : `SITE_NAME` }}
      </template>
    </metainfo>
    <div class="my-6 mt-24 w-full">
      <InformationForm
        :groupInfo="groupInfo"
        @getGroupInfo="getGroupInfo"
        @updateValidity="updateGroupInfoValidity"
        :isValid="groupInfoValid"
        :isNew="true"
      />
      <button
        :disabled="!groupInfoValid"
        :class="`btn btn bg-cyan font-bold my-2 ${
          !groupInfoValid ? 'opacity-50' : 'opacity-100'
        }`"
        @click="addGroup"
      >
        Save
      </button>
    </div>
  </div>
</template>
<script>
import InformationForm from "@/components/cards/groups/InformationForm.vue";
import { useCategoriesStore } from "@/store/categories";
import { useGroupsStore } from "@/store/groups";
import { useOptionsStore } from "@/store/options";
import { useMeta } from "vue-meta";
import { useDestinationsStore } from "@/store/destinations";
import { useSubjectsStore } from "@/store/subjects";
import { useTeachersStore } from "@/store/teachers";
import GroupLoaderModal from "@/components/modals/GroupLoaderModal.vue";

export default {
  setup() {
    useMeta({
      title: "New group",
      htmlAttrs: { lang: "en", amp: true },
    });
    const categoriesStore = useCategoriesStore();
    const groupsStore = useGroupsStore();
    const teachersStore = useTeachersStore();
    const destinationsStore = useDestinationsStore();
    const subjectsStore = useSubjectsStore();
    const optionsStore = useOptionsStore();
    return {
      categoriesStore,
      groupsStore,
      teachersStore,
      destinationsStore,
      subjectsStore,
      optionsStore,
    };
  },

  data() {
    return {
      groupInfoValid: false,
      groupInfoFormData: null,
      groupInfo: {
        cover: null,
        group_name: "",
        group_date: "",
        description: "",
        teacher: null,
        destination: null,
        subject: null,
        option: null,
        price_per_session: null,
        sessions_per_month: null,
        prof_percentage: null,
        max_seats: null,
      },
    };
  },

  async mounted() {
    await Promise.all([
      this.teachersStore.getTeachersWithoutPagination(),
      this.destinationsStore.getDestinations(),
      this.subjectsStore.getSubjects(),
      this.optionsStore.getOptions(),
    ]);
  },
  methods: {
    updateGroupInfoValidity(isFormValid) {
      this.groupInfoValid = isFormValid;
    },

    getGroupInfo(formData) {
      this.groupInfoFormData = formData;
    },

    async addGroup() {
      this.groupsStore.addError = false;
      this.groupsStore.errorMessages = [];

      try {
        this.groupsStore.loaderMessage = "Creating group main data";
        await this.groupsStore.addGroup(this.groupInfoFormData);

        if (this.groupsStore.errorMessages.length) {
          throw new Error("Error");
        }
        this.groupsStore.isLoadingModal = false;
        this.$swal({
          icon: "success",
          title: "Group added successfully",
          showConfirmButton: true,
          willClose: () => {
            this.groupsStore.addError = false;
            this.groupsStore.addSuccess = false;
            this.groupsStore.loaderMessage = "";
            this.$router.push({ path: "/admin/groups" });
          },
        });
      } catch (err) {
        this.$swal({
          icon: "danger",
          title: "Error occured while adding the group !",
          html: `<ul>
            ${this.groupsStore.errorMessages.map((error) => {
              return error.map((message) => {
                return `<li class="text-red-500">${message}</li>`;
              });
            })}
          </ul>`,
          willClose: async () => {
            this.groupsStore.isLoadingModal = false;
            this.groupsStore.addSuccess = false;
            this.groupsStore.addError = false;
            this.groupsStore.errorMessages = [];
          },
        });
        console.log(err);
      }
    },
  },

  components: {
    InformationForm,
    GroupLoaderModal,
  },
};
</script>
<style></style>
