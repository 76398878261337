<template>
  <div class="flex flex-wrap mt-4 self-stretch items-stretch">
    <LoaderModal v-if="codsStore.isLoadingModal || studentsStore.isLoadingModal" />
    <metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | RAYHANE` : `SITE_NAME`
      }}</template>
    </metainfo>

    <div class="my-6 mt-24 w-full">
      <div class="w-full">
        <div class="my-2">
          <h1 class="text-3xl text-black font-semibold">
            Offers & Promo codes
          </h1>
          <h1 class="text-md text-gray font-semibold mt-2">
            Manage your offers
          </h1>
        </div>
        <div
          class="bg-purple text-white font-bold text-3xl p-5 rounded-t-xl border-[#c9c9c9] border-b-0 border"
        >
          New offer
        </div>
        <form class="w-full border-[#c9c9c9] border border-t-0 p-4 rounded-xl">
          <div class="md:flex">
            <div class="md:mr-6 my-3 md:w-6/12">
              <p class="text-purple mb-1 text-left">Amount</p>
              <input
                v-model="amount"
                :class="v$.amount.$error ? 'border-red-500 border-[3px]' : ''"
                type="number"
                min="0"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p class="text-red-500" v-if="v$.amount.$error">
                You should add a valid Amount
              </p>
            </div>
            <div class="md:w-6/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Finishing date</p>
              <VueDatePicker
                v-model="date"
                :min-date="getTomorrow()"
                :format="'yyyy-MM-dd'"
                :enable-time-picker="false"
              ></VueDatePicker>

              <p class="text-red-500" v-if="v$.date.$error">
                You should enter a finishing date
              </p>
            </div>
          </div>
          <div>
            <div class="md:w-12/12 md:mr-6 my-3">
              <p class="text-purple mb-1 text-left">Generated Code</p>
              <input
                v-model="generatedCode"
                readonly
                :class="{
                  'border-red-500 border-[3px]': v$.generatedCode.$error,
                }"
                type="text"
                class="text-gray-700 px-2 leading-tight focus:outline-none border-[#c9c9c9] border rounded-md w-full py-3"
              />
              <p class="text-red-500" v-if="v$.generatedCode.$error">
                Generated Code is required
              </p>
              <button
                class="btn btn bg-cyan font-bold my-5"
                @click.prevent="generatePromoCode"
              >
                Generate Cod
              </button>
            </div>
          </div>
          <div>
            <!-- <SelectStudentDropdown /> -->
            <p class="text-purple mb-1 text-left">Select student</p>
            <GenericSelectDropdown
              v-model="this.codsStore.selectedStudent"
              :value="this.codsStore.selectedStudent"
              :options="this.studentsStore.students"
              :render="(student) => !!student ? student?.user?.first_name + ' ' + student?.user?.last_name : 'Select an option'"
              @updateValue="getNewStudent"
            />
            <p class="text-red-500" v-if="selectedStudentError">
              Please select a student
            </p>
          </div>
        </form>
        <div class="flex justify-start my-4">
          <button class="btn btn bg-cyan font-bold" @click="addCod">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useCodsStore } from "@/store/cods";
import GenericSelectDropdown from "@/components/cards/partials/GenericSelectDropdown.vue";
import { useUsersStore } from "@/store/users";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import { useMeta } from "vue-meta";
import router from "@/router";
export default {
  data() {
    return {
      amount: 0,
      generatedCode: "",
      date: null,
      selectedStudentError: false,
    };
  },
  setup() {
    useMeta({
      title: "New COD",
      htmlAttrs: { lang: "en", amp: true },
    });
    const codsStore = useCodsStore();
    const studentsStore = useUsersStore();
    return { v$: useVuelidate(), codsStore, studentsStore };
  },
  async mounted () {
    await this.studentsStore.getStudents()
  },
  methods: {
    getNewStudent(newValue) {
      this.codsStore.selectedStudent = newValue;
    },
    async addCod() {
      const result = await this.v$.$validate();
      this.selectedStudentError = false;
      if (!this.codsStore.selectedStudent) {
        this.selectedStudentError = true;
        return;
      }
      if (!result) {
        return;
      } else {
        await this.codsStore.addCod({
          amount: this.amount,
          code: this.generatedCode,
          expiration_date: this.dateFormat(this.date),
          student_id: this.codsStore.selectedStudent.user.id,
        });
        if (this.codsStore.isSuccess) {
          this.$swal({
            icon: "success",
            timer: 1500,
            title: "COD added successfully...",
            showConfirmButton: false,
          }).then(() => {
            router.push("/admin/cods");
          });
        } else if (this.codsStore.isFail) {
          this.$swal({
            icon: "error",
            title: "Something wrong has happened...",
            showConfirmButton: false,
            timer: 1500,
          });
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
        this.codsStore.isSuccess = false;
        this.codsStore.isFail = false;
      }
    },

    async generatePromoCode() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let code = "";
      for (let i = 0; i < 7; i++) {
        code += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return (this.generatedCode = code);
    },
    dateFormat(newDate) {
      const day = newDate.getDate();
      const month = newDate.getMonth() + 1;
      const year = newDate.getFullYear();
      return `${year}-${month}-${day}`;
    },

    getTomorrow() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow;
    },
  },
  validations() {
    return {
      amount: { required },
      date: { required },
      generatedCode: { required },
    };
  },
  components: { VueDatePicker, GenericSelectDropdown, LoaderModal },
};
</script>

<style scoped>
.upload-block {
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A0A0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
