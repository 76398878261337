import { defineStore } from "pinia";
import axios from "@/axios/axios";
import { useAuthStore } from "./auth";

export const useCoursesStore = defineStore("courseStore", {
  state: () => ({
    courses: [],
    lastestCourse: [],
    coursesCount: 0,
    itemsPerPage: 10,
    totalPages: 0,
    from: 0,
    to: 0,
    currentPage: 1,
    courseContent: [],
    isLoadingModal: false,
    isFailModal: false,
    pauseError: false,
    addError: false,
    addSuccess: false,
    isUpdateError: false,
    isUpdateSuccess: false,
    isShowEditor: false,
    errorMessages: [],
    errorMessage: "",
    selectedCourse: null,
    newCourse: null,
    editedCourse: null,
    showDeleteModal: false,
    deleteError: false,
    loaderMessage: "",
  }),
  actions: {
    async addCourse(course) {
      this.errorMessage = null;
      this.addError = false;
      this.addSuccess = false;
      this.isLoadingModal = true;

      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const res = await axios.post(
          `${process.env.VUE_APP_API_BASE}course/admin/store`,
          course,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // this.isLoadingModal = false;
        // this.addSuccess = true;
        return res.data.data;
      } catch (err) {
        // this.addError = true;
        // this.isLoadingModal = false;
        this.errorMessage = err.response.data.errors;
        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
        console.log(err);
      }
    },

    async addLesson(lesson) {
      this.errorMessage = null;

      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const res = await axios.post(
          `${process.env.VUE_APP_API_BASE}course/admin/lesson/store/attachment`,
          lesson,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return res.data;
      } catch (err) {
        this.addError = true;
        this.isLoadingModal = false;
        this.errorMessage = err.response.data.errors;
        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
        console.log(err);
      }
    },

    async updateLesson(lesson) {
      this.errorMessage = null;

      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const res = await axios.post(
          `${process.env.VUE_APP_API_BASE}course/admin/lesson/update/attachment`,
          lesson,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        return res.data.data;
      } catch (err) {
        this.isUpdateError = true;
        this.isLoadingModal = false;
        this.errorMessage = err.response.data.errors;
        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
        console.log(err);
      }
    },

    async removeLesson(lesson) {
      this.errorMessage = null;

      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const res = await axios.post(
          `${process.env.VUE_APP_API_BASE}course/admin/lesson/delete/${lesson}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        return res.data.data;
      } catch (err) {
        this.deleteError = true;
        this.isLoadingModal = false;
        this.errorMessage = err.response.data.errors;
        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
        console.log(err);
      }
    },

    async reorderLessons(order) {
      this.errorMessage = null;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const res = await axios.post(
          `${process.env.VUE_APP_API_BASE}course/admin/lesson/re-order`,
          { order },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        return res.data.data;
      } catch (err) {
        this.addError = true;
        this.isLoadingModal = false;
        this.errorMessage = err.response.data.errors;
        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
        console.log(err);
      }
    },

    async getCourses(query) {
      this.errorMessage = null;
      const authStore = useAuthStore();

      try {
        if (!query) {
          this.isLoadingModal = true;
        } else {
          authStore.isLoading = true;
        }
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}course/admin/get?paginate=true&page=${
            this.currentPage
          }&q=${query ? `&q=${encodeURIComponent(query)}` : ""}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.courses = data.data.data;
        this.from = data.data.meta.from;
        this.to = data.data.meta.to;
        this.totalPages = data.data.meta.total;
        this.currentPage = data.data.meta.current_page;
        this.isLoadingModal = false;
        authStore.isLoading = false;
      } catch (err) {
        this.isLoadingModal = false;
        authStore.isLoading = false;
        this.errorMessage = err.response.data.errors;
        console.log(err);
      }
    },

    async getCourse(courseId) {
      this.errorMessage = null;
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}course/admin/get/${courseId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.editedCourse = data.data;
        return data.data;
      } catch (err) {
        this.isLoadingModal = false;
        this.errorMessage = err.response.data.errors;
        console.log(err);
      }
    },

    async getCourseImage(url) {
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return data;
      } catch (err) {
        console.log(err);
      }
    },

    async pauseCourse(courseId) {
      this.errorMessage = null;
      this.pauseError = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;

        await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/courses/pause/${courseId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        await this.getCourses();
      } catch (err) {
        this.isLoadingModal = false;
        this.pauseError = true;
        console.log(err);
      }
    },
    async activateCourse(courseId) {
      this.errorMessage = null;
      this.isLoadingModal = true;
      this.pauseError = false;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/courses/unpause/${courseId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        await this.getCourses();
      } catch (err) {
        this.isLoadingModal = false;
        this.pauseError = true;
        console.log(err);
      }
    },
    async deleteCourse(courseId) {
      this.errorMessage = null;
      this.deleteError = false;
      this.isLoadingModal = true;
      this.pauseError = false;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}course/admin/delete/${courseId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        await this.getCourses();
      } catch (err) {
        this.isLoadingModal = false;
        this.deleteError = true;
        this.pauseError = true;
      }
    },

    async deleteNewCourse(courseId) {
      this.errorMessage = null;
      this.deleteError = false;
      this.isLoadingModal = true;
      this.pauseError = false;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}course/admin/delete/${courseId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
      } catch (err) {
        this.isLoadingModal = false;
        this.deleteError = true;
        this.pauseError = true;
      }
    },

    async updateCourse(course, courseId) {
      this.errorMessages = [];
      this.errorMessage = null;
      this.isUpdateError = false;
      this.isUpdateSuccess = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("rayhane-admin");
        const token = JSON.parse(admin).token;
        const res = await axios.post(
          `${process.env.VUE_APP_API_BASE}course/admin/update/${courseId}`,
          course,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isUpdateSuccess = true;
        return res.data.data;
      } catch (err) {
        this.errorMessage = err.response.data.errors;
        for (var key in err.response.data.errors) {
          this.errorMessages.push(err.response.data.errors[key]);
        }
        console.log(err);
        this.isUpdateError = true;
        this.isLoadingModal = false;
        throw err;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getCourses();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getCourses();
      }
    },
  },
});
