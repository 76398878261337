<template>
  <div class="user-actions w-10 mx-auto" @click="openDeleteModal">
    <a class="text-blueGray-500 block" href="#pablo" ref="btnDropdownRef">
      <div class="items-center flex justify-center">
        <img :src="deleteIcon" alt="actions" />
      </div>
    </a>
  </div>
</template>

<script>
import deleteIcon from "@/assets/icons/cards/deleteReview.svg";
import editIcon from "@/assets/icons/cards/editIcon.svg";
import { useAdsStore } from "@/store/ads";

export default {
  props: ["ad"],
  data() {
    return {
      dropdownPopoverShow: false,
      showBlockModal: false,
      showUnBlockModal: false,
    };
  },
  setup() {
    const adsStore = useAdsStore();
    return {
      adsStore,
      editIcon,
      deleteIcon,
    };
  },
  methods: {
    openDeleteModal() {
      this.adsStore.deletedAd = this.ad.id;
      this.adsStore.showDeleteModal = true;
    },
  },
};
</script>
