<template>
  <div
    class="flex justify-center items-center p-2 rounded-xl"
    :class="isSelected ? 'bg-[#00DDAF]' : 'bg-white'"
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 16L0.85 19.15C0.683333 19.3167 0.5 19.3583 0.3 19.275C0.0999999 19.1917 0 19.0333 0 18.8V2C0 1.45 0.196 0.979002 0.588 0.587002C0.98 0.195002 1.45067 -0.000664969 2 1.69779e-06H18C18.55 1.69779e-06 19.021 0.196002 19.413 0.588002C19.805 0.980002 20.0007 1.45067 20 2V14C20 14.55 19.804 15.021 19.412 15.413C19.02 15.805 18.5493 16.0007 18 16H4ZM10 10.475L11.9 11.625C12.0833 11.7417 12.2667 11.7373 12.45 11.612C12.6333 11.4867 12.7 11.316 12.65 11.1L12.15 8.925L13.85 7.45C14.0167 7.3 14.0667 7.121 14 6.913C13.9333 6.705 13.7833 6.59234 13.55 6.575L11.325 6.4L10.45 4.35C10.3667 4.15 10.2167 4.05 10 4.05C9.78333 4.05 9.63333 4.15 9.55 4.35L8.675 6.4L6.45 6.575C6.21667 6.59167 6.06667 6.70434 6 6.913C5.93333 7.12167 5.98333 7.30067 6.15 7.45L7.85 8.925L7.35 11.1C7.3 11.3167 7.36667 11.4877 7.55 11.613C7.73333 11.7383 7.91667 11.7423 8.1 11.625L10 10.475Z"
        :fill="color"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: ["color", "isSelected"],
};
</script>
<style></style>
