<template>
  <div>
    <div
      class="relative min-w-0 break-words w-full mb-6 shadow-md mt-4 rounded-xl border border-[#e8e8e8c9] bg-white"
    >
      <div class="block w-full overflow-x-auto">
        <table
          class="items-center w-full bg-transparent border border-[#e8e8e8c9] rounded-xl overflow-hidden"
          v-if="adsStore.ads.length > 0"
        >
          <thead class="">
            <tr class="border-[#e8e8e8c9] border">
              <th
                class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
              >
                Ad picture
              </th>
              <th
                class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
              >
                Added Date
              </th>
              <th
                class="text-purple px-4 align-middle py-6 uppercase font-medium text-left"
              >
                The Note
              </th>
              <th
                class="text-purple px-4 align-middle py-6 uppercase font-medium text-center"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(ad, index) in adsStore.ads.sort(compareDates)" :key="index">
              <td
                class="align-middle p-4 text-purple font-medium flex items-center justify-center"
              >
                <div>
                  <img
                    :src="ad.image.url"
                    class="md:mx-auto md:h-[100px] md:w-[200px]"
                    alt="addBg"
                  />
                </div>
              </td>
              <td class="align-middle whitespace-nowrap text-left">
                <p>{{ ad.created_at.slice(0, 10) }}</p>
              </td>

              <td class="align-middle whitespace-nowrap p-4 text-left">
                <p>{{ ad.note }}</p>
              </td>

              <td class="align-middle text-center">
                <AdsDropdown :ad="ad" />
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="flex flex-col justify-center items-center py-10">
          <img :src="noRecords" alt="noRecords" class="w-2/6" />
          <div class="text-xl text-[#747373]">No records at the moment !</div>
        </div>
      </div>
    </div>
    <div class="flex justify-between items-center">
      <div>
        Showing
        {{ adsStore.ads.length }} data
      </div>
    </div>
  </div>
</template>
<script>
import checkbox from "@/assets/icons/cards/checkbox.svg";
import separator from "@/assets/icons/cards/separator.svg";
import deleteReview from "@/assets/icons/cards/deleteReview.svg";
import avatarUser from "@/assets/icons/cards/avatarUser.svg";
import back from "@/assets/icons/cards/back.svg";
import forward from "@/assets/icons/cards/forward.svg";
import addBg from "@/assets/images/addBg.png";
import noRecords from "@/assets/images/noRecords.svg";
import { useAdsStore } from "@/store/ads";
import AdsDropdown from "../dropdowns/AdsDropdown.vue";
export default {
  components: { AdsDropdown },
  data() {
    return {};
  },
  async mounted() {
    await this.adsStore.getAds();
  },
  setup() {
    const adsStore = useAdsStore();
    return {
      back,
      addBg,
      forward,
      checkbox,
      avatarUser,
      deleteReview,
      separator,
      adsStore,
      noRecords,
    };
  },
  methods: {
    compareDates: (a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);

      return dateB - dateA;
    }
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
