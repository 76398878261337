<template>
  <div
    class="flex login-page 3xl:justify-between xl:justify-between justify-center items-center pt-20 md:pt-0 max-w-[1920px] lg:mx-auto"
  >
    <div class="lg:flex items-center 3xl:w-[55%]">
      <div class="px-4 md:w-[50vw] 2xl:w-[55vw]">
        <router-view />
      </div>
    </div>

    <div
      class="hidden xl:flex justify-end items-center bg-primary w-[45vw] 2x:w-[40vw] 3xl:w-[45%] h-screen"
    >
      <img :src="loginImage" class="h-full 3xl:h-[750px]" alt="ilustration" />
    </div>
  </div>
</template>
<script>
import logo from "@/assets/images/logo.png";
import loginImage from "@/assets/images/loginImage.png";
import { useAuthStore } from "@/store/auth";

export default {
  setup() {
    const authStore = useAuthStore();
    return { logo, loginImage, authStore };
  },
};
</script>
<style scoped>
input::-webkit-input-placeholder {
  color: rgba(41, 44, 48, 0.8);
  font-weight: 400;
}
.socials-logo {
  height: 20px;
}
</style>
